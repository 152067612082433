<template>
  <div class="cpt-command-device-drone-popup">
    <div class="header" v-interact>
      <div class="hd-box">{{ "机巢" + status_info.name }}</div>
      <div class="close_box" @click="() => $emit('handleClose')">关闭</div>
    </div>
    <div class="bd-box">
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd">单位名称</div>
          <div class="tb-hd">机巢名称</div>
          <div class="tb-hd">工作状态</div>
          <div class="tb-hd">设备状态</div>
        </div>
        <div class="tb-bd-box">
          <div class="tb-tr" v-for="item in list" :key="item.id">
            <div class="td">市巡特警支队</div>
            <div class="td">
              {{ item.deviceName || "暂无" }}
            </div>
            <div class="td">
              {{ status_info.name == "在线数量" ? "在线" : "离线" }}
            </div>
            <div class="td" :class="`tdclass${item.deviceStatus}`">
              {{ changeState(item.deviceStatus) || "暂无" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: {
    status_info: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    status_info: {
      handler(value) {
        console.log("----------value", value);
        this.get_list();
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  async mounted() {},
  methods: {
    async get_list() {
      let res = await API.DEVICE.DroneOrNestPopupInfo({ type: 2 });
      console.log("----------res", res);
      if (this.status_info.name == "在线数量") {
        this.list = res.onlineDevice || [];
      } else {
        this.list = res.offlineDevice || [];
      }
    },
    changeState(val) {
      const obj = { 1: "正常", 2: "维修", 3: "损坏", 4: "保养" };
      return obj[val];
    },
  },
};
</script>

<style lang="scss" scoped>
.tdclass1 {
  color: #00b900 !important;
}
.tdclass3 {
  color: red !important;
}
.tdclass2 {
  color: yellow !important;
}
.tdclass5 {
  color: #b3bbc5 !important;
}
.cpt-command-device-drone-popup {
  width: 643px;
  height: 362px;
  box-sizing: border-box;
  position: absolute;
  top: 200px;
  left: 50%;
  margin-left: -321px;
  z-index: 10;
  background: rgba(16, 30, 62, 0.9);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  .header {
    height: 44px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }
  .close_box {
    position: absolute;
    right: 28px;
    top: 13px;
    font-size: 18px;
    color: #00ffff;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 44px;
    padding-left: 20px;
  }
  .bd-box {
    height: calc(100% - 44px);
    box-sizing: border-box;
    padding: 0 10px 15px;
    .tb-box {
      box-sizing: border-box;
      height: 100%;
      .tb-hd-box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        .tb-hd {
          height: 100%;
          flex: 1;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          line-height: 52px;
          text-align: center;
          font-size: 16px;
          color: #00c6ff;
        }
      }
      .tb-bd-box {
        height: calc(100% - 40px);
        overflow: auto;
        .tb-tr {
          display: flex;
          box-sizing: border-box;
          &:hover {
            cursor: pointer;
            background: rgba(0, 34, 140, 0.2);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
            border-radius: 5px;
          }
          .td {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 10px 0;
            text-align: center;
            font-family: MicrosoftYaHei;
            word-break: break-word;
            box-sizing: border-box;
            font-size: 14px;
            color: #dce9ff;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
