<template>
  <div class="monitoringAreaBox" id="monitoringCeisum"></div>
</template>

<script>
import { Utils } from '@/lib/cesium'
import GeoJson from '@/data/geo/geo_yc.json'
import GeoJsonBorder from '@/data/geo/geo_yc_border.json'
import API from '@/api'
import { primitives_3d_tiles, global_leyers, base_layer } from '@/utils/global'

let viewer = null
let plate_data_source = null
let border_data_source = null
let center = null
let LAYERS = null
let envType = process.env.VUE_APP_ENV_TYPE;

switch (envType) {
  case '公网':
    LAYERS = Object.freeze({
      SATELLITE: {
        image: new Cesium.WebMapTileServiceImageryProvider({
          url:
            "https://{s}.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=a5e0a8dcf07f40afe19b76bf8a32eef0",
          layer: "img",
          style: "default",
          format: "tiles",
          tileMatrixSetID: "c",
          credit: new Cesium.Credit("天地图全球影像服务"),
          tilingScheme: new Cesium.GeographicTilingScheme(), //采用经纬度投影
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          tileMatrixLabels: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
          ],
          maximumLevel: 18,
        })
      },
    });
    break;
  //默认为内网配置
  default:
    LAYERS = Object.freeze({
      SATELLITE: {
        image: new Cesium.UrlTemplateImageryProvider({
          url: "http://32.128.12.23:58090/minemap-data/data/satellite/{sz}/{x}/{y}",
          tilingScheme: new Cesium.GeographicTilingScheme(),
          customTags: {
            sz: function (imageryProvider, x, y, level) {
              return level + 1;
            },
          },
        })
      }
    })
}


const MODES = Object.freeze({
  PLATE: Symbol('PLATE'),
  GLOBE: Symbol('GLOBE'),
})

const origin = new Cesium.Cartesian3(
  -2718455.981755302,
  4670924.665227746,
  // 3547699.7600637083
  3540001.2112579183
)

const plate_orign = new Cesium.Cartesian3(
  -2988857.0261368295,
  5044169.936281486,
  3519001.2112579183
)

const plate_hpr = {
  heading: 6.163788972318109,
  pitch: -1.0166554553384142,
  roll: 6.262799873942939,
}

const tinghu2_origin = new Cesium.Cartesian3(
  -2694782.3468037946,
  4621493.91410246,
  3479515.1219724463
)

export default {
  data () {
    return {
      MODES,
      viewer: null
    }
  },
  inject: ['g_show_zysb'],
  mounted () {
    if (!this.viewer) {
      this.init()
    }
  },
  beforeDestroy () {
    if (this.viewer) {
      this.viewer.destroy();
      this.viewer = null;
      // window.mthis = null;
    }
  },
  methods: {

    async init () {
      this.$store.commit('mapmanage/SET_VISIBLE_MAIN_MAP', true);
      await this.init_viewer()
      this.fly_to_globe()
      // await this.init_viewer()
      // this.fly_to_globe()
      // window.mthis = this;


      /* setTimeout(async () => {
         
      }, 5000) */
      // await this.init_plate()
      // await this.init_border()
      // await this.init_wrap_border()
    },

    async init_viewer () {
      // 解决航线被三维模型遮盖
      var oldPolylineUpdate = Cesium.PolylineCollection.prototype.update
      Cesium.PolylineCollection.prototype.update = function (frameState) {
        var oldMorphTime = frameState.morphTime
        frameState.morphTime = 0.0
        oldPolylineUpdate.call(this, frameState)
        frameState.morphTime = oldMorphTime
      }

      // viewer = new Viewer("monitoringAreaBox");
      this.viewer = new Cesium.Viewer('monitoringCeisum', {
        animation: false, //是否显示动画控件
        shouldAnimate: true,
        homeButton: false, //是否显示Home按钮
        fullscreenButton: false, //是否显示全屏按钮
        baseLayerPicker: false, //是否显示图层选择控件
        geocoder: true, //是否显示地名查找控件
        timeline: false, //是否显示时间线控件
        sceneModePicker: true, //是否显示投影方式控件
        navigationHelpButton: false, //是否显示帮助信息控件
        infoBox: false, //是否显示点击要素之后显示的信息
        requestRenderMode: true, //启用请求渲染模式
        maximumRenderTimeChange: Infinity,
        scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
        fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
        selectionIndicator: false,
        // imageryProvider: false,
        // imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
        //   url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer",
        // }),
        /*  imageryProvider: new Cesium.UrlTemplateImageryProvider({
           url: '',
         }), */
      })

      //限制相机最高高度
      this.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 1000000;

      // let services = window.___s3m

      console.log('LAYERS', LAYERS)

      let provider = this.viewer.imageryLayers.addImageryProvider(
        LAYERS['SATELLITE'].image
      );
      provider.show = true;
    },

    init_events () {
      let handler = new Cesium.ScreenSpaceEventHandler(this.viewer.canvas)
      handler.setInputAction((movement) => {
        // 点击事件获得实体
        // let pick = this.viewer.scene.pick(movement.position)

        // if (this.is_plate_mode()) {
        this.on_plate_left_click(movement)
        // }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK)
      handler.setInputAction((movement) => {
        //获取当前相机高度
        // if (this.is_plate_mode()) {
        let height = Math.ceil(this.viewer.camera.positionCartographic.height)
        // console.log('相机高度:', height)
        this.on_plate_wheel(height)
        // }
      }, Cesium.ScreenSpaceEventType.WHEEL)
    },

    async init_tiles () {
      let res = await API.MAPMANAGE.GetLayerList()
      let regexp = /^.*(\.json)$/
      res[1].children.forEach((item) => {
        if (regexp.test(item.url)) {
          let primitive = this.viewer.scene.primitives.add(
            new Cesium.Cesium3DTileset({
              url: item.url,
              // maximumScreenSpaceError: 2, // The maximum screen space error used to drive level of detail refinement. 值越小细节越好，默认16
              maximumScreenSpaceError: 64, // The maximum screen space error used to drive level of detail refinement. 值越小细节越好，默认16
              maximumMemoryUsage: 10240, // The maximum amount of memory in MB that can be used by the tileset. 显存调整到1G，目前低配的计算机显存差不多这个参数
              // skipLevelOfDetail: true
              dynamicScreenSpaceError: true,
              show: false,
            })
          )
          primitives_3d_tiles[item.id] = primitive
        }
      })

      // 调整模型位置
      // tileset.then((model) => {
      //   let cartographic = Cesium.Cartographic.fromCartesian(
      //       model.boundingSphere.center
      //     ),
      //     surface = Cesium.Cartesian3.fromRadians(
      //       cartographic.longitude,
      //       cartographic.latitude,
      //       0
      //     ),
      //     offset = Cesium.Cartesian3.fromRadians(
      //       cartographic.longitude,
      //       cartographic.latitude,
      //       0
      //     ),
      //     translation = Cesium.Cartesian3.subtract(
      //       offset,
      //       surface,
      //       new Cesium.Cartesian3()
      //     );
      //   model.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
      // });
    },

    async init_border () {
      let promise = Cesium.GeoJsonDataSource.load(GeoJsonBorder)
      let dataSource = await promise
      let entities = dataSource.entities.values
      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i]

        entity._name = 'border' + '_' + entity._name
        entity.polygon.outline = true
        entity.polygon.fill = false
        entity.polygon.outlineColor = Cesium.Color.fromCssColorString('#ffd33c')

        let positions = entity.polygon.hierarchy._value.positions

        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item)
            let wgs84 = Utils.transformCartesian2WGS84(c)
            return Utils.transformWGS842Cartesian(wgs84)
          }),
          width: 4,
          material: Cesium.Color.fromCssColorString('#36b558'),
          // material: Cesium.Color.fromCssColorString('#ffb319'),
        }
      }
      await this.viewer.dataSources.add(dataSource)
      dataSource.show = true
      border_data_source = dataSource
    },

    async init_wrap_border () {
      let promise = Cesium.GeoJsonDataSource.load(GeoJson)
      let dataSource = await promise
      let entities = dataSource.entities.values
      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i]

        entity._name = 'border' + '_' + entity._name
        entity.polygon.outline = true
        entity.polygon.fill = false
        entity.polygon.outlineColor = Cesium.Color.fromCssColorString('#ffd33c')

        let positions = entity.polygon.hierarchy._value.positions

        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item)
            let wgs84 = Utils.transformCartesian2WGS84(c)
            return Utils.transformWGS842Cartesian(wgs84)
          }),
          width: 4,
          material: Cesium.Color.fromCssColorString('#1645fc'),
        }
      }
      await this.viewer.dataSources.add(dataSource)
      dataSource.show = true
      border_data_source = dataSource
    },

    async init_plate () {
      let promise = Cesium.GeoJsonDataSource.load(GeoJson)
      let dataSource = await promise
      let entities = dataSource.entities.values
      let center_hash = {}

      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i],
          name = entity.name,
          centerHash = {}

        entity._name = 'plate' + '_' + entity._name
        entity.polygon.outline = true
        entity.polygon.outlineColor = Cesium.Color.fromCssColorString('#0e4a80')
        entity.polygon.material = Cesium.Color.fromCssColorString(
          '#00016c'
        ).withAlpha(1)
        entity.polygon.extrudedHeight = Math.floor(10000)

        let positions = entity.polygon.hierarchy._value.positions

        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item)
            let wgs84 = Utils.transformCartesian2WGS84(c)
            wgs84.alt += 10020
            return Utils.transformWGS842Cartesian(wgs84)
          }),
          width: 4,
          material: Cesium.Color.fromCssColorString('#14e9f1'),
        }

        let center = entity.properties.centroid._value
        center = Utils.transformWGS842Cartesian({
          lng: center[0],
          lat: center[1],
          alt: 10000,
        })

        if (!center_hash[name]) {
          entity.position = center
          entity.label = {
            text: name,
            show: true,
            showBackground: false,
            font: '16px monospace bolder',
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, 0),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            fillColor: Cesium.Color.fromCssColorString('#92D9FF'),
          }
          entity.billboard = {
            show: true,
            image: require('@/assets/images/poi.png'),
            pixelOffset: new Cesium.Cartesian2(0, -30),
            width: 24,
            height: 24,
          }
          center_hash[name] = center
        }
      }

      await this.viewer.dataSources.add(dataSource)
      dataSource.show = false
      plate_data_source = dataSource
    },
    get_plate_by_click (moment) {
      let pickedObject = this.viewer.scene.pick(moment.position)
      if (pickedObject?.id?._name) {
        if (
          pickedObject &&
          pickedObject.id &&
          pickedObject.id._name.indexOf('plate_') != -1
        ) {
          return pickedObject.id
        } else {
          return null
        }
      }
    },

    on_plate_left_click (movement) {
      let plate = this.get_plate_by_click(movement)
      if (plate) {
        // this.g_show_zysb(0, 0, 1)
        let center = plate.properties.center._value
        this.fly_to_globe({
          destination: Utils.transformWGS842Cartesian({
            lng: center[0],
            lat: center[1],
            alt: 10000,
          }),
        })
      }
    },
    on_plate_wheel (height) {
      if (height >= 1.4e7 && !plate_data_source.show) {
        this.fly_to_plate()
      }
    },
    is_plate_mode () {
      return plate_data_source && plate_data_source.show
    },

    location (options = { destination: origin }, fly_type = 'flyTo') {
      this.viewer.camera[fly_type](options)
    },

    fly_to_globe (options) {
      this.viewer.trackedEntity && (this.viewer.trackedEntity = undefined)
      this.viewer.scene.globe.show = true
      this.viewer.scene.skyAtmosphere.show = true
      this.viewer.scene.globe.showGroundAtmosphere = true
      plate_data_source && (plate_data_source.show = false)
      this.location(options)
    },
    // fly_to_plate() {
    //   this.viewer.scene.skyAtmosphere.show = true;
    //   this.viewer.scene.globe.showGroundAtmosphere = false;
    //   this.viewer.scene.globe.show = true;
    //   plate_data_source && (plate_data_source.show = true);
    //   this.location(
    //     {
    //       destination: plate_orign,
    //       orientation: plate_hpr,
    //     },
    //     "setView"
    //   );
    //   center && (this.viewer.trackedEntity = center);
    // },

    fly_to_plate (fly_type = 'setView', options) {
      plate_data_source && (plate_data_source.show = true)

      options = {}
      options.destination = plate_orign
      options.orientation = plate_hpr
      options.duration = 5
      options.complete = () => {
        this.viewer.scene.skyAtmosphere.show = false
        this.viewer.scene.globe.showGroundAtmosphere = false
        this.viewer.scene.globe.show = false
      }
      fly_type === 'setView' && options.complete && options.complete()

      this.location(options, fly_type)
      center && (this.viewer.trackedEntity = center)
    },

    // fly_to_by_mode(mode = MODES.PLATE) {
    //   if (mode === MODES.PLATE) {
    //     this.fly_to_plate();
    //   } else if (mode === MODES.GLOBE) {
    //     this.fly_to_globe();
    //   }
    // },

    fly_to_by_mode (mode = MODES.PLATE, ...options) {
      if (mode === MODES.PLATE) {
        this.fly_to_plate(...options)
      } else if (mode === MODES.GLOBE) {
        this.fly_to_globe()
      }
    },

    add_model (entities, data) {
      return entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        orientation: new Cesium.CallbackProperty(() => data.orientation, false),
        position: new Cesium.CallbackProperty(() => data.position, false),
        model: {
          // uri: 'cesium/model/1800_20210316_no_effect283g2color.gltf',
          uri: "cesium/model/mmcModel2.glb",
          minimumPixelSize: 50,
          maximumScale: 10000,
          scale: 1,
          ...(data.options ? data.options : {}),
          // show: new Cesium.CallbackProperty(
          //   () => !plate_data_source || !plate_data_source.show,
          //   false
          // ),
        },
      })
    },

    add_polyline (entities, data) {
      return entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        polyline: {
          positions: new Cesium.CallbackProperty(() => {
            return data.positions
          }, false),
          width: 5,
          material: Cesium.Color.fromCssColorString('#6DD400'),
          show: true,
          ...(data.options ? data.options : {}),
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.monitoringAreaBox {
  height: 100%;
  overflow: hidden;
}
</style>