<template>
  <div v-interact>
    <div class="hd-box">飞行任务 </div>
    <div class="intelligenceMission_navlist">
      <div @click="handclickFn(index)"
           :class="Index.activeIndex.activeIndex == index ? 'active' : ''"
           v-for="(item, index) in navlistTitle"
           :key="index">
        {{ item }}
        <div class="nav"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["getnul", "nul"],
  data () {
    return {
      navlistTitle: ["警情任务", "常态任务", "临时紧急任务", "飞行日志"],
      Index: this.nul
    };
  },
  methods: {
    handclickFn (index) {
      this.getnul(index)
    },
  },
};
</script>

<style lang="scss" scoped>
.hd-box {
  height: 56px;
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #dce9ff;
  font-weight: 400;
  line-height: 60px;
  padding-left: 120px;
  overflow: hidden;
}
.bd-box {
  height: calc(100% - 56px);
  box-sizing: border-box;
  ::v-deep .search-box {
    box-sizing: border-box;
    height: 76px;
    display: flex;
    align-items: center;
    .btn-add,
    .btn-submit {
      width: 134px;
      height: 40px;
      line-height: 44px;
      font-family: PangMenZhengDao;
      font-size: 22px;
      color: #ffffff;
      text-align: center;
      font-weight: 400;
      background-color: #129c9c;
      border: none;
      border-radius: 0;
      padding: 0;
    }
    .btn-add {
      margin-right: 41px;
    }
    .input-name {
      width: 186px;
      margin-right: 10px;
      .el-input__inner {
        width: 186px;
        height: 40px;
        background: #000000;
        border: 1px solid #08c2d1;
        color: #08c2d1;
        border-radius: 0;
      }
    }
    .input-orgname {
      width: 186px;
      margin-right: 10px;
      .el-input__inner {
        width: 186px;
        height: 40px;
        background: #000000;
        border: 1px solid #08c2d1;
        color: #08c2d1;
        border-radius: 0;
      }
    }
    .input-status {
      width: 186px;
      margin-right: 10px;
      .el-input__inner {
        width: 186px;
        height: 40px;
        background: #000000;
        border: 1px solid #08c2d1;
        color: #08c2d1;
        border-radius: 0;
      }
    }
    .input-excute {
      width: 186px;
      margin-right: 51px;
      .el-input__inner {
        width: 186px;
        height: 40px;
        background: #000000;
        border: 1px solid #08c2d1;
        color: #08c2d1;
        border-radius: 0;
      }
    }
  }
  .tb-box {
    box-sizing: border-box;
    height: calc(100% - 106px);
    .tb-hd-box {
      display: flex;
      // justify-content: space-between;
      height: 52px;
      .tb-hd {
        height: 100%;
        // flex: 1;
        background-color: #10181f;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #129c9c;
        font-weight: 400;
        line-height: 52px;
        text-align: center;
      }
    }
    .tb-bd-box {
      height: calc(100% - 52px);
      // overflow: auto;
      overflow-y: auto;
      background-color: #263542;
      .tb-tr {
        // height: 60px;
        display: flex;
        box-sizing: border-box;
        border-bottom: 1px solid #263542;
        .td {
          display: flex;
          align-items: center;
          justify-content: center;
          // width: calc(100% / 9);
          padding: 10px 0;
          text-align: center;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #b3bbc5;
          font-weight: 400;
          word-break: break-word;
          box-sizing: border-box;
          .iconfont {
            font-size: 20px;
            cursor: pointer;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.intelligenceMission_navlist {
  display: flex;
  & div {
    width: 174px;
    height: 48px;
    cursor: pointer;
    text-align: center;
    line-height: 48px;
    font-family: MicrosoftYaHei-Bold;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    font-family: MicrosoftYaHei-Bold;
    font-size: 20px;
    color: rgba(8, 255, 255, 0.5);
  }
  & .active {
    color: #00ffff;
    background: #123f4e;
    box-shadow: inset 0 0 8px 0 rgba(55, 160, 182, 0.5);
    padding-bottom: 2px;
    .nav {
      width: 83px;
      text-align: center;
      height: 0;
      border: 1px solid #08ffff;
      margin-left: 42px;
    }
  }
}
</style>