<template>
  <div class="page-command-airtask">
    <IntelligenceMissionNav />
    <img src="~@/assets/images/accident/close.png" class="img-close" @click="change_page" />
    <div class="bd-box">
      <div class="search-box" v-if="taskCateId == 2">
        <el-button class="btn-add" icon="el-icon-plus" @click="$emit('add')">创建常态任务</el-button>

        <el-input v-model="normalSearch.taskTitle" class="input-name ml400" placeholder="请输入任务名称" clearable />
        <el-cascader class="input-orgname" placeholder="请选择责任单位" v-model="normalSearch.orgId" :options="unitList"
          :show-all-levels="false" :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }" clearable>
        </el-cascader>
        <!-- <el-select
          v-model="search.type"
          class="input-status"
          placeholder="请选择任务方式"
          clearable
        >
          <el-option label="报备" :value="1"></el-option>
          <el-option label="申报" :value="0"></el-option>
        </el-select> -->
        <el-select v-model="normalSearch.taskSituation" class="input-excute" placeholder="请选择执行状态" clearable>
          <el-option label="待审批" :value="0"></el-option>
          <el-option label="审批不通过" :value="1"></el-option>
          <el-option label="待执行" :value="2"></el-option>
          <el-option label="执行中" :value="3"></el-option>
          <el-option label="已完成" :value="4"></el-option>
        </el-select>
        <el-button @click="handle_search" class="btn-submit">搜索</el-button>
      </div>
      <div class="search-box" v-if="taskCateId == 3">
        <el-button @click="$emit('add')" class="btn-add w200" icon="el-icon-plus">创建临时紧急任务</el-button>

        <el-input v-model="emergencySearch.taskTitle" class="input-name ml400" placeholder="请输入任务名称" clearable />
        <el-cascader class="input-orgname" placeholder="请选择责任单位" v-model="emergencySearch.orgId" :options="unitList"
          :show-all-levels="false" :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }" clearable>
        </el-cascader>
        <!-- <el-select
          v-model="emergencySearch.type"
          class="input-status"
          placeholder="请选择任务方式"
          clearable
        >
          <el-option label="报备" :value="1"></el-option>
          <el-option label="申报" :value="0"></el-option>
        </el-select> -->
        <el-select v-model="emergencySearch.taskSituation" class="input-excute" placeholder="请选择执行状态" clearable>
          <el-option label="待审批" :value="0"></el-option>
          <el-option label="审批不通过" :value="1"></el-option>
          <el-option label="待执行" :value="2"></el-option>
          <el-option label="执行中" :value="3"></el-option>
          <el-option label="已完成" :value="4"></el-option>
        </el-select>
        <el-button @click="handle_search" class="btn-submit">搜索</el-button>
      </div>
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd w180">任务名称</div>
          <div class="tb-hd w180">航线名称</div>
          <div class="tb-hd w180">任务方式</div>
          <!-- <div class="tb-hd">任务类别</div> -->
          <div class="tb-hd w100">任务类型</div>
          <!-- <div class="tb-hd">任务场景</div> -->

          <div class="tb-hd w100">执行状态</div>

          <div class="tb-hd w150">责任单位</div>
          <div class="tb-hd w150">飞手</div>
          <div class="tb-hd w100">预计开始时间</div>
          <div class="tb-hd pl80 pr80">操作</div>
        </div>
        <div class="tb-bd-box h460">
          <div class="tb-tr" v-for="item in list" :key="item.id">
            <div class="td w180">{{ item.taskTitle || "暂无" }}</div>
            <div class="td w180">{{ item.airlineTitle || "暂无" }}</div>
            <div class="td w180">{{ map.type[item.type] || "暂无" }}</div>
            <!-- <div class="td">{{ item.taskCateTitle || "暂无" }}</div> -->
            <div class="td w100">{{ item.taskTypeTitle || "暂无" }}</div>
            <!-- <div class="td">{{ item.cenarioName || "暂无" }}</div> -->
            <div class="td w100" :style="{ color: statusColor[get_status(item)] }">
              {{ get_status(item) }}
            </div>
            <div class="td w150">{{ item.organizationTitle || "暂无" }}</div>
            <div class="td w150">
              {{
                item.team && item.team.length ? item.team.join("、") : "暂无"
              }}
            </div>
            <div class="td w100">{{ item.startedAt || "暂无" }}</div>
            <div class="td pl80 pr80">
              <el-tooltip class="ml5" v-if="!isSelf(item.organizationId) &&
                (isJH ? item.applyStatus == 0 : item.papplyStatus == 0)
                " effect="dark" content="审批" placement="top">
                <div @click="handle_show_approve(item)" class="iconfont icon-shenhe"></div>
              </el-tooltip>
              <el-tooltip class="ml5" effect="dark" content="重新提交" placement="top" v-if="(item.applyStatus == 2 || item.papplyStatus == 2) &&
                item.userId == user_info.uid
                ">
                <div @click="handle_resubmit(item, 'resubmit')" class="iconfont icon-zhongxintijiao"></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="详情" placement="top">
                <div @click="handle_resubmit(item, 'detail')" class="iconfont icon-xiangqing"></div>
              </el-tooltip>
              <!-- <el-tooltip effect="dark" content="定位" placement="top">
                    <div
                      
                      class="iconfont icon-dingwei"
                    ></div>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="航线预览" placement="top">
                    <div
                      class="iconfont icon-guiji"
                    ></div>
                  </el-tooltip> -->
              <el-tooltip effect="dark" content="历史视频" :class="item.applyStatus == 1 &&
                item.papplyStatus == 1 &&
                item.executeStatus == 1
                ? ''
                : 'preventClickImage'
                " placement="top">
                <img @click="handleVideoPlayer(item)" class="h20 w20 mr5" src="@/assets/historyvideo.svg" alt="" />
              </el-tooltip>
              <el-tooltip effect="dark" content="历史图片" :class="item.applyStatus == 1 &&
                item.papplyStatus == 1 &&
                item.executeStatus == 1
                ? ''
                : 'preventClickImage'
                " placement="top">
                <img @click="handleImage(item)" class="h20 w20 mr5" src="@/assets/images/historyImage.svg" alt="" />
              </el-tooltip>
              <el-tooltip effect="dark" content="历史轨迹" placement="top" :class="item.applyStatus == 1 &&
                item.papplyStatus == 1 &&
                item.executeStatus == 1
                ? ''
                : 'preventClickImage'
                ">
                <img @click="handleHistotyTrack(item)" class="h20 w20" src="@/assets/historyTrack.svg" alt="" />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="tb-pagination">
          <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="pagination.pageNo"
            :page-size="pagination.pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.totalCount" @size-change="handle_size_change" @current-change="handle_page_change">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog center title="审批" :close-on-click-modal="false" :visible.sync="approve_form.visible" :append-to-body="true"
      top="20em" width="40%">
      <div>
        <div class="w20 h20 pa top20 right20 cp" @click="approve_form.visible = false">
          <img class="dib wih100 ht100" src="~@/assets/images/close.png" alt="" />
        </div>
        <el-form label-width="80px">
          <el-form-item label="备注">
            <el-input v-model="approve_form.approvalRemark" type="textarea" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" style="fotterBtn">
        <el-button type="danger" @click="handle_approve(0)">不通过</el-button>
        <el-button type="primary" @click="handle_approve(1)">通过</el-button>
      </span>
    </el-dialog>

    <videoMedia v-if="videoVisible" :historyVideo="video" @close="videoVisible = false"></videoMedia>
    <imagesMedia :historyImage="imageList" v-if="mediaVisible" @closeMedia="mediaVisible = false"></imagesMedia>
  </div>
</template>

<script>
import IntelligenceMissionNav from "@/components/command/intelligenceMissionNav"; //警情
import videoMedia from "@/components/command/situation/history/video"; //历史视频
import imagesMedia from "@/components/command/situation/history"; //历史图片
import { mapGetters } from "vuex";
import API from "@/api";
let history_entity = null;
let history_Line = null;
export default {
  props: ["taskCateId"],
  components: {
    videoMedia,
    imagesMedia,
    IntelligenceMissionNav,
  },
  data () {
    return {
      statusColor: {
        待执行: "#FF972B",
        执行中: "#2F76E4",
        已完成: "#89EC50",
        待审批: "#FF972B",
        审批不通过: "#B3BBC5",
      },
      curr_task: null,
      approve_form: {
        approvalRemark: null,
        visible: false,
      },
      search: {
        taskTitle: null,
        orgId: null,
        type: null,
        taskSituation: null,
      },
      normalSearch: {
        taskTitle: null,
        orgId: null,
        type: null,
        taskSituation: null,
      },
      emergencySearch: {
        taskTitle: null,
        orgId: null,
        type: null,
        taskSituation: null,
      },
      list: [],
      unitList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      map: {
        type: {
          0: "申报",
          1: "报备",
        },
      },
      imageList: null,
      video: null,
      videoVisible: false,
      mediaVisible: false,
      test: {
        object: {
          gbId: "32090000001520004600",
          replay: [
            {
              flightSortieName:
                "FLY-1637225817-0021002f344e50152039384e00000000",
              urlList: [
                "http://32.128.6.46:10800/api/v1/record/video/play/100/20211118165658/20211118172132/video.mp4",
              ],
            },
          ],
        },
      },
    };
  },
  inject: ["g_cesium_layer", "change_page", "history_track"],
  computed: {
    ...mapGetters(["user_info"]),
    isJH () {
      return this.user_info && this.user_info.departmentId == 2;
    },
  },
  methods: {
    // 历史视频
    async handleVideoPlayer (item) {
      try {
        if (this.videoVisible) {
          this.videoVisible = false;
        }
        let res = await API.TASK.airtaskVideo(item.id);
        console.log(res, 'resresresres');
        this.video = res["object"].replay[0];
        this.videoVisible = true;
      } catch (error) {
        // this.videoVisible = false;
        this.$el_message("无视频回放", () => { }, "error");
      }
    },
    // 历史图片
    async handleImage (item) {
      try {
        if (this.mediaVisible) {
          this.mediaVisible = false;
        } else {
          let res = await API.TASK.airtaskPhoto(item.id); //12
          if (res && res.length) {
            this.imageList = res;
            this.mediaVisible = true;
          } else {
            this.$el_message("无历史图片", () => { }, "error");
          }
        }
      } catch (error) {
        // this.videoVisible = false;
        this.$el_message("无历史图片", () => { }, "error");
      }
    },
    removeEntities () {
      if (history_entity) {
        viewer.entities.remove(history_entity);
      }
      if (history_Line) {
        viewer.entities.remove(history_Line);
      }
    },
    // 历史轨迹
    async handleHistotyTrack (item) {
      try {
        // let viewer = window.viewer;
        let res = null;
        this.removeEntities();
        res = await API.TASK.airtaskTrack(item.id);
        if (res[Object.keys(res)[0]]) {
          let polyline = res[Object.keys(res)[0]];
          // this.history_track(polyline)
          let positions = [];
          polyline.forEach((item, index) => {
            positions.push(Number(item.longitude), Number(item.latitude), 100);
            history_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                Number(item.longitude),
                Number(item.latitude)
              ),
            });
          });
          history_Line = viewer.entities.add({
            name: "Red line on terrain",
            polyline: {
              positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
              width: 5,
              material: Cesium.Color.RED,
              clampToGround: true,
            },
          });
          viewer.flyTo(history_Line);
        }
      } catch (error) {
        this.$el_message("无历史轨迹", () => { }, "error");
      }
    },
    async handle_resubmit (item, state) {
      let res = await API.TASK.FlightDetail(item.id);
      this.$store.commit("task/FLIGHT_DETAIL", res);
      this.$emit(state);
    },
    isSelf (id) {
      return this.user_info && this.user_info.departmentId == id;
    },
    handle_search () {
      this.get_list();
    },
    async getUnitList () {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async get_list () {
      if (this.taskCateId == 2) {
        this.search = this.normalSearch;
      } else {
        this.search = this.emergencySearch;
      }
      let { data, pageNo, pageSize, totalCount } = await API.TASK.List({
        cate: 3,
        taskCateId: this.taskCateId,
        ...this.pagination,
        ...this.search,
      });
      this.list = data || [];
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
    },
    get_status (item) {
      let val = item;
      let text = "";
      if (val.applyStatus == 1 && val.papplyStatus == 1) {
        text = "审批通过";

        if (val.executeStatus == 0) {
          text = "待执行";
        } else if (val.executeStatus == 1) {
          text = "已完成";
        } else {
          text = "执行中";
        }
      } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
        text = "审批不通过";
      } else {
        text = "待审批";
      }
      return text;
      // const obj = {
      //   0: '待审批',
      //   1: '审批不通过',
      //   2: '待执行',
      //   3: '执行中',
      //   4: '已完成',
      // }
      // return obj[item.taskSituation]
    },
    handle_size_change (size) {
      this.pagination.pageSize = size;
      this.get_list();
      //   (e) => $emit("size-change", e);
    },
    handle_page_change (page) {
      this.pagination.pageNo = page;
      this.get_list();
      //   (e) => $emit("page-change", e);
    },
    handle_show_approve (val) {
      this.curr_task = val;
      this.approve_form.visible = true;
    },
    handle_approve (status) {
      this.$el_confirm(
        "确定提交么？",
        async () => {
          let res = await API.TASK.Edit({
            id: this.curr_task.id,
            taskCateId: this.curr_task.taskCateId,
            status,
            approvalRemark: this.approve_form.approvalRemark,
          });
          this.$el_message("审批成功");
          this.get_list();
          this.approve_form.visible = false;
          // this.$router.push({ path: "/observe", query: { page: "1" } });
          // this.$router.push({ path: "/observe", query: { page: "1" } });
        },
        () => {
          this.approve_form.approvalRemark = null;
          this.approve_form.visible = false;
        }
      );

      // this.$el_confirm(
      //   "确定提交么？",
      //   async () => {
      //     this.g_approve({
      //       ...this.curr_task,
      //       approve: {
      //         status,
      //         approvalRemark: this.approve_form.approvalRemark,
      //       },
      //     });
      //     this.approve_form.visible = false;
      //   },
      //   () => {
      //     this.approve_form.approvalRemark = null;
      //     this.approve_form.visible = false;
      //   },
      //   {
      //     closeOnClickModal: false,
      //   }
      // );
    },
    start_timer () {
      this.timer = window.setInterval(() => {
        this.get_list();
      }, 2000);
    },
    stop_timer () {
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = null;
      }
    },
  },

  mounted () {
    this.get_list();
    this.start_timer();
    this.getUnitList();
  },
  watch: {
    taskCateId () {
      this.search = {
        taskTitle: null,
        orgId: null,
        type: null,
        taskSituation: null,
      };
    },
  },
  destroyed () {
    this.stop_timer();
    this.removeEntities();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.page-command-airtask {
  position: absolute;
  width: 1400px;
  min-height: 740px;
  box-sizing: border-box;
  padding: 0 30px 50px 41px;
  background: url("~@/assets/images/plate/bg_04.png");
  background-size: 100% 100%;

  .img-close {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .hd-box {
    height: 56px;
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
    line-height: 60px;
    padding-left: 120px;
    overflow: hidden;
  }

  .bd-box {
    height: calc(100% - 56px);
    box-sizing: border-box;

    ::v-deep .search-box {
      box-sizing: border-box;
      height: 76px;
      display: flex;
      // justify-content: space-around;
      align-items: center;

      .btn-add {
        width: 180px;
        height: 40px;
        line-height: 44px;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
        background-color: #129c9c;
        border: none;
        border-radius: 0;
        padding: 0;
      }

      .btn-submit {
        // width: 134px;
        // height: 40px;
        // line-height: 44px;
        // font-family: PangMenZhengDao;
        // font-size: 22px;
        // color: #ffffff;
        // text-align: center;
        // font-weight: 400;
        // background-color: #129c9c;
        // border: none;
        // border-radius: 0;
        // padding: 0;
        width: 134px;
        height: 40px;
        line-height: 44px;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
        background-color: #129c9c;
        border: none;
        border-radius: 0;
        padding: 0;
        cursor: pointer;
      }

      .btn-add {
        margin-right: 15px;
      }

      .input-name {
        width: 186px;
        margin-right: 10px;

        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }

      .input-orgname {
        width: 186px;
        margin-right: 10px;

        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }

      .input-status {
        width: 186px;
        margin-right: 10px;

        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }

      .input-excute {
        width: 186px;
        margin-right: 51px;

        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }
    }

    .tb-box {
      box-sizing: border-box;
      height: calc(100% - 76px);

      .tb-hd-box {
        display: flex;
        // justify-content: space-between;
        height: 52px;

        .tb-hd {
          height: 100%;
          background-color: #10181f;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #129c9c;
          font-weight: 400;
          line-height: 52px;
          text-align: center;
        }
      }

      .tb-bd-box {
        height: calc(100% - 52px);
        overflow: auto;
        background-color: #263542;

        .tb-tr {
          height: 60px;
          margin: 5px 0px 5px 0px;
          display: flex;
          box-sizing: border-box;
          border-bottom: 1px solid #263542;

          .td {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% / 10);
            height: 100%;
            padding: 10px 0;
            text-align: center;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #b3bbc5;
            font-weight: 400;
            word-break: break-word;
            box-sizing: border-box;

            .iconfont {
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .tb-pagination {
    height: 30px;
    display: flex;
    align-items: center;

    ::v-deep .el-pagination {
      margin-top: 50px;
      height: 30px;
      display: flex;
      align-items: center;

      button {
        background: transparent;
        font-size: 12px;
        color: #889fb2;
        text-align: center;
        font-weight: 400;

        &:disabled {
          background: transparent;
          color: #889fb2;
          opacity: 0.3;
        }
      }

      .el-pagination__total {
        font-size: 12px;
        color: #889fb2;
        text-align: center;
        font-weight: 400;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        margin: 0px;
      }

      .el-pagination__sizes {
        height: 30px;
        line-height: 30px;
        margin: 0px;

        .el-select {
          .el-input {
            width: 60px;
            padding-right: 20px;
            margin: 0;

            .el-input__inner {
              background: transparent;
              border: none;
              line-height: 30px;
              font-size: 12px;
              color: #889fb2;
              text-align: center;
              font-weight: 400;
              padding: 0px;
            }
          }
        }
      }

      .el-pager {
        .number {
          background: transparent;
          font-size: 12px;
          color: #889fb2;
          text-align: center;
          font-weight: 400;

          &.active {
            background: $primary-color;
            border-radius: 2px;
            color: #ffffff;
          }
        }

        .more {
          background: transparent;
          color: #889fb2;
        }
      }

      .el-pagination__jump {
        font-size: 12px;
        color: #889fb2;
        text-align: center;
        font-weight: 400;
        height: 30px;
        line-height: 30px;
        margin-left: 6px;

        .el-input {
          .el-input__inner {
            border: 1px solid rgba(36, 146, 252, 0.3);
            border-radius: 2px;
            background: transparent;
            color: #889fb2;
          }
        }
      }
    }

    ._jumper {
      font-size: 12px;
      color: #889fb2;
      text-align: center;
      font-weight: 400;
      display: flex;
      align-items: center;
      white-space: nowrap;

      ::v-deep .el-input {
        .el-input__inner {
          background: rgba(12, 13, 20, 0.5);
          border: 1px solid rgba(36, 146, 252, 0.3);
          border-radius: 2px;
          width: 33px;
          height: 24px;
          color: #889fb2;
          padding: 0;
          text-align: center;
        }
      }

      ._jumper_btn {
        width: 51px;
        height: 22px;
        background: #333c57;
        border: 1px solid $primary-border-color;
        border-radius: 2px;
        padding: 0px;
        font-size: 14px;
        color: $primary-color;
        letter-spacing: -0.08px;
        text-align: center;
        font-weight: 400;
        margin-left: 4px;
      }
    }
  }
}

.el-dialog {
  background-image: url("~@/assets/images/approve.png");
  background-size: 100% 100% !important;
  background: transparent;
  background-repeat: no-repeat;
}

.el-dialog__title {
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.el-dialog__header {
  text-align: left;
  margin-left: 100px;
}

.el-textarea__inner {
  color: #ffff;
  background-color: #000000;
  border: 1px solid #08c2d1;
}

.el-form-item__label {
  color: #fff;
}

::v-deep i.el-dialog__close.el-icon.el-icon-close {
  display: none;
}

.fotterBtn {
  display: flex;
  justify-content: center;
}
</style>
