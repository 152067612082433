
import API from "@/api"
import * as turf from '@turf/turf'
// import Popup from "./popup"
import { jichang, paodao } from '@/utils/global'
import uav_yellow from "@/assets/images/icons/uav_yellow.svg"
import uav_blue from "@/assets/images/icons/uav_blue.svg"
import grids_json from "@/utils/grids.json"

let uav_entity = {}
let entity_data_map = {}
let is_all = false
let hedgehop_primitive
let hedgehop_labels
let polyline_collection, label_collection, point_collection

function getRandomColor() {
  const colors = [
    '8a2be2ff', 'ff7f50ff', 'dc143cff', '008b8bff',
    '1e90ffff', 'adff2fff', 'ffa500ff', 'db7093ff',
    'ff6347ff', '48d1ccff', 'f0e68cff', '6495edff',
    '9370dbff', 'da70d6ff', '2e8b57ff', 'ff7f50ff'
  ]
  return colors[Math.floor(Math.random() * colors.length)]
}

// 颜色转换
function fromCssColorString(param) {
  if (typeof (param) === 'object') {
    const { r, g, b, a } = param
    return Cesium.Color.fromCssColorString(`rgba(${r}, ${g}, ${b}, ${a})`)
  } else {
    let r = Number.parseInt(param.substring(0, 2), 16)
    let g = Number.parseInt(param.substring(2, 4), 16)
    let b = Number.parseInt(param.substring(4, 6), 16)
    let a = Number.parseInt(param.substring(6, 8), 16)
    return Cesium.Color.fromBytes(r, g, b, a)
  }
}

function arrTrans(num, arr) { // 一维数组转换为二维数组
  const iconsArr = []; // 声明数组
  arr.forEach((item, index) => {
    const page = Math.floor(index / num); // 计算该元素为第几个素组内
    if (!iconsArr[page]) { // 判断是否存在
      iconsArr[page] = [];
    }
    iconsArr[page].push(item);
  });
  return iconsArr;
}

export default {
  init() {
    // polyline_collection = viewer.scene.primitives.add(new Cesium.PolylineCollection())
    label_collection = viewer.scene.primitives.add(new Cesium.LabelCollection())
    point_collection = viewer.scene.primitives.add(new Cesium.PointPrimitiveCollection())
  },
  show_log(item) {
    if (item.taskId == this.airway.current_log_id) {
      this.airway.current_log_id = null
    } else {
      this.airway.current_log_id = item.taskId
    }
  },
  get_polygon_height(item, min_height) {
    let res = []
    item.forEach((item, index) => {
      res.push(item)
      if (index % 2 == 1) {
        res.push(min_height)
      }
    })
    return res
  },
  shwo_hedgehop_labels() {
    hedgehop_labels = viewer.scene.primitives.add(new Cesium.LabelCollection())

    grids_json.forEach(item => {
      const polygon = this.airway_get_polygon_height(item.polygon, item.min_height)
      const positions = Cesium.Cartesian3.fromDegreesArrayHeights(polygon)
      const position = Cesium.BoundingSphere.fromPoints(positions).center
      hedgehop_labels.add({
        position,
        text: String((item.id + '-' + item.min_height)),
        font: "14px Helvetica",
        fillColor: Cesium.Color.CYAN,
        // fillColor: Cesium.Color.GOLD,
        outlineColor: Cesium.Color.BLACK,
        outlineWidth: 2,
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      });
    })
  },
  // 低空限制按钮
  hedgehop () {
    console.log('低空限制按钮');


    this.airway.hedgehop = !this.airway.hedgehop
    if (this.airway.hedgehop) {
      if (hedgehop_primitive) {
        hedgehop_primitive.show = true
        this.airway_shwo_hedgehop_labels()
      } else {
        const instances = []
        grids_json.forEach(item => {
          instances.push(new Cesium.GeometryInstance({
            geometry: new Cesium.RectangleGeometry({
              rectangle: Cesium.Rectangle.fromDegrees(item.polygon[0], item.polygon[1], item.polygon[4], item.polygon[5]),
              vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
              height: item.min_height,
              extrudedHeight: 0,
              perPositionHeight: true,
            }),
            attributes: {
              color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromRandom({ alpha: 0.5 }))
            }
          }))
        })
        hedgehop_primitive = new Cesium.Primitive({
          geometryInstances: instances,
          appearance: new Cesium.PerInstanceColorAppearance()
        })
        viewer.scene.primitives.add(hedgehop_primitive)

        this.airway_shwo_hedgehop_labels()
      }
    } else {
      hedgehop_primitive.show = false
      viewer.scene.primitives.remove(hedgehop_labels)
    }
  },
  async uav_data() {
    // console.log('uav_data')
    let res = await API.HOME.devicesByTasking()
    let data = []
    res.forEach(item => {
      if (!item.latestData) return
      let latestData = JSON.parse(item.latestData)
      let userInfo = latestData.data.uavInfo
      data.push({
        id: item.deviceHardId,
        deviceHardId: item.deviceHardId,
        deviceName: item.deviceName,
        lng: Number(userInfo.longitude),
        lat: Number(userInfo.latitude),
        alt: Number(userInfo.altitude),
        height: Number(userInfo.height),
        flightCourseJson: item.flightCourseJson,
        yaw: userInfo.yaw
      })
    })
    this.airway_show_uav(data)
    console.log(data,'sa--------------');
  },
  boolean_point_in_grid(point) {
    let res = { hedgehop: false }
    // grids.forEach(item => {
    grids_json.forEach(item => {
      let polygon = arrTrans(2, item.polygon)
      polygon.push(polygon[0])
      let pt = turf.point([point.lng, point.lat]);
      let poly = turf.polygon([polygon]);
      let bool = turf.booleanPointInPolygon(pt, poly);
      if (bool) {
        // console.log('bool', item)
        // console.log('alt', point.alt)
        if (point.alt < item.min_height) {
          res.min_height = item.min_height
          res.hedgehop = true
        }
      }
    })
    return res
  },
  boolean_point_in_polygon(point, polygon) {
    let pt = turf.point([point.lng, point.lat]);
    let poly = turf.polygon(polygon);
    return turf.booleanPointInPolygon(pt, poly);
  },
  get_min_distance(point, data) {
    // console.log(point, data) 
    let min_distance = 10000
    let from = turf.point([point.lng, point.lat]);
    let warning_name = ''
    data.forEach((item) => {
      var to = turf.point([item.lng, item.lat]);
      var options = { units: 'kilometers' };
      var distance = turf.distance(from, to, options) * 1000;
      min_distance = distance < min_distance && distance != 0 ? distance : min_distance
      if (min_distance < 200) {
        warning_name = item.deviceName || ''
      }
    })
    if (min_distance < 200 && this.airway.warning_num > 0) {
      this.airway.warning_cont = `${point.deviceName}距离${warning_name}过近，存在飞行安全隐患！！！`
      this.airway.is_show_warning = true
      this.airway.warning_num = 0
    }
    return min_distance.toFixed(2)
  },
  get_line(flightCourseJson) {
    // console.log('flightCourseJson', flightCourseJson)
    let flight_course_json = JSON.parse(flightCourseJson)
    let flight_course = flight_course_json.points.map(item => {
      return [item.lon, item.lat]
    })
    return turf.lineString(flight_course)
  },
  get_is_intersect(point, data) {
    // console.log(point, data)
    let is_intersect = false
    var line1 = this.airway_get_line(point.flightCourseJson)
    data.forEach((item) => {
      if (point.id == item.id) return
      var line2 = this.airway_get_line(item.flightCourseJson)
      var cross = turf.booleanCrosses(line1, line2)
      if (cross) {
        is_intersect = cross
      }
    })
    return is_intersect
  },
  show_uav(data) {
    console.log(data,'s----------');
    try {
      data.forEach(item => {
        let bool_jichang = this.airway_boolean_point_in_polygon(item, jichang)
        let bool_paodao = this.airway_boolean_point_in_polygon(item, paodao)
        let image = uav_blue, is_danger = false
        let position = Cesium.Cartesian3.fromDegrees(item.lng, item.lat)
        let airspace_hint = '安全', airline_hint = '安全', hedgehop_hint = null
        let airline_distance = this.airway_get_min_distance(item, data)
        let is_intersect = this.airway_get_is_intersect(item, data)
        let { hedgehop, min_height } = this.airway_boolean_point_in_grid(item)
        if (bool_jichang && !bool_paodao && item.alt < 145) {
          airspace_hint = '限高区内安全'
        }
        if (bool_jichang && !bool_paodao && item.alt > 145) {
          airspace_hint = '限高区内危险'
          image = uav_yellow
          is_danger = true
        }
        if (bool_paodao) {
          airspace_hint = '禁飞区内危险'
          image = uav_yellow
          is_danger = true
        }
        if (airline_distance < 200 && airline_distance > 100) {
          airline_hint = '临近注意'
          image = uav_yellow
          is_danger = true
        }
        if (airline_distance < 100) {
          airline_hint = '碰撞风险'
          image = uav_yellow
          is_danger = true
        }
        if (airline_distance >= 10000) {
          airline_distance = '大于10千'
        }
        if (is_intersect) {
          airline_hint = '存在航线交叉碰撞风险'
        }
        if (hedgehop) {
          hedgehop_hint = `低于该区域最低限高${min_height}米`
          image = uav_yellow
          is_danger = true
        }

        let id = item.deviceHardId
        let uav_entity_data = {
          id,
          longitude: item.lng,
          latitude: item.lat,
          altitude: item.alt,
          height: item.height,
          min_height: min_height,
          name: item.deviceName,
          airspace_hint,
          airline_hint,
          hedgehop_hint,
          airline_distance,
          flightCourseJson: item.flightCourseJson,
          is_danger,
          date: new Date().getTime()
        }

        if (entity_data_map[id]) {
          entity_data_map[id] = uav_entity_data
          uav_entity[id].position = position
          uav_entity[id].positions.push(position)
        } else {
          entity_data_map[id] = uav_entity_data
          uav_entity[id] = {}
          uav_entity[id].position = position
          uav_entity[id].positions = [position]
          uav_entity[id].billboard = viewer.entities.add({
            id: 'home_uav-' + id,
            name: 'home_uav',
            position: new Cesium.CallbackProperty(() => {
              return uav_entity[item.id].position
            }, false),
            billboard: {
              width: 36,
              height: 36,
              // rotation: Cesium.Math.PI_OVER_FOUR, // default: 0.0
              // rotation: 0.4, // default: 0.0
              // rotation: Cesium.Math.toRadians(parseFloat(yaw) + 90), // default: 0.0
              rotation: item.yaw, // default: 0.0
              image,
              horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.Bottom,
              // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
          });
          let track = viewer.entities.add({
            id: 'home_track-' + id,
            name: 'home_track',
            polyline: {
              positions: new Cesium.CallbackProperty(() => {
                return uav_entity[item.id].positions
              }, false),
              width: 4,
              material: Cesium.Color.fromCssColorString('#00A9A9'),
              // clampToGround: true,
            },
          })
          uav_entity[id].track = track
        }
      })
    } catch (error) {

    }
  },
  // click_uav(id, cartesian) {
  //   try {
  //     id = id.split('-')[1]
  //     let data = entity_data_map[id];

  //     // 调用弹窗方法
  //     uav_entity[id].popup && uav_entity[id].popup.close()
  //     uav_entity[id].popup = new Popup({
  //       viewer: viewer,
  //       geometry: cartesian,
  //       data: data,
  //       vue_this: this,
  //       callback: 'airway_close_popup2'
  //     });
      
  //     this.airway_show(data, true)
  //     this.airway_change_show_current_label(id, true)
  //     this.airway_change_show_current_point(id, true)
  //     this.airway_change_show_current_airline(id, true)
  //   } catch (error) { }
  // },
  async list(params) {
    let { pagination, search, status, selected_list } = this.airway
    this.airway.loading = true
    let { pageNo, pageSize, totalCount, data } = await API.TOWER.ListAirway({
      ...pagination,
      ...search,
      ...status,
      ...params
    })
    this.airway.loading = false
    if (data && data.list) {
      data.list = data.list.map(item => ({
        ...item,
        __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
      }))
    }

    this.airway.list = data?.list || []
    this.airway.pagination.pageNo = pageNo || 1
    this.airway.pagination.pageSize = pageSize || 10
    this.airway.pagination.totalCount = totalCount || 0
  },
  change_status(params) {
    this.airway.status = params
    this.airway_list(params)
  },
  change_size(size) {
    this.airway.pagination.pageSize = size
    this.airway_list()
  },
  change_page(page) {
    this.airway.pagination.pageNo = page
    this.airway_list()
  },
  search(name) {
    this.airway.search.name = name
    this.airway_list()
  },

  change(airway) {
    // console.log(airway)
    airway.__selected = !airway.__selected
    if (airway.__selected) {
      this.airway.selected_list.push(airway.id)
      this.airway_show(airway)
    } else {
      let index = this.airway.selected_list.findIndex(id => id === airway.id)
      if (index !== -1) {
        this.airway.selected_list.splice(index, 1)
      }
      this.airway_hide(airway)
    }
    let { list, selected_list } = this.airway
    list = list.map(item => ({
      ...item,
      __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
    }))
    this.airway.list = list || []
  },

  /**
   * 显示一条航线
   * @param {*} airway 
   */
  show(airway, is_all) {
    let id = airway.id
    if (uav_entity[id] && uav_entity[id].polyline) {
      uav_entity[id].polyline.show = true
      if (!is_all) {
        try {
          viewer.flyTo(uav_entity[id].polyline)
        } catch (error) {
        }
      }
    } else {
      if (airway.flightCourseJson) {
        try {
          let task_status = airway.taskStatus == 1 ? true : false
          uav_entity[id] = {}
          let json_data = JSON.parse(airway.flightCourseJson)
          let points = [], positions = []

          let entity_data = {
            id: airway.id,
            name: airway.flightName || json_data.filename,
            addtime: airway.addtime,
          }
          uav_entity[id].data = entity_data
          // let color = Cesium.Color.fromRandom({alpha : 1.0})
          let color = is_all ? fromCssColorString(getRandomColor()) : Cesium.Color.fromCssColorString('#00A9A9')

          json_data.points.forEach(item => {
            let position = Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
            positions.push(item.lon, item.lat, item.alt)
            point_collection.add({
              id,
              name: 'home_point',
              position,
              color,
              // blendOption: Cesium.BlendOption.OPAQUE
            })

            label_collection.add({
              id,
              name: 'home_point',
              show: false,
              position,
              text: String(item.alt) + 'm',
              font: 'bold 12px Microsoft YaHei',
              fillColor: Cesium.Color.fromCssColorString('#FF7F09'),
              horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              pixelOffset: new Cesium.Cartesian2(0, -6),
              // outlineColor: Cesium.Color.BLACK,
              // outlineWidth: 2,
              // style: Cesium.LabelStyle.FILL_AND_OUTLINE,
              // blendOption: Cesium.BlendOption.OPAQUE
            });
          })
          uav_entity[id].points = points

          let polyline_positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions)
          let material = task_status ? color
            :
            new Cesium.PolylineDashMaterialProperty({
              // color: Cesium.Color.fromRandom({alpha : 0.5}),
              color: color,
              dashLength: 20 //短划线长度
            })
          let polyline = viewer.entities.add({
            // id: entity_json_data,
            id,
            name: 'home_airline',
            polyline: {
              positions: polyline_positions,
              width: 4,
              material: material
              // clampToGround: true,
            },
          })
          uav_entity[id].polyline = polyline

          // polyline_collection.add({
          //   width: 4,
          //   // material: new Cesium.PolylineDashMaterialProperty({
          //   //   color: Cesium.Color.CYAN,
          //   // }),
          //   // material: Cesium.Material.fromType("PolylineOutline", {
          //   //   // color: Cesium.Color.GREEN
          //   //   color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromRandom({alpha : 1.0}))
          //   // }),
          //   material: Cesium.Material.fromType("PolylineOutline", {
          //     // color: Cesium.Color.fromRandom({alpha : 1.0}),
          //     color,
          //     outlineColor: new Cesium.Color(0.0, 0.0, 0.0, 0.0)
          //   }),
          //   positions: polyline_positions,
          // })

          if (!is_all) {
            viewer.flyTo(polyline)
          }
        } catch (error) {

        }
      }
    }
  },
  show_label(id) {
    uav_entity[id].points.forEach(item => {
      item.label.show = true
    })
  },
  /**
   * 隐藏一条航线
   * @param {*} airway 
   */
  hide(airway) {
    // console.log("hide airway:", airway);
    let id = airway.id
    this.airway_change_show_current_label(id, false)
    this.airway_change_show_current_point(id, false)
    this.airway_change_show_current_airline(id, false)
  },

  /**
   * 航线分布
   */
  async distribution() {
    // let res = await API.TOWER.GetAllAirway()
    // let data = res.list || []
    // console.log(res)

    let res = await API.TOWER.ListAirway({
      status: this.airway.status.status
    })
    let data = res.data.list || []

    if (!is_all) {
      is_all = true
      data.forEach(item => {
        this.airway_show(item, true)
      })
      this.airway_change_show_point(true)
      this.airway_change_show_airline(true)
      this.airway_change_all_selected(true)
    } else {
      is_all = false
      this.airway_change_show_label(false)
      this.airway_change_show_point(false)
      this.airway_change_show_airline(false)
      this.airway_change_all_selected(false)
    }
  },
  change_all_selected(is_select) {
    let { list } = this.airway
    let selected_list = []
    list = list.map(item => {
      if (is_select) {
        selected_list.push(item.id)
      }
      return {
        ...item,
        __selected: is_select
      }
    })
    this.airway.list = list || []
    this.airway.selected_list = selected_list
  },
  change_show_popup(id, cartesian, is_show) {
    try {
      // if (is_show && !uav_entity[id].popup) {
      if (is_show) {
        // 调用弹窗方法
        uav_entity[id].popup && uav_entity[id].popup.close()
        uav_entity[id].popup = new Popup({
          viewer: viewer,
          geometry: cartesian,
          data: uav_entity[id].data,
          vue_this: this,
          callback: 'airway_close_popup'
        });
        id && this.airway_change_show_current_point(id, true)
        id && this.airway_change_show_current_airline(id, true)
      }
    } catch (error) { }
  },
  close_popup(id) {
    id && this.airway_change_show_current_label(id, false)
    uav_entity[id].popup = null
  },
  close_popup2(id) {
    id && this.airway_hide({id})
  },
  change_show_current_label(id, is_show) {
    let len = label_collection.length;
    for (let i = 0; i < len; ++i) {
      let l = label_collection.get(i);
      if(l.id == id) {
        l.show = is_show;
      }
    }
  },
  change_show_current_point(id, is_show) {
    let len = point_collection.length;
    for (let i = 0; i < len; ++i) {
      let p = point_collection.get(i);
      if (p.id == id) {
        p.show = is_show;
      }
    }
  },
  change_show_current_airline(id, is_show) {
    uav_entity[id].polyline.show = is_show
  },
  change_show_label(is_show) {
    let len = label_collection.length;
    for (let i = 0; i < len; ++i) {
      let l = label_collection.get(i);
      l.show = is_show;
    }
  },
  change_show_point(is_show) {
    let len = point_collection.length;
    for (let i = 0; i < len; ++i) {
      let p = point_collection.get(i);
      p.show = is_show;
    }
  },
  change_show_airline(is_show) {
    for (let key in uav_entity) {
      if (uav_entity[key] && uav_entity[key].polyline) {
        uav_entity[key].polyline.show = is_show
      }
    }
  },
  close_log() {
    this.airway.current_log_id = null
  },
  destroy_entity() {
    for (let id in uav_entity) {
      if (uav_entity[id] && uav_entity[id].polyline) {
        viewer.entities.remove(uav_entity[id].polyline)
        uav_entity[id].polyline = null
      }
      if (uav_entity[id] && uav_entity[id].track) {
        viewer.entities.remove(uav_entity[id].track)
        uav_entity[id].track = null
      }
    }
  },
  destroy_hedgehop () {
    if (hedgehop_labels) {
      viewer.scene.primitives.remove(hedgehop_labels)
      hedgehop_labels = null
      hedgehop_primitive.show = false
      this.airway.hedgehop = false
    }
  },
  destroy() {
    this.airway_change_show_label(false)
    this.airway_change_show_point(false)
    // this.airway_change_show_airline(false)
    this.airway_destroy_entity()
    this.airway_destroy_hedgehop()
  }
}
