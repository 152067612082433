<!-- 
  点调
 -->

<template>
  <div class="cpt-command-airway-lists">
    <div class="header" v-interact>
      <div class="head_left">
        <div class="fontO center">{{ "点调" }}</div>
      </div>
      <div class="close" @click="$emit('close')">
        <img src="@/assets/img/i40.png" alt="" class="img">
      </div>
    </div>

    <div class="list-box">

      <div class="tb-box">

        <el-table :data="startList" max-height="600">
          <el-table-column align="center" prop="orgName" label="单位名称"></el-table-column>
          <el-table-column align="center" prop="deviceName" label="无人机"></el-table-column>
          <el-table-column align="center" prop="description" label="目的地"></el-table-column>
          <el-table-column align="center" prop="imageNormal" label="响应状态" width="250">

            <template slot-scope="scope">

              <el-radio-group v-model="scope.row.isTimely" :disabled="!doneDisable || scope.row.endDateTime"
                @change="(e) => changeTime(scope.row, e)">
                <el-radio :label="1">良好</el-radio>
                <el-radio :label="2">迟缓</el-radio>
                <el-radio :label="0">无应答</el-radio>
              </el-radio-group>

            </template>

          </el-table-column>
          <el-table-column align="center" prop="startDateTime" label="开始时间" width="100">
            <template slot-scope="scope">
              <div>{{ scope.row.startDateTime || "——" }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="imageNormal" label="图传是否正常" width="180">
            <template slot-scope="scope">
              <el-radio-group v-model="scope.row.imageNormal" :disabled="!doneDisable || scope.row.endDateTime"
                @change="(e) => changeImage(scope.row, e)">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="imageNormal" label="是否确认">
            <template slot-scope="scope">
              <div style="color: green" v-if="scope.row.socketStatus == 1">已确认</div>
              <div style="color: red" v-else>未确认</div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="endDateTime" label="结束时间" width="100">
            <template slot-scope="scope">
              <div>{{ scope.row.endDateTime || "——" }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="diff" label="操作">
            <template slot-scope="scope">
              <div v-if="!scope.row.endDateTime" style="display: flex; align-items: center; justify-content: center;">
                <div class="overTime cp" v-if="scope.row.startDateTime && !scope.row.endDateTime" @click="end(scope.row)">
                  结束
                </div>
                <div class="startTime cp" v-else @click="start(scope.row.id, scope.row.diff)">
                  开始
                </div>
              </div>
              <div v-else>/</div>

            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: ["id", "doneDisable"],
  data () {
    return {
      startList: [],
      timeshow: false,
      startall: [],
    };
  },
  mounted () {
    this.getStartList();
    this.init_ws()
  },
  methods: {
    handleSelectionChange (e) {
      this.startall = e;
    },
    check () {
      if (this.doneDisable) {
        return 1;
      } else {
        return 0;
      }
    },
    async allStart () {
      if (!this.doneDisable) {
        this.$message.error("不可操作，仅查看！");
      } else {
        let arr = [];
        let date = new Date();
        let y = date.getFullYear();
        let m =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        let mi =
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        let startDateTime =
          y + "-" + m + "-" + d + " " + h + ":" + mi + ":" + s;
        // let startDateTime = await API.WEATHER.getDate()
        if (this.startall.length > 0) {
          this.startList.forEach((val) => {
            console.log(val);
            if (val.feedBack == 2) {
              this.$message.error("选中的存在未响应！")
              return
            }
            if (!val.startDateTime) {
              const data = {
                id: val.feedbackId,
                itemId: this.id,
                startDateTime: startDateTime,
              };
              arr.push(data)
            }
          });
        }
        console.log(arr);
        let res = await API.TONE.updateFeedback(arr)
        this.getStartList()
      }
    },
    async getStartList () {
      let res = await API.TONE.getItemInfoListById({ itemId: this.id, pageNo: 1, pageSize: 500 });
      res.data.forEach((val) => {
        val.timer = null;
      });
      this.startList = res.data;
      this.startList.forEach((val) => {
        if (val.startDateTime && !val.endDateTime) {
          let diff = this.mockend(val.startDateTime)
          val.diff = diff
          this.timers(val.feedbackId, val.diff);
        }
      });
    },
    // startend(){
    //   this.startList.forEach(val=>{
    //     if(val.startDateTime&&!val.endDateTime){
    //       let diff = this.mockend(val.startDateTime)
    //       val.diff = diff
    //     }
    //   })
    //   console.log(this.startList);
    // },
    mockend (start) {
      let date = new Date();
      let y = date.getFullYear();
      let m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let mi =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let endTime =
        y + "-" + m + "-" + d + " " + h + ":" + mi + ":" + s;
      let date1 = new Date(start);
      let date2 = new Date(endTime)
      // console.log(start,endTime);
      let s1 = date1.getTime(),
        s2 = date2.getTime();
      let total = (s2 - s1) / 1000;

      let day = parseInt(total / (24 * 60 * 60)); //计算整bai数天du数
      let afterDay = total - day * 24 * 60 * 60; //取得值算出天数后dao剩余的转秒数shu
      let hour = parseInt(afterDay / (60 * 60)); //计算整数小时数
      let afterHour = total - day * 24 * 60 * 60 - hour * 60 * 60; //取得算出小时数后剩余的秒数
      let min = parseInt(afterHour / 60); //计算整数分
      let afterMin = parseInt(total - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60); //取得算出分后剩余的秒数

      // console.log("day", day);
      // console.log("afterDay", afterDay);
      // console.log("hour", hour);
      // console.log("afterHour", afterHour);
      // console.log("min", min);
      // console.log("afterMin", afterMin);
      let ho = hour < 10 ? "0" + hour : hour
      let mu = min < 10 ? "0" + min : min
      let ss = afterMin < 10 ? "0" + afterMin : afterMin

      let dateE = ho + ":" + mu + ":" + ss
      return dateE
    },
    async start (sid, diff) {
      if (!this.doneDisable) {
        this.$message.error("不可操作，仅查看！");
        return
      }
      let date = new Date();
      let y = date.getFullYear();
      let m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let mi =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // let startDateTime = await API.WEATHER.getDate()
      let startDateTime = y + "-" + m + "-" + d + " " + h + ":" + mi + ":" + s;
      let data =
      {
        id: sid,
        // itemId: this.id,
        startDateTime: startDateTime,
      }
      let res = await API.TONE.startDispatch(data);
      this.getStartList()
      // this.timers(sid, diff);
    },
    timers (sid, diff) {
      let hour = Number(diff.substring(0, 2));
      let min = Number(diff.substring(3, 5));
      let ss = Number(diff.substring(6, 8));
      this.startList.forEach((val) => {
        if (val.feedbackId == sid) {
          val.timer = setInterval(() => {
            ss = ss + 1;
            if (ss >= 60) {
              ss = 0;
              min = min + 1;
            }
            if (min >= 60) {
              min = 0;
              hour = hour + 1;
            }
            let h = hour >= 10 ? hour : "0" + hour;
            let m = min >= 10 ? min : "0" + min;
            let s = ss >= 10 ? ss : "0" + ss;
            let diff = h + ":" + m + ":" + s;
            val.diff = diff;
          }, 1000);
        }
      });
    },
    async end (sid) {
      if (!this.doneDisable) {
        this.$message.error("不可操作，仅查看！");
        return
      }
      this.startList.forEach((val) => {
        if (val.feedbackId == sid) {
          val.timer = null;
        }
      });
      this.timer = null;
      let date = new Date();
      let y = date.getFullYear();
      let m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let mi =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let endDateTime = y + "-" + m + "-" + d + " " + h + ":" + mi + ":" + s;
      // let endDateTime = await API.WEATHER.getDate()
      let data =
      {
        id: sid.id,
        isTimely: sid.isTimely,
        // itemId: this.id,
        endDateTime: endDateTime,
        done: true,
      }
      let res = await API.TONE.startDispatch(data);
      this.getStartList();
    },
    async changeDone (val, e) {
      let data = [
        {
          id: val.feedbackId,
          itemId: this.id,
          done: e,
        },
      ];
      let res = await API.TONE.updateFeedback(data);
      this.getStartList();
    },
    async changeImage (val, e) {
      let data =
      {
        id: val.id,
        // itemId: this.id,
        imageNormal: e,
      }
      let res = await API.TONE.startDispatch(data);
      // this.getStartList();
    },
    async changeTime (val, e) {
      let data =
      {
        id: val.id,
        isTimely: e,
      }
      let res = await API.TONE.startDispatch(data);
    },
    init_ws () {
      const data = new Date();
      let time = data.getHours();
      let ws_url_al = process.env.VUE_APP_WS_URL_ALARM;
      let ws = new WebSocket(ws_url_al);
      let _this = this;
      let { appid, username } = JSON.parse(
        localStorage.getItem("user_info")
      ).data;
      let token = JSON.parse(localStorage.getItem("user_info")).data[
        "mmc-identity"
      ];
      ws.onopen = () => {
        ws.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId: appid,
          })
        );
      };

      ws.onmessage = (e) => {
        let metadata = JSON.parse(e.data);
        // console.log(metadata, '无人机原始数据，可能引起地图压力过大6');
        if (metadata.data.wsSendUrlEnums[0] == 13) {
          this.getStartList()
        }

      };
      ws.onerror = (error) => {
        //console.log("error", error);
      };

      ws.onclose = (data) => {
        console.log("onclose", data);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.cpt-command-airway-lists {
  @import "~@/styles/flightTask.scss";
  width: 80%;
  position: absolute;
  // left: 400px;
  left: 10%;
  top: 150px;
  box-sizing: border-box;
  // background: rgba(0, 39, 121, 0.8);
  backdrop-filter: blur(2px);
  background-color: #0A293A;

  .header {
    height: 36px;
    background-color: #224D68;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;

    .head_left {
      display: flex;

      .fontO {
        color: #ffffff;
        font-weight: bold;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;

      .img {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    width: auto;
    white-space: nowrap;
  }

  .list-box {
    // width: 1132px;
    // background: rgba(0, 39, 121, 0.5);
    // box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    //   inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    //   inset 0px 0px 8px 0px #019aff;
    // border: 1px solid #43deff;
    border-top: none;
    // border-radius: 0 0 10px 10px;
    height: calc(100% - 56px);
    box-sizing: border-box;
    padding: 0 16px 0 16px;

    .search-box {
      margin-left: 0 !important;
      // height: 80px;
      height: auto;
      margin: 24px 0 24px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .item-plan {
        width: 79px;
        height: 32px;
        background: rgba(28, 67, 191, 0.6) !important;
        box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
          inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
          inset 0px 0px 4px 0px #00a7ff;
        border: 1px solid;
        border-image: linear-gradient(180deg,
            rgba(138, 218, 255, 1),
            rgba(82, 179, 255, 0)) 1 1;
        border-radius: 0px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #43deff;
        line-height: 16px;
      }

      .item-plan:hover {
        opacity: 0.5;
      }
    }

    .tb-box {
      padding: 0 !important;
      margin: 0 0 27px 0;

      /deep/ .el-table {
        background: transparent;

        .el-table__expanded-cell {
          background: transparent;
        }

        .el-table__body-wrapper {
          // width: 100%;
          // max-height: 600px;
          // overflow-y: auto;
        }

        .el-table__empty-text {
          color: #fff;
        }

        .el-table__expand-icon {
          color: #fff;
        }

        tr {
          background: transparent;
        }

        thead {
          .el-table__cell {
            background: transparent;
            font-family: MicrosoftYaHei-Bold;
            font-size: 16px;
            color: #b5e5ff;
            letter-spacing: 0;
            font-weight: 700;
          }
        }

        tbody {
          tr {
            color: #fff;
            font-size: 14px;
          }

          tr:hover>td {
            background: transparent !important;
          }
        }
      }
    }

    .startTime {
      width: 80px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: green;
    }

    .overTime {
      width: 80px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: red;
    }
  }
}

.el-radio {
  color: #fff;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #409EFF;
  background: #409EFF;
}
</style>
