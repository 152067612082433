<!-- 
  飞行任务组件
-->

<template>
  <div class="fly-task-container">

    <!-- 主面板 包含常态任务和临时紧急任务 -->
    <div class="main" v-show="panelFlag == 0">
      <div class="header">

        <div class="right">

          <div class="title title1" :class="[titleFlag == 0 ? 'title-selected' : '']" @click="titleClickHandle(0)">
            <span>常态任务</span>
          </div>

          <div class="title title2" :class="[titleFlag == 1 ? 'title-selected' : '']" @click="titleClickHandle(1)">
            <span>临时紧急任务</span>
          </div>

        </div>
        <div class="icon-plus">

          <el-tooltip class="item" effect="dark" content="飞行日志" placement="top">
            <img src="@/assets/img/i117.png" alt="" @click="goFlyLogPanel">
          </el-tooltip>

          <img src="@/assets/img/plus.png" alt="" @click="goCreatePanel">
        </div>
      </div>
      <!-- 搜索栏 -->
      <div class="form">
        <div class="line line1">
          <el-input class="input-search input-search1" v-model="search.taskTitle" placeholder="请输入任务名称" clearable
            @clear="searchInputTitleClear"></el-input>
          <el-input class="input-search input-search2" v-model="search.userName" placeholder="请输入创建人" clearable
            @clear="searchInputUserNameClear"></el-input>
          <div class="btn btn1" @click="searchClickHandle">
            <i class="el-icon-search"></i>
          </div>
        </div>
        <!-- 类型搜索 -->
        <div class="line line2">
          <el-cascader class="input-orgname-fly-task" placeholder="请选择责任单位" v-model="search.orgId"
            suffix-icon="el-icon-date" @visible-change="cascaderVisibleChange" :options="unitList"
            @change="cascaderNodeChange" popper-class="unit-cascader-drop-panel" :show-all-levels="false" :props="{
              children: 'children',
              label: 'orgName',
              value: 'id',
              checkStrictly: true,
              emitPath: false,
            }" clearable>
          </el-cascader>

          <div class="item item2" @click.stop="safePanelClick">
            <el-select v-model="search.taskSituation" class="input-excute" placeholder="请选择执行状态" clearable
              popper-class="input-excute-panel">
              <el-option label="待审批" :value="0"></el-option>
              <el-option label="审批不通过" :value="1"></el-option>
              <el-option label="待执行" :value="2"></el-option>
              <el-option label="执行中" :value="3"></el-option>
              <el-option label="已完成" :value="4"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="body" id="flyTaskContainerBody" v-loading="loading" element-loading-text="数据加载中"
        element-loading-spinner="my-custom-loader" element-loading-background="rgba(0, 0, 0, 0)">
        <div class="card-body">
          <Card class="card" :taskType="titleFlag == 0 ? '常态任务' : '临时紧急任务'" v-for="(item, index) in list" :key="index"
            :data="item" @delete="deleteTask">
          </Card>
        </div>
        <!-- 分页 -->
        <div class="pagation">
          <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="pagination.pageNo"
            :page-size="pagination.pageSize" layout="prev, pager, next, total,  jumper" :total="pagination.totalCount"
            :pager-count="5" @current-change="handleSizeChange_station">
          </el-pagination>
        </div>
        <!-- <img id="fltTaskScrollToTop" src="@/assets/img/i98.png" alt="" @click="scroll2Top" title="回到顶部"> -->
      </div>
    </div>

    <!-- 创建任务面板 -->
    <CreateTask v-show="panelFlag == 1" @mainShow="panelShowChange" :cateIdFlag="titleFlag"></CreateTask>

    <!-- 飞行日志 -->
    <FlightLogList v-show="panelFlag == 2" @mainShow="panelShowChange"></FlightLogList>

  </div>
</template>

<script>
import Card from './card.vue';
import { mapGetters } from "vuex";
import { downloadData } from "@/utils/download";
import { Loading } from 'element-ui';
import Bus from '@/assets/ligature';
import API from "@/api";
import CreateTask from './createTask';
import FlightLogList from "@/components/command/flight-task/flight-log/index-new";
import { DOM } from '@/utils/dom.js';

export default {
  components: {
    Card, CreateTask, FlightLogList,
  },
  data () {
    return {
      titleFlag: 0, // 0 常态任务， 1 临时紧急任务
      panelFlag: 0, //主面板和创建任务面板切换， 0 主面板， 1 创建任务面板
      form: {
        inputName: '',
        inputPerson: '',
      },
      cardData: [],
      typePanelShow: false,
      typeSelectFlag: -1, //类型下拉框选中标志
      safePanelShow: false,
      safeSelectFlag: -1, //安全状态下拉框选中标志
      airPanelShow: false,
      airSelectFlag: -1, //空域状态选中标志
      search: {
        taskTitle: null,
        orgId: null,
        type: null,
        taskSituation: null,
      },
      list: [],
      unitList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      trangleTimer: null,
      executeStatus: '执行状态',
      // vloading: false,
      loading: false,
    }
  },
  mounted () {
    this.$nextTick(() => {
      // 获取常态任务
      this.get_list_normal(0);
    });

    // Bus.$on('info-sidebar-selected', (v) => {
    //   if (v == 1) {
    //     this.$nextTick(() => {
    //       // 获取常态任务
    //       this.get_list_normal(0);
    //     });
    //   }
    // });
    // 获取责任单位
    this.getUnitList();


    // 滚动获取数据
    // let dom = document.getElementById('flyTaskContainerBody');
    // let domScroll = document.getElementById('fltTaskScrollToTop');
    // dom.addEventListener('scroll', () => {
    //   let clientHeight = dom.clientHeight;
    //   let scrollTop = dom.scrollTop;
    //   let scrollHeight = dom.scrollHeight;
    //   domScroll.style.top = `${scrollTop + clientHeight - 50}px`;
    //   domScroll.style.display = 'block';

    //   if (scrollTop == 0) {
    //     domScroll.style.display = 'none';
    //   }

    //   if (Math.abs(clientHeight + scrollTop - scrollHeight) < 2) {
    //     console.log('竖向滚动条已经滚动到底部')
    //     // 再次请求新的数据
    //     if (this.titleFlag == 0) {
    //       this.get_list_normal(1);
    //     } else {
    //       this.get_list_temporary(1);
    //     }
    //   }
    // });
  },
  methods: {
    /**
     *@Description: 分页
     *@Date: 2023-11-06 14:18:09
     *@Params1: 
     *@Return1: 
    */
    handleSizeChange_station (v) {
      this.pagination.pageNo = parseInt(v);
      if (this.titleFlag == 0) {
        this.get_list_normal();
      } else {
        this.get_list_temporary();
      }

    },
    // 跳到顶部
    scroll2Top () {
      let dom = document.getElementById('flyTaskContainerBody');
      dom.scrollTop = 0;
      setTimeout(() => {
        let domScroll = document.getElementById('scrollToTop');
        // console.log(domScroll, 'domScrolldomScrolldomScrolldomScroll');
        domScroll.style.display = 'none';
      }, 100);
    },
    // 点击标题常态任务，临时紧急任务
    titleClickHandle (v) {
      this.titleFlag = v;
      // 重置分页
      this.pagination.pageNo = 1;
      this.pagination.totalCount = 0;
      if (v == 0) {
        this.get_list_normal(0);
      } else {
        this.get_list_temporary(0);
      }
    },
    /**
     *@description: 获取常态任务列表
     *@date: 2023-09-28 11:14:37
     *@params: flag 0 重置搜索页面，从第1页开始， 1 按照滚动搜索，按照正常的页码顺序搜索
     *@return1: :参数2
    */
    async get_list_normal (flag) {
      this.loading = true;
      let { data, pageNo, pageSize, totalCount } = await API.TASK.List({
        cate: 3,
        taskCateId: 2,
        ...this.pagination,
        ...this.search,
      });
      // console.log(data, 'data常态任务');
      let temp = data || [];
      this.list = temp;
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
      this.loading = false;
    },
    /**
     *@description: 获取临时紧急任务列表
     *@date: 2023-09-28 11:31:06
     *@params1: flag 0 重置搜索页面，从第1页开始， 1 按照滚动搜索，按照正常的页码顺序搜索
    */
    async get_list_temporary (flag) {
      this.loading = true;
      let { data, pageNo, pageSize, totalCount } = await API.TASK.List({
        cate: 3,
        taskCateId: 3,
        ...this.pagination,
        ...this.search,
      });
      console.log(data, 'data临时紧急任务');
      let temp = data || [];
      this.list = temp;
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
      this.loading = false;
    },

    // 点击类型面板
    typePanelClick () {
      // this.safePanelShow = false;
      // this.airPanelShow = false;
      // this.typePanelShow = !this.typePanelShow;


      // console.log(this.$refs.unitCascaderPanel, 'this.$refs.unitCascaderPanel');
      // let dom = document.querySelector('.unit-cascader-drop-panel');
      // console.log(dom, 'dommmmmmmmmmmmmmm');
      // dom.style.display = 'block';
      // this.$refs.unitCascaderPanel._events.focus[0]();
      // this.cascaderFocus();


      // this.typeSelectFlag = -1;
    },
    // 类型选择
    typeClickHandle (v) {
      this.typeSelectFlag = v;
    },
    // 点击安全状态按钮
    safePanelClick () {
      this.typePanelShow = false;
      this.airPanelShow = false;
      this.safePanelShow = !this.safePanelShow;
      // this.safeSelectFlag = -1;
    },

    // 点击空域状态按钮
    airPanelClick () {
      this.typePanelShow = false;
      this.safePanelShow = false;
      this.airPanelShow = !this.airPanelShow;
    },
    // 空域状态选择
    airClickHandle (v) {
      this.airSelectFlag = v;
    },
    // 删除飞行任务（卡片）
    async deleteTask (item) {
      let res = await API.TASK.deleteTaskById(item.id);
      console.log(res, '删除飞行任务');
      if (res == 1) {
        this.$message.success("操作成功");
        if (this.titleFlag == 0) {
          this.get_list_normal();
        } else {
          this.get_list_temporary();
        }
      }
    },
    // 获取责任单位
    async getUnitList () {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
      console.log(this.unitList, 'this.unitList');
    },
    // 级联搜索
    cascaderFocus (v) {
      console.log(v, '级联搜索');
    },
    // 点击查询按钮，重置搜索
    searchClickHandle () {
      if (this.titleFlag == 0) {
        // 常态任务
        this.get_list_normal(0);
      } else {
        // 临时紧急任务
        this.get_list_temporary();
      }
    },
    // 清除标题事件
    searchInputTitleClear () {
      this.search.taskTitle = null;
      if (this.titleFlag == 0) {
        // 常态任务
        this.get_list_normal();
      } else {
        // 临时紧急任务
        this.get_list_temporary();
      }
    },
    // 清除创建人事件
    searchInputUserNameClear () {
      this.search.userName = null;
      if (this.titleFlag == 0) {
        // 常态任务
        this.get_list_normal();
      } else {
        // 临时紧急任务
        this.get_list_temporary();
      }
    },
    cascaderVisibleChange (v) {
      console.log(v, '');
      // let timer = null;
      setTimeout(() => {
        let dom = document.querySelectorAll('.input-orgname .el-input__suffix');
        console.log(dom, 'domdom');
        let d = dom[2];
        if (v) {
          d.style.transform = "rotate(0deg)";
          this.trangleTimer = setInterval(() => {
            d.style.transform = "rotate(0deg)";
            console.log('11111111');
          }, 20);
        } else {
          clearInterval(this.trangleTimer);
          setTimeout(() => {
            d.style.transform = "rotate(180deg)";
          }, 50)
        }
      }, 100);
    },
    cascaderNodeChange (v) {
      // console.log(v, 'mvvvvvvvvvvvvvvvvvvv');
      clearInterval(this.trangleTimer);
    },
    // 切换到创建任务面板
    goCreatePanel () {
      this.panelFlag = 1;
    },
    // 从创建任务面板切换回主面板
    panelShowChange () {
      this.panelFlag = 0;
      // 重新请求一次常态任务数据
      this.$nextTick(() => {
        if (this.titleFlag == 0) {
          // 常态任务
          this.get_list_normal(0);
        } else {
          // 临时紧急任务
          this.get_list_temporary(0);
        }
      });
    },
    // 飞行日志
    goFlyLogPanel () {
      // this.$message({
      //   type: 'warning',
      //   message: '暂未实现',
      //   duration: 1500
      // });
      this.panelFlag = 2;
    },
  },
}
</script>

<style lang="scss" scoped>
.fly-task-container {
  width: 100%;
  height: 100%;
  color: #fff;

  .main {
    display: block;
    width: 100%;
    height: 100%;

    .header {
      width: 100%;
      height: 54px;
      background: #224D68;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .right {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          width: 50%;
          height: 98%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }

        .title-selected {
          border-bottom: 2px solid #00F5FF !important;

          span {
            color: #00F5FF !important;
          }
        }

        .title2 {
          margin-left: 5px;
        }
      }

      .icon-plus {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }

    .form {
      width: 100%;
      height: 100px;
      background-color: #133E55;
      box-sizing: border-box;
      // padding: 15px 5px;
      // padding: 15px 5px 0 5px;
      padding: 0 10px;
      box-sizing: border-box;


      .line {
        height: 50%;
        width: 100%;
      }

      .line1 {
        // background-color: #6aa5a7;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .input-search1 {
          width: 45%;
        }

        .input-search2 {
          width: 37%;
          margin-left: -1%;
        }

        .btn1 {
          width: 12%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          background: #4E87AC;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .line2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        // border: 1px solid yellow;
        box-sizing: border-box;

        .input-orgname-fly-task {
          width: 45%;
        }

        .item {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          // background-color: gold;
          margin-left: 10px;
          cursor: pointer;

          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }

          .img {
            width: 11px;
            height: 6px;
            margin-left: 10px;
          }
        }

        .item1 {
          width: 30%;
          // width: auto;

          /deep/ .el-input__inner {
            background-color: transparent;
            color: #fff;
          }
        }

        .item2 {
          width: 38%;
          margin-left: 2%;

          // width: auto;
          .el-dropdown-link {
            color: #C0C4CC;
          }

          .img {
            // width: 29px;
            // height: 29px;
            width: 11px;
            height: 6px;
            margin-top: 1px;
          }



        }

        .panel {
          position: absolute;
          // border: 1px solid #16868F;
          top: 45px;
          // background-color: #0A293A;
          border-radius: 6px;
          padding: 10px 0 0 0;
          display: flex;
          flex-direction: column;
          height: 60px;
          width: 120px;
          height: auto;
          background-image: url('../../../../../assets/img/i-card.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          z-index: 100;

          .item {
            height: 30px;
            width: 100%;
            text-align: left;
            margin-left: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 400;
            padding: 0 10px;
            box-sizing: border-box;
            color: #FFFFFF;
          }

          .item-selected {
            background-color: #064A89;
          }

          // .triangle {
          //   position: absolute;
          //   top: -20px;
          //   overflow: hidden;
          //   width: 0;
          //   height: 0;
          //   border-width: 10px;
          //   border-style: solid dashed dashed dashed;
          // }

          // .triangle_bg {
          //   left: 20px;
          //   z-index: 3;
          //   border-color: transparent transparent #0A293A transparent;
          // }

          // .triangle_border {
          //   left: 20px;
          //   border-color: transparent transparent #ffffff transparent;
          // }
        }

        .type {
          left: 10px;
        }

        .safe-status {
          left: 160px;
          width: 100px;
        }

        .air-status {
          left: 250px;
          // height: 100px;
        }
      }

      /deep/ .el-input__inner {
        color: #fff;
      }
    }

    .body {
      // height: calc(90% -54px) !important;
      height: calc(100% - 154px);
      width: 100%;
      background-color: #0A293A;
      // background-color: #0A293A;
      // overflow-y: auto;
      box-sizing: border-box;
      padding: 0px 10px;
      position: relative;

      .card {
        margin-top: 10px;
      }

      #fltTaskScrollToTop {
        position: absolute;
        // bottom: 2%;
        right: 10px;
        // top: 0;
        z-index: 100;
        width: 28px;
        height: 28px;
        // float: right;
        // margin-top: -50px;
        cursor: pointer;
      }

      .card-body {
        width: 100%;
        height: calc(100% - 30px);
        box-sizing: border-box;
        // border: 1px solid yellow !important;
        overflow-y: auto !important;
      }

      .card-body::-webkit-scrollbar {
        display: none !important;
      }

      .pagation {
        height: 30px;
        box-sizing: border-box;
        padding: 0 0px;
        height: 30px;
        width: 100%;
        background-color: #0E3449;
        padding-top: 5px;
        // border: 1px solid red;

        /deep/ .el-pagination--small {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          padding: 0 !important;
        }

        /deep/ .el-pagination__total {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          margin-left: 5px;
        }

        /deep/ .btn-prev {
          background-color: #253B5A !important;
          color: #ffffff;
          width: 22px;
          border-radius: 2px;
        }

        /deep/ .btn-next {
          background-color: #253B5A !important;
          color: #ffffff;
          width: 22px;
          margin-left: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
        }

        /deep/ .el-pager {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;

          .number {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          li {
            margin-left: 2px;
            background-color: #253B5A !important;
            color: #ffffff;
            border-radius: 2px;
          }

          li.active {
            color: #FFFFFF;
            background-color: #409EFF !important;
          }

          li:hover {
            border: 1px solid #367DB4;
            background-color: #0C3549 !important;
          }
        }

        /deep/ .el-pagination__sizes .el-select--mini {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .el-input__inner {
            color: #ffffff;
          }
        }

        /deep/ .el-pagination__jump {
          margin-left: 5px !important;
          color: #ffffff;
          margin-top: -3px;
        }

        /deep/ .el-pagination__jump .el-input {
          width: 30px !important;
          background-color: #253B5A !important;

          .el-input__inner {
            background-color: #253B5A !important;
            color: #ffffff;
          }
        }
      }
    }

    .body::-webkit-scrollbar {
      display: none !important;
    }
  }
}
</style>
<style lang="scss">
@import "@/styles/wuhuCommon.scss";
@import "@/styles/loader.scss";

.unit-cascader-drop-panel {
  background-image: url('~@/assets/img/i101.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0 !important;
  margin: 0 !important;
  top: 215px !important;

  .el-scrollbar {
    background-color: transparent;
    border: 0;
    border-right: 1px solid #223E4D;
  }

  .el-scrollbar:last-child {
    border: 0 !important;
  }

  .el-cascader-node {
    background-color: transparent;
  }

  .el-cascader__dropdown {
    box-shadow: unset;
    margin: 0 !important;
    border: 0 !important;
  }

  .el-scrollbar__view {
    background-color: #0A293A;
  }

  .popper__arrow {
    display: none;
  }

  .el-cascader-node__label {
    color: #fff;
  }

  .el-icon-arrow-right {
    color: #fff;
  }

}

.input-orgname {
  .el-input__inner {
    background-color: transparent !important;
    color: #fff;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers，webkit内核浏览器 */
    color: #fff;
  }

  .el-input {
    display: relative;

    .el-input__suffix {
      position: absolute;
      top: 16px;
      right: 10px;
      height: 9px;
      width: 13px;
      background-image: url('~@/assets/img/i62.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }

  .el-input__suffix-inner {
    background-color: #fff;
    height: 10px !important;
    width: 10px !important;

    i {
      display: none;
    }
  }
}

.input-excute-panel {
  border: 0;
  top: 203px;

  .el-scrollbar {
    border: 0 !important;
    background-color: #0A293A !important;

    .el-select-dropdown__item {
      color: #ffffff !important;
    }
  }

  .popper__arrow {
    display: none;
  }
}
</style>