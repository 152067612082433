<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-form-item label="机巢名称:" prop="name">
        <el-input v-model="ruleForm.name" clearable placeholder="请输入机巢名称"></el-input>
      </el-form-item>
      <el-form-item label="机巢型号:" prop="model">
        <el-input v-model="ruleForm.model" clearable placeholder="请输入机巢型号"></el-input>
      </el-form-item>
      <el-form-item label="机巢位置:" prop="address">
        <el-input v-model="ruleForm.address" clearable placeholder="请输入机巢位置"></el-input>
      </el-form-item>
      <el-form-item label="机巢纬度" prop="latitude">
        <el-input v-model="ruleForm.latitude" clearable placeholder="请输入机巢纬度"></el-input>
      </el-form-item>
      <el-form-item label="机巢经度:" prop="longitude">
        <el-input v-model="ruleForm.longitude" clearable placeholder="请输入机巢经度"></el-input>
      </el-form-item>
      <el-form-item label="机巢入网ID:" prop="netId">
        <el-input v-model="ruleForm.netId" clearable placeholder="请输入机巢入网ID"
          oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
      </el-form-item>
      <el-form-item label="所属机构:" prop="organizationId">
        <el-cascader popper-class="personal-cascader" v-model="ruleForm.organizationId" :options="options" clearable
          filterable :showAllLevels="false" placeholder="请选择所属机构" :props="{
            value: 'id',
            label: 'orgName',
            children: 'children',
            emitPath: false,
            checkStrictly: true,
          }"></el-cascader>
      </el-form-item>
      <el-form-item label="机巢状态:" prop="state">
        <el-radio-group v-model="ruleForm.state">
          <el-radio :label="1">正常</el-radio>
          <el-radio :label="3">维修</el-radio>
          <el-radio :label="2">损坏</el-radio>
          <el-radio :label="4">保养</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="异常信息:" prop="abnormalContent" v-if="id && ruleForm.state != 1">
        <el-input v-model="ruleForm.abnormalContent" clearable placeholder="请输入异常信息"></el-input>
      </el-form-item>
      <el-form-item label="配发状态:" prop="store">
        <el-radio-group v-model="ruleForm.store">
          <el-radio :label="2">入库</el-radio>
          <el-radio :label="1">出库</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上传图片:" prop="image">
        <el-upload :action="url" :http-request="handle_http_request" :multiple="false" accept=".png, .jpg"
          :file-list="fileList" :on-change="changePic" :on-remove="onRemove">
          <el-button size="small" type="primary" style="margin: 0">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">图片支持jpg/png格式</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="备注说明:" prop="comment">
        <el-input v-model="ruleForm.comment" clearable type="textarea" placeholder="请输入备注说明"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn-box1027">
      <div class="btn1027" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: ['form'],
  data () {
    let validateLat = (rule, value, callback) => {
      let lat = /^(\+|)?([0-8]?\d{1}\.\d{0,1000}|90\.0{0,8}|[0-8]?\d{1}|90)$/
      if (!value) {
        callback(new Error('请输入机巢纬度'))
      } else if (!lat.test(value)) {
        callback(new Error('请输入正确的纬度(0,90)'))
      } else {
        callback()
      }
    }
    let validateLng = (rule, value, callback) => {
      let lng = /^(\+|)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,1000})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,8}|180)$/
      if (!value) {
        callback(new Error('请输入机巢经度'))
      } else if (!lng.test(value)) {
        callback(new Error('请输入正确的经度(0,180)'))
      } else {
        callback()
      }
    }
    return {
      url: "",
      ruleForm: {
        deviceId: null,
        image: null,
        store: 2
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入机巢名称",
            // validator: formRules.validateNestName,
            trigger: 'blur',
          },
        ],
        netId: [
          {
            required: true,
            message: '请输入机巢入网ID',
            trigger: 'blur',
          },
        ],
        latitude: [
          {
            required: true,
            validator: validateLat,
            trigger: 'blur'
          }
        ],
        longitude: [
          {
            required: true,
            validator: validateLng,
            trigger: 'blur'
          }
        ],
        organizationId: [
          {
            required: true,
            message: '请选择所属机构',
            trigger: 'change'
          }
        ],
        deviceId: [
          {
            required: true,
            message: '请选择关联无人机',
            trigger: 'change'
          }
        ]
      },
      options: [],
      fileList: [],
    };
  },
  methods: {
    /*机构 */
    async getOptions () {
      let res = await API.DEPARTMENT.Drd();
      // each(res);
      this.options = res || [];
    },
    async handle_http_request (e) {
      let file = e.file,
        fd = new FormData();
      fd.append("uploadFiles", file);
      let res = await API.MAPMANAGE.fileUpload(fd);
      this.ruleForm.image = res[0].url;
    },
    submit () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            // if(res == 1){
            API.DEVICE.nestEdit(this.ruleForm).then(res => {
              this.$message.success("编辑成功!");
              this.$emit("closedevice");
            })
            // }
          } else {
            API.DEVICE.nestAdd(this.ruleForm).then((res) => {
              // if (res == 1) {
              this.$message.success("创建成功!");
              this.$emit("closedevice");
              // }
            });
          }
        } else {
          return false;
        }
      });
    },
    changePic (file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; //这一步，是 展示最后一次选择文件
      }
    },
    onRemove () {
      this.ruleForm.image = null;
    },
  },
  mounted () {
    let baseUrl = process.env.VUE_APP_BASE_API;
    this.url = baseUrl + "/upload/v2_uploads";
    if (this.form != null) {
      this.ruleForm = this.form
    }
    this.getOptions();
  },
}
</script>

<style lang="scss" scoped>
.btn {
  margin-left: 120px;
  width: 94px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  background: rgba(23, 70, 216, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
    inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
    inset 0px 0px 4px 0px #00a7ff;
  border-radius: 6px;
  // border: 1px solid;
  border-image: linear-gradient(180deg,
      rgba(138, 218, 255, 1),
      rgba(82, 179, 255, 0)) 1 1;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #00d3ff;
}

.el-form-item {
  margin-bottom: 18px;
}

/deep/ .el-form-item__label {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #92d9ff;
  letter-spacing: 0;
  font-weight: 700;
}

/deep/ .el-select .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/ .el-cascader .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/ .el-textarea .el-textarea__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  // height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/ .el-input .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

.el-select,
.el-input,
.el-cascader {
  width: 316px;
}
</style>