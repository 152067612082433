<template>
  <div class="cpt-command-new_home-airway-list">
    <div class="hd">
      <div class="tr">
        <div class="th name">
          <div class="name">基站名称</div>
        </div>
        <div class="th name">
          <div class="name">基站位置</div>
        </div>
        <div class="th ope">
          <div class="ope">操作</div>
        </div>
      </div>
    </div>
    <div class="bd">
      <div class="tr" v-for="(item, index) in list" :key="index">
        <div class="td name">
          <div class="name">{{ item.name }}</div>
        </div>
        <div class="td name">
          <div class="name">{{ item.address }}</div>
        </div>
        <div class="td icon">
          <div class="action">
            <el-tooltip content="查看" placement="top">
              <span
                :class="{ active: item.__selected }"
                class="el-icon-view"
                @click="$emit('click-item', item)"
              ></span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="tb-pagination">
      <el-pagination
        small
        :page-sizes="[10, 20, 30, 40]"
        :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.totalCount"
        @size-change="handle_size_change"
        @current-change="handle_page_change"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handle_size_change(size) {
      this.$emit("size-change", size);
    },
    handle_page_change(page) {
      this.$emit("page-change", page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
@import "../../common/style.scss";
</style>