<template>
  <div class="cpt-command-device-mount cpt-command-device-common">
    <div class="head">
      <!-- <span :class="Highlight ? 'active' : ''" @click="btn">执飞员报备</span> -->
      <!-- <span :class="!Highlight ? 'active' : ''" @click="btn">飞行助理报备</span> -->
      <div class="left-img" @click="btn" :class="[Highlight ? 'head-img-selected' : 'head-img-un-selected']">
        <span>执飞员报备</span>
      </div>
      <div class="left-img" @click="btn" :class="[!Highlight ? 'head-img-selected' : 'head-img-un-selected']">
        <span>飞行助理报备</span>
      </div>
    </div>
    <div class="search-box">
      <!-- <div class="seaLeft"></div> -->
      <div class="seaRight">
        <div v-if="visible" @click="userShow" class="button-nomal button-nomal1">
          人员登记
        </div>
        <el-cascader popper-class="personal-cascader" class="cascader" v-model="filter.orgId" :options="options" clearable :show-all-levels="false" placeholder="请选择" style="margin-top: -3px" :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"></el-cascader>
        <el-select popper-class="popper-select-panel-info-person" class="w110" v-model="filter.type" placeholder="请选择" clearable @change="handle_search">
          <el-option label="执飞员" :value="0"> </el-option>
          <el-option label="飞行助理" :value="1"> </el-option>
        </el-select>
        <el-select popper-class="popper-select-panel-info-person" class="w110" v-model="filter.status" clearable placeholder="请选择" @change="handle_search">
          <el-option label="已报备" value="1"> </el-option>
          <el-option label="未报备" value="0"> </el-option>
        </el-select>
        <div @click="handle_search" class="button-nomal button-nomal2">搜索</div>
      </div>
    </div>
    <div class="content">
      <div class="containLeft" id="containLeftID">
        <div class="bd-box">
          <div class="tb-box">
            <div class="tb-hd-box" style="justify-content: space-around; padding-right: 4px">
              <div class="tb-hd-item">姓名</div>
              <div v-if="filter.type == 0" class="tb-hd-item">编号</div>
              <div class="tb-hd-item">身份</div>
              <div class="tb-hd-item">报备状态</div>
              <div class="tb-hd-item">手机号</div>
              <div class="tb-hd-item">所属单位</div>
              <div class="tb-hd-item">报备时间</div>
              <!-- 隐藏掉操作按钮 v-if="visible2" 换成false-->
              <div class="tb-hd-item" style="padding: 0 13px 0 0" v-if="false">
                操作
              </div>
            </div>
            <div class="tb-bd-box">

              <div class="tb-tr" v-for="(item, index) in list" :key="index">
                <div class="td">
                  {{ item.name || "暂无" }}
                </div>
                <div v-if="filter.type == 0" class="td">
                  {{ item.siren || "暂无" }}
                </div>
                <div class="td">
                  <span>{{ item.type == 0 ? "执飞员" : "飞行助理" }}</span>
                </div>
                <div class="td baobei td-bb-status" :class="{ weibaobei: item.status == 1 }">
                  <div class="circle" :class="{ 'weibaobei-circle': item.status == 1 }"></div>
                  {{ item.status == 1 ? "已报备" : "未报备" || "暂无" }}
                </div>
                <div class="td">
                  {{ item.phone || "暂无" }}
                </div>
                <div class="td">
                  {{ item.anotherName && String(item.anotherName).replace('芜湖', '').replace('合肥', '') || "暂无" }}
                </div>
                <div class="td">
                  {{ item.addtime || "暂无" }}
                </div>
                <!-- 隐藏掉操作按钮 v-if="visible2" 换成false-->
                <div class="td" v-if="false">
                  <el-tooltip effect="dark" content="编辑" placement="top">
                    <div style="flex: 0.3; display: flex" @click="handle_edit(item.id)">
                      <div class="yijian_baobei">
                        <span class="iconfont icon-basebianji2"></span>
                        <!-- 一键报备 -->
                      </div>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="tb-pagination">
            <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="filter.pageNo" :page-size="filter.pageSize" layout="total, prev, pager, next, sizes, jumper" :total="total" @current-change="handleSizeChange" @size-change="handlePageSizeChange">
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="containRight">
        <div class="tuli" v-if="false">
          <div class="li" v-for="(item, index) in tuli" :key="index">
            <div class="yuan" :style="{ background: item.color }"></div>
            <div class="fontO">{{ item.name }}</div>
          </div>
        </div>
        <div id="echart" style="height: 355px; width: 100%"></div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
import { Chart } from "@antv/g2";
import UserInfo from "@/components/header-right/userinfo.vue";
import { DOM } from '@/utils/dom.js';
import Bus from '@/assets/ligature.js';
export default {
  components: {
    UserInfo,
  },
  data() {
    return {
      form: null,
      Highlight: true,
      userVisible: false,
      list: [],
      filter: {
        pageNo: 1,
        pageSize: 10,
        type: null,
        name: null,
        phone: null,
        status: null,
        orgId: null,
      },
      total: 0, //表格数据总数
      options: [],
      dataFlight: [
        { name: "盐城高新区", value: 32 },
        { name: "盐都区辖区", value: 10 },
      ],
      chart: null,
      echartFilter: {
        type: null,
        status: null,
        orgId: null,
      },
      visible: false,
      xzhou: [], //x周数据
      yzhou: [], //y轴数据
      userVisible: false,
      form: null,
      visible2: false,
      tuli: [],
      yanse: [
        "#1228E4FF",
        "#17ACFFFF",
        "#6DFFCBFF",
        "#2107BFFF",
        "#FAFF23FF",
        "#FFB036FF",
        "#FF9A00FF",
        "#00ff66",
        "#ffff33",
        "#66CC66",
        "#990033",
        "#99CCFF",
        "#CC9933",
        "#009933",
        "#FF9966",
        "#6699CC",
        "#66CC66",
        "#CCCCFF",
      ], //颜色库
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  created() {
    const permissions = this.$store.state.user.user_info.permissions;
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("system:user:add") > -1) {
      this.visible = true;
    }
    if (permissionList.indexOf("system:user:edit") > -1) {
      this.visible2 = true;
    }
  },
  async mounted() {
    this.getCommanderCheck();
    this.filter.orgId = this.user_info.departmentId;
    this.echartFilter.orgId = this.user_info.departmentId;
    await this.getechartData();

    // this.init(this.dataFlight, "command_echart");
    // this.drawChart()
    this.getOptions();

    Bus.$on('zhifiyuanbaobei_initData', () => {
      console.log('xdddddddddddddddddddddddddddddddddddddddddddddddddddd');
      this.getCommanderCheck();
    });
  },

  methods: {
    userShow() {
      this.$emit("user", this.form);
    },
    async handle_edit(id) {
      let res = await API.DEVICE.userDetail(id);
      this.form = res;
      this.$emit("user", this.form);
    },
    shuaxin() {
      this.userVisible = false;
      this.form = null;
      this.getCommanderCheck();
    },

    drawChart() {
      // 提炼数据
      // let a = this.dataFlight;
      // console.log(a, "下面的值");
      this.dataFlight.forEach((item) => {
        this.xzhou.push(item.name);
        this.yzhou.push(item.value);
        // if (item.value == 0) {
        //   this.yzhou.push("-");
        // } else {
        //   this.yzhou.push(item.value);
        // }
      });

      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      // 颜色
      // #region
      let colorNew = new this.$echarts.graphic.LinearGradient(
        0,
        0,
        1,
        0,
        [
          { offset: 0, color: "#0078F4" },
          { offset: 0.1, color: "#0078F4" },
          { offset: 1, color: "#2AE7C8" },
        ],
        false
      );
      // #endregion
      let barTopColor = [
        "#1228E4FF", //1
        "#17ACFFFF", //2
        "#6DFFCBFF", //3
        "#2107BFFF", //4
        "#FAFF23FF", //5
        "#FFB036FF", //6
        "#FF9A00FF", //7
        "#00ff66", //8
        "#ffff33", //9
        "#66CC66", //10
        "#990033", //11
        "#FFFF66", //12
        "#99CCFF", //13
        "#CC9933", //14
        "#009933", //15
        "#6699CC", //17
        "#FF9966", //16
        "#66CC66", //18
        "#CCCCFF", //19
      ];
      let barBottomColor = [
        "#1765FFFF", //1
        "#4FE1FFFF", //2
        "#18DD9BFF", //3
        "#A278FCFF", //4
        "#DE8D28FF", //5
        "#FF7917FF", //6
        "#FF0000FF", //7
        "#00ffcc", //8
        "#ffff99", //9
        "#99CC66", //10
        "#CC3366", //11
        "#FFFF00", //12
        "#6699FF", //13
        "#996633", //14
        "#006633", //15
        "#666699", //17
        "#CC6600", //16
        "#006600", //18
        "#9966CC", //19
      ];
      const myChart = this.$echarts.init(document.getElementById("echart"));
      myChart.on("click", (params) => {
        // console.log(this.dataFlight, "现在什么值");
        let obj = this.dataFlight.find((o) => o.name == params.name);
        this.echartFilter.orgId = obj.orgId;
        this.filter.orgId = this.echartFilter.orgId;
        this.getechartData();
        this.getCommanderCheck();
      });

      let option = {
        color: [colorNew],
        grid: {
          top: "15%",
          left: "2%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },
        legend: {
          show: false,
        },
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: "value",
          data: this.xzhou,
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            // show: function (v) {
            //   console.log(v, 'vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv');
            //   return true;
            // },
            // show: {
            //   formatter (v) {
            //     console.log(v, 'vvvv');
            //     return true;
            //   }
            // }
          },
          // offset: 5,
          axisLine: {
            show: false,
            // symbol: ["none", "arrow"], // 是否显示轴线箭头
            lineStyle: {
              color: "#FFFFFF",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 0, //倾斜的程度
            textStyle: {
              color: "#FFFFFF",
            },
            margin: 10, //刻度标签与轴线之间的距离。
            formatter: function (value) {
              if (value.length > 4) {
                return value.slice(0, 4) + "...";
              }
              return value;
            },
          },
          // min: 2,
        },
        yAxis: {
          type: "category",
          name: '',
          nameTextStyle: {
            padding: [0, 0, 0, -70],
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#888",
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          // offset: 5,
          axisLine: {
            show: false,
            onZero: false,
            lineStyle: {
              color: "#252D42",
              color: "#FFFFFF",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#FFFFFF",
            },
          },
          data: this.xzhou,
          // min: 2,
        },
        series: [
          {
            type: "bar",
            itemStyle: {
              normal: {
                color: function (params) {

                  // return colorList[params.dataIndex];
                  return colorNew;
                },
              },
            },
            barWidth: 5,
            data: this.yzhou,
            label: {
              show: false,
              normal: {
                show: false,
                position: "top",
                formatter: "{c}",
                textStyle: {
                  fontSize: 18,
                  color: "#fff",
                  fontWeight: "400",
                  fontFamuly: "MicrosoftYaHei",
                },
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    btn() {
      this.$emit("cut");
      this.Highlight = !this.Highlight;
    },
    // 更新图表数据
    async getechartData() {
      let res = await API.PERSON.getechartData(this.echartFilter);

      this.dataFlight = res.map(
        (item) =>
        (item = {
          value: item.count,
          name: item.name,
          orgId: item.organizationId,
        })
      );
      this.xzhou = [];
      this.yzhou = [];
      this.tuli = [];
      res.forEach((item, index) => {
        this.tuli.push({ name: item.name, color: this.yanse[index] });
      });
      this.drawChart();
    },
    init(data, dataName) {
      const chart = (this.chart = new Chart({
        container: dataName,
        autoFit: true,
        height: 280,
      }));

      chart.data(data);

      chart.coordinate("theta", {
        radius: 0.75,
      });

      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });

      chart.legend({
        position: "left",
        pageNavigator: {
          marker: {
            style: {
              // 非激活，不可点击态时的填充色设置
              inactiveFill: "#fff",
              inactiveOpacity: 0.45,
              // 默认填充色设置
              fill: "#fff",
              opacity: 0.8,
              size: 16,
            },
          },
        },
        itemName: {
          style: {
            fill: " #DCE9FF",
            fontSize: 16,
          },
        },
      });

      chart.interval().adjust("stack").position("value").color("name");
      // 点击饼图
      chart.on("interval:click", async (ev) => {
        this.echartFilter.orgId = ev.data.data.orgId;
        this.filter.orgId = this.echartFilter.orgId;

        await this.getechartData();
        this.getCommanderCheck();

        this.$nextTick(() => {
          this.chart.data(this.dataFlight);
          this.chart.render();
        });
        //
      });
      chart.interaction("element-active");
      chart.render();
    },

    async getOptions() {
      let res = await API.DEPARTMENT.Drd();

      this.options = [res] || [];
    },

    async handle_search() {
      this.getCommanderCheck();
      this.echartFilter.type = this.filter.type;
      this.echartFilter.orgId = this.filter.orgId;
      this.echartFilter.status = this.filter.status;
      await this.getechartData();
      this.chart.data(this.dataFlight);
      this.chart.render();
    },
    async handle_inform(item) {
      if (item.noticeStatus == 1) {
        return false;
      }
      let sendId = this.user_info.uid;
      let receiveId = item.id;
      await API.PERSON.Inform({ sendId, receiveId });
      this.$el_message("通知成功");
      this.getCommanderCheck();
    },
    async getCommanderCheck() {
      let loadingInstance = DOM.createLoading("正在加载数据", 'containLeftID');
      try {
        let res = await API.PERSON.Check(this.filter);
        this.list = res.data || [];
        this.total = res.totalCount;
      } catch (error) {
        console.log(error);
      } finally {
        loadingInstance.close();
      }
    },
    handleSizeChange(pageNo) {
      this.getCommanderCheck();
    },
    /**
     *@Description:页码数量发生变化
     *@Date: 2023-10-13 17:41:28
     *@Params1: 
     *@Return1: 
    */
    handlePageSizeChange(v) {
      this.filter.pageSize = parseInt(v);
      this.getCommanderCheck();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/person1027.scss";

::v-deep .el-select {
  position: relative !important;

  .el-popper {
    position: absolute !important;
    top: 30px !important;
    left: 0 !important;
    z-index: 10 !important;
  }
}

.cpt-command-device-mount {
  display: flex;
  flex-direction: column;
  // padding: 16px;
  width: 100%;
  height: 100%;
  position: relative;

  .head {
    height: 42px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;

    span {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 42px;
      cursor: pointer;
    }

    height: 42px;
    width: 100%;
    // border: 1px solid yellow;
    box-sizing: border-box;
    padding: 0 10px;

    .left-img {
      width: 6%;
      height: 100%;

      span {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .unit-img {
      background: url("~@/assets/img/i65.png") no-repeat;
      background-size: 100% 100%;
    }

    .person-img {
      background: url("~@/assets/img/i128.png") no-repeat;
      background-size: 100% 100%;
    }

    .head-img-selected {
      background: url("~@/assets/img/i65.png") no-repeat;
      background-size: 100% 100%;
    }

    .head-img-un-selected {
      background: url("~@/assets/img/i128.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 28px;
  }

  .search-box {
    position: absolute;
    top: 10px;
    right: 0;
    // border: 1px solid yellow;
  }

  .content {
    display: flex;
    flex-direction: row;
    // height: 388.5px;
    width: 100%;
    margin-top: 10px;
    height: calc(100% - 52px);
    // border: 1px solid yellow;
    gap: 16px;

    .containRight {
      width: 50%;
      height: 100%;
      background: rgba(99, 177, 245, 0.09);
      border-radius: 4px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 9;

      .tuli {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        box-sizing: border-box;
        margin-top: 40px;

        .li {
          display: flex;
          align-items: center;

          .yuan {
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }

          .fontO {
            font-size: 14px;
            font-family: MicrosoftYaHeiUI;
            color: #dce9ff;
            margin: 0 0 0 11px;
          }
        }
      }
    }

    .containLeft {
      width: 50%;
      height: 100%;

      .td {
        width: calc(100% / 7);
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
      }

      .tb-hd-item {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 20px;
        width: calc(100% / 7);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .bd-box {
        margin-top: 0px;
        background: rgba(99, 177, 245, 0.09);
        width: 100%;
        height: 100%;

        .tb-box {
          height: calc(100% - 36px);
          width: 100%;
          padding: 0 12px !important;

          .tb-bd-box {
            height: calc(100% - 36px) !important;
          }
        }

        .tb-pagination {
          width: 100%;
          height: 36px;

          /deep/ .el-pagination__sizes .el-select--mini {
            width: 90px !important;
          }

          /deep/ .el-pagination__jump .el-input {
            width: 36px !important;
          }
        }
      }

      .td-bb-status {
        .circle {
          border-radius: 50%;
          width: 9px;
          height: 9px;
          background: #ffbd36;
          margin-right: 5px;
        }
      }

      .yijian_baobei {
        cursor: pointer;
      }
    }
  }

  width: 100%;
  height: 100%;
}

.search-box {
  width: auto !important;
  height: 36px;
  // border: 1px solid red;

  .seaRight {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  /deep/ .el-input__inner {
    border: 1px solid rgba(255, 255, 255, 0.21);
    background-color: #19243e;
  }
}

.echarts {
  width: 100% !important;
  height: 100% !important;
}

.cascader {
  width: 36%;

  /deep/ .el-input__inner {
    background-color: #19243e;
    border: 1px solid rgba(255, 255, 255, 0.21);
  }
}

/deep/ .el-cascader .el-input .el-input__inner:focus,
/deep/ .el-cascader .el-input.is-focus .el-input__inner {
  border-color: rgba(255, 255, 255, 0.21) !important;
}

/deep/ .el-button {
  font-family: MicrosoftYaHei !important;
  color: #dce9ff !important;
}

.baobei {
  color: #ffbd36 !important;
}

.weibaobei {
  color: #04cc0f !important;
}

.weibaobei-circle {
  background-color: #04cc0f !important;
}

.btn {
  background: url("~@/assets/images/command/personN.png") no-repeat;
  background-size: 100% 100%;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2d9ff;
  line-height: 33px;
  text-align: center;
  width: 64px !important;
  height: 33px !important;

  &:hover {
    background: url("~@/assets/images/command/twobg.png");
  }
}

.cpt-command-device-common .bd-box .tb-box .tb-bd-box {
  height: calc(100% - 60px) !important;
}

.tb-box {
  padding: 17px 0 0 0;
}

.el_cas {
  // padding: 4px 0 0 0;
}

.btn1027 {
  padding: 5px 8px;
  white-space: nowrap;
}

.button-nomal {
  height: 32px;
  background: #495e93;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 1px 0px 0px rgba(255, 255, 255, 0.29);
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 32px;
  cursor: pointer;
  padding: 0px 8px;
  width: 90px;
}

.button-nomal1 {
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-nomal2 {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.popper-select-panel-info-person {
  .el-scrollbar {
    background-color: #0a293a !important;
    border: 1px solid #223e4d;
  }

  .el-select-dropdown__item:hover {
    background-color: #34466e !important;
  }
}
</style>
