<template>
  <div class="monitoringAreaBox">
    <div class="monitoringAreaItem" v-if="flag">
      <div class="itemA mr10 mb10" v-for="(item,index) in batteryList" :key="index">
        <div class="jcsb">
          <span class="title">{{item.batteryName}}</span>
          <span style="color: green">正常</span>
        </div>
        <div
          class=""
          style="border: 1px solid rgba(255, 255, 255, 0.22)"
        ></div>
        <div class="text">
          <div>编号:{{item.batteryId}}</div>
          <div>型号:{{item.batteryType}}</div>
          <div>使用时长:{{(item.duration / 3600).toFixed(2)}}h</div>
          <div>飞行架次:{{item.flightSorties}}次</div>
        </div>
      </div>
    </div>
    <div class="monitoringAreaItem" v-else>
      <div class="itemB mr10 mb10" v-for="(item,index) in batteryList" :key="index">
        <div class="jcsb">
          <span class="title">{{item.batteryName}}</span>
          <span class="iconfont icon-yichang " style="color:red"></span>
        </div>
        <div
          class=""
          style="border: 1px solid rgba(255, 255, 255, 0.22)"
        ></div>
        <div class="text">
          <div>编号:{{item.batteryId}}</div>
          <div>型号:{{item.batteryType}}</div>
          <div>使用时长:{{(item.duration / 3600).toFixed(2)}}h</div>
          <div>飞行架次:{{item.flightSorties}}次</div>
          <div>异常信息:<span style="color:#FF7A00">{{item.abnormalContent}} 导致异常</span> </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import API from "@/api";
export default {
  data() {
    return {
      batteryList:[],
   
    };
  },
  props: {
    flag: {
      Type: Boolean,
      default: false,
    },
  },
  watch:{
    flag(val){
      this.getlist()
    }
  },
  mounted(){
    this.getlist(true)
  },
  methods:{
    async getlist(flag){
      this.batteryList = [];
      let res = await API.MONITOR.getList({type: 6,isAbnormal:Number(this.flag)})
      
      this.batteryList = res.batteryList
      if(res.batteryList.length==0 &&flag){
        this.$emit('changeFlag',1)
      }
      this.$emit('change',res.count)
    }
  }
};
</script>

<style scoped lang='scss'>
.monitoringAreaBox {
    overflow-y: auto;
    height: 70vh;
  .monitoringAreaItem {
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    overflow-y: auto;
    flex-wrap: wrap;
    .itemA {
      width: 400px;
      padding: 20px;
      height: 150px;
      background: rgba(11, 57, 117, 0.61);
      border: 1px solid #00a2e6;
      box-shadow: 0 2px 10px 0 rgba(4, 21, 69, 0.5),
        inset 0 0 38px 0 rgba(12, 149, 178, 0.5);
      border-radius: 18px;
      .title {
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        color: #00ffff;
        letter-spacing: 0;
        font-weight: 700;
      }
      .text {
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #ccf5ff;
        letter-spacing: 1.93px;
        line-height: 24px;
        font-weight: 400;
      }
    }
    .itemB {
      width: 400px;
      padding: 20px;
      background: rgba(117, 11, 11, 0.2);
      border: 1px solid rgba(230, 0, 0, 0.5);
      box-shadow: 0 2px 10px 0 rgba(4, 21, 69, 0.5),
        inset 0 0 38px 0 rgba(186, 25, 25, 0.55);
      border-radius: 18px;
      .title {
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        color: #ccf5ff;
        letter-spacing: 0;
        font-weight: 700;
      }
      .text {
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #ccf5ff;
        letter-spacing: 1.93px;
        font-weight: 400;
      }
    }
  }
}
</style>