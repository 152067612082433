<template>
  <div class="card-container">

    <div class="line line1">
      <div class="item item-left">
        <div class="f">{{ data.name }}</div>
        <!-- <div class="n">{{ data.a2 }}</div> -->
      </div>

      <div class="item item-right">
        <div class="f1" :class="[data.done ? 'gray' : 'green']">{{ data.done ? '已完成' : '未完成' }}</div>
      </div>
    </div>

    <div class="line line2">
      <span class="item item1">所属单位：{{ data.orgNames }}</span>
      <span class="item item2">开始时间：{{ data.startTime }}</span>
      <span class="item item3">结束时间：{{ data.endTime }}</span>
    </div>

    <div class="line line5">
      <div class="item">
        <!-- :class="[iFlag == 0 ? 'selected' : '']" -->
        <div class="i i1" @click="exportDone">
          <img src="@/assets/img/i18.png" alt="">
          <span>导出</span>
        </div>
        <div class="i i2" @click="sonClick(1, data.id)">
          <img src="@/assets/img/i19.png" alt="">
          <span>查看</span>
        </div>
        <div class="i i3" @click="iClickHandle(2)">
          <img src="@/assets/img/i20.png" alt="">
          <span v-if="data.doneStatus == 0" @click="sonClick(2, data.id)"> 开始点调 </span>
          <span v-else-if="data.doneStatus == 1"> 继续点调 </span>
          <span v-else="data.doneStatus == 2" @click="addTone(2, data)"> 再次点调 </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  data () {
    return {
      iFlag: 2,
    }
  },
  mounted () {

  },
  methods: {
    iClickHandle (v) {
      this.iFlag = v;
    },
    /**
     *@Description: 导出
     *@Date: 2023-10-11 11:47:52
     *@Params1: 
     *@Return1: 
    */
    exportDone () {
      console.log('this.data', this.data);
      if (this.data.id) {
        let ids = this.data.id;
        let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/dispatch/exportDispatchReport?id=${ids}&FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`;
        window.open(url);
      } else {
        this.$message.error("无导出数据！");
      }
    },
    /**
     *@Description: 点调
     *@Date: 2023-10-11 13:46:47
     *@Params1: 
     *@Return1: 
    */
    sonClick (type, id) {
      console.log(type, id);
      this.$emit("sonClick", type, id);
    },
    /**
     *@Description: 再次点调
     *@Date: 2023-10-11 14:34:33
     *@Params1: 
     *@Return1: 
    */
    addTone (type, val) {
      this.$emit("addTone", type, val.id);
    },
  },
}
</script>

<style lang="scss" scoped>
.card-container {
  width: 100%;
  height: 169px;
  background: #163B53;
  border-radius: 4px;
  padding: 5px 0 0 0;

  .line {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .line1 {
    height: 18%;
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;

    .item {

      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .item-left {
      width: 60%;

      .f {
        // border: 1px solid #3CE171;
        padding: 2px 2px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }

      .n {
        // margin-lef;
        margin-left: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .item-right {
      width: 40%;
      display: flex;
      justify-content: flex-end;

      .f1 {
        padding: 2px 5px;
        font-size: 12px !important;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }

      .gray {
        border: 1px solid #898A8E;
        color: #7F8898;
      }

      .green {
        border: 1px solid #3CE171;
        color: #3CE171;
      }
    }
  }

  .line2 {
    height: 62%;
    // background-color: rgb(0, 255, 106);
    height: 62%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    // background-color: #3CE171;
    justify-content: center;
    // padding: ;

    .item {
      // height: 90%;
      // width: 26%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      margin-top: 8px;
    }
  }

  .line5 {
    height: 20%;
    // background-color: rgb(200, 255, 0);
    display: flex;
    justify-content: flex-end;

    .item {
      height: 100%;
      width: 60%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      // background-color: #3CE171;



      .i {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 5px;
        box-sizing: border-box;

        img {
          height: 16px;
          width: 16px;
        }

        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          margin-left: 5px;
        }
      }

      .selected {
        background: #234D68;
        border-radius: 4px;
      }
    }
  }
}
</style>