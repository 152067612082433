<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-form-item label="挂载型号:" prop="model">
        <el-input v-model="ruleForm.model" clearable placeholder="请输入挂载型号"></el-input>
      </el-form-item>
      <el-form-item label="挂载名称:" prop="name">
        <el-input v-model="ruleForm.name" clearable placeholder="请输入挂载名称"></el-input>
      </el-form-item>
      <el-form-item label="挂载类型:" prop="mountId">
        <el-select popper-class="popper-select-panel-info-person" v-model="ruleForm.mountId" clearable
          placeholder="请选择挂载类型">
          <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上传图片:" prop="image">
        <el-upload :action="url" :http-request="handle_http_request" :multiple="false" accept=".png, .jpg"
          :file-list="fileList" :on-change="changePic" :on-remove="onRemove">
          <el-button size="small" type="primary" style="margin: 0">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">图片支持jpg/png格式</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="挂载状态:" prop="state">
        <el-radio-group v-model="ruleForm.state">
          <el-radio :label="1">正常</el-radio>
          <el-radio :label="2">损坏</el-radio>
          <el-radio :label="3">维修</el-radio>
          <el-radio :label="4">保养</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="异常信息:" prop="abnormalContent">
        <el-input v-model="ruleForm.abnormalContent" clearable placeholder="请输入异常信息"></el-input>
      </el-form-item>
      <el-form-item label="配发状态:" prop="store">
        <el-radio-group v-model="ruleForm.store">
          <el-radio :label="2">入库</el-radio>
          <el-radio :label="1">出库</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="所属机构:" prop="organizationId">
        <el-cascader v-model="ruleForm.organizationId" :options="options" clearable filterable :showAllLevels="false"
          popper-class="personal-cascader" placeholder="请选择所属机构" :props="{
            value: 'id',
            label: 'orgName',
            children: 'children',
            emitPath: false,
            checkStrictly: true,
          }"></el-cascader>
      </el-form-item>
      <el-form-item label="关联无人机:" prop="deviceId">
        <el-select popper-class="popper-select-panel-info-person" v-model="ruleForm.deviceId" clearable
          placeholder="请选择关联无人机">
          <el-option v-for="item in stateList" :key="item.id" :label="item.deviceName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="挂载描述:" prop="describe">
        <el-input v-model="ruleForm.describe" clearable type="textarea" placeholder="请输入挂载描述"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn-box1027">
      <div class="btn1027" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: ['form'],
  data () {
    let valiIcon = (rule, value, callback) => {
      // 图片验证
      if (!this.ruleForm.image) {
        callback(new Error("请上传图片"));
      } else {
        callback();
      }
    };
    return {
      url: "",
      ruleForm: {
        teamId: null,
        image: null,
        udpStatus: 1,
        store: 2,
        state: 1
      },
      rules: {
        name: [
          {
            required: true,
            // validator: formRules.validateDeviceName,
            message: "请输入挂载名称",
            trigger: "blur",
          },
        ],
        image: [
          {
            required: true,
            validator: valiIcon,
            trigger: "change",
          },
        ],
        organizationId: [
          {
            required: true,
            message: '请选择所属单位',
            trigger: 'change'
          }
        ],
        deviceId: [
          {
            required: true,
            message: '请选择关联无人机',
            trigger: 'change'
          }
        ]
      },
      stateList: [],
      typeList: [],
      options: [],
      fileList: [],
    };
  },
  methods: {
    async getMountType () {
      let res = await API.DEVICE.listAll()
      this.typeList = res
    },
    async getUav () {
      let res = await API.BATTERY.getUav()
      this.stateList = res
    },
    /*机构 */
    async getOptions () {
      let res = await API.DEPARTMENT.Drd();
      // each(res);
      this.options = res || [];
    },
    async handle_http_request (e) {
      let file = e.file,
        fd = new FormData();
      fd.append("uploadFiles", file);
      let res = await API.MAPMANAGE.fileUpload(fd);
      this.ruleForm.image = res[0].url;
    },
    submit () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            // if(res == 1){
            API.DEVICE.mountEdit(this.ruleForm).then(res => {
              this.$message.success("编辑成功!");
              this.$emit("closedevice");
            })
            // }
          } else {
            API.DEVICE.mountAdd(this.ruleForm).then((res) => {
              // if (res == 1) {
              this.$message.success("创建成功!");
              this.$emit("closedevice");
              // }
            });
          }
        } else {
          return false;
        }
      });
    },
    changePic (file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; //这一步，是 展示最后一次选择文件
      }
    },
    onRemove () {
      this.ruleForm.image = null;
    },
  },
  mounted () {
    let baseUrl = process.env.VUE_APP_BASE_API;
    this.url = baseUrl + "/upload/v2_uploads";
    if (this.form != null) {
      this.ruleForm = this.form
    }
    this.getOptions();
    this.getMountType()
    this.getUav()
  }
}
</script>

<style lang="scss" scoped>
.btn {
  margin-left: 120px;
  width: 94px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  background: rgba(23, 70, 216, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
    inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
    inset 0px 0px 4px 0px #00a7ff;
  border-radius: 6px;
  // border: 1px solid;
  border-image: linear-gradient(180deg,
      rgba(138, 218, 255, 1),
      rgba(82, 179, 255, 0)) 1 1;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #00d3ff;
}

.el-form-item {
  margin-bottom: 18px;
}

/deep/ .el-form-item__label {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #92d9ff;
  letter-spacing: 0;
  font-weight: 700;
}

/deep/ .el-select .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/ .el-cascader .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/ .el-textarea .el-textarea__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  // height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/ .el-input .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

.el-select,
.el-input,
.el-cascader {
  width: 316px;
}
</style>