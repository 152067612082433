<!-- 
  绩效排名
 -->
 
<template>
  <div class="cpt-command-device-mount cpt-command-device-common mb14">
    <div class="head">
      <!-- <span>绩效排名</span> -->
      <div class="left-img" @click="handle_search(0)"
        :class="[rankFlag == 0 ? 'head-img-selected' : 'head-img-un-selected']">
        <span>单位排名</span>
      </div>
      <div class="left-img" @click="handle_search(1)"
        :class="[rankFlag == 1 ? 'head-img-selected' : 'head-img-un-selected']">
        <span>人员排名</span>
      </div>
    </div>
    <!-- <div class="command-header pr"> -->
    <!-- <div class="search-box-rank">
        <div @click="handle_search(0)" class="button-nomal" :class="isOrgan ? 'button-active' : ''">
          单位排名
        </div>
        <div @click="handle_search(1)" :class="isOrgan ? '' : 'button-active'" class="button-nomal">
          人员排名
        </div>
      </div> -->
    <!-- </div> -->
    <div class="bd-box">
      <div class="tb-box" id="tbBoX">
        <div class="tb-hd-box">
          <div class="tb-hd table">排名</div>
          <div class="tb-hd table">{{ isOrgan ? "单位名称" : "人员姓名" }}</div>
          <div class="tb-hd table">飞行总任务数</div>
          <div class="tb-hd table">飞行总时长</div>
          <div class="tb-hd table">飞行总里程</div>
          <div class="tb-hd table">最新飞行时间</div>
        </div>
        <div class="tb-bd-box">
          <div class="tb-tr" v-for="(item, index) in list" :key="index">
            <div class="td">
              {{ item.serial || "暂无" }}
            </div>
            <div class="td">
              {{ isOrgan ? String(item.anotherName).replace('芜湖', '').replace('合肥', '') : item.name || "暂无" }}
            </div>
            <div class="td" style="cursor: pointer;" @click="$emit('handle_detail', item, isOrgan)">
              {{ item.totalCount || 0 }}
            </div>
            <div class="td">
              {{ formatSeconds(item.totalTime) || 0 }}
            </div>
            <div class="td">
              {{ item.totalMileage == 0 ? 0 : (item.totalMileage / 1000).toFixed(1) + "km" }}
            </div>
            <div class="td">
              {{ item.endTime || "暂无" }}
            </div>
          </div>
        </div>
      </div>
      <div class="tb-pagination">
        <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="filter.pageNo"
          :page-size="filter.pageSize" layout="total, prev, pager, next, sizes, jumper" :total="total"
          @current-change="handleSizeChange" @size-change="handlePageSizeChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { DOM } from '@/utils/dom.js';
export default {
  data () {
    return {
      filter: {
        pageNo: 1,
        pageSize: 10,
        type: 2,
      },
      total: 0,
      list: [],
      isOrgan: true,
      // 单位排名 0 ，人员排名 1
      rankFlag: 0,
    };
  },

  mounted () {
    this.getRank();
  },

  methods: {
    open () {
      this.$emit("handle_detail");
    },
    handle_search (val) {
      this.rankFlag = parseInt(val);
      this.list = [];
      if (val == 0) {
        if (!this.isOrgan) {
          this.filter.type = 2;
          this.filter.pageNo = 1;
          this.isOrgan = true;
        }
      } else {
        if (this.isOrgan) {
          this.filter.type = 1;
          this.filter.pageNo = 1;
          this.isOrgan = false;
        }
      }
      this.getRank();
    },
    async getRank () {
      let loadingInstance = DOM.createLoading("正在加载数据", 'tbBoX');
      try {
        let res = await API.PERSON.Rank(this.filter);
        this.list = res.data || [];
        this.list.forEach((item, n) => {
          item.serial = (this.filter.pageNo - 1) * this.filter.pageSize + n + 1;
          if (item.endTime) {
            item.endTime = item.endTime.split(" ")[0];
          }
        });
        this.total = res.totalCount;
      } catch (error) {
        console.log(error, '加载数据出错');
      } finally {
        loadingInstance.close();
      }
    },
    handleSizeChange (pageNo) {
      this.getRank();
    },
    formatSeconds (value) {
      if (value == 0) {
        return 0;
      }
      let result = parseInt(value);
      // let h =
      //   Math.floor(result / 3600) < 10
      //     ? "0" + Math.floor(result / 3600)
      //     : Math.floor(result / 3600);
      // let m =
      //   Math.floor((result / 60) % 60) < 10
      //     ? "0" + Math.floor((result / 60) % 60)
      //     : Math.floor((result / 60) % 60);
      // let s =
      //   Math.floor(result % 60) < 10
      //     ? "0" + Math.floor(result % 60)
      //     : Math.floor(result % 60);
      let h = (result / 3600).toFixed(2);
      result = `${h}小时`;
      return result;
    },
    /**
     *@Description: 每一页数量改变
     *@Date: 2023-10-13 11:41:04
     *@Params1: 
     *@Return1: 
    */
    handlePageSizeChange (v) {
      // console.log(v, 'vvvvvvvvvvvvvvvvv');
      this.filter.pageSize = parseInt(v);
      this.getRank();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/person1027.scss";

.cpt-command-device-mount {
  // height: 440px !important;
  // background: rgba(35, 47, 79, 0.9);
  // border-radius: 12px;
  // backdrop-filter: blur(1px);
  // width: 50% !important;
  width: 100%;
  height: 100%;
  background: #232F4F;
  box-sizing: border-box;
  // border: 1px solid yellow !important;
  position: relative;

  .head {
    // position: absolute;
    left: 0px;
    top: 0px;
    // width: 420px;
    height: 42px;
    // background: url("~@/assets/img/content_box_title_bg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-left: 20px;


    height: 42px;
    width: 100%;
    // border: 1px solid yellow;
    box-sizing: border-box;
    padding: 0 10px;

    .left-img {
      width: 12%;
      height: 100%;
      // background: url("~@/assets/img/i65.png") no-repeat;
      // background-size: 100% 100%;
      // border: 1px solid yellow;

      span {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
    }

    .unit-img {
      background: url("~@/assets/img/i65.png") no-repeat;
      background-size: 100% 100%;

    }

    .person-img {
      background: url("~@/assets/img/i128.png") no-repeat;
      background-size: 100% 100%;
    }

    .head-img-selected {
      background: url("~@/assets/img/i65.png") no-repeat;
      background-size: 100% 100%;
    }

    .head-img-un-selected {
      background: url("~@/assets/img/i128.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  // width: 707px;
  .command-header {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;

    .search-box-rank {
      display: flex;
      margin: 8px 0 20px 17px;
      gap: 20px;
    }
  }

  .td {
    width: calc(100% / 6);
  }

  .btn {
    background: url("~@/assets/images/command/person.png");
    background-size: 100%;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2d9ff;
    line-height: 33px;
    text-align: center;
    width: 92px !important;
    height: 33px !important;

    &:hover {
      background: url("~@/assets/images/command/twobg.png") no-repeat;
      background-size: 100% 100%;
    }

    span {
      margin-left: 2px;
    }
  }

  .act {
    background: url("~@/assets/images/command/twobg.png");
    background-size: 100%;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2d9ff;
    line-height: 33px;
    text-align: center;
    width: 92px !important;
    height: 33px !important;
  }
}

.cpt-command-device-mount .command-header .search-box-rank {
  overflow: hidden;
}

.table {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tb-hd {
  font-size: 14px !important;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei !important;
  font-weight: bold !important;
  color: #ffffff !important;
  text-shadow: 0px 2px 4px #020118 !important;
}

// .cpt-command-device-common .bd-box .tb-box .tb-bd-box {
//   // height: 270px;
//   overflow: auto;
// }



.tb-bd-box::-webkit-scrollbar {
  width: 4px !important;
}

.button-nomal {
  height: 32px;
  background: #495e93;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 1px 0px 0px rgba(255, 255, 255, 0.29);
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 32px;
  cursor: pointer;
  width: 80px;
  text-align: center;

  &:hover {
    background: rgba(1, 109, 255, 0.85);
  }
}

.button-active {
  background: rgba(1, 109, 255, 0.85);
}

.bd-box {
  // margin-left: 16px;
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 10px;

  // border: 1px solid yellow !important;
  .tb-box {
    width: 100%;
    height: calc(100% - 30px) !important;
    // height: 80% !important;

    // border: 1px solid rgb(9, 247, 88) !important;
    overflow-y: auto !important;

    .tb-bd-box {
      height: calc(100% - 36px) !important;
    }
  }

  .tb-pagination {
    height: 30px;
    width: 100%;

    // border: 1px solid red;
    /deep/ .el-pagination__sizes .el-select--mini {
      width: 90px !important;
    }

    /deep/ .el-pagination__jump .el-input {
      width: 36px !important;
    }
  }
}
</style>
