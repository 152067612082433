<template>
  <div class="cpt-command-airway_list">
    <List :list="list" :pagination="pagination" @click-item="(args) => $emit('click-item', args)"
      @size-change="(args) => $emit('size-change', args)" @page-change="(args) => $emit('page-change', args)" />
  </div>
</template>

<script>
import Tabs from "./tabs";
import Search from "./search";
import List from "./list";
export default {
  props: {
    pagination: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: { Tabs, Search, List },
  data () {
    return {
      tab: null,
      tabs: [
        { id: 1, label: "已执行航线", status: 1, taskCateId: 2 },
        { id: 2, label: "在执行航线", status: 3, taskCateId: 2 },
        { id: 3, label: "未执行航线", status: 0, taskCateId: 2 },
      ],
    };
  },
  methods: {
    handle_change (item) {
      this.$emit("change-status", item);
    },
    handle_click_item (args) {
      console.log("argsargsargsargs:", args);
      //(args) => $emit('click-item', args)
    },
  },
  mounted () {
    this.tab = this.tabs[0];
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-airway_list {
  color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
</style>