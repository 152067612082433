<template>
  <!-- 视频窗口 -->
  <div class="command-situation-media">
    <div class="hd-box" v-interact>历史图片</div>
    <img
      src="~@/assets/images/accident/close.png"
      class="img-close"
      @click="() => $emit('closeMedia')"
    />
    <div class="box">
      <div class="left">
        <img class="leftimg" :src="selectImg" />
      </div>
      <div class="right">
        <div class="upIcon center" @click="move('up')">
          <img src="~@/assets/images/command/up.svg" alt="" />
        </div>
        <div class="centerimg">
          <img
            v-for="(item, index) in historyImage"
            :key="index"
            @click="handleImg(index)"
            :class="selectIndex == index ? 'hightLight' : 'rightimg'"
            :src="item.url"
            alt=""
          />
        </div>
        <div class="upIcon center" @click="move('down')">
          <img src="~@/assets/images/command/down.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // historyImage: [
      //   {
      //     images:
      //       "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.mp.itc.cn%2Fq_70%2Cc_zoom%2Cw_640%2Fupload%2F20170401%2Fa586cb0aa0db441683ecddccd5b236f5_th.jpeg&refer=http%3A%2F%2Fimg.mp.itc.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1637646601&t=de2e661adf342e68e6bd54c46026a17e",
      //   },
      //   {
      //     images:
      //       "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fbbs.005.tv%2Fdata%2Fattachment%2Fforum%2F201602%2F19%2F164002an27i7awz77f55yo.jpg&refer=http%3A%2F%2Fbbs.005.tv&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1637646601&t=704a90ac84fcc5236beeee478fc3401c",
      //   },
      //   {
      //     images:
      //       "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2F1b3758c27a8006235965a12e47e305747a26f9f9126a8-o0yH9e_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1637646601&t=dc1cb25bb4b76079698999728c039764",
      //   },
      //   {
      //     images:
      //       "https://image.baidu.com/search/detail?ct=503316480&z=0&ipn=d&word=%E8%BF%9B%E5%87%BB&step_word=&hs=0&pn=9&spn=0&di=27610&pi=0&rn=1&tn=baiduimagedetail&is=0%2C0&istype=0&ie=utf-8&oe=utf-8&in=&cl=undefined&lm=undefined&st=undefined&cs=2322703511%2C1666812215&os=2132978565%2C3207149987&simid=3400479584%2C309882030&adpicid=0&lpn=0&ln=1567&fr=&fmq=1635054678976_R&fm=&ic=undefined&s=undefined&hd=undefined&latest=undefined&copyright=undefined&se=&sme=&tab=0&width=undefined&height=undefined&face=undefined&ist=&jit=&cg=&bdtype=0&oriquery=&objurl=https%3A%2F%2Fgimg2.baidu.com%2Fimage_search%2Fsrc%3Dhttp%3A%2F%2Fhbimg.b0.upaiyun.com%2Fc87b6596148aca4e45dc14e38c23e9172813f0d2e2d3b-bivoZq_fw658%26refer%3Dhttp%3A%2F%2Fhbimg.b0.upaiyun.com%26app%3D2002%26size%3Df9999%2C10000%26q%3Da80%26n%3D0%26g%3D0n%26fmt%3Djpeg%3Fsec%3D1637646601%26t%3D6f820a884012fb9cacb7937db75d77b9&fromurl=ippr_z2C%24qAzdH3FAzdH3Fi7wkwg_z%26e3Bv54AzdH3Fk5w61fAzdH3F8lbmm08lAzdH3F&gsm=1&rpstart=0&rpnum=0&islist=&querylist=&nojc=undefined",
      //   },
      // ],
      selectImg: null,
      selectIndex: 0,
    };
  },
  props: ["historyImage"],
  mounted() {
    this.selectImg = this.historyImage[0].url;
  },
  methods: {
    handleImg(index) {
      this.selectIndex = index;
      this.selectImg = this.historyImage[index].url;
    },
    move(val) {
      var div = document.getElementsByClassName("centerimg")[0];
      if (val == "up") {
        if (div.scrollTop != null) {
          div.scrollTop -= 118;
        }
      } else {
        if (div.scrollTop != null) {
          div.scrollTop += 118;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
.command-situation-media {
  z-index: 1000;
  position: absolute;
  left: 300px;
  top: 200px;
  width: 950px;
  height: 535px;
  box-sizing: border-box;
  padding: 0 30px 50px 41px;
  background: url("~@/assets/images/plate/bg_04.png");
  background-size: 100% 100%;
  .img-close {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .hd-box {
    height: 56px;
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
    line-height: 60px;
    padding-left: 120px;
    overflow: hidden;
  }
  .box {
    margin-top: 20px;
    display: flex;
    .left {
      width: 700px;
      height: 400px;
      .leftimg {
        width: 700px;
        height: 400px;
        border: 1px solid #07eaff;
      }
    }
    .right {
      margin-left: 10px;
      width: 176px;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .upIcon {
        width: 100%;
        height: 16px;
        background: #13212b;
        border: 1px solid #00c6d4;
        box-shadow: inset 0 0 7px 1px rgba(0, 198, 212, 0.55);
        img {
          width: 16px;
          height: 16px;
        }
      }
      .centerimg {
        overflow: auto;
        width: 176px;
        height: 360px;
        ::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        .rightimg {
          margin-top: 5px;
          width: 170px;
          height: 112px;
          border: 1px solid #006c75;
        }
        .hightLight {
          margin-top: 5px;
          width: 170px;
          height: 112px;
          border: 1px solid #07eaff;
        }
      }
    }
  }
}
</style>
