<!-- 备案航线 -->
<template>
  <div>
    <!-- <BoxSidebar :containerStyle="{ width: '563px' }" direction="left">
      <Airway
        :list="airway.list"
        :pagination="airway.pagination"
        @search="airway_search"
        @change-status="airway_change_status"
        @click-item="airway_change"
        @size-change="airway_change_size"
        @page-change="airway_change_page"
        @distribution="airway_distribution"
      />
    </BoxSidebar>-->
    <div class="wrap">
      <div class="tabs">
        <div class="tab">
          <el-badge :value="alarm.pagination.totalCount" v-for="item in tabs" :key="item.id"
            :hidden="item.id != 4 || alarm.pagination.totalCount == 0" class="tab-badge">
            <div v-if="item.id == 4" :class="{
              'tab-item': 1,
              [item.class_name]: 1,
              active: tab_current == item.id,
              alarm: alarm.pagination.totalCount > 0,
            }" @click="handle_change_tab(item)"></div>
            <div v-else :class="`tab-item ${item.class_name} ${tab_current == item.id ? 'active' : ''
              }`" @click="handle_change_tab(item)"></div>
          </el-badge>
        </div>
        <div class="panel">
          <div class="panel-item" v-show="tab_current == 1">
            <div class="panel-item-head">
              <!-- <div>{{ is_show_air ? task_name : "备案航线" }}</div> -->
              <div class="flex">
                <div class="biao"></div>
                <div>{{ is_show_air ? task_name : "备案航线" }}</div>
              </div>
              <div class="close" v-if="!is_show_air" @click="handle_close">
                关闭
              </div>
              <div class="close" v-else @click="handle_back">返回</div>
            </div>
            <div class="panel-item-body">
              <div v-show="!is_show_air" id="container" style="width: 100%; min-height: 420px"></div>
              <div v-show="is_show_air" v-loading="airway.loading" element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
                <Airway :list="airway.list" :pagination="airway.pagination" :current="airway.current_log_id"
                  @show-log="airway_show_log" @search="airway_search" @change-status="airway_change_status"
                  @click-item="airway_change" @size-change="airway_change_size" @page-change="airway_change_page"
                  @distribution="airway_distribution" @hedgehop="airway_hedgehop" ref="airway" />
              </div>
            </div>
          </div>
          <div class="panel-item" v-show="tab_current == 2">
            <div class="panel-item-head">
              <div class="flex">
                <div class="biao"></div>
                <div>基站</div>
              </div>
              <div class="close" @click="handle_close">关闭</div>
            </div>
            <div class="panel-item-body">
              <Station v-loading="station.loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :list="station.list" :pagination="station.pagination"
                @search="station_search" @change-status="station_change_status" @click-item="station_change"
                @size-change="station_change_size" @page-change="station_change_page"
                @distribution="station_distribution" />
            </div>
          </div>
          <div class="panel-item" v-show="tab_current == 3">
            <div class="panel-item-head shebie">
              <div class="flex">
                <div class="biao"></div>
                <div>设备预警</div>
              </div>

              <div class="tab">
                <div class="tab-item" v-for="item in warning.tabs" :key="item.id"
                  :class="{ active: warning.status.status == item.id }" @click="warning_change_tab(item.id)">
                  <!-- <el-badge :value="12"> -->
                  {{ String(item.name).replace("鹰巢", "机巢") }}
                  <!-- </el-badge> -->
                </div>
              </div>
              <div class="close" @click="handle_close">关闭</div>
            </div>
            <div class="panel-item-body">
              <Warning v-loading="warning.loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :list="warning.list" :pagination="warning.pagination"
                @search="warning_search" @change-status="warning_change_status" @click-item="warning_change"
                @size-change="warning_change_size" @page-change="warning_change_page"
                @distribution="warning_distribution" />
            </div>
          </div>
          <div class="panel-item" v-show="tab_current == 4">
            <div class="panel-item-head" style="width: 100%">
              <div class="flex">
                <div class="biao"></div>
                <div>告警</div>
              </div>
              <div class="close" @click="handle_close">关闭</div>
            </div>
            <div class="panel-item-body">
              <Alarm v-loading="alarm.loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :list="alarm.list" :pagination="alarm.pagination"
                @search="alarm_search" @change-status="alarm_change_status" @click-item="alarm_change"
                @size-change="alarm_change_size" @page-change="alarm_change_page" @distribution="alarm_distribution" />
            </div>
          </div>
          <div class="panel-item" v-show="tab_current == 5">
            <div class="panel-item-head" style="width: 100%">
              <div class="flex">
                <div class="biao"></div>
                <div>空域管理</div>
              </div>
              <div class="close" @click="handle_close">关闭</div>
            </div>
            <div class="panel-item-body panel-item-body-kygl">
              <!-- <Alarm v-loading="alarm.loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)" :list="alarm.list" :pagination="alarm.pagination"
                @search="alarm_search" @change-status="alarm_change_status" @click-item="alarm_change"
                @size-change="alarm_change_size" @page-change="alarm_change_page" @distribution="alarm_distribution" /> -->
              <Airspace></Airspace>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Log v-if="airway.current_log_id" :id="airway.current_log_id" @close="airway_close_log" />

    <div class="warning" v-if="airway.is_show_warning">
      <div class="box">
        <div class="close iconfont icon-guanbi" @click="handle_close_warning"></div>
        <div class="cont">{{ airway.warning_cont }}</div>
      </div>
    </div>
    <!-- 鼠标滑动到航线上的提示框 -->
    <div id="airline-tips">
      <el-row>
        <el-col :span="6">任务名称：</el-col>
        <el-col :span="18">{{ airInfo.taskName }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="6">任务ID：</el-col>
        <el-col :span="18">{{ airInfo.taskId }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="6">添加时间：</el-col>
        <el-col :span="18">{{ airInfo.addtime }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="6">飞行名称：</el-col>
        <el-col :span="18">{{ airInfo.flightName }}</el-col>
      </el-row>
    </div>

    <!-- 鼠标滑动到opensky上的提示框 -->
    <div id="opensky-tips">
      <el-row>
        <el-col :span="12">编码：</el-col>
        <el-col :span="12">{{ openskyInfo[0] }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">通信呼号：</el-col>
        <el-col :span="12">{{ openskyInfo[1] }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">国家/地区：</el-col>
        <el-col :span="12">{{ openskyInfo[2] }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">位置最新时间：</el-col>
        <el-col :span="12">{{ openskyInfo[3] }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">最新联系时间：</el-col>
        <el-col :span="12">{{ openskyInfo[4] }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">经度：</el-col>
        <el-col :span="12">{{ openskyInfo[5] }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">纬度：</el-col>
        <el-col :span="12">{{ openskyInfo[6] }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">气压高度：</el-col>
        <el-col :span="12">{{ openskyInfo[7] }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">速度：</el-col>
        <el-col :span="12">{{ openskyInfo[9] }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">角度：</el-col>
        <el-col :span="12">{{ openskyInfo[10] }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">海拔：</el-col>
        <el-col :span="12">{{ openskyInfo[13] }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12">应答器代码：</el-col>
        <el-col :span="12">{{ openskyInfo[14] }}</el-col>
      </el-row>
    </div>

    <!-- 刷新opensky数据按钮 -->
    <div class="opensky-refresh" v-show="showFlag" @click="refreshData" title="刷新数据">
      <i class="el-icon-refresh"></i>
    </div>


  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
import Popup from "./methods/popup";
import BoxSidebar from "@/components/box-sidebar";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import Highcharts3D from "highcharts/highcharts-3d";
HighchartsMore(Highcharts);
Highcharts3D(Highcharts);
import data from "./data";
import methods from "./methods";
import Airway from "@/components/command/new-home/airway";
import Station from "@/components/command/new-home/station";
import Warning from "@/components/command/new-home/warning";
import Alarm from "@/components/command/new-home/alarm";
import Log from "@/components/command/new-home/log";
import warning_image from "@/assets/images/icons/warning.svg";
import axios from 'axios';
import Bus from "@/assets/ligature";
import Airspace from "@/pages/command/airspace";

let uav_entity = {};
let alarm_entity = {};
let handler = null;
let billboardsOpenSky = null;
let billboardsAirLine = null;

export default {
  components: {
    BoxSidebar,
    Airway,
    Station,
    Warning,
    Alarm,
    Log,
    Airspace,
  },
  data () {
    return {
      icon_id_arr: [],
      timer: null,
      airline_map: {},
      tabs: [
        {
          id: 1,
          class_name: "tab-item-a",
          title: "备案航线",
        },
        {
          id: 2,
          class_name: "tab-item-b",
          title: "基站",
        },
        {
          id: 3,
          class_name: "tab-item-c",
          title: "预警",
        },
        {
          id: 4,
          class_name: "tab-item-d",
          title: "警情",
        },
        {
          id: 5,
          class_name: "tab-item-e",
          title: "空域管理",
        },
      ],
      tab_current: 1,
      is_show_air: false,
      pie_data: [],
      task_name: "",
      ...data,
      airInfo: {},
      // openskyData
      openSkyResDataArray: [],
      openskyInfo: [],
      showFlag: true,
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  async mounted () {
    // 设置刷新按钮位置
    let a = document.querySelector('.qysStyleLeft');
    let b = parseFloat(window.getComputedStyle(a).getPropertyValue('height'));
    document.querySelector('.opensky-refresh').style.top = `${b + 50}px !important`;
    this.showFlag = true;
    // 回调执行函数 监控命名规则为 watch_+变量名
    this.g_bus.$on("watch_viewer", async () => {
      console.log('watch_viewerwatch_viewerwatch_viewerwatch_viewerwatch_viewer');
      // 具体函数内容
      this.airway_init();
    });

    const loadingDefault = this.$loading({
      lock: true,
      text: '正在加载数据...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.5)'
    });
    ///hawksystemserver/task2uav/getTaskFlightLineSelect 航线列表
    await this.get_pie_data();
    await this.init_pie();
    window.viewer.scene.globe.depthTestAgainstTerrain = false;

    // 获取到全部航线
    if (!billboardsAirLine) {
      billboardsAirLine = window.viewer.scene.primitives.add(new Cesium.PolylineCollection());
      let res = await this.airway_getAllairline();
      this.addAirLine2Map(res);
    } else {
      let len = billboardsAirLine.length;
      for (let i = 0; i < len; ++i) {
        let b = billboardsAirLine.get(i);
        b.show = true;
      }
    }

    // 获取opensky
    loadingDefault.close();
    if (!billboardsOpenSky) {
      billboardsOpenSky = window.viewer.scene.primitives.add(new Cesium.BillboardCollection());
      axios.all([this.airway_getOpenSkyData1(), this.airway_getOpenSkyData2(), this.airway_getOpenSkyData3(), this.airway_getOpenSkyData4()]).then(
        axios.spread((c, d, e, f) => {
          this.addOpenSkyData2Map(c?.data.states);
          this.addOpenSkyData2Map(d?.data.states);
          this.addOpenSkyData2Map(e?.data.states);
          this.addOpenSkyData2Map(f?.data.states);
          loadingDefault.close();
        })
      );
    } else {
      let len = billboardsOpenSky.length;
      for (let i = 0; i < len; ++i) {
        let b = billboardsOpenSky.get(i);
        b.show = true;
      }
      window.viewer.scene.forceRender();
    }

    let handler = new Cesium.ScreenSpaceEventHandler(window.viewer.scene.canvas);
    let tipsDom = document.getElementById('airline-tips');
    let openskyTipsDom = document.getElementById('opensky-tips');
    handler.setInputAction((event) => {
      let pickedObjects = window.viewer.scene.drillPick(event.endPosition);
      if (Cesium.defined(pickedObjects)) {
        if (pickedObjects.length > 0) {
          let pick = pickedObjects[0];
          if (pick.id?.flag && pick.id.flag == 'airline') {
            //转屏幕坐标
            let panCoord = event.endPosition;
            tipsDom.style.display = 'block';
            let d = parseFloat(window.getComputedStyle(tipsDom).getPropertyValue('height'));
            let innerWidth = window.innerWidth;
            tipsDom.style.left = `${panCoord.x - 180}px`;
            if (panCoord.x < 180) {
              tipsDom.style.left = `${panCoord.x}px`;
            }
            if (innerWidth - panCoord.x < 180) {
              tipsDom.style.left = `${panCoord.x - 360}px`;
            }
            tipsDom.style.top = `${panCoord.y - 60 - parseFloat(window.getComputedStyle(tipsDom).getPropertyValue('height'))}px`;
            if (panCoord.y - parseFloat(d) < 130) {
              tipsDom.style.top = `${panCoord.y + 10}px`;
            }
            this.airInfo = pick.id.value;
          }
          if (pick.id?.flag && pick.id.flag == 'opensky') {
            //转屏幕坐标
            let panCoord = event.endPosition;
            openskyTipsDom.style.display = 'block';
            let d = parseFloat(window.getComputedStyle(openskyTipsDom).getPropertyValue('height'));
            let innerWidth = window.innerWidth;
            openskyTipsDom.style.left = `${panCoord.x - 180}px`;
            if (panCoord.x < 180) {
              openskyTipsDom.style.left = `${panCoord.x}px`;
            }
            if (innerWidth - panCoord.x < 180) {
              openskyTipsDom.style.left = `${panCoord.x - 360}px`;
            }
            openskyTipsDom.style.top = `${panCoord.y - 60 - parseFloat(window.getComputedStyle(openskyTipsDom).getPropertyValue('height'))}px`;
            if (panCoord.y - parseFloat(d) < 130) {
              openskyTipsDom.style.top = `${panCoord.y + 10}px`;
            }
            this.openskyInfo = pick.id.value;
          }
        } else {
          tipsDom.style.display = 'none';
          openskyTipsDom.style.display = 'none';
        }
      } else {
        tipsDom.style.display = 'none';
        openskyTipsDom.style.display = 'none';
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);


    // ///hawksystemserver/task2uav/devicesByTasking 在执无人机列表
    // this.airway_uav_data();
    // this.click_billboard();

    // //hawksystemserver/task2uav/devicesByTasking
    this.airway_list();
    this.station_list();
    this.warning_list();
    this.alarm_list();

    // this.get_alarm_list()

    // this.timer = setInterval(() => {
    //   this.airway_uav_data();
    // }, 5000);
  },
  methods: {
    ...methods,
    handle_close_warning () {
      this.airway.is_show_warning = false;
    },
    async get_alarm_list () {
      let res = await API.TOWER.JingQingList({
        unfinish: 1,
        pageSize: 100000,
      });
      res.data.forEach((item) => {
        this.show_alarm(item);
      });
    },
    show_alarm (item) {
      let entity_data = {
        id: item.id,
        name: item.typeTitle,
        address: item.incidentAddress,
        content: item.name,
        longitude: item.sfjd || item.gisx,
        latitude: item.sfwd || item.gisy,
        addtime: item.addtime,
      };
      if (item.gisx && item.gisy) {
        let entity_id = JSON.stringify(entity_data);
        let position = Cesium.Cartesian3.fromDegrees(
          Number(entity_data.longitude),
          Number(entity_data.latitude)
        );
        alarm_entity[item.id] = {};
        alarm_entity[item.id].billboard = viewer.entities.add({
          id: entity_id,
          name: "warning",
          position,
          billboard: {
            width: 32,
            height: 32,
            image: warning_image,
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
        });
        // alarm_entity[item.id].ellipse = viewer.entities.add({
        //     name: "",
        //     position: position,
        //     ellipse: {
        //       // height: 200,
        //       semiMinorAxis: 500,
        //       semiMajorAxis: 500,
        //       material: new Cesium.CircleWaveMaterialProperty({
        //         duration: 2e3,
        //         gradient: 0,
        //         color: new Cesium.Color(1.0, 0.0, 0.0, 1.0),
        //         count: 3
        //       })
        //     }
        // });
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            Number(entity_data.longitude),
            Number(entity_data.latitude),
            5000.0
          ),
        });
      }
    },
    async get_pie_data () {
      this.airway.loading = true;
      let dt = new Date();
      let a = dt.getFullYear();
      let b = dt.getMonth();
      b = b + 1;
      b = b < 10 ? '0' + b : b;
      let c = dt.getDate();
      c = c < 10 ? '0' + c : c;
      // a + '-' + b + '-' + c
      let time = DEFAULT_CONFIG_getTaskFlightLineSelectTIME ? DEFAULT_CONFIG_getTaskFlightLineSelectTIME : '2023-07-18';
      let res = await API.TOWER.ListAirway({ time: time });
      this.airway.loading = false;
      // console.log(res, 'hangxian')
      if (res && res.data) {
        const { completeCount, executeCount, expectationCount } = res.data;
        this.pie_data = [
          ["已完成", completeCount],
          ["执行中", executeCount],
          ["待执行", expectationCount],
        ];
      }
    },
    close (id) {
      // uav_entity.forEach(item => {
      //   viewer.entities.remove(item)
      // })
      if (uav_entity[id] && uav_entity[id].polyline) {
        viewer.entities.remove(uav_entity[id].polyline);
        uav_entity[id].polyline = null;
      }
      if (uav_entity[id] && uav_entity[id].track) {
        viewer.entities.remove(uav_entity[id].track);
        uav_entity[id].track = null;
      }
      if (uav_entity[id] && uav_entity[id].points) {
        uav_entity[id].points.forEach((item) => {
          viewer.entities.remove(item);
        });
        uav_entity[id].points = [];
      }
    },
    click_station (entity_json_data, cartesian) {
      let _this = this;
      try {
        let data = JSON.parse(entity_json_data);
        let id = data.id;
        let arr = _this.icon_id_arr;
        // 调用弹窗方法
        if (arr.indexOf(data.id) < 0) {
          _this.icon_id_arr.push(data.id);
          uav_entity[id].popup = new Popup({
            viewer: viewer,
            geometry: cartesian,
            data: data,
            vue_this: this,
          });
        }
      } catch (error) { }
    },
    click_warning (entity_json_data, cartesian) {
      let _this = this;
      try {
        let data = JSON.parse(entity_json_data);
        let id = data.id;
        let arr = _this.icon_id_arr;
        // 调用弹窗方法
        if (arr.indexOf(data.id) < 0) {
          _this.icon_id_arr.push(data.id);
          uav_entity[id].popup = new Popup({
            viewer: viewer,
            geometry: cartesian,
            data: data,
            vue_this: this,
          });
        }
      } catch (error) { }
    },
    click_billboard () {
      handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction(async (movement) => {
        let pickedLabel = viewer.scene.pick(movement.position);
        // console.log('pickedLabel',  pickedLabel)
        if (Cesium.defined(pickedLabel) && pickedLabel.id) {
          let entity_json_data = pickedLabel.id.id;
          let pick = new Cesium.Cartesian2(
            movement.position.x,
            movement.position.y
          );
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          if (pickedLabel.id.name == "home_uav") {
            this.airway_click_uav(entity_json_data, cartesian);
          } else if (pickedLabel.id.name == "station") {
            this.click_station(entity_json_data, cartesian);
          } else if (pickedLabel.id.name == "warning") {
            this.click_warning(entity_json_data, cartesian);
          } else if (pickedLabel.id.name == "home_airline") {
            this.airway_change_show_popup(pickedLabel.id.id, cartesian, true);
            this.airway_change_show_current_label(pickedLabel.id.id, true);
          }
          if (pickedLabel.id && typeof pickedLabel.id === "number") {
            this.airway_change_show_popup(pickedLabel.id, cartesian, true);
            this.airway_change_show_current_label(pickedLabel.id, true);
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    clear_entity () {
      if (handler) {
        handler.destroy();
        handler = null;
      }
      for (let key in uav_entity) {
        // viewer.entities.remove(uav_entity[key])
        this.close(key);
        if (uav_entity[key] && uav_entity[key].billboard) {
          viewer.entities.remove(uav_entity[key].billboard);
        }
        if (uav_entity[key] && uav_entity[key].popup) {
          uav_entity[key].popup.close();
        }
      }
      uav_entity = {};
    },
    init_pie (item) {
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = Highcharts.chart("container", {
        chart: {
          type: "pie",
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0,
          },
        },
        legend: {
          itemStyle: {
            // fontSize:'35px',
            // font: '35pt Trebuchet MS, Verdana, sans-serif',
            color: "#fff",
          },
          itemHoverStyle: {
            color: "#00ffff",
          },
          // itemHiddenStyle: {
          //     color: '#444'
          // }
        },
        title: {
          text: "",
        },
        tooltip: {
          // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          pointFormat: "{series.name}: <b>{point.y}</b>",
        },
        // 去掉 highcharts水印
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
            showInLegend: true,
            events: {
              click: (e) => {
                this.task_name = e.point.name;
                switch (e.point.name) {
                  case "待执行":
                    this.airway.status.status = 0;
                    break;
                  case "执行中":
                    this.airway.status.status = 3;
                    break;
                  case "已完成":
                    this.airway.status.status = 1;
                    break;
                }
                this.airway_list();
                this.handle_change_air();
              },
            },
          },
        },
        series: [
          {
            type: "pie",
            name: "数量",
            data: this.pie_data,
          },
        ],
      });
      this.chart.update({
        chart: {
          backgroundColor: null, //背景为透明，使用rgba(0,0,0,0)或空字符也可以实现透明
        },
      });
    },
    handle_change_tab (item) {
      if (item.id == 1) {
        if (this.$refs.airway?.$children[0]?.name) {
          this.$refs.airway.$children[0].name = "";
          this.airway_search();
        }
      }
      if (this.tab_current == item.id) {
        this.tab_current = null;
      } else {
        if (item.id == 1) {
          this.is_show_air = false;
        }
        this.tab_current = item.id;
      }
      this.airway.current_log_id = null;
    },
    handle_close () {
      this.tab_current = null;
    },
    handle_change_air () {
      this.is_show_air = true;
    },
    handle_back () {
      this.is_show_air = false;
      if (this.$refs.airway?.$children[0]?.name) {
        this.$refs.airway.$children[0].name = "";
        this.airway_search();
      }
    },
    // 把获取到的航线加载的球上
    addAirLine2Map (res) {
      res.list.forEach(item => {
        let a = JSON.parse(item.flightCourseJson);
        let b = a.points;
        let pointHeightArray = [];
        b.forEach(v => {
          // console.log(parseFloat(v.alt) + 600, ' parseFloat(v.alt)');
          pointHeightArray.push(parseFloat(v.lon), parseFloat(v.lat), parseFloat(v.alt) + 0);
        });
        if (pointHeightArray.length >= 2) {
          billboardsAirLine.add({
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(pointHeightArray),
            width: 4,
            material: Cesium.Material.fromType('Color', {
              color: Cesium.Color.fromRandom()
            }),
            id: { flag: 'airline', value: item }
          });
        }
      });
      window.viewer.scene.forceRender();
    },
    // 把opensky的数据加载到球上 this.openSkyResDataArray.length
    addOpenSkyData2Map (openSkyResDataArray) {
      for (let i = 0; i < openSkyResDataArray.length; i++) {
        if (openSkyResDataArray[i][5] != null && openSkyResDataArray[i][6] != null) {
          // if (this.openSkyResDataArray[i][0] != null && this.openSkyResDataArray[i][1] != null) {
          billboardsOpenSky.add({
            position: new Cesium.Cartesian3.fromDegrees(parseFloat(openSkyResDataArray[i][5]), parseFloat(openSkyResDataArray[i][6]), 100),
            image: 'image/aircraft.png',
            rotation: openSkyResDataArray[i][10] == null ? 0 : parseFloat(openSkyResDataArray[i][10]),
            scale: .1,
            id: { flag: 'opensky', value: openSkyResDataArray[i] }
          });
        }
      }
      // window.viewer.scene.forceRender();
    },
    /**
     * discard
     * **/
    addOpenSkyData2Map1 () {
      for (let i = 0; i < this.openSkyResDataArray.length; i++) {
        if (this.openSkyResDataArray[i][5] != null && this.openSkyResDataArray[i][6] != null) {
          // if (this.openSkyResDataArray[i][0] != null && this.openSkyResDataArray[i][1] != null) {
          billboardsOpenSky.add({
            position: new Cesium.Cartesian3.fromDegrees(parseFloat(this.openSkyResDataArray[i][5]), parseFloat(this.openSkyResDataArray[i][6]), 100),
            image: 'image/aircraft.png',
            rotation: this.openSkyResDataArray[i][10] == null ? 0 : parseFloat(this.openSkyResDataArray[i][10]),
            scale: .1,
            id: { flag: 'opensky', value: this.openSkyResDataArray[i] }
          });
        }
      }
      window.viewer.scene.forceRender();
    },
    // 刷新航线数据和opensky数据
    async refreshData () {
      const loading = this.$loading({
        lock: true,
        text: '正在加载全球数据...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });

      let res = await this.airway_getAllairline();
      billboardsAirLine.removeAll();
      // billboardsAirLine = window.viewer.scene.primitives.add(new Cesium.PolylineCollection());
      this.addAirLine2Map(res);

      axios.all([this.airway_getOpenSkyData1(), this.airway_getOpenSkyData2(), this.airway_getOpenSkyData3(), this.airway_getOpenSkyData4()]).then(
        axios.spread((c, d, e, f) => {
          billboardsOpenSky.removeAll();
          // billboardsOpenSky.destory();
          // billboardsOpenSky = window.viewer.scene.primitives.add(new Cesium.BillboardCollection());
          this.addOpenSkyData2Map(c?.data.states);
          this.addOpenSkyData2Map(d?.data.states);
          this.addOpenSkyData2Map(e?.data.states);
          this.addOpenSkyData2Map(f?.data.states);
          loading.close();
        })
      );
      loading.close();
    }
  },
  destroyed () {
    // clearInterval(this.timer);
    // this.clear_entity();
    this.showFlag = false;
    if (billboardsOpenSky != null) {
      let len = billboardsOpenSky.length;
      for (let i = 0; i < len; ++i) {
        let b = billboardsOpenSky.get(i);
        b.show = false;
      }
    }
    if (billboardsAirLine != null) {
      let len1 = billboardsAirLine.length;
      for (let i = 0; i < len1; ++i) {
        let b = billboardsAirLine.get(i);
        b.show = false;
      }
    }
    window.viewer.scene.forceRender();
  },
};
</script>

<style lang="scss" scss>
.wrap {
  position: absolute;
  top: 200px;
  left: 0;
  z-index: 1;

  // min-width: 630px;
  // height: 600px;
  .tabs {
    display: flex;

    .tab {

      // width: 30px;
      .tab-badge {
        display: block;

        .tab-item {
          width: 52px;
          height: 52px;
          margin-bottom: 10px;
          cursor: pointer;

          // padding: 10px 0;
          // writing-mode: vertical-rl;`
          // background: rgba(0,23,79,0.70);
          // box-shadow: inset 0 0 20px 0 rgba(61,205,255,0.05);
          &-a {
            background: url("~@/assets/images/command/home/tab-a.svg") center no-repeat;
            // background: url("~@/assets/images/command/home/tab-a.png") center no-repeat;

            &.active {
              background: url("~@/assets/images/command/home/tab-a_active.svg") center no-repeat;
              // background: url("~@/assets/images/command/home/tab-a_active.png") center no-repeat;
            }
          }

          &-b {
            background: url("~@/assets/images/command/home/tab-b.svg") center no-repeat;
            // background: url("~@/assets/images/command/home/tab-b.png") center no-repeat;

            &.active {
              background: url("~@/assets/images/command/home/tab-b_active.svg") center no-repeat;
              // background: url("~@/assets/images/command/home/tab-b_active.png") center no-repeat;
            }
          }

          &-c {
            background: url("~@/assets/images/command/home/tab-c.svg") center no-repeat;

            &.active {
              background: url("~@/assets/images/command/home/tab-c_active.svg") center no-repeat;
            }
          }

          &-d {
            background: url("~@/assets/images/command/home/tab-d.svg") center no-repeat;

            &.alarm {
              background: url("~@/assets/images/command/home/tab-d_alarm.png") center no-repeat;
            }

            &.active {
              background: url("~@/assets/images/command/home/tab-d_active.svg") center no-repeat;
            }
          }

          &-e {
            background: url("~@/assets/images/command/home/tab-e.svg") center no-repeat;

            &.active {
              background: url("~@/assets/images/command/home/tab-e_active.svg") center no-repeat;
            }
          }
        }
      }
    }

    .panel {
      // min-width: 485px;
      margin-left: 10px;
      flex: 1;

      .panel-item {
        background: rgba(0, 23, 79, 0.7);
        box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
          inset 0 0 40px 0 rgba(0, 184, 255, 0.5);

        // border-radius: 10px;
        .panel-item-head {
          // display: flex;
          position: relative;
          // padding: 10px 20px;
          font-size: 18px;
          color: #00ffff;
          letter-spacing: 0;
          font-weight: 700;
          background: rgba(16, 65, 215, 0.2);
          box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
          // border-radius: 10px 10px 0 0;
          // 修改后的数据
          min-width: 468px;
          line-height: 32px;
          height: 32px;
          background: linear-gradient(180deg,
              #9198ff 0%,
              rgba(45, 81, 153, 0.45) 40%,
              #05091a 100%);
          box-shadow: inset 0px 0px 10px 2px #3f9dff;
          border: 1px solid #43deff;
          font-size: 18px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
          text-shadow: 0px 2px 4px #136791;

          .tab {
            position: absolute;
            right: 80px;
            top: 1px;
            display: flex;
            margin-left: 30px;

            // height: 18px;
            .tab-item {
              padding: 0 10px;
              margin: 0 5px;
              font-size: 12px;
              color: rgba(0, 255, 255, 0.8);
              letter-spacing: 0;
              font-weight: 400;
              background: rgba(0, 3, 36, 0.4);
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
                inset 0 0 15px 0 rgba(0, 182, 255, 0.9);
              border-radius: 6px;
              height: 28px;
              line-height: 28px;
              text-align: center;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }

              &:active {
                opacity: 0.5;
              }

              &.active {
                background: rgba(0, 34, 140, 0.2);
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
                  inset 0 0 15px 0 #00b6ff;
                border-radius: 6px;
              }
            }
          }

          .close {
            position: absolute;
            top: 0px;
            right: 20px;
            cursor: pointer;
            font-size: 16px;
            color: #00ffff;
            letter-spacing: 0;
            font-weight: 400;
          }
        }

        .panel-item-body {
          max-height: 600px;
          padding: 10px;
          color: #b5e5ff;
          overflow-y: auto;
          //修改后
          background: rgba(0, 39, 121, 0.5);
          box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
            inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
            inset 0px 0px 8px 0px #019aff;
          border: 1px solid #43deff;
          backdrop-filter: blur(2px);
        }
      }
    }
  }
}

.warning {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  // align-items: center;
  background: url("~@/assets/images/command/home/warning.png") center no-repeat;

  .box {
    position: relative;
    margin-top: 150px;
    padding: 40px;
    width: 400px;
    height: 100px;
    background: rgba(22, 29, 45, 0.8);
    box-shadow: 0 2px 13px 0 rgba(195, 0, 0, 0.14);
    border-radius: 10px;
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #fd4d4d;
    text-align: center;
    line-height: 28px;
    font-weight: 400;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      background: #fd4d4d;
      border-radius: 50%;
      color: rgba(22, 29, 45, 0.8);
      text-align: center;
      line-height: 20px;
      cursor: pointer;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 0 0 16px;
}

.biao {
  width: 3px;
  height: 15px;
  background: #ffbd36;
  margin: 3px 5px 0 0;
}

.shebie {
  display: flex;
  align-items: center;
  width: 100% !important;
}

.input-box {
  height: 32px;
  background: rgba(13, 50, 92, 0.7);
  border: 1px solid #43deff;
}

/deep/ .panel-item-body .cpt-command-airway_list .cpt-command-new_home-airway-search .button-box button {
  height: 32px;
  background: rgba(23, 70, 216, 0.2) !important;
  box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
    inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
    inset 0px 0px 4px 0px #00a7ff !important;
  border-radius: 6px !important;
  border: 0px solid !important;
  border-image: linear-gradient(180deg,
      rgba(138, 218, 255, 1),
      rgba(82, 179, 255, 0)) 1 1 !important;
  font-size: 12px !important;
  font-family: MicrosoftYaHei !important;
  color: #43deff !important;
  line-height: 16px !important;
}

.hd {
  height: 38px !important;
  background: rgba(0, 39, 121, 0.2);
  border: 1px solid rgba(207, 234, 255, 0.33);
}

.bd .tr {
  padding: 2px 0px;
  border: 1px solid transparent;
  // height: 38px;
  background: rgba(2, 19, 96, 0.2);
  // border: 1px solid rgba(207, 234, 255, 0.33);

  background-image: url("~@/assets/newImage/tiaokaung.png");
  background-size: 100% 100%;
  margin: 8px 0 0 0;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background: rgba(2, 19, 96, 0.2);
    box-shadow: inset 0px 0px 10px 2px #3fcbff;
    border: 1px solid #70f6f9;
  }

  .td {
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;

    .name {
      height: 100% !important;
    }
  }
}

.bd {
  // height: 425px;
  height: auto;
  overflow-y: scroll;
  flex: none !important;
  margin: 0 0 16px 0;
}

#airline-tips {
  width: 360px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  position: absolute;
  color: #ffffff;
  padding: 15px;
  border-radius: 6px;
  // text-align: center;
}

#opensky-tips {
  width: 240px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  position: absolute;
  color: #ffffff;
  padding: 15px;
  border-radius: 6px;
}

.opensky-refresh {
  // position: absolute;
  height: 48px;
  width: 48px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  font-size: 20px;
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  background: rgba(35, 47, 79, 0.75);
  opacity: 0.7;
  margin-top: 260px;
  cursor: pointer;
  z-index: 99999999;
  position: absolute;
  top: 92px;
  right: 22px;
}

.opensky-refresh:hover {
  background: rgba(22, 29, 45, 0.7);
  border: 1px solid #43ddfe;
  color: #43ddfe;
}

.panel-item-body-kygl {
  height: 550px;
  width: 595px;
}
</style>