<!-- 
  备案航线组件
 -->
<template>
  <div class="card-container">
    <div class="line line1">
      <div class="item item-left">
        <div class="f" :class="[data.taskStatus == 1 ? '' : 'f0']">{{ data.taskStatus == 1 ? '安全' : '待确定' }}</div>
        <div class="n">{{ data.flightName }}</div>
      </div>
      <div class="item item-right" style="visibility: hidden;">
        <div class="f1">{{ data.a3 }}</div>
      </div>
    </div>

    <div class="line line2" v-show="false">
      <div class="item item1">
        <span class="num">{{ data.a4 }}</span>
        <span class="name">航线长度(m)</span>
      </div>
      <div class="item item2">
        <span class="num">{{ data.a5 }}</span>
        <span class="name">预计时长(S)</span>
      </div>
      <div class="item item3">
        <span class="num">{{ data.a6 }}</span>
        <span class="name">航点个数(个)</span>
      </div>
    </div>

    <div class="line line3">

      <div class="item item1">
        <img src="@/assets/img/i2.png" alt="">
        <span>{{ data.id }}</span>
      </div>

      <div class="item item2">
        <img src="@/assets/img/i3.png" alt="">
        <span>所属区域：{{ String(data.flightName).split('-')[0] }}</span>
      </div>

    </div>

    <div class="line line4">

      <div class="item item1">
        <img src="@/assets/img/i1.png" alt="">
        <span>{{ data.addtime }}</span>
      </div>

      <div class="item item2">
        <img src="@/assets/img/i4.png" alt="">
        <span>{{ data.flightName }}</span>
      </div>

    </div>

    <div class="line line5">

      <div class="item item1">
        <img src="@/assets/img/c.png" alt="">
        <span>执行时间：{{ data.executeTime }}</span>
      </div>

      <div class="item item2">
        <!-- <img src="@/assets/img/i7.png" alt="" style="visibility: hidden;"> -->
        <!-- <img src="@/assets/img/i5.png" alt="" style="visibility: hidden;"> -->


        <el-tooltip content="飞行日志" placement="top">
          <span v-if="data.taskStatus == 1" class="iconfont icon-chakanrizhi" @click="$emit('show-log', data)"></span>
        </el-tooltip>

        <el-tooltip class="item" effect="dark" content="定位" placement="top">
          <img src="@/assets/img/i6.png" alt="" @click="$emit('itemClick', data)">
        </el-tooltip>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  }
}
</script>

<style lang="scss" scoped>
.card-container {
  width: 100%;
  // height: 169px;
  height: auto;
  background: #163B53;
  border-radius: 4px;
  padding: 5px 0 5px 0;

  .line {
    width: 100%;
    padding: 6px 10px;
    box-sizing: border-box;
  }

  .line1 {
    height: 18%;
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .item-left {
      .f {
        border: 1px solid #3CE171;
        padding: 2px 5px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3CE171;
      }

      .f0 {
        border: 1px solid #FA9523;
        color: #FA9523;
      }

      .n {
        // margin-lef;
        margin-left: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .item-right {
      display: flex;
      justify-content: flex-end;

      .f1 {
        border: 1px solid #3CE171;
        padding: 2px 5px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3CE171;
      }
    }
  }

  .line2 {
    height: 32%;
    // background-color: rgb(0, 255, 106);
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      height: 90%;
      width: 26%;
      // border: 1px solid yellow;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .num {
        font-size: 16px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #FFFFFF;
      }

      .name {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(98, 123, 139, 1);
        margin-top: 5px;
      }
    }
  }

  .line3,
  .line4 {
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .item {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 18px;
        height: 18px;
      }

      span {
        margin-left: 5px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(99, 130, 163, 1);
      }
    }
  }

  .line5 {
    height: 20%;
    // background-color: rgb(200, 255, 0);
    display: flex;

    .item {
      height: 100%;

      span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF7362;
      }
    }

    .item1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 85%;

      img {
        height: 20px;
        width: 20px;
      }

      span {
        margin-left: 5px;
      }
    }

    .item2 {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon-chakanrizhi {
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
      }

      img {
        height: 18px;
        width: 18px;
        cursor: pointer;
      }
    }
  }
}
</style>