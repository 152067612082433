export default {
  selected_list: [],
  current_log_id: null,
  // current_log_id: 2669,
  list: [],
  status: {
    status: 1,
  },
  search: {
    name: null,
  },
  loading: false,
  is_show_warning: false,
  warning_cont: "",
  warning_num: 1,
  is_show_hedgehop: false,
  pagination: {
    pageNo: 1,
    pageSize: 10,
    totalCount: 0,
  },
};
