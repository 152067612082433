

export default {
    tabs: [
        {
          id: 1,
          name: '无人机',
        },
        {
          id: 2,
          name: '鹰巢',
        },
        {
          id: 3,
          name: '基站',
        },
    ],
    selected_list: [],
    list: [],
    status: {
        status: 1
    },
    search: {
        name: null
    },
    loading: false,
    pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0
    }
}