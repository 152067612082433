<template>
  <div class="cpt-command-new_home-airway-search">
    <div class="input-box">
      <el-input placeholder="请输入基站名称" v-model="name" clearable/>
      <div @click="$emit('submit', name)" class="icon-box">
        <span class="el-icon-search" />
      </div>
    </div>
    <div class="button-box">
      <el-button @click="$emit('distribution')">基站分布</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-new_home-airway-search {
  display: flex;
  align-items: center;
  margin: 8px 0;
  flex-shrink: 0;
  .input-box {
    display: flex;
    align-items: center;
    flex: 1;
    border-radius: 4px;
    border: 1px solid #00B6FF;
    box-shadow: 0 2px 4px 0 rgba(28,94,124,0.50), inset 0 0 3px 0 #00B6FF;
    border-radius: 6px;
    ::v-deep .el-input {
      input {
        background: none;
        border: none;
        color: #fff;
      }
    }
    .icon-box {
      width: 46px;
      height: 46px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      &:active {
        opacity: 0.5;
      }
      .el-icon-search {
        font-size: 18px;
      }
    }
  }
  .button-box {
    width: 131px;
    height: 46px;
    flex-shrink: 0;
    margin-left: 6px;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.5;
    }
    ::v-deep .el-button {
      width: 100%;
      height: 100%;
      background: rgba(0,34,140,0.20);
      box-shadow: 0 2px 4px 0 rgba(28,94,124,0.50), inset 0 0 15px 0 #00B6FF;
      border-radius: 6px;
      border: none;
      padding: 0;
      font-family: Microsoft YaHei;
      font-size: 16px;
      color: #00FFFF;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
    }
  }
}
</style>