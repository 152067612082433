<!-- 新版 首页 -->
<template>
  <div class="command-home">
    <img src="~@/assets/new_yingkong/command-home-img.png" alt="" class="img-view" />
    <div class="center-view">
      <span>联控</span>
    </div>
    <div class="command-item diaodu-view" @click="() => $emit('changePage', list[0].type)">
      <span>调度中心</span>
    </div>
    <div class="command-item shebei-manager" @click="() => $emit('changePage', list[2].type)">
      <span>设备管理</span>
    </div>
    <div class="command-item shebei-jiance" @click="() => $emit('changePage', list[3].type)">
      <span>设备监测</span>
    </div>
    <div class="command-item user-manager" @click="() => $emit('changePage', list[1].type)">
      <span>人员管理</span>
    </div>
  </div>
</template>

<script>
import API from "@/api";

const list = [
  {
    img: require("@/assets/new_yingkong/ddzx.png"),
    href: "",
    style: {},
    type: 5,
  },
  {
    img: require("@/assets/new_yingkong/rygl.png"),
    href: "",
    style: {},
    type: 6,
  },
  {
    img: require("@/assets/new_yingkong/sbgl.png"),
    href: "",
    style: {},
    type: 7,
  },
  {
    img: require("@/assets/new_yingkong/sbjc.png"),
    href: "",
    style: {},
    type: 11,
  },
];
export default {
  components: {},
  data () {
    return {
      list: list, //环绕项数组
      angle: 0, //旋转角度
      pauseTurn: false, //暂停旋转
      pauseTurnTimeId: null,
      timeId: null, //主旋转定时器
      turnTimeId: null, //手动旋转定时器
    };
  },

  computed: {
    surroundStyle () {
      return {
        transform: `perspective(800px) rotateX(-5deg) rotateY(${this.angle}deg)`,
      };
    },
    earthStyle () {
      return {
        transform: `translate3d(-432px, -277px, 0px) rotateY(${-this
          .angle}deg)`,
      };
    },
  },
  created () {
    // console.log("created");
    this.$store.commit("mapmanage/SET_VISIBLE_MAIN_MAP", false);
  },
  mounted () {
    //计算所有环绕项
    var len = this.list.length;
    this.list.forEach((item, i) => {
      item.style = {
        transform:
          "rotateY(" +
          (360 / len) * i +
          "deg) translateZ(400px) translateY(30px)",
        transition: "1s all ease " + (len - i) * 200 + "ms",
      };
    });

    //定时器实现旋转
    this.timeId = setInterval(() => {
      //暂停旋转
      if (this.pauseTurn) {
        return;
      }
      this.angle -= 1;
    }, 50);
  },
  beforeDestroy () {
    clearInterval(this.timeId);
  },
  methods: {
    /**
     * 旋转事件
     * @param {string} 类型, left | right
     */
    onTurn (type) {
      if (this.turnTimeId) {
        return;
      }
      const len = this.list.length;
      const itemAngle = (360 / len) * (type === "left" ? -1 : 1); //每一项角度
      let remainder = Number((this.angle % itemAngle).toFixed(4)); //求得与最近一项还差多少度数, 注意, 当前项为正前方时, 得0, 这时需要直接转动到下一项
      console.log("remainder", remainder);
      if (remainder === 0 && type === "right") {
        remainder = itemAngle;
      }
      let sumAngle = (type === "left" ? itemAngle : 0) - remainder; //总共要旋转多少度
      let angleUnit = 1; //每N毫秒旋转多少度

      // console.log("unit", type);
      // console.log('this.angle', this.angle);
      // console.log('itemAngle', itemAngle);
      // console.log('sumAngle', sumAngle);

      //right情况下有特殊, 转角少于10度时, 不要回正第一项, 要回正第二项
      /* if(type === 'right' && sumAngle < 10){
        sumAngle += itemAngle;

        if(Math.abs(sumAngle) > itemAngle){
          sumAngle -= itemAngle;
        }
      } */

      //暂停旋转N秒
      if (this.pauseTurnTimeId) {
        clearTimeout(this.pauseTurnTimeId);
      }
      this.pauseTurn = true;
      this.pauseTurnTimeId = setTimeout(() => {
        this.pauseTurn = false;
      }, 2000);

      if (this.turnTimeId) {
        clearInterval(this.turnTimeId);
      }
      let angleCount = 0; // 旋转角度计数
      this.turnTimeId = setInterval(() => {
        if (angleCount >= Math.abs(sumAngle)) {
          clearInterval(this.turnTimeId);
          this.turnTimeId = null;
          angleUnit = Math.abs(sumAngle) - angleCount;
        }

        switch (type) {
          case "left":
            this.angle -= angleUnit;
            break;

          case "right":
            this.angle += angleUnit;
            break;
        }

        angleCount += angleUnit;

        // console.log('angleCount', sumAngle, angleCount, angleUnit, this.angle)
      }, 10);
    },

    /**
     * 鼠标悬停事件
     */
    onMousemove () {
      clearTimeout(this.pauseTurnTimeId);
      this.pauseTurn = true;
    },
    onMouseout () {
      this.pauseTurn = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.command-home {
  width: 100vw;
  position: absolute;
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);
  background-color: #1f2030;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
}

.img-view {
  height: 81vh;
  width: 97.5vw;
  margin-bottom: 3vh;
}

.center-view {
  position: absolute;
  bottom: 36vh;
  right: 0;
  width: 100%;
  text-align: center;
  z-index: 2;

  span {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 56px;
    letter-spacing: 3px;
    margin-bottom: 219px;
    background: linear-gradient(142deg,
        #fdffff 0%,
        #5cdfef 0%,
        #5fe0f7 52%,
        #f8feff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.command-item {
  width: 24.4vw;
  height: 33vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #ffffff;
  line-height: 46px;
  cursor: pointer;
}

.command-item:hover {
  background-image: url("~@/assets/new_yingkong/command-home-hover.png");
  background-size: 100% 100%;
}

.diaodu-view {
  position: absolute;
  left: 4vw;
  top: 7vh;
}

.shebei-manager {
  position: absolute;
  // right: 4vw;
  right: 2vw;
  top: 7vh;
}

.shebei-jiance {
  position: absolute;
  left: 4vw;
  // top: 44vh;
  top: 36vh;
}

.user-manager {
  position: absolute;
  // right: 4vw;
  right: 2vw;
  // top: 44vh;
  top: 38vh;
}

.command-home_btn {
  width: 150px;
  height: 50px;
  z-index: 10;
  bottom: 10px;
  right: 20px;
  background-image: url("~@/assets/img/homeChange1.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.command-home_btn:hover {
  // background-image: url("~@/assets/img/homeChange2.png") ;
}

.surround {
  width: 133px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -65px;
  margin-top: -200px;
  /*开启3D*/
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;

  .earth {
    width: 1000px;
  }

  .surround-item {
    cursor: pointer;
    list-style: none;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: 100% 100%;
    /*图片一圈加阴影*/
    box-shadow: 0 0 10px #fff;
    /*加圆角*/
    border-radius: 10px;
    /*给li加倒影*/
    -webkit-box-reflect: below 10px -webkit-linear-gradient(rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.3));

    .surround-item__img {
      width: 100%;
      height: 100%;
    }
  }
}

.turn {
  position: absolute;
  width: 35%;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: start;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 156px;
    height: 182px;
    background: rgba(3, 10, 36, 0.5);

    img {
      opacity: 0.6;
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }

  &.turn--left {
    left: 0;
  }

  &.turn--right {
    right: 0;
    justify-content: end;

    img {
      transform: rotate(180deg);
    }
  }
}
</style>
