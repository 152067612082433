<template>
  <div class="command-situation-media dialog1027">
    <div class="dialog-header-new" v-interact>
      <div class="dialog-header__title">历史图片</div>
      <div class="right_header_box">
        <img src="@/assets/img/i123.png" alt="" class="img" style="margin-right: 10px;" @click="handle_click">
        <img src="@/assets/img/i40.png" alt="" class="img" @click="close_history_img_model(1)">
      </div>
    </div>
    <div class="box dialog-content">
      <div class="left">
        <img class="leftimg" :src="selectImg" />
      </div>
      <div class="right">
        <div class="upIcon center" @click="move('up')">
          <img src="~@/assets/images/command/up.svg" />
        </div>
        <div class="centerimg">
          <!-- :src="require(imgApi + '/uploads' + item.fileKey)" :class="active == index ? 'active' : ''" /> -->
          <el-image v-for="(item, index) in historyImage" :key="index" @click="handleImg(index)" class="rightimg"
            :src="item.visitUrl" :class="active == index ? 'active' : ''" @error="imgerror(item)"></el-image>
        </div>
        <div class="upIcon center" @click="move('down')">
          <img src="~@/assets/images/command/down.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgApi: process.env.VUE_APP_IMG_URL,
      selectImg: null,
      active: 0,
    };
  },
  props: ["historyImage"],
  inject: ["flight_task_chage_view", "close_history_img_model"],
  mounted () {
    // this.selectImg = this.imgApi + '/uploads' + this.historyImage[0].fileKey;
    this.selectImg = this.historyImage[0].visitUrl;
  },
  methods: {
    handle_click () {
      let canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        image = new Image();
      image.setAttribute("crossOrigin", "Anonymous");
      image.src = this.selectImg;

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;

        ctx.drawImage(image, 0, 0, image.width, image.height);

        let base64 = canvas.toDataURL("image/jpeg", 0.8);

        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = base64;
        a.download = "图片";
        a.click();
      };
    },
    handleImg (index) {
      this.active = index;
      this.selectImg = this.imgApi + '/uploads' + this.historyImage[index].fileKey;
      this.selectImg = this.historyImage[index].visitUrl;
    },
    move (val) {
      var div = document.getElementsByClassName("centerimg")[0];
      if (val == "up") {
        if (div.scrollTop != null) {
          div.scrollTop -= 118;
        }
      } else {
        if (div.scrollTop != null) {
          div.scrollTop += 118;
        }
      }
    },
    /**
     *@description: 图片无效
     *@date: 2023-09-27 15:45:51
    */
    imgerror (item) {
      console.log(item, '图片地址无效');
    }
  },
};
</script>

<style lang="scss" scoped>
// @import "~@/styles/var.scss";

.command-situation-media {
  z-index: 1000;
  position: absolute;
  left: 500px;
  top: 250px;
  width: 935px;
  box-sizing: border-box;
  background-color: #0A293A;
  border-radius: 4px;

  .dialog-header-new {
    width: 100%;
    height: 36px;
    background-color: #224D68;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 10px 0 10px;
    border-radius: 4px 4px 0 0;

    .dialog-header__title {
      color: #ffffff;
      font-weight: bold;
    }

    .right_header_box {
      .img {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 20px;
  }

  .box {
    display: flex;
    justify-content: space-around;

    .left {
      width: 700px;
      height: 400px;
      border: 2px solid #025d88;

      .leftimg {
        width: 700px;
        height: 400px;
        border: 0;
      }
    }

    .right {
      margin-left: 10px;
      width: 176px;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .upIcon {
        width: 100%;
        height: 16px;
        background: #13212b;
        border: 1px solid #0096d4;
        box-shadow: inset 0 0 7px 1px rgba(23, 127, 255, 0.55);

        img {
          width: 22px;
          height: 22px;
        }
      }

      .centerimg {
        overflow-y: auto;
        overflow-x: hidden;
        width: 176px;
        height: 360px;

        ::-webkit-scrollbar {
          display: none;
          /* Chrome Safari */
        }

        .rightimg {
          margin-right: 5px;
          width: 160px;
          height: 112px;
          cursor: pointer;
          padding: 2px;
        }

        .active {
          border: 2px solid #00c6d4;
        }
      }
    }
  }
}
</style>
