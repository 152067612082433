<template>
  <div class="slide-bar-container">
    <div class="item item1" @click="click(0)" :class="flag == 0 ? 'selected' : ''">
      <img src="@/assets/img/inte-air.png" alt="">
      <span>智能空域</span>
    </div>
    <div class="item item2" @click="click(1)" :class="flag == 1 ? 'selected' : ''">
      <img src="@/assets/img/fly-task.png" alt="">
      <span>飞行任务</span>
    </div>
    <div class="item item3" @click="click(2)" :class="flag == 2 ? 'selected' : ''">
      <img src="@/assets/img/air-line.png" alt="">
      <span>航空航线</span>
    </div>
    <div class="item item4" @click="click(3)" :class="flag == 3 ? 'selected' : ''">
      <img src="@/assets/img/real-time.png" alt="">
      <span>实时调度</span>
    </div>
  </div>
</template>

<script>
import Bus from '@/assets/ligature.js'
export default {
  data () {
    return {
      flag: 0,
    }
  },
  methods: {
    click (flag) {
      this.flag = flag;
      switch (flag) {
        case 0:
          this.$emit('handleSelected', 0);
          break;
        case 1:
          this.$emit('handleSelected', 1);
          break;
        case 2:
          this.$emit('handleSelected', 2);
          break;
        case 3:
          this.$emit('handleSelected', 3);
          break;
      }
    }
  },
  mounted () {
    Bus.$on('info-slider-flag', (v) => {
      this.flag = v;
    });
  },
}
</script>

<style lang="scss" scoped>
.slide-bar-container {
  width: 100%;
  height: 100%;

  .item {
    width: 82px;
    height: 81px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    cursor: pointer;

    span {
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      margin-top: 6px;
    }
  }

  .selected {
    background: #2B72EA;
  }
}
</style>