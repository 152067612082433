<template>
  <div>
    <AirwayList v-if="!airway.add.visible" :columns="airway.columns" :pagination="airway.pagination" :list="airway.list"
      :isNone="isNone" @changelist='airway_list' @search="airway_search" @page-change="airway_page_change"
      @size-change="airway_size_change" @add="airway.add.visible = true" @chanegSafety="airway_list"
      @isN="() => $emit('isN')" />
    <AirwayEdit @add="airway_add" v-if="airway.add.visible" />
  </div>
</template>

<script>
import API from "@/api";
import AirwayList from '@/components/command/airway-list'
import AirwayEdit from '@/components/command/airway-edit/index'
import { mapGetters } from 'vuex'

import data from './data'
import methods from './methods'

export default {
  components: { AirwayList, AirwayEdit },
  props: {
    isNone: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return JSON.parse(JSON.stringify(data))
  },
  methods: {
    ...methods,
    // async getlable () {
    //   await API.AIRWAY.labelList()
    // }
  },
  inject: ['g_cesium_toolbar', 'change_flightTask_view'],
  computed: {
    ...mapGetters(['is_from_situation_edit', 'situation_detail']),
  },
  mounted () {
    // this.getlable()
    this.g_cesium_toolbar().moveToolbar('left')
    this.airway.add.visible = false
    Promise.all([this.airway_list()])
  },
  beforeDestroy () {
    this.$store.commit('situation/IS_FROM_SITUATION_EDIT', false)
  },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.cpt-command-airway {
  position: absolute;
  left: 0;
  top: 180px;
  width: 640px;
  height: 720px;
  box-sizing: border-box;
  background: url("~@/assets/images/plate_bg_01.png") no-repeat;
  background-size: 100% 100%;

  .title-box {
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
    height: 56px;
    box-sizing: border-box;
    display: flex;
    line-height: 3.2;
    padding-left: 132px;
    position: relative;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      height: 1px;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(270deg,
          rgba(0, 117, 221, 0) 0%,
          #3ea4d5 50%,
          rgba(0, 117, 221, 0) 100%);
    }
  }

  .list-box {
    height: calc(100% - 56px);
    box-sizing: border-box;
    padding: 0 20px 20px 30px;

    .search-box {
      height: 80px;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .item-plan {
        flex-shrink: 0;
        height: 40px;
        width: 134px;
        background: #129c9c;
        border: none;
        border-radius: 0;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
      }

      .item-input {
        width: 277px;
        height: 40px;
        margin-left: 35.2px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
          background: #000000;
          border: 1px solid #08c2d1;
          border-radius: 0;
        }
      }

      .item-search {
        flex-shrink: 0;
        height: 40px;
        width: 114px;
        background: #129c9c;
        border: none;
        border-radius: 0;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
      }
    }

    .list-inner-box {
      height: calc(100% - 80px);
      border: 1px solid $primary-border-color;

      ._table {
        height: calc(100% - 35px);
        box-sizing: border-box;
        overflow: auto;

        .tr {
          display: flex;
          align-items: center;
        }

        .thd {
          .tr {
            background: #1e2026;
            background-image: linear-gradient(180deg,
                rgba(255, 255, 255, 0.05) 0%,
                rgba(255, 255, 255, 0) 100%);
            box-shadow: inset 1px 1px 0 0 #000000;

            .th {
              font-family: Microsoft YaHei;
              font-size: 14px;
              color: #b3bbc5;
              text-align: center;
              font-weight: 400;
              height: 32px;
              box-sizing: border-box;
              line-height: 32px;

              &.id {
                width: 60px;
              }

              &.flightName {
                width: 112px;
              }

              &.pointCount,
              &.distance,
              &.time {
                width: 70px;
              }

              &.addTime {
                width: 110px;
              }

              &.operate {
                width: 110px;
              }
            }
          }
        }

        .tbd {
          background: #263542;

          .tr {
            &:hover {
              cursor: pointer;
              background: #394d5f;
            }

            .td {
              text-align: center;
              font-family: Microsoft YaHei;
              font-size: 14px;
              color: #b3bbc5;
              font-weight: 400;
              height: 32px;
              align-items: center;
              text-align: center;
              line-height: 32px;

              &.id {
                width: 60px;
              }

              &.flightName {
                width: 112px;
              }

              &.pointCount,
              &.distance,
              &.time {
                width: 70px;
                color: #89ec50;
              }

              &.addTime {
                width: 110px;
              }

              &.operate {
                width: 110px;
                display: flex;
                justify-content: space-around;
              }
            }
          }
        }
      }

      // .air-list_table {
      //   height: calc(100% - 30px);
      //   border-collapse: collapse;
      //   width: 100%;
      //   text-align: center;
      //   font-family: MicrosoftYaHei;
      //   font-size: 14px;
      //   color: #b3bbc5;
      //   margin-bottom: 15px;
      //   .air-list_th {
      //     height: 40px;
      //     background: #1e2026;
      //     background-image: linear-gradient(
      //       180deg,
      //       rgba(255, 255, 255, 0.05) 0%,
      //       rgba(255, 255, 255, 0) 100%
      //     );
      //     box-shadow: inset 1px 1px 0 0 #000000;
      //     > th:last-of-type {
      //       width: 100px;
      //     }
      //   }
      //   .air-list_td {
      //     height: 37px;
      //     background: #263542;
      //     td {
      //       height: 37px;
      //       img {
      //         cursor: pointer;
      //       }
      //     }
      //     > td:last-of-type {
      //       width: 100px;
      //       display: flex;
      //       justify-content: space-around;
      //       align-items: center;
      //     }
      //   }
      // }

      ._pagination {
        height: 30px;
        display: flex;
        align-items: center;

        ::v-deep .el-pagination {
          height: 30px;
          display: flex;
          align-items: center;

          button {
            background: transparent;
            font-size: 12px;
            color: #889fb2;
            text-align: center;
            font-weight: 400;

            &:disabled {
              background: transparent;
              color: #889fb2;
              opacity: 0.3;
            }
          }

          .el-pagination__total {
            font-size: 12px;
            color: #889fb2;
            text-align: center;
            font-weight: 400;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            margin: 0px;
          }

          .el-pagination__sizes {
            height: 30px;
            line-height: 30px;
            margin: 0px;

            .el-select {
              .el-input {
                width: 60px;
                padding-right: 20px;
                margin: 0;

                .el-input__inner {
                  background: transparent;
                  border: none;
                  line-height: 30px;
                  font-size: 12px;
                  color: #889fb2;
                  text-align: center;
                  font-weight: 400;
                  padding: 0px;
                }
              }
            }
          }

          .el-pager {
            .number {
              background: transparent;
              font-size: 12px;
              color: #889fb2;
              text-align: center;
              font-weight: 400;

              &.active {
                background: $primary-color;
                border-radius: 2px;
                color: #ffffff;
              }
            }

            .more {
              background: transparent;
              color: #889fb2;
            }
          }

          .el-pagination__jump {
            font-size: 12px;
            color: #889fb2;
            text-align: center;
            font-weight: 400;
            height: 30px;
            line-height: 30px;
            margin-left: 6px;

            .el-input {
              .el-input__inner {
                border: 1px solid rgba(36, 146, 252, 0.3);
                border-radius: 2px;
                background: transparent;
                color: #889fb2;
              }
            }
          }
        }

        ._jumper {
          font-size: 12px;
          color: #889fb2;
          text-align: center;
          font-weight: 400;
          display: flex;
          align-items: center;
          white-space: nowrap;

          ::v-deep .el-input {
            .el-input__inner {
              background: rgba(12, 13, 20, 0.5);
              border: 1px solid rgba(36, 146, 252, 0.3);
              border-radius: 2px;
              width: 33px;
              height: 24px;
              color: #889fb2;
              padding: 0;
              text-align: center;
            }
          }

          ._jumper_btn {
            width: 51px;
            height: 22px;
            background: #333c57;
            border: 1px solid $primary-border-color;
            border-radius: 2px;
            padding: 0px;
            font-size: 14px;
            color: $primary-color;
            letter-spacing: -0.08px;
            text-align: center;
            font-weight: 400;
            margin-left: 4px;
          }
        }
      }
    }
  }
}</style>
