
import airway from "./airway"
import station from "./station"
import warning from "./warning"
import alarm from "./alarm"

function prefix(meta, prefix) {
    return Object.keys(meta).reduce((obj, key) => {
        obj[`${prefix}_${key}`] = meta[key]
        return obj
    }, {})
}

export default {
    ...prefix(airway, "airway"),
    ...prefix(station, "station"),
    ...prefix(warning, "warning"),
    ...prefix(alarm, "alarm"),
}