<!-- 人员统计 -->
<template>
  <div class="cpt-command-device-mount cpt-command-device-common" id="statisticsBody">
    <div class="head">
      <div class="left-img">
        <span>人员统计</span>
      </div>
    </div>

    <div class="body" id="personStaticId">
      <div class="body-left">
        <div id="heng" class="bd-box-chart">
        </div>
        <div class="foot">
          <div class="item item1">
            <div class="left">
              <div class="color-img"></div>
            </div>
            <div class="right">
              <div class="v">{{ this.dataPerson[0].value }}</div>
              <div class="n">机长</div>
            </div>
          </div>
          <div class="item item2">
            <div class="left">
              <div class="color-img"></div>
            </div>
            <div class="right">
              <div class="v">{{ this.dataPerson[1].value }}</div>
              <div class="n">驾驶员</div>
            </div>
          </div>
          <div class="item item3">
            <div class="left">
              <div class="color-img"></div>
            </div>
            <div class="right">
              <div class="v">{{ this.dataPerson[2].value }}</div>
              <div class="n">飞行助理</div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-right">
        <div class="divSC">
          <div class="search-box-statistics">

            <el-cascader popper-class="personal-cascader" class="cascader" ref="cascaderRef" @change="CSshow"
              v-model="filter.organId" :options="options" clearable :show-all-levels="false" placeholder="请选择单位" :props="{
                children: 'children',
                label: 'orgName',
                value: 'id',
                checkStrictly: true,
                emitPath: false,
              }">
            </el-cascader>

            <div @click="handle_search" class="button-nomal">搜索</div>
          </div>
        </div>
        <div class="divTable">
          <el-table :data="tableData" :header-cell-style="{ background: 'transparent' }" row-key="id"
            :cell-style="cellStyle" :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
            @cell-click="cellClick" max-height="400">
            <el-table-column align="left" prop="anotherName" label="单位名称" width="160"></el-table-column>
            <el-table-column @click="hanle_click(1)" align="center" prop="count" label="人员总数"></el-table-column>
            <el-table-column align="center" prop="flyingHandCount" :label="dataPerson[0].name"></el-table-column>
            <el-table-column align="center" prop="mingjingCount" :label="dataPerson[1].name"></el-table-column>
            <el-table-column align="center" prop="fujingCount" :label="dataPerson[2].name"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
import { Chart } from "@antv/g2";
import echarts from "echarts";
import { DOM } from '@/utils/dom.js';
export default {
  data () {
    return {
      tableData: [],
      list: [],
      filter: {
        organId: null,
      },
      options: [],
      dataPerson: [
        { name: "机长", value: 0 },
        { name: "驾驶员", value: 0 },
        { name: "飞行助理", value: 0 },
      ],
      chart: null,
    };
  },

  async mounted () {
    this.filter.organId = this.user_info.departmentId;
    await this.getOptions();
    await this.getStatistics();
    // this.init(this.dataPerson, "person_container");
    this.charts();
  },

  computed: {
    ...mapGetters(["user_info"]),
  },

  methods: {
    CSshow () {
      // console.log(this.$refs.cascaderRef.dropDownVisible, 'refffffff')
      this.$nextTick(() => {
        this.$refs.cascaderRef.dropDownVisible = true;
      });
    },
    //表格行颜色
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      let str = '';
      if (rowIndex % 2 == 1) {
        str = 'background:#081A3A;';
      } else {
        str = 'background:rgba(73,135,210,0.2);';
      }
      if (columnIndex == 2 || columnIndex == 3 || columnIndex == 4) {
        str += ' cursor: pointer';
      }
      return str;
    },
    charts1 () {
      // 计算百分比
      var zong =
        this.dataPerson[0].value +
        this.dataPerson[1].value +
        this.dataPerson[2].value;
      var a = (this.dataPerson[0].value / zong) * 100;
      var b = (this.dataPerson[1].value / zong) * 100;
      var c = (this.dataPerson[2].value / zong) * 100;
      // 放进容器里
      let xData = [""],
        yData = [],
        barData = [];
      var barData2 = [];
      var barData3 = [];
      yData.push(100);
      barData.push(a);
      barData2.push(b);
      barData3.push(c);

      let myChart = this.$echarts.init(document.getElementById("heng"));
      let option = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
          padding: [
            18, // 上
            30, // 右
            18, // 下
            21, // 左
          ],
          formatter: (name) => {
            // tooltip不支持rich属性,但是支持更自由html
            if (name.seriesName == this.dataPerson[1].name) {
              return (
                "<div>" +
                name.seriesName +
                "数量" +
                "</div>" +
                '<div style="display:flex;align-items: center;margin: 5px 0 0px 0;font-size:14px;"><div style="width:14px;height:14px;background:#02C979;border-radius:50%;margin:0 14px 0 0;"></div><div style="color:#02C979;">' +
                this.dataPerson[1].value +
                "</div><div>" +
                "/" +
                zong +
                "</div></div>"
              );
            } else if (name.seriesName == this.dataPerson[2].name) {
              return (
                "<div>" +
                name.seriesName +
                "数量" +
                "</div>" +
                '<div style="display:flex;align-items: center;margin: 5px 0 0px 0;font-size:14px;"><div style="width:14px;height:14px;background:#EFA914;border-radius:50%;margin:0 14px 0 0;"></div><div style="color:#EFA914;">' +
                this.dataPerson[2].value +
                "</div><div>" +
                "/" +
                zong +
                "</div></div>"
              );
            } else if (name.seriesName == this.dataPerson[0].name) {
              return (
                "<div>" +
                name.seriesName +
                "数量" +
                "</div>" +
                '<div style="display:flex;align-items: center;margin: 5px 0 0px 0;font-size:14px;"><div style="width:14px;height:14px;background:#1449f2;border-radius:50%;margin:0 14px 0 0;"></div><div style="color:#1449f2;">' +
                this.dataPerson[0].value +
                "</div><div>" +
                "/" +
                zong +
                "</div></div>"
              );
            }
          },
        },
        grid: {
          top: "2%",
          bottom: "-30px",
          right: "15%",
          left: "5%",
          containLabel: true, // gird 区域是否包含坐标轴的刻度标签
        },
        legend: {
          data: [
            this.dataPerson[0].name,
            this.dataPerson[1].name,
            this.dataPerson[2].name,
          ],
          selectedMode: false, //取消图例上的点击事件
          right: "0%",
          top: "2%",
          x: "center",
          orient: "vertical",
          icon: "circle",
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          itemGap: 15,
          orient: "horizontal",
        },
        animation: false,
        xAxis: [
          {
            show: false,
            type: "value",
          },
        ],
        yAxis: [
          {
            type: "category",
            data: xData,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#ddd",
              },
              show: false,
            },
            interval: 1,
          },
        ],
        series: [
          {
            name: "内层波浪",
            type: "pictorialBar",
            silent: true,
            symbolSize: [0, 18],
            symbolOffset: [0, 12],
            z: 1,
            itemStyle: {
              normal: {
                color: "#363A76",
              },
            },
            data: yData,
          },
          {
            name: this.dataPerson[0].name,
            type: "bar",
            stack: "person",
            barWidth: 30,
            z: 12,
            itemStyle: {
              //lenged文本
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                1,
                0,
                [
                  {
                    offset: 0,
                    color: "#1765FFFF", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#1228E4FF", // 0% 处的颜色
                  },
                ],
                false
              ),
            },
            label: {
              show: true,
              position: "inside",
              formatter: () => {
                return this.dataPerson[0].value;
              },
            },
            data: barData,
          },
          {
            name: this.dataPerson[1].name,
            type: "bar",
            barWidth: 30,
            stack: "person",
            z: 12,
            itemStyle: {
              //lenged文本
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                1,
                0,
                [
                  {
                    offset: 0,
                    color: "#00E087FF", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#069257FF", // 100% 处的颜色
                  },
                ],
                false
              ),
            },
            label: {
              show: true,
              position: "inside",
              formatter: () => {
                return this.dataPerson[1].value;
              },
            },
            data: barData2,
          },
          {
            name: this.dataPerson[2].name,
            type: "bar",
            barWidth: 30,
            stack: "person",
            z: 12,
            itemStyle: {
              //lenged文本
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                1,
                0,
                [
                  {
                    offset: 0,
                    color: "#FFB417FF", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#B9830CFF", // 100% 处的颜色
                  },
                ],
                false
              ),
            },
            label: {
              show: true,
              position: "inside",
              formatter: () => {
                return this.dataPerson[2].value;
              },
            },
            data: barData3,
          },
          {
            name: "最底层",
            type: "pictorialBar",
            silent: true,
            symbolSize: [8, 30],
            symbolOffset: [-4, 0],
            symbolPosition: "start",
            z: 13,
            color: "#1228E4FF",
            data: barData,
          },
          {
            name: "第二层",
            type: "pictorialBar",
            silent: true,
            symbolSize: [8, 30],
            symbolOffset: [4, 0],
            z: 13,
            color: "#1763FEFF",
            symbolPosition: "end",
            data: barData,
          },
          {
            name: "第三层",
            type: "pictorialBar",
            silent: true,
            symbolSize: [8, 30],
            symbolOffset: [4, 0],
            z: 13,
            color: "#00E087FF",
            symbolPosition: "end",
            data: [barData[0] + barData2[0]],
          },
          {
            name: "最顶层",
            type: "pictorialBar",
            silent: true,
            symbolSize: [8, 30],
            symbolOffset: [4, 0],
            z: 13,
            color: "#FFB417FF",
            symbolPosition: "end",
            data: [barData[0] + barData2[0] + barData3[0]],
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    charts () {
      // 就算百分比
      var zong = this.dataPerson[0].value + this.dataPerson[1].value + this.dataPerson[2].value;
      var a = (this.dataPerson[0].value / zong) * 100;
      var b = (this.dataPerson[1].value / zong) * 100;
      var c = (this.dataPerson[2].value / zong) * 100;
      // 放进容器里
      let xData = [""], yData = [], barData = [];
      var barData2 = [];
      var barData3 = [];
      yData.push(100);
      barData.push(a);
      barData2.push(b);
      barData3.push(c);
      let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: '#7D8696', // 0% 处的颜色
        },
        {
          offset: 1,
          color: '#7D8696', // 100% 处的颜色
        },
      ]);
      let colorList = ['#66DE69', '#CBD26D'];
      let seriesdata1 = [
        {
          name: '驾驶员',
          value: this.dataPerson[1].value,
          itemStyle: {
            normal: {
              color: {
                colorStops: [

                  {
                    offset: 1,
                    color: "#00DD5B" // 0% 处的颜色
                  },
                  {
                    offset: 0,
                    color: "#2CE68A" // 100% 处的颜色
                  }
                ]
              },
            },
          },
        },
        {
          name: '机长',
          value: this.dataPerson[0].value,
          itemStyle: {
            normal: {
              color: {
                colorStops: [
                  {
                    offset: 1,
                    color: "#007BFF" // 0% 处的颜色
                  },
                  {
                    offset: 0,
                    color: "#0ECAFF" // 100% 处的颜色
                  }
                ]
              },
            },
          },
        },

        {
          name: '飞行助理',
          value: this.dataPerson[2].value,
          itemStyle: {
            normal: {
              color: {
                colorStops: [
                  {
                    offset: 1,
                    color: "#FF8216" // 0% 处的颜色
                  },
                  {
                    offset: 0,
                    color: "#FFB95F" // 100% 处的颜色
                  }
                ]
              },
            },
          },
        }
      ];

      let myChart = this.$echarts.init(document.getElementById("heng"));
      let option = {
        backgroundColor: "transparent",
        tooltip: {
          show: false,
          trigger: "item",
          padding: [
            18, // 上
            30, // 右
            18, // 下
            21, // 左
          ],
          formatter: (name) => {
            // tooltip不支持rich属性,但是支持更自由html
            if (name.seriesName == this.dataPerson[1].name) {
              return (
                "<div>" +
                name.seriesName +
                "数量" +
                "</div>" +
                '<div style="display:flex;align-items: center;margin: 5px 0 0px 0;font-size:14px;"><div style="width:14px;height:14px;background:#02C979;border-radius:50%;margin:0 14px 0 0;"></div><div style="color:#02C979;">' +
                this.dataPerson[1].value +
                "</div><div>" +
                "/" +
                zong +
                "</div></div>"
              );
            } else if (name.seriesName == this.dataPerson[2].name) {
              return (
                "<div>" +
                name.seriesName +
                "数量" +
                "</div>" +
                '<div style="display:flex;align-items: center;margin: 5px 0 0px 0;font-size:14px;"><div style="width:14px;height:14px;background:#EFA914;border-radius:50%;margin:0 14px 0 0;"></div><div style="color:#EFA914;">' +
                this.dataPerson[2].value +
                "</div><div>" +
                "/" +
                zong +
                "</div></div>"
              );
            } else if (name.seriesName == this.dataPerson[0].name) {
              return (
                "<div>" +
                name.seriesName +
                "数量" +
                "</div>" +
                '<div style="display:flex;align-items: center;margin: 5px 0 0px 0;font-size:14px;"><div style="width:14px;height:14px;background:#1449f2;border-radius:50%;margin:0 14px 0 0;"></div><div style="color:#1449f2;">' +
                this.dataPerson[0].value +
                "</div><div>" +
                "/" +
                zong +
                "</div></div>"
              );
            }
          },
        },
        title: [{
          text: '总人数',
          top: '42%',
          left: '42%',
          // padding: [-15, 0, 0, -15],
          textStyle: {
            color: '#c0c0c0',
            fontSize: 14,
            fontWeight: '400',
          },
        },
        {
          text: zong,
          top: '34%',
          left: '42%',
          // padding: [15, 0, 0, -20],
          textStyle: {
            color: '#fff',
            fontSize: 22,
            fontWeight: '600',
          },
        }],
        grid: {
          top: "2%",
          bottom: "2px",
          right: "2%",
          left: "2%",
          containLabel: true, // gird 区域是否包含坐标轴的刻度标签
        },
        legend: {
          show: false,
          data: [
            this.dataPerson[0].name,
            this.dataPerson[1].name,
            this.dataPerson[2].name,
          ],
          selectedMode: false, //取消图例上的点击事件
          right: "0%",
          top: "2%",
          x: "center",
          orient: "vertical",
          icon: "circle",
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          itemGap: 15,
          orient: "horizontal",
        },
        animation: false,
        xAxis: [
          {
            show: false,
            type: "value",
          },
        ],
        yAxis: [
          {
            show: false,
            type: "category",
            data: xData,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {
                color: "#ddd",
              },
              show: false,
            },
            interval: 1,
          },
        ],
        series: [
          //  花瓣
          {
            type: 'gauge',
            center: ['50%', '40%'],
            startAngle: 0,
            endAngle: 360,
            splitNumber: 8,
            radius: '70%',
            pointer: {
              show: false,
            },
            progress: {
              show: false,
              roundCap: true,
              width: 0,
            },
            axisLine: {
              show: false,
              lineStyle: {
                width: 0,
                color: [
                  [0.4, color],
                  [1, color],
                ],
              },
            },
            axisTick: {
              distance: -25,
              length: 8,
              splitNumber: 5,
              lineStyle: {
                width: 2,
                color: 'auto',
                cap: 'round'
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
              lineStyle: {
                width: 6,
                color: '#099fe4',
              },
            },
            axisLabel: {
              show: false,
            },
            anchor: {
              show: false,
            },
            title: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: [
              {
                value: 100,
              }
            ]
          },
          // 外圈
          {
            type: 'pie',
            center: ['50%', '40%'],
            radius: ['50%', '55%'],
            clockwise: true,
            avoidLabelOverlap: false,
            hoverOffset: 15,
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            data: seriesdata1,
            seriesIndex: 0,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            }
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    cellClick (row, column, cell, event) {
      let { dataPerson } = this;
      switch (column.label) {
        case "单位名称":
          dataPerson[0].value = row.flyingHandCount;
          dataPerson[1].value = row.mingjingCount;
          dataPerson[2].value = row.fujingCount;
          this.chart.data(this.dataPerson);
          this.chart.render();
          break;
        case this.dataPerson[0].name:
          this.$emit("handle_detail", row.id, 2);
          break;
        case this.dataPerson[1].name:
          this.$emit("handle_detail", row.id, 0);
          break;
        case this.dataPerson[2].name:
          this.$emit("handle_detail", row.id, 1);
          break;
        default:
          break;
      }
      // if (column.label == '单位名称') {

      //   dataPerson[0].value = row.flyingHandCount
      //   dataPerson[1].value = row.mingjingCount
      //   dataPerson[2].value = row.fujingCount
      //   this.chart.data(this.dataPerson)
      //   this.chart.render()
      // }
    },
    async handle_click (e) {
      let { dataPerson } = this;
      dataPerson[0].value = e.flyingHandCount;
      dataPerson[1].value = e.mingjingCount;
      dataPerson[2].value = e.fujingCount;
      this.chart.data(this.dataPerson);
      this.chart.render();
    },
    async handle_search () {
      let { dataPerson } = this;
      dataPerson[0].value = 0;
      dataPerson[1].value = 0;
      dataPerson[2].value = 0;
      await this.getStatistics();
      // this.chart.data(this.dataPerson);

      // this.chart.render();
    },
    async getOptions () {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    async getStatistics () {
      let loadingInstance = DOM.createLoading("正在加载数据", 'personStaticId');
      try {
        let level;
        function eachTree (tree, depth = 0) {
          tree.forEach((item) => {
            item.depth = depth;
            level = depth + 1;
            if (item.child && item.child.length) {
              eachTree(item.child, depth + 1);
            }
          });
        }
        let { dataPerson } = this;
        let res = await API.PERSON.Statistics(this.filter);
        dataPerson[0].value = res[0].flyingHandCount;
        dataPerson[1].value = res[0].mingjingCount;
        dataPerson[2].value = res[0].fujingCount;

        eachTree(res);
        if (this.filter.organId == 1) {
          this.tableData = res[0].child;
        } else {
          this.tableData = res;
        }
        this.charts();
      } catch (error) {
        console.log(error);
      } finally {
        loadingInstance.close();
      }



    },
    init (data, dataName) {
      const chart = (this.chart = new Chart({
        container: dataName,
        autoFit: true,
        height: 120,
      }));

      chart.data(data);

      chart.coordinate("theta", {
        radius: 0.75,
      });

      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });

      chart.legend({
        position: "left",
        itemName: {
          style: {
            fill: "#fff",
          },
        },
      });

      chart.interval().adjust("stack").position("value").color("name");

      chart.interaction("element-active");
      chart.render();
    },
  },
  beforeDestroy () {
    this.chart.destroy();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/person1027.scss";

.cpt-command-device-mount {
  margin-top: 0;
  border-radius: 0;
  backdrop-filter: blur(1px);
  background: #232F4F;
  box-sizing: border-box;
  height: 100%;

  .head {
    height: 42px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;

    .left-img {
      width: 12%;
      height: 100%;
      background: url("~@/assets/img/i65.png") no-repeat;
      background-size: 100% 100%;

      span {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
        cursor: pointer;
        // width: 248px;
        // height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        // line-height: 20px;
      }
    }
  }

  .divSC {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: 25px;

    .search-box-statistics {
      display: flex;
      flex-direction: row;
      gap: 0px;
    }

    .bd-box-chart {
      height: 100px;
      width: 80%;
    }
  }

  .btn {
    background: url("~@/assets/images/command/fourbg.png") no-repeat;
    // background: #00e6fe;
    // border: 1px solid #fff;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2d9ff;
    line-height: 32px;
    text-align: center;
    width: 64px !important;
    height: 32px !important;
  }

  ::v-deep {
    .el-table {
      margin-left: 16px;
      width: 96.5%;
      height: 295px;
      background: rgba(35, 47, 79, 0.9);
      border-radius: 0px;

      thead {
        background: rgba(99, 177, 245, 0.09);
        border-radius: 4px;

        .el-table__cell {
          background: transparent;
          font-size: 14px;
          color: #ffffff;
          line-height: 22px;
          letter-spacing: 1px;
        }
      }

      .tr {
        background-color: transparent;
      }

      // height: calc(100% - 217px);
      .el-table__header-wrapper {
        .el-table__header tr {
          z-index: 20;
          background-color: transparent !important;
        }

        .el-table__header tr th {
          // background-color: red;
          // font-size: 20px !important;
          // font-family: MicrosoftYaHei-Bold, MicrosoftYaHei !important;
          // font-weight: bold !important;
          // color: #00c6ff !important;
          // text-shadow: 0px 2px 4px #020118 !important;
          border: none;
        }
      }

      .el-table__body-wrapper {
        overflow-x: hidden;
        overflow-y: auto;

        // background: #263542;
        tr {
          background: transparent;
          font-size: 12px;
          color: #ffffff;

          td {
            border: none;
          }
        }
      }
    }

    el-table__row>td {
      border: none;
    }

    .el-table::before {
      height: 0px;
    }
  }

  .body {
    width: 100%;
    height: calc(100% - 42px);
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    .body-left {
      width: 35%;
      height: 100%;
      position: relative;

      .bd-box-chart {
        height: 100%;
        width: 100%;
      }

      .foot {
        position: absolute;
        width: 100%;
        height: 18%;
        left: 0;
        bottom: 8%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .item {
          width: 33%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .left {
            width: 40%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;

            .color-img {
              width: 80%;
              height: 4px;
              background: linear-gradient(264deg, rgba(76, 167, 255, 0.11) 0%, #2B77EC 100%);
              margin-bottom: 6px;
            }
          }

          .right {
            width: 60%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: column;

            .v {
              height: 60%;
              width: 100%;
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              font-size: 18px;
              font-family: DINAlternate-Bold, DINAlternate;
              font-weight: bold;
              color: #FFFFFF;
            }

            .n {
              height: 40%;
              width: 100%;
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #788093;
            }
          }
        }

        .item2 {
          .left {
            .color-img {
              background: linear-gradient(255deg, rgba(113, 233, 143, 0) 0%, #4DD4A7 100%);
            }
          }
        }

        .item3 {
          .left {
            .color-img {
              background: linear-gradient(270deg, rgba(247, 207, 86, 0) 0%, #F0893F 100%);
            }
          }
        }
      }
    }

    .body-right {
      width: 63%;
      height: 100%;
      // margin-top: -42px;
      margin-top: -100px;

      .divSC {
        width: 100%;
        height: 43px;
        // border: 1px solid yellow;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .search-box-statistics {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: baseline;
          justify-content: flex-start;
          // border: 1px solid yellow;
          box-sizing: border-box;
          padding-top: 5px;

          .cascader {
            width: 36%;

            /deep/ .el-input__inner {
              background-color: #19243E;
              border: 1px solid rgba(255, 255, 255, 0.21);
            }
          }
        }
      }

      .divTable {
        width: 100%;
        height: calc(100% - 43px);
        // border: 1px solid red;
      }
    }
  }

}

.cpt-command-device-common {
  padding: 0 !important;
  // height: 439px;
  margin: 0px 0 24px 0;
}

.biaotou {
  margin: 16px 1% 0 0;
  width: 40%;
  height: 38px;
  white-space: nowrap;
}

.flex {
  display: flex;
  width: 100%;
}

.xbj {
  height: 1px;
  width: 100%;
  background: linear-gradient(270deg, rgba(0, 230, 254, 0) 0%, #00e6fe 100%);
}

.ti {
  // height: 100%;
  display: flex;
  margin: 5px 0 3px 0;
}

.tiao {
  width: 4px;
  height: 19px;
  background: #ffbf00;
  margin: 0 10px 0 0px;
}

.fontO {
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #00ffff;
  white-space: nowrap;
  overflow: hidden;
  // line-height: 26px;
}

/deep/ .el-button {
  font-family: MicrosoftYaHei !important;
  color: #dce9ff !important;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 4px !important;
}

.btn1027 {
  padding: 5px 20px;
  white-space: nowrap;
  margin-top: -6px;
  margin-left: 10px;
}

.button-nomal {
  height: 32px;
  background: #495e93;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 1px 0px 0px rgba(255, 255, 255, 0.29);
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 32px;
  cursor: pointer;
  width: 80px;
  text-align: center;
}

.divSC1 {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-top: 25px;

  .search-box-statistics {
    display: flex;
    flex-direction: row;
    gap: 0px;
  }

  .bd-box-chart {
    height: 100px;
    width: 80%;
  }
}

.btn {
  background: url("~@/assets/images/command/fourbg.png") no-repeat;
  // background: #00e6fe;
  // border: 1px solid #fff;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2d9ff;
  line-height: 32px;
  text-align: center;
  width: 64px !important;
  height: 32px !important;
}

::v-deep {
  .el-table {
    margin-left: 0;
    width: 100%;
    height: 295px;
    background: rgba(35, 47, 79, 0.9);
    border-radius: 0px;

    thead {
      background: rgba(99, 177, 245, 0.09);
      border-radius: 4px;

      .el-table__cell {
        background: transparent;
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
        letter-spacing: 1px;
      }
    }

    .tr {
      background-color: transparent;
    }

    .el-table__header-wrapper {
      .el-table__header tr {
        z-index: 20;
        background-color: transparent !important;
      }

      .el-table__header tr th {
        border: none;
      }
    }

    .el-table__body-wrapper {
      overflow-x: hidden;
      overflow-y: auto;

      // background: #263542;
      tr {
        background: transparent;
        font-size: 12px;
        color: #ffffff;

        td {
          border: none;
        }
      }
    }
  }

  el-table__row>td {
    border: none;
  }

  .el-table::before {
    height: 0px;
  }
}

.cpt-command-device-common {
  padding: 0 !important;
  // height: 439px;
  // margin: 0px 0 24px 0;
}

.fontO {
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #00ffff;
  white-space: nowrap;
  overflow: hidden;
  // line-height: 26px;
}

/deep/ .el-button {
  font-family: MicrosoftYaHei !important;
  color: #dce9ff !important;
}

/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 4px !important;
}

.btn1027 {
  padding: 5px 20px;
  white-space: nowrap;
  margin-top: -6px;
  margin-left: 10px;
}

.button-nomal {
  height: 32px;
  background: #495e93;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 1px 0px 0px rgba(255, 255, 255, 0.29);
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 32px;
  cursor: pointer;
  width: 80px;
  text-align: center;
}
</style>
<style lang="scss">
@import "@/styles/wuhuCommon.scss";
@import "@/styles/loader.scss";

.personal-cascader {
  background-image: url('~@/assets/img/i101.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0 !important;
  margin: 0 !important;
  // top: 130px !important;

  .el-scrollbar {
    background-color: transparent;
    border: 0;
    border-right: 1px solid #223E4D;
  }

  .el-scrollbar:last-child {
    border: 0 !important;
  }

  .el-cascader-node {
    background-color: transparent;
  }

  .el-cascader__dropdown {
    box-shadow: unset;
    margin: 0 !important;
    border: 0 !important;
  }

  .el-scrollbar__view {
    background-color: #0A293A;
  }

  .popper__arrow {
    display: none;
  }

  .el-cascader-node__label {
    color: #fff;
  }

  .el-icon-arrow-right {
    color: #fff;
  }

}

.input-orgname {
  .el-input__inner {
    background-color: transparent !important;
    color: #fff;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers，webkit内核浏览器 */
    color: #fff;
  }

  .el-input {
    display: relative;

    .el-input__suffix {
      position: absolute;
      top: 16px;
      right: 10px;
      height: 9px;
      width: 13px;
      background-image: url('~@/assets/img/i62.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }

  .el-input__suffix-inner {
    background-color: #fff;
    height: 10px !important;
    width: 10px !important;

    i {
      display: none;
    }
  }
}

.input-excute-panel {
  border: 0;
  top: 203px;

  .el-scrollbar {
    border: 0 !important;
    background-color: #0A293A !important;

    .el-select-dropdown__item {
      color: #ffffff !important;
    }
  }

  .popper__arrow {
    display: none;
  }
}
</style>