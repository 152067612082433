<template>
  <!-- <div class="log" v-if="airway.current_log_id"> -->
  <div class="log">
    <div class="panel-item">
      <div class="panel-item-head" v-interact>
        飞行日志
        <div class="close" @click="$emit('close')">
          <img class="img" src="@/assets/img/i40.png" alt="">
        </div>
      </div>
      <div class="panel-item-body">
        <div class="list">
          <div class="list-tr">
            <div class="item">
              <div class="label">无人机名称:</div>
              <div class="text">{{ log_data.deviceName || "暂无" }}</div>
            </div>
            <div class="item">
              <div class="label">历史轨迹:</div>
              <el-tooltip content="查看" placement="top">
                <div class="action el-icon-view" :class="{ active: is_show }" @click="show_airline"></div>
              </el-tooltip>
            </div>
          </div>

          <div class="list-tr">
            <div class="item">
              <div class="label">责任单位:</div>
              <div class="text">{{ log_data.anotherName || "暂无" }}</div>
            </div>
            <div class="item">
              <div class="label">飞手:</div>
              <div class="text">{{ log_data.flyUserNames && log_data.flyUserNames.join("、") || "暂无" }}</div>
            </div>
          </div>

          <div class="list-tr">
            <div class="item">
              <div class="label">任务类别:</div>
              <div class="text">{{ log_data.taskCateName || "暂无" }}</div>
            </div>
            <div class="item">
              <div class="label">任务类型:</div>
              <div class="text">{{ log_data.taskTypeName || "暂无" }}</div>
            </div>
          </div>

          <div class="list-tr">
            <div class="item">
              <div class="label">飞行时长:</div>
              <div class="text">{{ formatSeconds(log_data.flightDuration || "暂无") }}</div>
            </div>
            <div class="item">
              <div class="label">飞行距离(m):</div>
              <div class="text">{{ log_data.flightDistance || "暂无" }}</div>
            </div>
          </div>

          <div class="list-tr">
            <div class="item">
              <div class="label">飞行开始时间:</div>
              <div class="text">{{ log_data.executeTime || "暂无" }}</div>
            </div>
            <div class="item">
              <div class="label">飞行结束时间:</div>
              <div class="text">{{ log_data.endTime || "暂无" }}</div>
            </div>
          </div>

          <div class="list-tr">
            <div class="item">
              <div class="label">所属任务:</div>
              <div class="text">{{ log_data.taskTitle || "暂无" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api'

let history_Line

export default {
  props: {
    id: {
      type: Number || null,
      default: () => null,
    }
  },
  watch: {
    id: {
      handler (value) {
        console.log('watch', value)
        this.is_show = false
        this.get_data()
        this.clear()
      },
      deep: true,
      immediate: true,
    },
  },
  data () {
    return {
      log_data: {},
      is_show: false
    }
  },
  mounted () {
    console.log('log')
    // this.get_data()
  },
  methods: {
    formatSeconds (value) {
      console.log(value, 'valuevaluevaluevaluevalue');
      if (!value || value == '暂无') {
        return "暂无";
      } else {
        let secondTime = parseInt(value); // 秒
        let minuteTime = 0; // 分
        let hourTime = 0; // 小时
        if (secondTime >= 60) {
          minuteTime = parseInt(secondTime / 60);
          secondTime = parseInt(secondTime % 60);
          if (minuteTime >= 60) {
            hourTime = parseInt(minuteTime / 60);
            minuteTime = parseInt(minuteTime % 60);
          }
        }
        let result =
          "" +
          (parseInt(secondTime) < 10
            ? "0" + parseInt(secondTime)
            : parseInt(secondTime));

        result =
          "" +
          (parseInt(minuteTime) < 10
            ? "0" + parseInt(minuteTime)
            : parseInt(minuteTime)) +
          ":" +
          result;
        result =
          "" +
          (parseInt(hourTime) < 10
            ? "0" + parseInt(hourTime)
            : parseInt(hourTime)) +
          ":" +
          result;
        return result;
      }
    },
    async get_data () {
      console.log('get_data1')
      if (!this.id) return
      let res = await API.TOWER.FlightLog({
        id: this.id
        // id: 2669
      })
      this.log_data = res
      console.log('res', res)
    },
    async show_airline () {
      this.is_show = !this.is_show
      if (this.is_show) {
        try {
          let res = await API.TASK.airtaskTrack(this.id);
          console.log(res)
          if (res && res[Object.keys(res)[0]]) {
            let polyline = res[Object.keys(res)[0]];
            // this.history_track(polyline)
            let positions = [];
            polyline.forEach((item, index) => {
              positions.push(Number(item.longitude), Number(item.latitude), 100);
              // history_entity = viewer.entities.add({
              //   position: Cesium.Cartesian3.fromDegrees(
              //     Number(item.longitude),
              //     Number(item.latitude)
              //   ),
              // });
            });
            history_Line = viewer.entities.add({
              name: "Red line on terrain",
              polyline: {
                positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
                width: 5,
                material: Cesium.Color.RED,
                clampToGround: true,
              },
            });
            viewer.flyTo(history_Line);
          } else {
            this.$el_message("无历史轨迹", () => { }, "info");
          }
        } catch (error) {
          this.$el_message("无历史轨迹", () => { }, "info");
        }
      } else {
        this.clear()
      }
    },
    clear () {
      if (history_Line) {
        viewer.entities.remove(history_Line);
      }
    },
  },
  destroyed () {
    this.clear()
  }
}
</script>

<style lang="scss" scoped>
.log {
  position: absolute;
  top: 200px;
  left: 550px;
  z-index: 1;
  border-radius: 0;

  .panel-item {
    width: 750px;
    height: 300px;
    background: rgba(0, 23, 79, 0.7);
    background-color: #0A293A;
    // box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35), inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
    border-radius: 0;

    .panel-item-head {
      // display: flex;
      position: relative;
      padding: 10px 20px;
      font-size: 18px;
      // color: #00ffff;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 700;
      background: rgba(16, 65, 215, 0.2);
      // box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
      border-radius: 10px 10px 0 0;
      background-color: #224D68;
      border-radius: 0;

      .close {
        position: absolute;
        top: 10px;
        right: 20px;
        cursor: pointer;
        font-size: 16px;
        color: #00ffff;
        letter-spacing: 0;
        font-weight: 400;

        .img {
          height: 20px;
          width: 20px;
        }
      }
    }

    .panel-item-body {
      max-height: 600px;
      padding: 10px;
      color: #b5e5ff;
      overflow-y: auto;
      border-radius: 0;

      box-sizing: border-box;

      .list {

        // display: flex;
        .list-tr {
          display: flex;

          .item {
            flex: 1;
            padding: 10px 10px;
            // float: left;
            // width: 50%;
            box-sizing: border-box;
            display: flex;

            .label {
              width: 110px;
            }

            .action {
              cursor: pointer;
              // color: #b0b9c3;
              font-size: 20px;
              color: #b0b9c3;

              &.active {
                color: #B5E5FF;
              }
            }
          }
        }
      }
    }
  }
}
</style>