<!-- 空域管理 -->
<!-- 关闭左边的图标,在地图上点三下,然后右键打开菜单 -->
<template>
  <div class="airspaceBox">
    <div class="head">
      <div class="flex">
        <div class="xian"></div>
        <div class="kongyu">空域管理</div>
      </div>
      <div class="clone" @click="$emit('close')">关闭</div>
    </div>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="空域名称:" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请选择空域名称"
          size="mini"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="空域类型:" prop="region">
        <el-select
          v-model="form.typeId"
          size="mini"
          placeholder="请选择空域类型"
        >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="空域类别:" prop="region">
        <el-select
          v-model="form.region"
          size="mini"
          placeholder="请选择空域类型"
        >
          <el-option
            v-for="item in regionList"
            :key="item.id"
            :label="item.name"
            :value="item.val"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <div>
        <div class="dib sezhi">色值选择：</div>
        <div class="dib vam">
          <el-color-picker v-model="form.color"></el-color-picker>
        </div>
      </div>
      <el-form-item label="空域层级:" prop="tier">
        <el-input v-model="form.tier" placeholder="" size="mini"></el-input>
      </el-form-item>
      <el-form-item label="所属机构:" prop="pid">
        <el-cascader
          v-model="form.pid"
          :options="options"
          clearable
          :showAllLevels="false"
          placeholder="请选择所属机构"
          :props="{
            value: 'id',
            label: 'org_NAME',
            children: 'child',
            emitPath: false,
            checkStrictly: true,
          }"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="区域形状:" prop="regionType">
        <el-select
          v-model="form.regionType"
          size="mini"
          placeholder="请选择层级"
        >
          <el-option
            v-for="item in regionTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.val"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="有效时间:" prop="noFlyStartTime">
        <div class="shijian">
          <el-date-picker
            size="mini"
            v-model="form.noFlyStartTime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择开始时间"
            prefix-icon="null"
          >
          </el-date-picker>
          <div class="duanheng">－</div>
          <el-date-picker
            size="mini"
            v-model="form.noFlyEndTime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择结束时间"
            prefix-icon="null"
          >
          </el-date-picker>
        </div>
      </el-form-item>

      <el-form-item label="坐标内容:" prop="desc">
        <el-input
          disabled
          placeholder="请输入坐标内容"
          type="textarea"
          v-model="form.region"
        ></el-input>
      </el-form-item>
      <!-- 提示：至少三组经纬度坐标，坐标之间用空格分隔 (例：120.17,33.35 120.17,33.34 120.17,33.34) -->
      <el-form-item class="queren">
        <div class="btn dib cp" @click="confirmBtn">确认</div>
        <!-- <div class="btn ml20 dib cp" @click="$emit('close')">取消</div> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import API from "@/api";
export default {
  data() {
    let validateRemark = (rule, value, callback) => {
      if (value && value.length > 30) {
        callback(new Error("长度限制在30位数以内"));
      } else {
        callback();
      }
    };
    return {
      value: "",
      options: [],
      regionList: [],
      typeList: [],
      regionTypeList: [
        {
          id: 1,
          val: "POLYGON",
          name: "多边形",
        },
        {
          id: 2,
          val: "CIRCLE",
          name: "圆形",
        },
      ],
      form: {
        name: "", //空域名称
        color: "", //颜色
        category: 15,
        pid: 246158,
        region: "",
        regionList: "",
        regionType: "POLYGON",
        tier: 1, //层
        typeId: 1,
        noFlyStartTime: "", //开始时间
        noFlyEndTime: "", //结束时间
      },
      rules: {
        region: [
          {
            required: true,
            message: "请输入坐标内容",
            trigger: "blur",
          },
        ],
        regionType: [
          {
            required: true,
            message: "请选择围栏类型",
            trigger: "change",
          },
        ],
        category: [
          {
            required: true,
            message: "请选择空域类别",
            trigger: "change",
          },
        ],
        pid: [
          {
            required: true,
            message: "请选择所属机构",
            trigger: "blur",
          },
        ],
        remark: [
          {
            // required: true,
            validator: validateRemark,
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  inject: ["g_cesium_resetJfq"],
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    confirmBtn() {
      if (this.form.name == "") return this.$message.info("请填写空域名称");
      if (this.form.noFlyStartTime == "" || this.form.noFlyEndTime == "") {
        return this.$message.info("请选择开始或者结束时间！");
      }
      if (this.form.regionList == "") {
        this.$message.info("请重新选择空域范围！");
        return this.$emit("close");
      }
      API.AIRSPACE.addAirSpaceWeb(this.form).then((res) => {
        this.$emit("close");
        this.g_cesium_resetJfq();
        this.$emit("getList");
      });
    },
    init() {
      API.AIRSPACE.getAllOrganizationBack().then((res) => {
        this.options = this.getTreeData(res);
      });
      API.AIRSPACE.getTypeList().then((res) => {
        this.regionList = res;
      });
      API.AIRSPACE.selectType().then((res) => {
        this.typeList = res;
      });
    },
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].child.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].child = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].child);
        }
      }
      return data;
    },
    handleChange() {},
  },
};
</script>

<style scoped lang='scss'>
.airspaceBox {
  position: absolute;
  right: -490px;
  top: 0px;
  width: 367px;
  height: 532px;
  background: rgba(0, 39, 121, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    inset 0px 0px 8px 0px #019aff;
  border: 1px solid #43deff;
  backdrop-filter: blur(2px);
  // overflow-y: auto;
  /deep/.el-color-picker__trigger {
    width: 100px;
    border: transparent;
  }
  /deep/.el-form-item__label {
    color: #fff;
  }
  /deep/.el-form-item {
    margin-bottom: 10px;
  }
  /deep/.el-cascader {
    width: 200px;
    color: #fff;
  }
  /deep/.el-select {
    width: 200px;
    color: #fff;
  }
  /deep/.el-input {
    width: 250px;
    color: #fff;
  }
  /deep/.el-input__inner {
    width:250px;
    height: 28px;
    background: rgba(2, 31, 51, 0.7);
    border-radius: 2px;
    border: 1px solid #56e2e5;
  }
  /deep/.el-date-editor.el-input {
    width: 166px;
    color: #fff;
  }
  /deep/.el-input--prefix .el-input__inner {
    width: 166px;
  }

  /deep/ .el-textarea.is-disabled {
    .el-textarea__inner {
      background: #0e2436;
      border: 1px solid #036eaf;
    }
  }
  // /deep/.el-input__inner::placeholder {
  //   color: #fff;
  // }
  // /* 谷歌 */
  // /deep/.el-input__inner::-webkit-input-placeholder {
  //   color: #fff;
  // }
  // /* 火狐 */
  // /deep/ .el-input__inner:-moz-placeholder {
  //   color: #fff;
  // }
  // /*ie*/
  // /deep/ .el-input__inner:-ms-input-placeholder {
  //   color: #fff;
  // }

  .btn {
    width: 76px;
    height: 27px;
    text-align: center;
    line-height: 27px;
    background: rgba(0, 34, 140, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
    border-radius: 6px;
    font-size: 14px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 400;
  }
}
// ------------------------------------------
.head {
  width: 367px;
  height: 32px;
  background: linear-gradient(
    180deg,
    #9198ff 0%,
    rgba(45, 81, 153, 0.45) 40%,
    #05091a 100%
  );
  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-bottom: 1px solid #43deff;
  margin: 0 0 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  padding: 0 16px 0 16px;
}
.sezhi {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 19px;
  margin: 0 0 0 6px;
}
.flex {
  display: flex;
}
.xian {
  width: 3px;
  height: 15px;
  background: #ffbd36;
  margin: 4px 5px 0 0;
}
.kongyu {
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  text-shadow: 0px 2px 4px #136791;
}
.clone {
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #00ffff;
  margin: 0 16px 0 0px;
  cursor: pointer;
}
/deep/ .queren {
  width: 100%;
  display: flex;
  justify-content: center;
  .el-form-item__content {
    margin: 0px 0 0 0;
    margin-left: 0px !important;
  }
}
/deep/ .el-textarea__inner {
  height: 76px;
  width: 250px;
}
/deep/ .shijian {
  display: flex;
  width: 250px;
  min-width:250px;
  height: 28px;
  background: rgba(2, 31, 51, 0.7);
  border-radius: 2px;
  border: 1px solid rgba(86, 226, 229, 0.7);
  div:nth-child(1){
    input{
      text-align: end;
    }
  }
  .el-input__inner {
    border: 0px solid;
    padding: 0;
    width: 119px !important;
    min-width: 119px;
  }
  .el-input {
    display: flex;
  }
  .duanheng {
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #70f6f9;
    line-height: 28px;
  }
}
</style>