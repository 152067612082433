<!-- 
  飞行日志卡片组件
 -->
<template>
  <div class="fly-log-card">
    <div class="item" shadow="hover">

      <div class="line line1 ">
        <span class="name c-font">{{ data.deviceName }}</span>
        <el-tooltip class="item" effect="dark" :content="data.taskTitle" placement="top">
          <span class="title c-font">{{ String(data.taskTitle).length > 15 ?
            String(data.taskTitle).substring(0, 12) +
            '...' : String(data.taskTitle) }}</span>
        </el-tooltip>
      </div>

      <div class="line line2">
        <div class="eve eve1">
          <span class="v">{{ data.flightDistance }}</span>
          <span class="n">飞行距离(m)</span>
        </div>
        <div class="eve eve2">
          <span class="v c-font">{{ formatSeconds(data.flightDuration) }}</span>
          <span class="n c-font">飞行时长</span>
        </div>
      </div>

      <div class="line line3">
        <div class="eve eve1">
          <div class="left eve-item">
            <img class="img" src="@/assets/img/i2.png" alt="">
            <span class="img-name">{{ data.id }}</span>
          </div>
          <div class="right eve-item">
            <img class="img" src="@/assets/img/i3.png" alt="">

            <el-tooltip class="item" effect="dark" :content="data.anotherName" placement="top">
              <span class="img-name">所属区域：{{ String(data.anotherName).length > 5 ? String(data.anotherName).substring(0,
                4) +
                '...'
                : String(data.anotherName) }}</span>
            </el-tooltip>
          </div>
        </div>
        <div class="eve eve2">
          <div class="left eve-item">
            <img class="img" src="@/assets/img/i121.png" alt="">
            <span class="img-name">{{ data.executeTime }}</span>
          </div>
          <div class="right eve-item">
            <img class="img" src="@/assets/img/i122.png" alt="">
            <span class="img-name">{{ data.endTime }}</span>
          </div>
        </div>
      </div>

      <div class="line line4">
        <div class="eve eve1">
          <span class="name">任务类别：{{ data.taskCateName }}</span>
          <span class="name">任务类型：{{ data.taskTypeName }}</span>
        </div>
        <div class="eve eve2">
          <span class="name">飞手：{{ data.flyUserNames.join("、") || "暂无" }}</span>
        </div>
      </div>

      <div class="line line5">
        <el-tooltip class="item" effect="dark" content="图片" placement="top">
          <img src="@/assets/img/i5.png" alt="" class="img img1" @click="handle_image(data)">
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="历史轨迹" placement="top">
          <img src="@/assets/img/i11.png" alt="" class="img img2" @click="handle_histoty_track(data)">
        </el-tooltip>
      </div>
    </div>
  </div>
</template>
 
<script>
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import objectSupport from 'dayjs/plugin/objectSupport';
dayjs.extend(duration)
dayjs.extend(objectSupport)

export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  inject: ["handle_video_Player", "handle_image", "handle_histoty_track"],
  methods: {
    formatSeconds (value) {
      if (!value) {
        return "暂无";
      } else {
        let result = dayjs({
          seconds: parseInt(value)
        }).format('HH:mm:ss')
        return result;
      }
    },
  },
}
</script>
 
<style lang="scss" scoped>
.fly-log-card {
  width: 100%;
  height: 100%;
  // border: 1px solid yellow;
  background-color: #163B53;
  box-sizing: border-box;
  padding: 5px 10px 10px 10px;
  border-radius: 4px;

  .c-font {
    font-family: PingFangSC-Regular, PingFang SC;
    color: #ffffff;
  }

  .item {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .line {
      width: 100%;
      box-sizing: border-box;
    }

    .line1 {
      height: 15%;
      // border: 1px solid greenyellow;
      display: flex;
      align-items: center;
      justify-content: space-between;


      .name {
        width: 40%;
        height: 100%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: #D5DBE0;
      }

      .title {
        width: 50%;
        height: 100%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #D5DBE0;
      }
    }

    .line2 {
      height: 26%;
      // border: 1px solid greenyellow;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .eve {
        height: 100%;
        width: 20%;
        // border: 1px solid pink;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .v {
          font-size: 16px;
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
        }

        .n {
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #627b8b;
          margin-top: 5px;
        }
      }
    }

    .line3 {
      height: 28%;
      width: 100%;
      // border: 1px solid blueviolet;

      .eve {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 50%;

        .eve-item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 45%;
          // border: 1px solid yellow;

          .img {
            width: 18px;
            height: 18px;
          }

          .img-name {
            margin-left: 3px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            // color: rgba(99, 130, 163, 1);
            // color: #ffffff;
            color: #6382a3;
          }
        }
      }

      .eve2 {
        .left {
          margin-left: -2px;
        }

        .eve-item {

          .img {
            width: 22px;
            height: 20px;
          }
        }
      }


    }

    .line4 {
      height: 24%;
      width: 100%;
      // border: 1px solid yellow;

      .eve {
        height: 45%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .name {
          font-size: 12px;
          color: #D5DBE0;
        }
      }
    }

    .line5 {
      height: 7%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .img {
        height: 18px;
        width: 18px;
        // margin-bottom: 5px;
        cursor: pointer;
      }

      .img2 {
        margin-left: 10px;
      }
    }
  }
}
</style>