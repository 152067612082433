<template>
  <div class="cpt-command-task-check-popup">
    <div class="header" v-interact>
      <div class="hd-box">飞行任务</div>
      <div class="hd-box cp mr20" @click="flight_task_chage_view(1)">关闭</div>
    </div>
    <div class="popup_content">
      <div class="task_detail" v-if="situation_is_show">
        <div class="task_detail_box">
          <span>警情事件内容</span>
          <div>{{ situation_detail.name || '暂无' }}</div>
        </div>
        <div class="task_detail_box">
          <span>航线名称</span>
          <div>{{ situation_detail.flightLineName || '暂无' }}</div>
        </div>
        <div class="task_detail_box">
          <span>警情类型</span>
          <div>{{ situation_detail.typeTitle || '暂无' }}</div>
        </div>
        <div class="task_detail_box">
          <span>执行状态</span>
          <div>{{ get_status(situation_detail) }}</div>
        </div>
        <div class="task_detail_box">
          <span>调度状态</span>
          <div>{{ get_approvalStatus(situation_detail.approvalStatus) }}</div>
        </div>
        <div class="task_detail_box">
          <span>责任单位</span>
          <div>{{ situation_detail.policeUnitTitle || '暂无' }}</div>
        </div>
        <div class="task_detail_box">
          <span>协助单位</span>
          <div>{{ situation_detail.assistUnit || '暂无' }}</div>
        </div>
        <div class="task_detail_box">
          <span>飞手</span>
          <div>
            {{
              situation_detail.team && situation_detail.team.length
              ? situation_detail.team.join('、')
              : '暂无'
            }}
          </div>
        </div>
        <div class="task_detail_box">
          <span>审批意见</span>
          <el-input v-model="remark" placeholder="请输入内容描述"> </el-input>
        </div>
      </div>

      <div class="task_detail" v-else>
        <div class="task_detail_box">
          <span>任务名称</span>
          <div>{{ situation_detail.taskTitle || '暂无' }}</div>
        </div>
        <div class="task_detail_box">
          <span>航线名称</span>
          <div>{{ situation_detail.airlineTitle || '暂无' }}</div>
        </div>
        <div class="task_detail_box">
          <span>任务类型</span>
          <div>{{ situation_detail.taskTypeTitle || '暂无' }}</div>
        </div>
        <div class="task_detail_box">
          <span>执行状态</span>
          <div>{{ get_status_normal(situation_detail) }}</div>
        </div>
        <div class="task_detail_box">
          <span>责任单位</span>
          <div>{{ situation_detail.organizationTitle || '暂无' }}</div>
        </div>
        <div class="task_detail_box">
          <span>飞手</span>
          <div>
            {{
              situation_detail.team && situation_detail.team.length
              ? situation_detail.team.join('、')
              : '暂无'
            }}
          </div>
        </div>
        <div class="task_detail_box">
          <span>预计开始时间</span>
          <div>{{ situation_detail.startedAt || '暂无' }}</div>
        </div>
        <div class="task_detail_box">
          <span>审批意见</span>
          <el-input v-model="remark" placeholder="请输入内容描述"> </el-input>
        </div>
      </div>
      <div id="page-command-container"></div>
    </div>
    <div class="btns">
      <el-button class="btn_second" @click="handle_click(1)">审批通过</el-button>
      <el-button class="btn_first" @click="handle_click(0)">审批不通过</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import API from '@/api'
import { Utils } from '@/lib/cesium'

let viewer = null
let LAYERS = null
let air_line_preview = []
let isSwtxDev = process.env.VUE_APP_SWTX_ENV === 'development'
if (isSwtxDev) {
  LAYERS = Object.freeze({
    MINE_MAP: {
      BASIC: {
        url:
          'https://datahive.minedata.cn/2d/raster/q4/mercator/5873/raster/{z}/{x}/{y}',
      },
      TEXT: {
        url:
          'https://datahive.minedata.cn/2d/raster/q4/mercator/5873/text/{z}/{x}/{y}',
      },
    },
  })
} else {
  LAYERS = Object.freeze({
    MINE_MAP: {
      BASIC: {
        url: 'http://32.128.12.23:58088/minemap-data/data/raster/{sz}/{x}/{y}',
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          sz: function (imageryProvider, x, y, level) {
            return level + 1
          },
        },
      },
      TEXT: {
        url: 'http://32.128.12.23:58088/minemap-data/data/text/{sz}/{x}/{y}',
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          sz: function (imageryProvider, x, y, level) {
            return level + 1
          },
        },
      },
    },
  })
}

export default {
  data () {
    return {
      remark: '',
    }
  },
  inject: ['flight_task_chage_view'],
  async mounted () {
    await this.init_viewer()
    console.log("禁飞区1");
    this.show_xfq()
    if (this.situation_detail?.name || this.situation_detail?.taskTitle) {
      await this.handleAirLinePreview(this.situation_detail)
    }
  },

  computed: {
    ...mapGetters(['situation_detail', 'situation_is_show']),
  },
  methods: {
    async show_xfq () {
      // 禁飞区
      let data1 = await API.AIRSPACE.GetNotFlyAreas()

      // 电子围栏
      let data2 = await API.AIRSPACE.GetAreaList()
      // 当前机构自己的电子围栏
      let data3 = await API.AIRSPACE.GetCurrentAreaList()
      // 限高区
      let data4 = await API.AIRSPACE.GetLimitHeightAreas()
      data3.forEach((item) => {
        item.typeId = 4
      })
      let _this = this
      let data = [...data1, ...data2, ...data3, ...data4] || []

      data.forEach((item) => {
        _this.init_area(item.regionType, item.regionList, item)
      })
    },
    init_area (regionType, positions, item) {
      if (regionType == 'CIRCLE') {
        viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(positions[0], positions[1]),
          ellipse: {
            semiMajorAxis: positions[2],
            semiMinorAxis: positions[2],
            height: 8,
            material: Cesium.Color.fromCssColorString(
              item.color || 'red'
            ).withAlpha(0.3),
            outline: true,
            outlineColor: Cesium.Color.fromCssColorString(item.color || 'red'),
            zIndex: item.tier,
          },
          show: true,
          id: JSON.stringify(item),
          name: 'area',
        })
      }
      if (regionType == 'POLYGON') {
        viewer.entities.add({
          polygon: {
            hierarchy: {
              positions: Cesium.Cartesian3.fromDegreesArray(positions),
            },
            // height: 50000,
            outline: true,
            outlineColor: Cesium.Color.fromCssColorString(item.color || 'red'),
            outlineWidth: 2,
            material: Cesium.Color.fromCssColorString(
              item.color || 'red'
            ).withAlpha(0.3),
            zIndex: item.tier,
          },
          show: true,
          id: JSON.stringify(item),
          name: 'area',
        })
      }
    },
    async handle_click (status) {
      let { situation_detail, remark } = this
      let res = await API.TASK.Edit({
        // id: situation_detail.id,
        // taskId: situation_detail.taskId,
        // 注意：警情和其他任务传的字段不一样
        id: situation_detail.is_from_situation ? situation_detail.taskId : situation_detail.id,
        taskCateId: situation_detail.is_from_situation
          ? undefined
          : situation_detail.taskCateId,
        status,
        approvalRemark: remark
      })
      this.$el_message('审批成功')
      this.flight_task_chage_view(1)
    },
    get_status_normal (item) {
      let val = item
      let text = ''
      if (val.applyStatus == 1 && val.papplyStatus == 1) {
        text = '审批通过'

        if (val.executeStatus == 0) {
          text = '待执行'
        } else if (val.executeStatus == 1) {
          text = '已完成'
        } else {
          text = '执行中'
        }
      } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
        text = '审批不通过'
      } else {
        text = '待审批'
      }
      return text
    },
    get_status (item) {
      if (item.taskSituation == -1) {
        item.taskSituation = 5
      }
      const obj = {
        0: '待审批',
        1: '审批不通过',
        2: '待执行',
        3: '执行中',
        4: '已完成',
        5: '待派发',
      }
      return obj[item.taskSituation]
    },
    get_approvalStatus (item) {
      if (item == 0) {
        return '待审批'
      } else if (item == 1) {
        return '通过'
      } else if (item == 2) {
        return '驳回'
      } else {
        return '暂无'
      }
    },

    // 航线预览
    async handleAirLinePreview (item) {
      let state = item.is_from_situation
      try {
        let res = {}
        if (state) {
          res = await API.TASK.JingQingTrack({
            taskId: item.taskId,
          })
        } else {
          res.flightCourseJson = item.flightCourseJson
        }
        if (res?.flightCourseJson) {
          let positions = []
          let polyline = JSON.parse(res.flightCourseJson)
          let label_arr = []
          let _this = this
          polyline.points.forEach((item, index) => {
            positions.push(item.lon, item.lat, item.alt)
            label_arr.push(
              Cesium.Cartesian3.fromDegrees(item.lon, item.lat, item.alt)
            )
            let point_entity = null
            if (index == polyline.points.length - 1) {
              point_entity = viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(
                  item.lon,
                  item.lat,
                  item.alt
                ),
                name: 'show_airline_point',
                label: {
                  text: String(item.alt) + 'm',
                  scale: 0.6,
                  font: 'bold 28px Microsoft YaHei',
                  // fillColor: Cesium.Color.BLUE,
                  fillColor: Cesium.Color.fromCssColorString('#FF7F09'),
                  horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                  verticalOrigin: Cesium.VerticalOrigin.CENTER,
                  disableDepthTestDistance: Number.POSITIVE_INFINITY,
                  showBackground: false,
                  outlineWidth: 0,
                  pixelOffset: new Cesium.Cartesian2(25, -10),
                },
              })
            } else {
              point_entity = viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(
                  item.lon,
                  item.lat,
                  item.alt
                ),
                name: 'show_airline_point',
                point: {
                  pixelSize: 10,
                  // color: Cesium.Color.RED,
                  color: Cesium.Color.fromCssColorString('#00A9A9'),
                  // fillColor: Cesium.Color.RED,
                  // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                },
                label: {
                  text: String(item.alt) + 'm',
                  scale: 0.6,
                  font: 'bold 28px Microsoft YaHei',
                  // fillColor: Cesium.Color.BLUE,
                  fillColor: Cesium.Color.fromCssColorString('#FF7F09'),
                  horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                  verticalOrigin: Cesium.VerticalOrigin.CENTER,
                  disableDepthTestDistance: Number.POSITIVE_INFINITY,
                  showBackground: false,
                  outlineWidth: 0,
                  pixelOffset: new Cesium.Cartesian2(25, -10),
                },
              })
            }



            if (label_arr.length > 1) {
              let before = label_arr[label_arr.length - 2]
              let after = label_arr[label_arr.length - 1]
              _this.create_label(before, after)
            }
          })
          positions = Cesium.Cartesian3.fromDegreesArrayHeights(positions)

          let redLine = viewer.entities.add({
            name: 'Red line on terrain',
            polyline: {
              positions: new Cesium.CallbackProperty(() => {
                return positions
              }, false),
              width: 4,
              // material: Cesium.Color.RED,
              material: new Cesium.PolylineArrowMaterialProperty(
                Cesium.Color.fromCssColorString("#00A9A9"),
              ),
              // clampToGround: true,
            },
          })
          viewer.flyTo(redLine)
        } else {
          this.$el_message('暂无航线', () => { }, 'error')
        }
      } catch (error) {
        this.$el_message('暂无航线', () => { }, 'error')
      }
    },

    create_label (before, after) {
      let before_wgs84 = Utils.transformCartesian2WGS84(before)
      let after_wgs84 = Utils.transformCartesian2WGS84(after)

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      })

      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`

      let label_entity = viewer.entities.add({
        id: `label_${before}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: 'bold 30px Microsoft YaHei',
          fillColor: Cesium.Color.fromCssColorString('#fff'),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
        },
      })
    },

    async init_viewer () {
      // 解决航线被三维模型遮盖
      var oldPolylineUpdate = Cesium.PolylineCollection.prototype.update
      Cesium.PolylineCollection.prototype.update = function (frameState) {
        var oldMorphTime = frameState.morphTime
        frameState.morphTime = 0.0
        oldPolylineUpdate.call(this, frameState)
        frameState.morphTime = oldMorphTime
      }

      let options = {
        layers: [LAYERS.MINE_MAP.BASIC, LAYERS.MINE_MAP.TEXT],
      }
      // viewer = new Viewer("layer-container");
      viewer = new Cesium.Viewer('page-command-container', {
        animation: false, //是否显示动画控件
        shouldAnimate: true,
        homeButton: false, //是否显示Home按钮
        fullscreenButton: false, //是否显示全屏按钮
        baseLayerPicker: false, //是否显示图层选择控件
        geocoder: true, //是否显示地名查找控件
        timeline: false, //是否显示时间线控件
        sceneModePicker: true, //是否显示投影方式控件
        navigationHelpButton: false, //是否显示帮助信息控件
        infoBox: false, //是否显示点击要素之后显示的信息
        requestRenderMode: true, //启用请求渲染模式
        scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
        fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
        selectionIndicator: false,
        animation: false, //是否显示动画控件
        // imageryProvider: false,
        // imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
        //   url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer",
        // }),
        imageryProvider: new Cesium.UrlTemplateImageryProvider(
          options.layers[0]
        ),
      })

      let services = [
        '3D-1-1',
        '3D-1-2',
        '3D-1-3',

        '3D-3',

        '3D-4-1',
        '3D-4-2',
        '3D-4-3',
        '3D-4-4',

        '3D-6-1',
        '3D-6-2',
        '3D-6-3',

        '3D-7-1',
        '3D-7-2',
        '3D-7-3',

        '3D-10-1',
        '3D-10-2-1',
        '3D-10-2-2',
        '3D-10-3',

        '3D-11-1',
        '3D-11-2',
        '3D-11-3',

        '3D-12',

        '3D-13-1',
        '3D-13-2',
        '3D-13-3',

        '3D-15-1',
        '3D-15-2',

        '3D-16-1',
        '3D-16-2',
        '3D-16-3',

        '3D-18-1',
        '3D-18-2',
        '3D-18-3',
        '3D-18-4',
        '3D-18-5',
        '3D-18-6',

        '3D-19-1',
        '3D-19-2',
        '3D-19-3',
        '3D-19-4',

        '3D-shizhengfu',
      ]

      if (options.layers && options.layers.length) {
        options.layers.forEach((layerOptions) => {
          viewer.imageryLayers.addImageryProvider(
            new Cesium.UrlTemplateImageryProvider(layerOptions)
          )
        })
      }

      services.forEach((item) => {
        viewer.scene.addS3MTilesLayerByScp(
          `http://32.128.6.46:8090/iserver/services/${item}/rest/realspace/datas/Config/config`,
          {
            name: item,
          }
        )
      })

      viewer.scene.globe.depthTestAgainstTerrain = false
    },
  },
}
</script>

<style lang="scss" scoped>
.cpt-command-task-check-popup {
  width: 1216px;
  height: 756px;
  position: absolute;
  top: 120px;
  left: 100px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  background-size: 100% 100%;

  ::v-deep {
    .el-input {
      padding: 0;

      .el-input__inner {
        background: transparent;
        border: none;
        color: #08c2d1;
        padding: 0;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 44px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    margin-left: 20px;
  }

  .popup_content {
    display: flex;
    justify-content: space-around;
    margin-top: 55px;

    .task_detail {
      .task_detail_box {
        display: flex;
        margin-bottom: 23px;

        &>span {
          line-height: 40px;
          display: inline-block;
          width: 100px;
          text-align: right;
          margin-right: 20px;
          font-size: 16px;
          color: #ffffff;
        }

        &>div {
          width: 396px;
          height: 40px;
          border: 1px solid #06b6e0;
          font-family: MicrosoftYaHeiUI;
          font-size: 15px;
          color: #08c2d1;
          background: rgba(2, 31, 51, 0.7);
          font-size: 16px;
          color: #ffffff;
          line-height: 40px;
          padding-left: 15px;
          box-sizing: border-box;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
      }
    }

    #page-command-container {
      width: 610px;
      height: 549px;
    }
  }

  .btns {
    display: flex;
    width: 430px;
    justify-content: space-between;
    margin-left: 393px;
    margin-top: 47px;

    .el-button {
      width: 156px;
      height: 40px;
      padding: 0;
      font-family: PangMenZhengDao;
      font-size: 22px;
      color: #ffffff;
      border: none;
      border-radius: 0;

      &:hover {
        opacity: 0.7;
      }
    }

    .btn_first {
      background: rgba(201, 6, 6, 0.3);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 10px 0 #ff5c00;
      border-radius: 6px;
    }

    .btn_second {
      background: rgba(0, 34, 140, 0.2);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
      border-radius: 4px;
    }
  }
}
</style>
