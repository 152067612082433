<template>
  <div class="cpt-command-new_home-airway-list">
    <div class="hd">
      <div class="tr">
        <div class="th name name1">
          <div class="name">告警内容</div>
        </div>
        <div class="th name name2">
          <div class="name">告警类型</div>
        </div>
        <div class="th name name3">
          <div class="name">执行状态</div>
        </div>
        <div class="th name name4">
          <div class="name">责任单位</div>
        </div>
        <div class="th name name5">
          <div class="name">时间</div>
        </div>
        <div class="th ope  name6">
          <div class="ope">操作</div>
        </div>
      </div>
    </div>
    <div class="bd">
      <div class="tr" v-for="(item, index) in list" :key="index" :class="index % 2 == 0 ? 'tr-odd' : 'tr-even'">
        <div class="td name name1">
          <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
            <div class="name">{{ item.name }}</div>
          </el-tooltip>
        </div>
        <div class="td name name2">
          <el-tooltip class="item" effect="dark" :content="item.typeTitle" placement="top">
            <div class="name">{{ item.typeTitle }}</div>
          </el-tooltip>
        </div>
        <div class="td name  name3">
          <el-tooltip class="item" effect="dark"
            :content="item.taskStatus == '3' ? '执行中' : (item.taskStatus == '1' ? '执行完成' : '待执行')" placement="top">
            <div class="name">{{ item.taskStatus == '3' ? '执行中' : (item.taskStatus == '1' ? '执行完成' : '待执行') }}</div>
          </el-tooltip>
        </div>
        <div class="td name  name4">
          <el-tooltip class="item" effect="dark" :content="item.organizationName" placement="top">
            <div class="name">{{ item.organizationName }}</div>
          </el-tooltip>
        </div>
        <div class="td name  name5">
          <el-tooltip class="item" effect="dark" :content="item.addtime" placement="top">
            <div class="name">{{ item.addtime }}</div>
          </el-tooltip>
        </div>
        <div class="td icon name6">
          <div class="action">
            <el-tooltip content="查看" placement="top">
              <span :class="{ active: item.__selected }" class="el-icon-view" @click="$emit('click-item', item)"></span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="tb-pagination">
      <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="pagination.pageNo"
        :page-size="pagination.pageSize" layout="prev, pager, next,  sizes,  jumper" :total="pagination.totalCount"
        @size-change="handle_size_change" @current-change="handle_page_change"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handle_size_change (size) {
      this.$emit("size-change", size);
    },
    handle_page_change (page) {
      this.$emit("page-change", page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
@import "../../common/style.scss";

.w90 {
  flex: none !important;
}

.w100 {
  flex: none !important;
}

.w140 {
  flex: none !important;
}

.cpt-command-new_home-airway-list {
  width: 100%;
  height: 100%;

  .hd {
    border: 0;
    border-color: #163B53;
    height: 40px !important;

    .name,
    .ope {
      color: #ffffff !important;
      font-size: 14px !important;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
  }

  .bd {
    .tr-odd {
      background-color: #133144;
    }

    .tr-even {
      background-color: #163B53;
    }
  }

  .bd .tr {
    background-image: none;
    border: 0;
    box-shadow: none !important;
    padding: 0;
    margin: 0;
    height: 40px;

    .name {
      font-size: 12px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .el-icon-view {
      font-size: 15px !important;
    }
  }

  .bd .tr:hover {
    border: 0;
    box-shadow: none !important;
  }

  .tb-pagination {
    /deep/ .el-pager {
      .active {
        background-color: #1890FF !important;
      }
    }
  }
}
</style>