
import API from "@/api"
import image from "@/assets/images/icons/station.svg"

let station_entity = {}
let entity_data_map = {}
let is_all = false

// 颜色转换
function fromCssColorString(param) {
    if (typeof (param) === 'object') {
      const { r, g, b, a } = param
      return Cesium.Color.fromCssColorString(`rgba(${r}, ${g}, ${b}, ${a})`)
    } else {
      let r = Number.parseInt(param.substring(0, 2), 16)
      let g = Number.parseInt(param.substring(2, 4), 16)
      let b = Number.parseInt(param.substring(4, 6), 16)
      let a = Number.parseInt(param.substring(6, 8), 16)
      return Cesium.Color.fromBytes(r, g, b, a)
    }
}
  
export default {

    async list(params) {
        let { pagination, search, status, selected_list } = this.station
        this.station.loading = true
        let { pageNo, pageSize, totalCount, data } = await API.TOWER.ListStation({
            ...pagination,
            ...search,
            ...status,
            ...params
        })
        this.station.loading = false
        if (data) {
            data = data.map(item => ({
                ...item,
                __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
            }))
        }

        this.station.list = data || []
        this.station.pagination.pageNo = pageNo || 1
        this.station.pagination.pageSize = pageSize || 10
        this.station.pagination.totalCount = totalCount || 0
    },
    update_list_selected() {
        let { selected_list } = this.station
        this.station.list = this.station.list.map(item => ({
            ...item,
            __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
        }))
    },
    change_status(params) {
        this.station.status = params
        this.station_list(params)
    },

    change_size(size) {
        this.station.pagination.pageSize = size
        this.station_list()
    },
    change_page(page) {
        this.station.pagination.pageNo = page
        this.station_list()
    },

    search(name) {
        this.station.search.name = name
        this.station_list()
    },

    change(station) {
        station.__selected = !station.__selected
        if (station.__selected) {
            this.station.selected_list.push(station.id)
            this.station_show(station)
        } else {
            let index = this.station.selected_list.findIndex(id => id === station.id)
            if (index !== -1) {
                this.station.selected_list.splice(index, 1)
            }
            this.station_hide(station)
        }

        let { list, selected_list } = this.station
        list = list.map(item => ({
            ...item,
            __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
        }))
        this.station.list = list || []
    },

    /**
     * 显示基站
     * @param {*} station 
     */
    show(station, is_all) {
        // console.log("show station:", station);
        try {
            let id = station.id
            let position = Cesium.Cartesian3.fromDegrees(station.longitude, station.latitude)
            if(station_entity[id] && station_entity[id].billboard) {
                if(!is_all) {
                    viewer.camera.flyTo({
                        destination: Cesium.Cartesian3.fromDegrees(station.longitude, station.latitude, 5000),
                    })
                }
            } else {
                let entity_data = {
                    id: station.id,
                    name: station.name,
                    // address: station.address,
                    temperature: station.temperature,
                    humidity: station.humidity,
                    elecState: station.elecState,
                    network: station.network,
                    signalRate: station.signalRate,
                    camera: station.camera,
                    date: new Date().getTime()
                }
                station_entity[id] = {}
                let entity_id = JSON.stringify(entity_data)
                station_entity[id].billboard = viewer.entities.add({
                    id: entity_id,
                    name: 'station',
                    position,
                    billboard: {
                        width: 36,
                        height: 36,
                        image,
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                        verticalOrigin: Cesium.VerticalOrigin.Bottom,
                        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                        disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    },
                });

                // station_entity[id].greenCircle = viewer.entities.add({
                //     position,
                //     name: "greenCircle",
                //     ellipse: {
                //         semiMinorAxis: 3000.0,
                //         semiMajorAxis: 3000.0,
                //         //   height: 200000.0,
                //         material: Cesium.Color.fromCssColorString('#43B94D').withAlpha(0.5),
                //         outline: true, // height must be set for outline to display
                //         zIndex: 4
                //     },
                // });
                // station_entity[id].blueCircle = viewer.entities.add({
                //     position,
                //     name: "blueCircle",
                //     ellipse: {
                //         semiMinorAxis: 5000.0,
                //         semiMajorAxis: 5000.0,
                //         //   height: 200000.0,
                //         material: Cesium.Color.fromCssColorString('#00ADFF').withAlpha(0.5),
                //         outline: true, // height must be set for outline to display
                //         zIndex: 3
                //     },
                // });
                // station_entity[id].yellowCircle = viewer.entities.add({
                //     position,
                //     name: "yellowCircle",
                //     ellipse: {
                //         semiMinorAxis: 7000.0,
                //         semiMajorAxis: 7000.0,
                //         //   height: 200000.0,
                //         material: Cesium.Color.fromCssColorString('#E1C51F').withAlpha(0.5),
                //         outline: true, // height must be set for outline to display
                //         zIndex: 2
                //     },
                // });
                // station_entity[id].pinkCircle = viewer.entities.add({
                //     position,
                //     name: "pinkCircle",
                //     ellipse: {
                //         semiMinorAxis: 15000.0,
                //         semiMajorAxis: 15000.0,
                //         //   height: 200000.0,
                //         material: Cesium.Color.PINK.withAlpha(0.5),
                //         outline: true, // height must be set for outline to display
                //         zIndex: 1
                //     },
                // });
                
                if(!is_all) {
                    viewer.camera.flyTo({
                        destination: Cesium.Cartesian3.fromDegrees(station.longitude, station.latitude, 50000.0),
                        // orientation: {
                        //     heading: Cesium.Math.toRadians(20.0),
                        //     pitch: Cesium.Math.toRadians(-35.0),
                        //     roll: 0.0,
                        // },
                    });
                    // viewer.flyTo(station_entity[id].billboard)
                }
            }
        } catch (error) {
            this.$el_message("无位置信息", () => {}, "info");
        }
    },

    /**
     * 隐藏基站
     * @param {*} station 
     */
    hide(station) {
        // console.log("hide station:", station);
        let id = station.id
        this.station_close(id)
    },

    /**
     * 基站分布
     */
    async distribution() {
        let data = await API.TOWER.ListStation()
        // console.log("data:", data);

        if(!is_all) {
            data.data.map(item => {
                // let json_data = JSON.parse(item.flightCourseJson)
                this.station_show(item, true)
                return item.id
            })
            is_all = true
        } else {
            is_all = false
            this.station_clear_entity()
        }
    },

    close(id) {
        // console.log('id', id)
        // console.log('station_entity', station_entity)
        Object.values(station_entity[id]).forEach(item => {
            viewer.entities.remove(item)
        })
        station_entity[id] = null
    },

    clear_entity() {
        console.log('clear_entity')
        for (let key in station_entity) {
          // viewer.entities.remove(station_entity[key])
          this.station_close(key)
        }
        station_entity = {}
        this.station.selected_list = []
        this.station_update_list_selected()
    }
}