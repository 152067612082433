<!-- 
  联控---信息管理组件
  在create()中给page赋值5 信息管理定位到实时调度，加载实时调度组件 FlightTask
  智能空域、飞行任务、航空航线、实时调度都在 顶部实时调度下面
 -->

<template>
  <div class="page-command">
    <Header title="信息管理" v-if="page != 3" :user="user_info" @taps="taps" :type="headerType" :flag="page" />
    <PageTabs v-model="page" @isN="isNone = false" />
    <!-- 首页 -->
    <Home @changePage="changePage" v-if="page == 1" />
    <Situation v-if="page == 2" />
    <Airspace v-if="page == 3" />
    <!-- 智能空域 -->
    <IntellectSpace v-if="page == 17"></IntellectSpace>
    <!-- 航空航线 -->
    <Airway ref="airway" :isNone="isNone" @isN="isNone = true" v-if="page == 4" />
    <el-tooltip class="item" effect="dark" :content="showHideTitle" placement="left">
      <div class="show-hide" :class="[showHideTitle == '隐藏' ? 'show-hide-img' : 'show-show-img']" v-show="showHideFlag"
        @click="hidePanel">
      </div>
    </el-tooltip>
    <!-- <Airtask v-if="page == 5" /> -->
    <!-- <IntelligenceMissionA v-if="page == 5"/> -->
    <!-- 实时调度 -->
    <FlightTask class="flight-task" @close="change_page" ref="flightTask" v-show="page == 5" />
    <!-- 人员管理 -->
    <Person v-if="page == 6" />
    <!-- 设备管理 -->
    <Device v-if="page == 7" />
    <MapManage v-if="page == 8" />
    <!-- 设备监测 -->
    <Monitoring v-if="page == 11" />
    <!-- 影像管理 -->
    <ImageVideo v-if="page == 12" />
    <!-- 实时点调 -->
    <SpotTone v-if="page == 16" />
    <NestList v-if="isNestList && page == 4" v-model="nest.selected" :async-list="list" :list="nest.list"
      @change="nestChange" />
    <!-- 服务器情况页面 -->
    <server v-if="page == 13"></server>
    <!-- :list="nest.list" -->
    <!--  -->
    <!-- @item-location="nest_location" -->
  </div>
</template>

<script>
let locations = [];
import { mapGetters } from "vuex";
import Header from "@/components/command/header/index";
import PageTabs from "@/components/command/page-tabs/index";
import Home from "@/pages/command/home";
import NestList from "@/components/command/nest/list/index.vue";
import Situation from "@/pages/command/situation";
// 安全管制，更多 跳转页面
import Airspace from "@/pages/command/airspace";
// 智能空域
import IntellectSpace from "@/pages/command/intellectspace";

// 航空航线
import Airway from "@/pages/command/airway";

import Airtask from "@/pages/command/airtask";

import IntelligenceMissionA from "@/pages/command/intelligenceMission";
import MapManage from "@/pages/command/map-manage";
import Person from "@/pages/command/person/index";
import Device from "@/pages/command/device";

// 实时调度，页面初始化显示该组件
import FlightTask from "@/pages/command/flight-task";
import Monitoring from "@/pages/command/monitoring";
import ImageVideo from "@/pages/command/imagevideo";
import SpotTone from "@/pages/command/spottone/index";
import server from "@/pages/server_details/index.vue"; //服务器详情
import API from "@/api";
import Bus from '@/assets/ligature'
import { Utils } from "@/lib/cesium";

export default {
  components: {
    Header,
    PageTabs,
    Home,
    Situation,
    Airspace,
    Airway,
    Airtask,
    MapManage,
    Person,
    Device,
    IntelligenceMissionA,
    FlightTask,
    Monitoring,
    ImageVideo,
    SpotTone,
    NestList,
    server,
    IntellectSpace,
  },
  data () {
    return {
      page: 1,
      isNone: false, //鹰控航空航线
      nest: {
        list: [],
        selected: {},
      },
      isNestList: false,
      headerType: "首页",
      showHideFlag: false,
      showHideTitle: '隐藏',
      showItem: null,
    };
  },
  watch: {
    page: function (num) {
      console.log('监控page', num);
      window.localStorage.setItem('infoPage', num);
      if (num != 4) {
        this.isNestList = false;
      }

      //首页，设备监测，设备管理，人员管理，不需要展示地图
      if ([1, 11, 7, 6].includes(Number(num))) {
        this.$store.commit("mapmanage/SET_VISIBLE_MAIN_MAP", false);
        // this.SET_VISIBLE_MAIN_MAP(false);
      } else {
        this.$store.commit("mapmanage/SET_VISIBLE_MAIN_MAP", true);
        // this.SET_VISIBLE_MAIN_MAP(true);
      }

      if (![0, 5].includes(num)) {
        // console.log('window.$flightTask', window.$flightTask)
        //离开飞行任务时，清除航迹
        let viewer = window.viewer;
        if (window.$flightTask?.history_entity) {
          viewer.entities.remove(window.$flightTask.history_entity);
          window.$flightTask.history_entity = null;
        }
        if (window.$flightTask?.history_Line) {
          viewer.entities.remove(window.$flightTask.history_Line);
          window.$flightTask.history_Line = null;
        }
        if (window.$flightTask?.history_Name) {
          viewer.entities.remove(window.$flightTask.history_Name);
          window.$flightTask.history_Name = null;
        }
        if (window.$flightTask?.point_entity_began) {
          viewer.entities.remove(window.$flightTask.point_entity_began);
          window.$flightTask.point_entity_began = null;
        }
        if (window.$flightTask?.point_entity_over) {
          viewer.entities.remove(window.$flightTask.point_entity_over);
          window.$flightTask.point_entity_over = null;
        }
        if (window.$flightTask?.blueLine) {
          viewer.entities.remove(window.$flightTask.blueLine);
          window.$flightTask.blueLine = null;
        }
        if (window.$flightTask?.airline_entitys) {
          window.$flightTask?.airline_entitys.forEach((item) => {
            viewer.entities.remove(item);
          });
          window.$flightTask.airline_entitys = null;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  inject: ["g_show_zysb", "g_hide_zysb"],
  provide () {
    return {
      change_page: this.change_page,
      change_airway_visible: this.change_airway_visible,
      change_flightTask_view: this.change_flightTask_view,
      showNest_list: this.showNest_list,
    };
  },
  created () {
    let numPage = localStorage.getItem("infoPage");
    console.log(numPage, 'numPagenumPagenumPagenumPagenumPage');
    if (!numPage) {
      console.log(numPage, 'XXXXXXXXXXXXXXXX');
      numPage = 5;
    }
    console.log(numPage, 'numPage');
    this.page = numPage;


    // console.log(this.page, 'this.page');
    // let { page, version } = this.$route.query;
    // this.page = parseInt(page) || 1;
    // console.log(this.$route.query, 'this.$route.querythis.$route.query');
    // // 这里点击信息管理定位到实时调度 5
    // if (Object.keys(this.$route.query).length == 0) {
    //   this.page = 5;
    // }
    // this.page = 5;
    // console.log('默认跳转到page5, FlightTask 实时调度');
  },
  mounted () {
    // if (this.$route.query.type) {
    //   this.headerType = this.$route.query.type;
    //   console.log('this.$route', this.$route);
    //   this.$router.replace("/command");
    // }
    if (this.page != 11) {
      this.$router.replace("/command");
      this.$store.commit("device/SET_FlAG", true);
    } else {
      //由于cesium地图默认创建，创建需要时间，而直接销毁cesium地图组件，会导致cesium未创建完成就立即调用destroy方法，出现报错，故设置为1秒后销毁
      /* this.$nextTick(() => {
        this.$store.commit('mapmanage/SET_VISIBLE_MAIN_MAP', false);
      }) */
    }
    document.title = "信息管理";
    // 回调执行函数 监控命名规则为 watch_+变量名
    this.g_bus.$on("watch_viewer", async () => {
      // 具体函数内容
      // await this.g_show_zysb(0, 0, 1);
      // console.log('this.page', this.page)
      if (this.page == 1) {
        await this.g_hide_zysb({ cate_id: 1 });
        this.$store.commit("device/SET_FlAG", true);
      }
    });

    // 点击按钮隐藏或者显示面板
    Bus.$on('hidePanel', (v) => {
      if (v) {
        this.change_page();
        this.showHideTitle = '显示';
      }
    });

    // 点击tab之后发送的数据
    Bus.$on("tap", (item) => {
      this.showItem = item;
      if (item.title == '飞行任务' || item.title == '航空航线') {
        this.showHideFlag = true;
        this.showHideTitle = '隐藏';
      } else {
        this.showHideFlag = false;
      }
    });
  },
  methods: {
    taps (val) {
      this.headerType = val.title;
    },
    changePage (val) {
      console.log("changePage", val);
      this.page = val;

      switch (val) {
        case 5:
          this.headerType = "调度中心";
          this.initShowPanel();
          break;

        case 1:
          this.headerType = "首页";
          break;

        case 6:
          this.headerType = "人员管理";
          break;

        case 7:
          this.headerType = "设备管理";
          break;

        case 11:
          this.headerType = "设备监测";
          break;

        default:
          this.headerType = "所有";
          break;
      }
    },
    showNest_list (flag) {
      this.isNestList = flag;
    },
    async list () {
      let data = await API.NEST.getNestList();
      //筛选可操控飞机机巢
      // data.map(item => item.devices= item.devices.filter(val =>
      //     [4, 6, 8, 9, 11].includes(val.id)
      // ))

      function each (tree, data) {
        return tree.map((item) => ({
          ...item,
          offLineCount: data.offLineCount,
          onLineCount: data.onLineCount,
          collapse: false,
          child_collapse: false,
          child: item.child && item.child.length && each(item.child),
        }));
      }
      if (data.tree && data.tree.length) {
        data = each(data.tree, data);
      }
      this.nest = {
        list: data || [],
        selected: this.nest.selected,
      };
      console.log(this.nest, "1231231");
    },
    nestChange (device) {
      console.log(device, "device");
      if (device) {
        if (device.latitude && device.longitude) {
          let viewer = window.viewer;
          let f = viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(
              Number(device.longitude),
              Number(device.latitude)
            ),
            billboard: {
              image: require("@/assets/images/icons/icon_location.png"),
              width: 30,
              height: 30,
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
          });
          locations.push(f);
          console.log(viewer, "viewer");
          console.log(f, "f");
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              Number(device.longitude),
              Number(device.latitude),
              30000
            ),
          });

          // let position = Utils.transformWGS842Cartesian({
          //     lng: device.longitude,
          //     lat: device.latitude,
          // });

          // viewer.camera.flyTo({
          //     destination: Utils.transformWGS842Cartesian({
          //         lng: device.longitude,
          //         lat: device.latitude,
          //         alt: 1000
          //     })
          // });

          // if (location_icon) {
          //     location_icon.position = position;
          // } else {
          //     location_icon = viewer.entities.add({
          //         position: viewer.scene.clampToHeight(position),
          //         billboard: {
          //             width: 36,
          //             height: 36,
          //             image: require("@/assets/images/icons/icon_location.png"),
          //             horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          //             verticalOrigin: Cesium.VerticalOrigin.Bottom,
          //             heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          //         },
          //     });
          // }
        } else {
          let viewer = window.viewer;
          locations.forEach((item, index) => viewer.entities.remove(item));
          this.$el_message("该机巢没有经纬度", () => { }, "warning");
        }
      }
    },

    cesium_layer_change_mode (fn, modes) {

      // 定位到芜湖
      Utils.initLocation2WUHU();
      return;


      window.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          120.218241,
          33.165767,
          21812.321476599747
        ),
        orientation: {
          heading: 0.006918732929549698,
          pitch: -0.6928665494487145,
          roll: 7.638334409421077e-14,
        },
        time: 5,
      });
    },
    // 关闭航空航线
    change_page (item) {
      console.log(item, '点击了关闭');
      if (item) {
        this.page = item;
        this.showHideTitle = '显示';
      } else {
        this.page = 0;
      }
    },
    change_airway_visible () {
      let _this = this;
      this.$nextTick(() => {
        _this.$refs.airway.airway.add.visible = true;
      });
    },
    change_flightTask_view () {
      let _this = this;
      this.page = 5;
      this.$nextTick(() => {
        _this.$refs.flightTask.chage_view(2);
      });
    },
    // 隐藏面板
    hidePanel () {
      // debugger
      if (this.showHideTitle == '显示') {
        Bus.$emit('faketap', this.showItem);
        this.showHideTitle = '隐藏';
      } else {
        this.change_page();
        this.showHideTitle = '显示';
      }
    },
    // 初始化隐藏显示面板相关
    initShowPanel () {
      this.showHideFlag = true;
      this.showItem = { id: 5, title: '飞行任务' }
      this.showHideTitle = '隐藏';
    },
  },
};
</script>

<style lang="scss" scoped>
.task {
  background: rgba(9, 32, 87, 0.7);
  border-radius: 10px;
  color: #fff;
  position: absolute;
  top: 90px;
  left: 20px;
  text-align: center;
  line-height: 50px;
}

.show-hide {
  position: absolute;
  right: 26px;
  top: 42%;
  z-index: 999999;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.show-hide-img {
  background-image: url('../../assets/img/hide.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.show-show-img {
  background-image: url('../../assets/img/show.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.flight-task {
  // border: 10px solid yellow;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.page-command {
  width: 100%;
  height: 100%;
}
</style>
