import API from "@/api"

export default {

    async add(args) {
      let res= await API.TASK.Add(args);
      if(res){
        this.$el_message("创建成功", () => {
            this.task.add.visible = 0
        });  
      }else{
          this.$message.error('请填写完整信息！')
      }
      
    },

    async approve(args) {
        await API.TASK.Edit({
            id: args.id,
            ...args.approve
        })
        this.$el_message("审批成功");
    }
}