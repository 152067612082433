<!-- 飞行日志页面1027 -->

<template>
  <div class="cpt-command-task-flight-log-new">

    <div class="header">
      <div class="left">
        <img src="@/assets/img/i38.png" alt="" class="img" @click="toMain">
        <span class="name">飞行日志</span>
      </div>
    </div>

    <div class="duanheng">

      <div class="chang">

        <div class="search-box">

          <div class="name-own">
            <div class="flexs waikuang-new">
              <el-input v-model="search.deviceName" class="input-name" placeholder="请输入无人机名称" clearable />
            </div>
            <div class="flexs waikuang-new">
              <el-input v-model="search.taskTitle" class="input-name" placeholder="请输入所属任务" clearable />
            </div>
          </div>

          <div class="flexs waikuang shijanSelect">
            <el-date-picker prefix-icon="ccc" v-model="pickTime" type="datetimerange" range-separator="至"
              value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"
              popper-class="flight-log">
            </el-date-picker>
          </div>

          <div class="type-unit">
            <div class="flexs waikuang">
              <el-select v-model="search.taskCateId" class="input-excute mr5" placeholder="请选择任务类别" clearable
                popper-class="input-excute-panel-fly-log">
                <el-option label="警情任务" :value="1"></el-option>
                <el-option label="常态任务" :value="2"></el-option>
                <el-option label="临时紧急任务" :value="3"></el-option>
              </el-select>
            </div>

            <div class="flexs waikuang">
              <el-cascader class="input-orgname-fly-log" placeholder="请选择责任单位" v-model="search.organizationId"
                :options="unitList" popper-class="unit-cascader-drop-panel-fly-log" :show-all-levels="false" :props="{
                  children: 'children',
                  label: 'orgName',
                  value: 'id',
                  checkStrictly: true,
                  emitPath: false,
                }" clearable>
              </el-cascader>
            </div>
          </div>

          <div class="yuan">
            <div @click="get_list(0)" class="btn-search">搜索</div>
            <div @click="handle_upload" class="btn-daochu">导出日志</div>
          </div>
        </div>
        <div class="tb-bd-box" id="tbBdBoxId">
          <!--  -->
          <div class="tb-tr" v-for="item in list" :key="item.id" v-if="false">
            <!-- 无人机名称 -->
            <div class="td">{{ item.deviceName || "暂无" }}</div>
            <!-- 飞行开始时间 -->
            <div class="td times">{{ item.executeTime || "暂无" }}</div>
            <!-- 飞行结束时间 -->
            <div class="td times">{{ item.endTime || "暂无" }}</div>
            <!-- 飞行时长 -->
            <div class="td">
              {{ formatSeconds(item.flightDuration) || "暂无" }}
            </div>
            <!-- 飞行距离 -->
            <div class="td">{{ item.flightDistance || "暂无" }}</div>
            <!-- 所属任务 -->
            <div class="td">
              {{ item.taskTitle || "暂无" }}
            </div>
            <!-- 任务类别 -->
            <div class="td">{{ item.taskCateName || "暂无" }}</div>
            <!-- 任务类型 -->
            <div class="td">
              {{ item.taskTypeName || "暂无" }}
            </div>
            <!-- 责任单位 -->
            <div class="td">
              {{ item.anotherName || "暂无" }}
            </div>
            <div class="td">
              <div v-if="item.flyUserNames">
                {{ item.flyUserNames.join("、") || "暂无" }}
              </div>
              <div v-else>暂无</div>
            </div>
            <div class="td last-td">
              <!-- <el-tooltip effect="dark" content="视频" placement="top">
                  <div
                    @click="handle_video_Player(item)"
                    class="iconfont icon-lishishipin"
                  ></div>
                </el-tooltip> -->
              <el-tooltip effect="dark" content="图片" placement="top">
                <div @click="handle_image(item)" class="iconfont icon-lishizhaopian"></div>
              </el-tooltip>
              <el-tooltip effect="dark" content="历史轨迹" placement="top">
                <div @click="handle_histoty_track(item)" class="iconfont icon-lishiguiji"></div>
              </el-tooltip>
            </div>
          </div>

          <div class="card-body" v-loading="loading" element-loading-text="数据加载中"
            element-loading-spinner="my-custom-loader" element-loading-background="rgba(0, 0, 0, 0)">
            <Card class="card" v-for="item in  list " :key="uuidKey(item.id)" :data="item"></Card>
          </div>
          <div class="pagation">
            <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="pagination.pageNo"
              :page-size="pagination.pageSize" layout="prev, pager, next, total,  jumper" :total="pagination.totalCount"
              :pager-count="5" @current-change="handleSizeChange_station">
            </el-pagination>
          </div>
          <!-- 回到顶部 -->
          <!-- <el-tooltip class="item" effect="dark" content="回到顶部" placement="top-start">
            <img id="flyLogScrollToTop" src="@/assets/img/i98.png" alt="" @click="scroll2Top">
          </el-tooltip> -->
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import API from "@/api";
import _ from 'lodash';
import { Loading } from 'element-ui';
import { mapGetters } from "vuex";
import { downloadData } from "@/utils/download";
import Card from './card.vue';
export default {
  components: {
    Card,
  },
  inject: ["change_page"],
  data () {
    return {
      loading: false,
      qianVal: "",
      pickTime: null,
      search: {
        deviceName: null,
        taskTitle: null,
        taskCateId: null,
        organizationId: null,
        executeTime: null,
        endTime: null,
      },
      list: [],
      unitList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      timer: null,
      ws: "",
      searchFlag: false, // true, 重新搜索，此时滚动无效
    };
  },
  inject: ["handle_video_Player", "handle_image", "handle_histoty_track"],
  computed: {
    ...mapGetters(["user_info"]),
    isJH () {
      return this.user_info && this.user_info.departmentId == 2;
    },
  },
  mounted () {
    this.getUnitList();
    this.init_get_list(0);
    // 鼠标滑动
    // let dom = document.getElementById('tbBdBoxId');
    // let domScroll = document.getElementById('flyLogScrollToTop');
    // dom.addEventListener('scroll', () => {
    //   let clientHeight = dom.clientHeight;
    //   let scrollTop = dom.scrollTop;
    //   let scrollHeight = dom.scrollHeight;
    //   domScroll.style.top = `${scrollTop + clientHeight - 50}px`;
    //   domScroll.style.display = 'block';
    //   if (scrollTop == 0) {
    //     domScroll.style.display = 'none';
    //   }
    //   if (Math.abs(clientHeight + scrollTop - scrollHeight) <= 0) {
    //     console.log('竖向滚动条已经滚动到底部')
    //     console.log(this.pagination, 'this.pagination');
    //     // 下一页
    //     this.pagination.pageNo += 1;
    //     // 再次请求新的数据
    //     if (!this.searchFlag) {
    //       this.get_list(1);
    //     }
    //   }
    // });
  },
  methods: {
    /**
     *@Description: 分页
     *@Date: 2023-11-06 14:34:49
     *@Params1: 
     *@Return1: 
    */
    handleSizeChange_station (v) {
      this.pagination.pageNo = parseInt(v);
      this.get_list();
    },
    // 手动页码跳转
    queding () {
      let page = 1;
      if (this.qianVal != "") {
        page = this.qianVal;
      }
      this.handle_page_change(page);
    },
    // 计算总页码
    zongye (num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
    formatSeconds (value) {
      if (!value) {
        return "暂无";
      } else {
        let secondTime = parseInt(value); // 秒
        let minuteTime = 0; // 分
        let hourTime = 0; // 小时
        if (secondTime >= 60) {
          minuteTime = parseInt(secondTime / 60);
          secondTime = parseInt(secondTime % 60);
          if (minuteTime >= 60) {
            hourTime = parseInt(minuteTime / 60);
            minuteTime = parseInt(minuteTime % 60);
          }
        }
        let result =
          "" +
          (parseInt(secondTime) < 10
            ? "0" + parseInt(secondTime)
            : parseInt(secondTime));

        result =
          "" +
          (parseInt(minuteTime) < 10
            ? "0" + parseInt(minuteTime)
            : parseInt(minuteTime)) +
          ":" +
          result;
        result =
          "" +
          (parseInt(hourTime) < 10
            ? "0" + parseInt(hourTime)
            : parseInt(hourTime)) +
          ":" +
          result;
        return result;
      }
    },
    handle_upload () {
      console.log(this.search, 'search');
      let url = `/hawksystemserver/flightLog/exportData?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID
        }&mmc-identity=${this.user_info["mmc-identity"]}&deviceName=${this.search.deviceName || ""
        }&taskTitle=${this.search.taskTitle || ""}&taskCateId=${this.search.taskCateId || ""
        }&organizationId=${this.search.organizationId || ""}&executeTime=${this.search.executeTime || ""
        }&endTime=${this.search.endTime || ""}`;
      console.log(url, '导出日志URL');

      downloadData(url, "飞行日志");
    },
    async getUnitList () {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },

    // 初始化加载
    async init_get_list () {
      this.loading = true;
      if (this.pickTime) {
        this.search.executeTime = this.pickTime[0];
        this.search.endTime = this.pickTime[1];
      } else {
        this.search.executeTime = null;
        this.search.endTime = null;
      }

      let { records, current, size, total } = await API.TASK.flightlog(
        this.search,
        this.pagination.pageNo,
        this.pagination.pageSize
      );
      let temp = records || [];
      this.list = temp;
      this.pagination.pageNo = current;
      this.pagination.pageSize = size;
      this.pagination.totalCount = total;
      this.loading = false;
    },

    /**
     *@description: 获取日志列表
     *@date: 2023-09-27 10:23:58
     *@params: flag 0 重新搜索， 1 分页
     *@return: void
    */
    async get_list () {

      this.loading = true;
      if (this.pickTime) {
        this.search.executeTime = this.pickTime[0];
        this.search.endTime = this.pickTime[1];
      } else {
        this.search.executeTime = null;
        this.search.endTime = null;
      }
      let { records, current, size, total } = await API.TASK.flightlog(this.search, this.pagination.pageNo, this.pagination.pageSize
      );
      let temp = records || [];
      this.list = temp;
      this.pagination.pageNo = current;
      this.pagination.pageSize = size;
      this.pagination.totalCount = total;
      this.searchFlag = false;
      this.loading = false;
    },

    handle_size_change (size) {
      this.pagination.pageSize = size;
      this.get_list();
    },
    handle_page_change (page) {
      this.pagination.pageNo = page;
      this.get_list();
    },
    // 返回主面板
    toMain () {
      this.$emit('mainShow');
    },
    // 生成唯一key
    uuidKey (id) {
      return _.uniqueId('flyLog_') + id;
    },
    // 回到顶部
    scroll2Top () {
      let dom = document.getElementById('tbBdBoxId');
      dom.scrollTop = 0;
      setTimeout(() => {
        let domScroll = document.getElementById('flyLogScrollToTop');
        domScroll.style.display = 'none';
      }, 100);
    },
  },

  destroyed () { },
};
</script>

<style lang="scss" scoped>
.cpt-command-task-flight-log-new {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .header {
    width: 100%;
    height: 54px;
    background: #224D68;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0 10px;

    .left {
      width: 30%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .img {
        width: 16px;
        height: 12px;
        cursor: pointer;
      }

      .name {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin-left: 5px;
      }
    }
  }

  .duanheng {
    width: 100%;
    height: calc(100% - 54px);
    // background-color: cadetblue;

    .chang {
      width: 100%;
      height: 100%;
      // background-color: pink;
      // overflow-y: auto;

      .search-box {
        width: 100%;
        // height: 50px !important;
        height: 222px;
        height: auto !important;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        // border: 1px solid red;
        padding: 0 10px;

        .name-own {
          width: 100%;
          // border: 1px solid greenyellow;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 40px;
          margin: 5px 0;

          .waikuang-new {
            width: 48%;
            height: 100%;
          }
        }

        .shijanSelect {
          width: 100%;
          height: 40px;
          // border: 1px solid cyan;
          margin: 5px 0;

          /deep/ .el-range-separator {
            color: #C0C4CC !important;
            margin-top: 6px;
          }
        }

        .type-unit {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          // border: 1px solid yellow;
          margin: 5px 0;
        }

        .yuan {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          // border: 1px solid pink;
          margin: 5px 0;

          .btn-search,
          .btn-daochu {
            width: 45%;
            height: 40px;
            background: #4E87AC;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }

        /deep/ .el-input__inner {
          background: rgba(13, 50, 92, 0) !important;
          border: 1px solid #3B5461 !important;
          align-items: center !important;
          padding-left: 5px !important;
          color: #ffffff;
        }

        /deep/ .waikuang .el-range-input {
          height: 40px !important;
          width: 100%;
          background-color: transparent;
          color: #ffffff;
        }

        /deep/ .waikuang .el-date-editor {
          padding: 0;
          border: 0px solid;
          height: 40px;
          background: rgba(13, 50, 92, 0) !important;
        }
      }

      .tb-bd-box {
        box-sizing: border-box;
        padding: 0 10px;
        // border: 1px solid yellow;
        overflow-y: auto;
        height: calc(100% - 202px);
        width: 100%;
        position: relative;

        .card {
          width: 100%;
          height: 200px;
          margin: 5px 0;
        }

        #flyLogScrollToTop {
          position: absolute;
          right: 10px;
          z-index: 100;
          width: 28px;
          height: 28px;
          cursor: pointer;
          display: none;
        }

        .card-body {
          width: 100%;
          height: calc(100% - 30px);
          box-sizing: border-box;
          // border: 1px solid yellow !important;
          overflow-y: auto !important;
        }

        .card-body::-webkit-scrollbar {
          display: none !important;
        }

        .pagation {
          height: 30px;
          box-sizing: border-box;
          padding: 0 0px;
          height: 30px;
          width: 100%;
          background-color: #0E3449;
          padding-top: 5px;
          // border: 1px solid red;

          /deep/ .el-pagination--small {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding: 0 !important;
          }

          /deep/ .el-pagination__total {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            margin-left: 5px;
          }

          /deep/ .btn-prev {
            background-color: #253B5A !important;
            color: #ffffff;
            width: 22px;
            border-radius: 2px;
          }

          /deep/ .btn-next {
            background-color: #253B5A !important;
            color: #ffffff;
            width: 22px;
            margin-left: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
          }

          /deep/ .el-pager {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;

            .number {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            li {
              margin-left: 2px;
              background-color: #253B5A !important;
              color: #ffffff;
              border-radius: 2px;
            }

            li.active {
              color: #FFFFFF;
              background-color: #409EFF !important;
            }

            li:hover {
              border: 1px solid #367DB4;
              background-color: #0C3549 !important;
            }
          }

          /deep/ .el-pagination__sizes .el-select--mini {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .el-input__inner {
              color: #ffffff;
            }
          }

          /deep/ .el-pagination__jump {
            margin-left: 5px !important;
            color: #ffffff;
            margin-top: -3px;
          }

          /deep/ .el-pagination__jump .el-input {
            width: 30px !important;
            background-color: #253B5A !important;

            .el-input__inner {
              background-color: #253B5A !important;
              color: #ffffff;
            }
          }
        }
      }
    }

    .flexs {
      display: flex;
    }
  }
}
</style>
<style lang="scss">
@import "@/styles/wuhuCommon.scss";

.flight-log {
  border: none;

  .el-picker-panel__body-wrapper {
    // background: #0d224f;
    background-color: #0A293A !important;
    border: 1px solid #3B5461;
    color: #fff;

    .el-input__inner {
      color: #fff;
      border: 1px solid #3B5461;
    }

    .el-date-table th {
      border-bottom: solid 1px #3B5461;
      color: #fff;
    }

    .el-date-table td.in-range div {
      background-color: #0A293A;
    }

    .el-input--small .el-input__inner {
      // background-color: #0d224f;
      background-color: #0A293A;
    }

    .el-picker-panel__icon-btn {
      color: #fff;
    }
  }

  .el-picker-panel__footer {
    // background-color: #0d224f !important;
    background-color: #0A293A !important;
    border-top: 1px solid #3B5461;
  }

  .el-button.el-button--mini.is-plain,
  .el-button.is-disabled.is-plain {
    background-color: transparent !important;
    border: none;
    color: #00aeff;
  }

  .el-date-range-picker__time-header {
    border: 1px solid #3B5461;
  }

  .el-date-range-picker__content.is-left {
    border: 1px solid #3B5461;
  }

  .el-scrollbar {
    background-color: #0A293A !important;
    border: 1px solid #3B5461;
  }

  .el-time-panel__footer {
    background-color: #0A293A !important;
    border-top: 1px solid #3B5461;
  }

  .el-time-panel {
    border: 1px solid #3B5461;
  }
}

.cpt-command-task-flight-log .tb-box .tb-bd-box .tb-tr .td {
  font-size: 12px !important;
}


.my-custom-loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 24px 0 #fff, -24px 0 #fff;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
  // width: 10px;
  // height: 10px;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

@keyframes flash {
  0% {
    background-color: #FFF2;
    box-shadow: 24px 0 #FFF2, -24px 0 #FFF;
  }

  50% {
    background-color: #FFF;
    box-shadow: 24px 0 #FFF2, -24px 0 #FFF2;
  }

  100% {
    background-color: #FFF2;
    box-shadow: 24px 0 #FFF, -24px 0 #FFF2;
  }
}

.unit-cascader-drop-panel-fly-log {
  background-image: url('~@/assets/img/i101.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0 !important;
  margin: 0 !important;
  top: 272px !important;

  .el-scrollbar {
    background-color: transparent;
    border: 0;
    border-right: 1px solid #223E4D;
  }

  .el-scrollbar:last-child {
    border: 0 !important;
  }

  .el-cascader-node {
    background-color: transparent;
  }

  .el-cascader__dropdown {
    box-shadow: unset;
    margin: 0 !important;
    border: 0 !important;
  }

  .el-scrollbar__view {
    background-color: #0A293A;
  }

  .popper__arrow {
    display: none;
  }

  .el-cascader-node__label {
    color: #fff;
  }

  .el-icon-arrow-right {
    color: #fff;
  }

}

.input-orgname-fly-log {
  .el-input__inner {
    background-color: transparent !important;
    color: #fff;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers，webkit内核浏览器 */
    color: #C0C4CC !important;
  }

  .el-input {
    display: relative;

    // .el-input__suffix {
    //   position: absolute;
    //   top: 16px;
    //   right: 10px;
    //   height: 9px;
    //   width: 13px;
    //   background-image: url('~@/assets/img/i62.png');
    //   background-repeat: no-repeat;
    //   background-size: 100% 100%;
    //   transform: rotate(180deg);
    // }
  }

  // .el-input__suffix-inner {
  //   background-color: #fff;
  //   height: 10px !important;
  //   width: 10px !important;

  //   i {
  //     display: none;
  //   }
  // }
}

.input-excute-panel-fly-log {
  border: 0;
  top: 203px;
  top: 270px !important;

  .el-scrollbar {
    border: 0 !important;
    background-color: #0A293A !important;

    .el-select-dropdown__item {
      color: #ffffff !important;
    }
  }

  .popper__arrow {
    display: none;
  }
}
</style>
