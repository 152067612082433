<template>
  <div class="cpt-command-airway-list">
    <div class="header" v-interact>
      <div class="toubu">
        <!-- <img src="~@/assets/images/mount/mount_head.png" /> -->
        <div class="hd-box">
          {{ list.type_id == 4 ? "基站" : list.type_id == 3 ? "机巢" : "无人机" }}维保记录
        </div>
      </div>
      <div class="right">
        <div class="hd-box cp " @click="$emit('close')">
          <img src="@/assets/img/i40.png" alt="" class="img">
        </div>
      </div>
    </div>
    <div class="list-box">
      <div class="search-box">
        <div class="item-plan cp center ml16" @click="exportDone">导出</div>
        <div class="flex fangkuai">
          <el-input size="mini" v-model="search.title" class="item-input" placeholder="请输入名称" clearable />
        </div>
        <!-- <div class="flex fangkuai">
          <el-date-picker  size="mini" prefix-icon="ccc" v-model="pickTime" type="datetimerange" range-separator="至"
            value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" popper-class="flight-log">
          </el-date-picker>
        </div> -->
        <div class="item-plan cp center ml16" @click="getList">搜索</div>
        <div class="waixian"></div>
      </div>
      <div class="tb-box">
        <el-table ref="tableT" :cell-style="cellStyle" :data="toneList" max-height="250"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column align="center" prop="serial" label="序号"></el-table-column>
          <el-table-column align="center" prop="title" width="250" label="巡检名称">
          </el-table-column>
          <el-table-column align="center" prop="detectionTime" label="日期" width="100"></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <div class="cp item-btn" @click="handleClick(scope.row)">
                <img src="@/assets/images/device/wb.png" alt="" />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
import Moment from "moment";
export default {
  props: {
    list: {
      type: Object,
      default: {},
    },
  },
  data () {
    return {
      ToneFormShow: false,
      startShow: false,
      itemId: null,
      formId: null,
      type: 0,
      qianVal: "",
      unitList: [],
      pickTime: null,
      dangqianye: 1,
      search: {
        // name: "",
        // organId: null,
        page: 1,
        page_size: 10,
      },
      toneList: [],
      pagination: {
        page: 1,
        page_size: 10000,
      },
      doneDisable: true,
      changeId: [],
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  mounted () {
    this.getList();
  },
  methods: {
    //表格行颜色
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 2 == 1) {
        return "background:#081A3A;";
      } else {
        return "background:rgba(73,135,210,0.2);";
      }
    },
    handleSelectionChange (e, row) {
      this.changeId = e.map((item) => (this.changeId.id = item.id));
    },
    async exportDone () {
      this.changeId.forEach(async (val) => {
        let res = await API.DEVICE.RecordExport({ id: val });
        const blob = new Blob([res], { type: res.type });
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "维保记录";
        a.click();
        URL.revokeObjectURL(a.href);
      });
    },
    closeStart () {
      this.startShow = false;
      this.search = {
        page: this.pagination.page,
        page_size: 1000,
      };
      this.getList();
    },
    rec () {
      this.ToneFormShow = false;
      this.pickTime = null;
      this.search = {
        page: this.pagination.page,
        page_size: 10,
      };
      this.changeId = null;
      this.getList();
    },
    async getList () {
      this.search = {
        ...this.list,
        ...this.search,
      };
      if (this.pickTime) {
        this.search.start = this.pickTime[0];
        this.search.end = this.pickTime[1];
      } else {
        this.search.start = null;
        this.search.end = null;
      }
      let res = await API.DEVICE.getWbList(this.search);
      this.pagination.totalCount = res.totalCount;
      this.toneList = res.records || [];

      this.toneList.forEach((val, index) => {
        val.serial = index + 1;
        const moment = Moment(val.detectionTime);
        val.detectionTime = moment.format("YYYY-MM-DD");
      });
    },
    async addTone (type, val) {
      this.type = type;
      if (type == 1) {
        if (val) {
          this.formId = val.id;
        }
      } else if (type == 0) {
        this.formId = null;
      } else {
        if (val) {
          this.formId = val.id;
        }
      }
      this.ToneFormShow = true;
    },
    page_change (e) {
      this.search.page = e;
      this.getList();
      this.pagination.page = e;
    },
    size_change (e) {
      console.log(e, "size_change");
    },
    changePage () {
      if (this.qianVal > this.zongye(this.pagination.totalCount)) {
        this.qianVal = this.zongye(this.pagination.totalCount);
      }
      this.search.page = this.qianVal;
      this.getList();
      this.pagination.page = this.qianVal;
    },
    /**
     * 点调的操作
     */
    handleClick (val) {
      this.$emit("detail", val.id);
    },
    /**
     * 子点调的操作
     */
    async sonClick (type, id) {
      console.log(id);
      this.itemId = id;
      if (type == 1) {
        this.doneDisable = false;
      } else if (type == 2) {
        // let res = await API.TONE.startDispatch({itemDispatchId: id})
        // this.getList()
        this.doneDisable = true;
      } else {
        this.doneDisable = true;
      }
      this.startShow = true;
    },
    // 计算总页码
    zongye (num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.cpt-command-airway-list {
  @import "~@/styles/flightTask.scss";
  // position: absolute;
  position: fixed;
  left: calc(50% - 297px);
  // top: calc(50% - 188px);
  // top: -80%;
  top: -65%;
  width: 594px;
  height: 376px;
  box-sizing: border-box;
  box-sizing: border-box;
  background-color: #0A293A;
  // background: rgba(12, 34, 73, 0.7);
  // border-radius: 10px;
  // border: 1px solid rgba(26, 92, 246, 0.5);
  // backdrop-filter: blur(1px);
  z-index: 100;

  .header {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // width: 1132px;
    // height: 32px;
    // background: linear-gradient(180deg,
    //     #9198ff 0%,
    //     rgba(45, 81, 153, 0.45) 40%,
    //     #05091a 100%);
    // box-shadow: inset 0px 0px 10px 2px #3f9dff;
    // border-radius: 10px 10px 0px 0px;
    // border: 1px solid #427dff;

    // padding: 0 12px;
    height: 36px;
    background-color: #224D68;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;

    .toubu {
      display: flex;

      .hd-box {
        font-size: 16px;
        // font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 18px;
        // text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        // background: linear-gradient(135deg,
        //     #e3aa77 0%,
        //     #f5cda9 38%,
        //     #f9ecd3 58%,
        //     #fcdbb1 79%,
        //     #edb07a 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        color: #ffffff;
        font-weight: 700;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }

    .right {
      display: flex;

      .hd-box {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #d2dfff;
      }

      .img {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }
  }

  .list-box {
    // width: 1132px;
    height: calc(100% - 56px);
    box-sizing: border-box;
    // padding: 0 16px 0 16px;
    padding: 0 10px 0 10px;

    .search-box {
      margin-left: 0 !important;
      // height: 80px;
      height: auto;
      margin: 24px 0 24px 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .fangkuai {
        border: 1px solid #315ec7;
        background: #02173d;
      }

      .item-plan {
        width: 64px;
        height: 32px;
        background: url("~@/assets/images/command/personN.png") no-repeat;
        background-size: 100% 100%;
        // background: rgba(38,71,238,0.7);
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 32px;

        &:hover {
          // background: url("~@/assets/images/command/twobg.png");
        }
      }

      .item-plans {
        width: 92px;
        height: 32px;
        background: url("~@/assets/images/command/personN.png") no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ffffff;
        line-height: 32px;

        &:hover {
          background: url("~@/assets/images/command/fourbg.png");
        }
      }

      .item-plan:hover {
        // opacity: 0.5;
      }

      .item-input {
        width: 168px;
        margin-left: 10px;
        // margin-right: 10px;
        color: #ffffff;

        ::v-deep .el-input__inner {
          background: rgba(2, 31, 51, 0);
          border: 0px solid #06b6e0;
          border-radius: 4px;
          font-family: MicrosoftYaHeiUI;
          font-size: 16px;
          color: #ffffff;
          font-weight: 400;
          padding-left: 0;

          &::placeholder {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #397c8b;
            line-height: 19px;
          }
        }
      }
    }

    .tb-box {
      padding: 0 !important;
      margin: 0 0 27px 0;
      overflow: hidden;

      .btn {
        display: flex;
        justify-content: space-around;

        .item-btn {
          width: 64px;
          height: 28px;
          line-height: 28px;
          background: url("~@/assets/images/command/personN.png") no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: rgba(38, 71, 238, 0.71);
            box-shadow: inset 0px 1px 3px 0px #ffffff,
              inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
            border-radius: 4px;
            // border: 1px solid;
            border-image: linear-gradient(180deg,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 0)) 1 1;
          }
        }
      }

      /deep/ .el-table {
        overflow: hidden;
        // background: transparent;
        background: rgba(8, 26, 58, 0.8);

        .el-table__expanded-cell {
          background: transparent;
        }

        .el-table__body-wrapper {
          // width: 100%;
          // max-height: 600px;
          // overflow-y: auto;
        }

        .el-table__empty-text {
          color: #fff;
        }

        .el-table__expand-icon {
          color: #fff;
        }

        tr {
          background: transparent;
        }

        thead {
          background: rgba(8, 26, 58, 0.8);
          border-radius: 4px;
          border: 1px solid #315ec7;

          .el-table__cell {
            background: transparent;
            font-size: 16px;
            font-family: YouSheBiaoTiHei;
            color: #ebf9ff;
            line-height: 22px;
            letter-spacing: 1px;
          }
        }

        tbody {
          tr {
            color: #fff;
            font-size: 14px;
          }

          tr:hover>td {
            background: transparent !important;
          }
        }
      }
    }

    .tb-pagination {
      float: right;
      margin: 0 0 19px 0;

      .zongji {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #b6d4ff;
        line-height: 16px;

        .tiaoshu {
          color: #315ec7;
        }
      }

      .douhao {
        margin: 0 5px 0 5px;
      }

      .dangqianye {
        margin: 0 20px 0 0;
      }

      .zhuan {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #b6d4ff;
        line-height: 16px;

        input {
          width: 48px;
          min-width: 48px;
          max-width: auto;
          text-align: center;
          background: rgba(12, 13, 20, 0.5);
          border-radius: 2px;
          border: 1px solid rgba(36, 146, 252, 0.3);
          margin: 0 5px 0 5px;
          color: #fff;
          outline: 0px solid;
        }
      }

      .btnqueding {
        margin: 0 0 0 8px;
        width: 79px;
        height: 32px;
        background: url("~@/assets/images/command/true.png");
        line-height: 1px;
        color: #315ec7;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #43deff;
      }

      .btnqueding:hover {
        opacity: 0.5 !important;
      }
    }
  }
}

/deep/ .fangkuai .el-cascader .el-input__inner {
  width: 150px !important;
  background: rgba(13, 50, 92, 0) !important;
  border: 0px solid #315ec7 !important;
  align-items: center !important;
  padding-left: 15px !important;
}

//时间选择器样式
/deep/ .waikuang .el-range-input {
  width: 140px;
}

/deep/ .fangkuai .el-date-editor {
  padding: 0;
  border: 0px solid;
  background: rgba(13, 50, 92, 0) !important;
}

/deep/ .el-date-editor .el-range-input {
  background: rgba(2, 31, 51, 0.1);
  width: 390px;
  color: #fff;
}

.flight-log {
  border: none;

  .el-picker-panel__body-wrapper {
    background: #0d224f;
    border: 1px solid #00aeff;
    color: #fff;

    .el-input__inner {
      color: #fff;
      border: 1px solid #00aeff;
    }

    .el-date-table th {
      // border-bottom: solid 1px #00aeff;
      color: #fff;
    }

    .el-date-table td.in-range div {
      background-color: #00aeff;
    }

    .el-input--small .el-input__inner {
      background-color: #0d224f;
    }

    .el-picker-panel__icon-btn {
      color: #fff;
    }
  }

  .el-picker-panel__footer {
    background-color: #0d224f !important;
  }

  .el-button.el-button--mini.is-plain,
  .el-button.is-disabled.is-plain {
    background-color: transparent !important;
    border: none;
    color: #00aeff;
  }
}

.orgNames {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}

/deep/.el-table__header-wrapper {
  .el-table-column--selection {
    .el-checkbox {
      visibility: hidden;
    }
  }
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: transparent;
  border: 1px solid #f0f3ff;
  opacity: 1;
}

/deep/.el-checkbox__inner {
  opacity: 0.6;
}

/deep/.el-cascader {
  line-height: 32px !important;
}
</style>
