import API from '@/api'
import { Utils } from '@/lib/cesium'
let location_icon = null

export default {
  async handle_position(data) {
    if (data && data.incidentAddress) {
      if (data.incidentAddress == '盐城市') {
        return false
      }
      let res = await API.MAP.Geo({ keywords: data.incidentAddress })
      let rows = res.data.data.rows
      if (rows && rows.length) {
        let cesium_layer = this.g_cesium_layer()
        let viewer = cesium_layer.viewer()

        cesium_layer.fly_to_globe()

        let item = rows[0]
        let location = item.location.split(',')

        let potiion = Utils.transformWGS842Cartesian({
          lng: Number(location[0]),
          lat: Number(location[1]),
          alt: 1000,
        })
        viewer.camera.flyTo({
          destination: potiion,
        })
        this.situation_init_location_icon(potiion)
      }
    }
  },
  init_location_icon(position) {
    let cesium_layer = this.g_cesium_layer()
    let viewer = cesium_layer.viewer()

    if (location_icon) {
      location_icon.position = position
    } else {
      location_icon = viewer.entities.add({
        position,
        billboard: {
          width: 92,
          height: 67,
          image: require('@/assets/images/factor/jwys/1.png'),
          // image: require('@/assets/images/factor/jwys/1.svg'),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.Bottom,
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        },
      })
    }
  },
}
