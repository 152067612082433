<!-- 头部 -->
<template>
  <div class="cpt-home_header">
    <div class="left">
      <div class="title" @click="handleClick">
        <img alt="" src="~@/assets/img/command-kong-logo.png" />
        <span>信息管理</span>
      </div>
      <template>
        <div class="menu">
          <ul class="ul">
            <li class="tap" @mouseover="tapyi(item.id)" @mouseout="tapyichu" v-for="item in optionsLeft" :key="item.id"
              @click="tap_click(item)" :class="{ jin: item.id == JinId }">
              <span class="menu-title">{{ item.title }}</span>
            </li>
          </ul>
        </div>
      </template>
    </div>
    <div class="right">
      <HeaderRight :data="user" class="jl" />
    </div>
  </div>
</template>

<script>
import HeaderRight from "@/components/header-right/index_zhz";
//箭头
import zuo from "@/assets/newImage/zuojian.png";
import zuoJin from "@/assets/newImage/zuojianJin.png";
import you from "@/assets/newImage/youjian.png";
import youJin from "@/assets/newImage/youjianJin.png";
// bus
import Bus from "@/assets/ligature";
// 引入 天气状况
// import tianqi from "@/components/command/header/tianqi/index.vue";
import tianqi from "@/components/header-left/index_v5.vue";

const menus = [
  // { id: 1, title: "首页" },//把首页隐藏了
  { id: 5, title: "实时调度" },
  { id: 6, title: "人员管理" },
  { id: 7, title: "设备管理" },
  // { id: 11, title: "设备监测" },

];
const menus2 = [
  { id: 1, title: "首页" },
  { id: 5, title: "飞行任务" },
  { id: 4, title: "航空航线" },
  { id: 17, title: "智能空域" },
  // { id: 3, title: "空域管理" }, // 空域管理放到智能空域下面
  { id: 16, title: "实时调度" },

];
export default {
  components: { HeaderRight, tianqi },
  props: {
    title: {
      type: String,
      default: () => "标题",
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    flag: {
      type: Number | String,
      default: () => 1,
    },
    type: {
      type: String,
      default: "首页", //类型可选, 首页 | 调度中心 | 所有 | 设备管理 | 设备监测 | 人员管理
    },
  },
  data () {
    return {
      JinId: 99,
      zuo: zuo,
      you: you,
      imgId: "",
      optionsLeft: [],
      arr: menus,
    };
  },
  watch: {
    flag: function (vla, old) {
      // 监听flag的值来给相应的tap标签修改背景
      this.JinId = parseInt(vla);
    },
    type: {
      handler () {
        this.optionsLeft = menus2;
        for (let i = 0; i < menus.length; i++) {
          if (menus[i].title === this.type && this.type !== "调度中心") {
            this.optionsLeft = menus;
            break;
          }
        }
        if (this.type === "首页") {
          this.optionsLeft = menus;
        }
      },
      immediate: true,
    },
  },

  created () { },
  mounted () {
    console.log('toubu1');
    // this.JinId = 5;
    this.JinId = this.flag;
    // console.log(this.flag, 'flagHeader');
    // console.log(this.type, 'typetypetypetype');
  },
  methods: {
    /**
     * 111111
     */
    tapyi (id) {
      this.imgId = id;
    },
    tapyichu () {
      this.imgId = "";
    },

    // tap标签跳转功能
    tap_click (item) {
      // 由于尽量不动源代码,这里采用bus调用原方法
      // 调用路径@/components/command/page-tabs/index.vue
      Bus.$emit("tap", item);
      this.$emit("taps", item);
      // //点击首页时不显示导航条
      if (item.id === 1) {
        this.$emit("update:type", "首页");
      } else if (item.id === 5) {
        //点飞行任务时到调度中心
        this.$emit("update:type", "调度中心");
      }
    },
    // 信息管理首页跳转功能
    handleClick () {
      // window.open('#/home', "_blank");
      // this.$router.push('/home');
      // this.$router.go(0);
      // let sto = setTimeout(() => {
      //   this.$router.go(0);
      //   clearTimeout(sto);
      // }, 50);

      let { href } = this.$router.resolve({ path: "/home" });
      window.open(href, "_self");
      setTimeout(() => {
        this.$router.go(0);
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 58px;
  height: 25px;
  position: relative;
  z-index: 100;
  left: 50%;
  margin-left: -29px;
  margin-top: 8px;
  cursor: pointer;
}

.bg1 {
  background: linear-gradient(to bottom, #000 0%, transparent 100%);
}

.bg2 {
  background: center 0px url("~@/assets/images/command/Rectangle.png") no-repeat;
  background-size: 100% 100%;
}

.cpt-home_header {
  width: 100%;
  // height: 68px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  // background: url("~@/assets/img/head_bg.png") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // width: 1919px;
  height: 64px;
  background: #0E3042;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-left: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      // margin-top: -10px;

      span {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        margin-left: 10px;
      }

      img {
        // width: 63px;
        // height: 58px;
        width: 47px;
        height: 39px;
        margin-right: 8px;
      }
    }

    .menu {
      margin-left: 150px;

      ul {
        list-style: none;
        display: flex;
        height: 100%;
        vertical-align: top;

        .tap {
          width: 185px;
          height: 64px;
          position: relative;
          text-align: center;
          // margin: -8px 0 0 0;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.tap {
  background-image: url("~@/assets/img/head-menu-bg-nomal.png");
  background-size: 100% 100%;
  cursor: pointer;
}

.tap:hover {
  background-image: url("~@/assets/img/head-menu-bg-select.png");
  background-size: 100% 100%;
}

.jin {
  background-image: url("~@/assets/img/head-menu-bg-select.png");
  background-size: 100% 100%;
}
</style>
