<template>
  <div class="cpt-command-device-drone-popup">
    <div class="header" v-interact>
      <div class="hd-box">无人机设备详情</div>
      <div class="close_box" @click="() => $emit('handleClose')">
        <img src="@/assets/img/i40.png" alt="" class="img">
      </div>
    </div>
    <div class="content_box">
      <el-cascader clearable popper-class="personal-cascader" v-model="organId" :options="options"
        :show-all-levels="false" placeholder="请选择单位" :props="{
          children: 'children',
          label: 'anotherName',
          value: 'id',
          checkStrictly: true,
          emitPath: false,
        }"></el-cascader>
      <div id="container"></div>
      <div class="bd-box">
        <div class="tb-box">
          <div class="tb-hd-box">
            <div class="tb-hd">无人机名称</div>
            <div class="tb-hd">序列号</div>
            <div class="tb-hd">无人机品牌</div>
            <!-- <div class="tb-hd">无人机颜色</div> -->
            <div class="tb-hd">无人机型号</div>
            <div class="tb-hd">无人机图片</div>
            <!-- <div class="tb-hd">工作状态</div> -->
            <div class="tb-hd">设备状态</div>
            <div class="tb-hd">所属单位</div>
            <!-- <div class="tb-hd">入库时间</div> -->
            <!-- <div class="tb-hd">出库时间</div> -->
          </div>
          <div class="tb-bd-box">
            <div class="tb-tr" v-for="item in list" :key="item.id">
              <div class="td" @click="handle_click(item)">
                {{ item.deviceName || '暂无' }}
              </div>
              <div class="td">
                {{ item.deviceHardId || '暂无' }}
              </div>
              <div class="td">
                {{ item.firmName || '暂无' }}
              </div>
              <div class="td">
                {{ item.cateName || '暂无' }}
              </div>
              <div class="td">
                <el-image :src="baseUrl + item.image" :preview-src-list="[baseUrl + item.image]">
                </el-image>
              </div>
              <!-- <div
                class="td"
                :style="{ color: item.reportStatus == 1 ? '#00b900' : '#b3bbc5' }"
              >
                {{ (item.reportStatus == 1 ? "在线" : "离线") || "暂无" }}
              </div> -->
              <div class="td" :class="`tdclass${item.state}`">
                {{ changeState(item.state) || '暂无' }}
              </div>
              <div class="td">
                {{ item.anotherName || '暂无' }}
              </div>
              <!-- <div class="td">
                {{ item.inTime || "暂无" }}
              </div>
              <div class="td">
                {{ item.outTime || "暂无" }}
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="page_num">
        共计<span>{{ totalCount }}</span>条
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/api'
import { Chart } from '@antv/g2'

export default {
  props: {
    organId: {
      type: Number,
      default: () => 1,
    },
  },

  // 实现点击不同区域刷新列表
  watch: {
    organId () {
      this.getDroneList()
    },
  },
  data () {
    return {
      list: [],
      baseUrl: process.env.VUE_APP_IMG_URL,
      totalCount: 0,
      info: [],
      options: [],
      // firmId:[],
    }
  },
  async mounted () {
    await this.getOptions()
    await this.getDroneList()
    await this.getContainerInfo()
    this.init(this.info, 'container')
  },
  methods: {
    handle_click (e) {
      this.$emit('handleClick', e.id)
    },
    async getOptions () {
      let { organId } = this
      // let res = await API.DEVICE.GetPopupUnitList({ organId });
      let res = await API.DEVICE.getAllOrganList({ organId })
      this.options = res || []
    },
    async getContainerInfo () {
      let { organId } = this
      let res = await API.DEVICE.PopupDroneChart({
        organId: organId,
      })
      this.info = res || []
    },
    init (data, dataName) {
      // console.log(data, dataName)
      let _this = this
      if (!this[dataName]) {
        const chart = (this[dataName] = new Chart({
          container: dataName,
          width: 1000,
          height: 200,
          padding: [40, 0, 40, 0],
        }))
        chart.data(data)
        chart.tooltip({
          showTitle: false,
          showMarkers: false,
        })
        chart.legend({
          position: 'top',
          itemName: {
            style: {
              fill: '#fff',
            },
          },
          itemHeight: 20,
        })

        chart.axis('count', {
          grid: null,
          label: {
            style: {
              fill: '#fff',
            },
          },
        })

        chart.axis('name', {
          grid: null,
          line: null,
          tickLine: null,
          label: {
            offset: 10,
            style: {
              fill: '#fff',
            },
          },
        })

        chart
          .interval()
          .position('name*count')
          .size(30)
          .style({ radius: [15, 15, 15, 15] })
          .color('name')

        chart.interaction('element-active')
        chart.render()

        chart.on('element:click', async (ev) => {
          // console.log('------ev', ev)
          _this.firmId = ev.data.data.id
          _this.getDroneList()
        })
      } else {
        this[dataName].data(data)
        this[dataName].render()
      }
    },
    async getDroneList () {
      let { organId, firmId } = this
      let res = await API.DEVICE.PopupListDrone({
        organId: organId,
        firmId: firmId,
      })
      // console.log('---------res???',res);
      this.totalCount = res.totalCount
      this.list = res.data || []
    },
    changeState (val) {
      const obj = { 1: '正常', 2: '损坏', 3: '维修', 5: '保养' }
      return obj[val]
    },
  },
}
</script>

<style lang="scss" scoped>
.tdclass1 {
  color: #00b900 !important;
}

.tdclass3 {
  color: yellow !important;
}

.tdclass2 {
  color: red !important;
}

.tdclass5 {
  color: #b3bbc5 !important;
}

.cpt-command-device-drone-popup {
  // background: url("~@/assets/images/device/left_popup_drone.png") no-repeat;
  // background-size: 100% 100%;
  width: 1267px;
  height: 651px;
  box-sizing: border-box;
  // padding: 0 35px 60px 35px;
  position: absolute;
  // top: 200px;
  top: 5%;
  left: 20%;
  z-index: 100;
  // background: rgba(16, 30, 62, 0.9);
  // box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
  //   inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  // border-radius: 13px;
  box-sizing: border-box;
  background-color: #0A293A;


  .header {
    height: 64px;
    // background: rgba(16, 65, 215, 0.2);
    // box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    // border-radius: 10px 10px 0 0;
    height: 36px;
    background-color: #224D68;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
  }

  .content_box {
    padding: 0 10px 15px;
  }

  .close_box {
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #00ffff;
    position: absolute;
    right: 12px;
    top: 8px;
    opacity: 0.8;
    cursor: pointer;

    .img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  #container {
    display: flex;
    justify-content: center;
  }

  ::v-deep {
    .el-cascader {
      width: 200px;
      // position: absolute;
      // right: 50px;
      // top: 100px;
      margin: 10px;

      .el-input__inner {
        background: #212a40;
        border: 1px solid #00aeff;
        font-family: MicrosoftYaHeiUI;
        font-size: 16px;
        color: #00aeff;

        background: transparent;

        border: 1px solid #00b6ff;
        box-shadow: inset 0 0 3px 0 #00b6ff;
        border-radius: 6px;

        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: rgba(0, 255, 255, 0.8);
      }
    }
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 64px;
    // padding-left: 30px;
    color: #ffffff;
    font-weight: bold;
  }

  .bd-box {
    height: 300px;
    box-sizing: border-box;

    .tb-box {
      box-sizing: border-box;
      height: 100%;

      .tb-hd-box {
        display: flex;
        justify-content: space-between;
        height: 52px;

        .tb-hd {
          height: 100%;
          flex: 1;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          line-height: 52px;
          text-align: center;
          font-size: 20px;
          color: #00aeff;
        }
      }

      .tb-bd-box {
        height: calc(100% - 52px);
        overflow: auto;

        .tb-tr {
          display: flex;
          box-sizing: border-box;
          border-bottom: 1px solid #263542;

          &:hover {
            cursor: pointer;
            background: rgba(0, 34, 140, 0.2);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
            border-radius: 5px;
          }

          .td {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% / 7);
            padding: 10px 0;
            text-align: center;
            font-family: MicrosoftYaHei;
            word-break: break-word;
            box-sizing: border-box;
            font-size: 16px;
            color: #dce9ff;
            font-weight: 400;
          }
        }
      }
    }
  }

  .page_num {
    text-align: right;
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #dce9ff;
    line-height: 24px;
    margin: 6px 20px 0 0;

    &>span {
      color: #00aeff;
    }
  }

  /deep/.el-image__inner {
    width: 100px;
    height: 100px;
  }

  /deep/.el-image__error {
    width: 90px;
    height: 70px;
  }
}
</style>
