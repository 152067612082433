<template>
  <div class="cpt-command-device-drone-popup">
    <div class="header" v-interact>
      <div class="hd-box">无人机状态详情</div>
      <el-cascader class="cascader" v-model="organId" :options="options" size="small" :show-all-levels="false" clearable
        popper-class="personal-cascader-status" placeholder="请选择单位" :props="{
          children: 'children',
          label: 'orgName',
          value: 'id',
          checkStrictly: true,
          emitPath: false,
        }"></el-cascader>
      <div class="close_box" @click="() => $emit('handleClose')">
        <img src="@/assets/img/i40.png" alt="" class="img">
      </div>
    </div>
    <div class="bd-box">
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd">无人机名称</div>
          <div class="tb-hd">无人机型号</div>
          <div class="tb-hd">无人机序列号</div>
          <div class="tb-hd">无人机图片</div>
          <div class="tb-hd">所属单位</div>
          <div class="tb-hd">设备状态</div>
        </div>
        <div class="tb-bd-box">
          <div class="tb-tr" v-for="item in list" :key="item.id">
            <div class="td">
              {{ item.deviceName || "暂无" }}
            </div>
            <div class="td">
              {{ item.cateName || "暂无" }}
            </div>
            <div class="td">
              {{ item.deviceSerial || "暂无" }}
            </div>
            <div class="td">
              <el-image :src="baseUrl + item.image" :preview-src-list="[baseUrl + item.image]">
              </el-image>
            </div>
            <div class="td">
              {{ item.anotherName || "暂无" }}
            </div>
            <div class="td" :class="`tdclass${item.state}`">
              {{ changeState(item.state) || "暂无" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page_num">
      共计<span>{{ totalCount }}</span>条
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: {
    organId: {
      type: Number,
      default: () => 1,
    },
    state: {
      type: Number,
      default: () => 1,
    },
  },
  watch: {
    organId () {
      this.getDroneList();
    },
  },
  data () {
    return {
      list: [],
      baseUrl: process.env.VUE_APP_IMG_URL,
      totalCount: 0,
      options: [],
    };
  },
  async mounted () {
    await this.getDroneList();
    await this.getOptions();
  },
  methods: {
    async getOptions () {
      let { organId } = this;
      let res = await API.DEVICE.GetPopupUnitList({ organId });
      this.options = [res] || [];
    },
    async getDroneList () {
      let { organId, state } = this;
      // console.log(organId,state,"拿到的")
      let res = await API.DEVICE.PopupListDrone({
        organId,
        state: state == 4 ? 5 : state,
      });
      this.totalCount = res.totalCount;
      this.list = res.data || [];
    },
    changeState (val) {
      const obj = { 1: "正常", 2: "损坏", 3: "维修", 5: "保养" };
      return obj[val];
    },
  },
};
</script>

<style lang="scss" scoped>
.tdclass1 {
  color: #00b900 !important;
}

.tdclass3 {
  color: yellow !important;
}

.tdclass2 {
  color: red !important;
}

.tdclass5 {
  color: #b3bbc5 !important;
}

.cpt-command-device-drone-popup {
  // background: url("~@/assets/images/device/popup_drone.png") no-repeat;
  // background-size: 100% 100%;
  width: 1267px;
  height: 557px;
  box-sizing: border-box;
  // padding: 0 35px 60px 35px;
  position: absolute;
  // top: 200px;
  top: 5%;
  left: 20%;
  z-index: 100;
  // background: rgba(16, 30, 62, 0.9);
  // box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
  //   inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  // border-radius: 13px;
  box-sizing: border-box;
  background-color: #0A293A;

  .header {
    // height: 64px;
    // background: rgba(16, 65, 215, 0.2);
    // box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    // border-radius: 10px 10px 0 0;
    height: 36px;
    background-color: #224D68;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
  }

  .close_box {
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #00ffff;
    position: absolute;
    // right: 28px;
    // top: 23px;
    // opacity: 0.8;
    // cursor: pointer;
    right: 12px;
    top: 8px;
    opacity: 0.8;
    cursor: pointer;

    .img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  ::v-deep {
    .el-cascader {
      width: 200px;
      position: absolute;
      right: 120px;
      // top: 11px;
      top: 2px;
      height: 80% !important;

      .el-input__inner {
        background: transparent;
        border: 1px solid #00b6ff;
        box-shadow: inset 0 0 3px 0 #00b6ff;
        border-radius: 6px;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: rgba(0, 255, 255, 0.8);
      }
    }
  }

  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;

    // line-height: 64px;
    // padding-left: 20px;
    .cascader {
      height: 80%;
    }
  }

  .bd-box {
    height: calc(100% - 100px);
    box-sizing: border-box;
    padding: 0 10px 15px;

    .tb-box {
      box-sizing: border-box;
      height: 100%;

      .tb-hd-box {
        display: flex;
        justify-content: space-between;
        height: 52px;

        .tb-hd {
          height: 100%;
          flex: 1;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          line-height: 52px;
          text-align: center;
          font-size: 20px;
          color: #00c6ff;
        }
      }

      .tb-bd-box {
        height: calc(100% - 52px);
        overflow: auto;

        .tb-tr {
          display: flex;
          box-sizing: border-box;
          border-bottom: 1px solid #263542;

          &:hover {
            cursor: pointer;
            background: rgba(0, 34, 140, 0.2);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
            border-radius: 5px;
          }

          .td {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            padding: 10px 0;
            text-align: center;
            font-family: MicrosoftYaHei;
            word-break: break-word;
            box-sizing: border-box;
            font-size: 16px;
            color: #dce9ff;
            font-weight: 400;
          }
        }
      }
    }
  }

  .page_num {
    text-align: right;
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #dce9ff;
    line-height: 24px;
    margin: 0 20px 0 0;

    &>span {
      color: #08c2d1;
    }
  }

  /deep/.el-image__inner {
    width: 100px;
    height: 100px;
  }

  /deep/.el-image__error {
    width: 90px;
    height: 70px;
  }
}
</style>

<style lang="scss">
.personal-cascader-status {
  background-image: url('~@/assets/img/i101.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0 !important;
  margin: 0 !important;
  top: 150px !important;

  .el-scrollbar {
    background-color: transparent;
    border: 0;
    border-right: 1px solid #223E4D;
  }

  .el-scrollbar:last-child {
    border: 0 !important;
  }

  .el-cascader-node {
    background-color: transparent;
  }

  .el-cascader__dropdown {
    box-shadow: unset;
    margin: 0 !important;
    border: 0 !important;
  }

  .el-scrollbar__view {
    background-color: #0A293A;
  }

  .popper__arrow {
    display: none;
  }

  .el-cascader-node__label {
    color: #fff;
  }

  .el-icon-arrow-right {
    color: #fff;
  }
}
</style>
