<template>
  <div class="page-command-airtask">
    <div>
      <Situation @handle_click="handle_click" v-if="obj.activeIndex == 0" />
      <Airtask taskCateId="2" v-if="obj.activeIndex == 1" />
      <Airtask taskCateId="3" v-if="obj.activeIndex == 2" />
      <Flight taskCateId="4" v-if="obj.activeIndex == 3" />
    </div>
  </div>
</template>

<script>
import data from "./data";
import methods from "./methods";
import Airtask from "@/pages/command/airtask"; //飞行任务
import Situation from "@/pages/command/situation"; //警情
import Flight from "@/pages/command/flight"; //飞行日志

export default {
  data() {
    return {
      ...data,
      obj: {
        activeIndex: 0,
      },
      flag: true,
    };
  },
  provide() {
    return {
      // 双向绑定
      nul: {
        activeIndex: this.obj,
      },
      getnul: (ites) => {
        this.obj.activeIndex = ites;
      },
      history_track:this.history_track
    };
  },
  components: {
    Airtask,
    Situation,
    Flight,
  },
  methods: {
    ...methods,
    handle_click() {
      this.flag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-command-airtask {
  position: absolute;
  left: 60px;
  top: 170px;
  min-width: 1151px;
  min-height: 800px;
  box-sizing: border-box;
  padding: 0 30px 50px 41px;
  // background: url("~@/assets/images/plate/bg_04.png");
  background-size: 100% 100%;
  .hd-box {
    height: 56px;
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
    line-height: 60px;
    padding-left: 120px;
    overflow: hidden;
  }
}
</style>