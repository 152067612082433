

export default {
    selected_list: [],
    list: [],
    status: {
        status: 1
    },
    search: {
        name: null
    },
    loading: false,
    pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0
    }
}