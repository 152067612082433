<!-- 
  飞行任务-任务详情组件
 -->
<template>
  <div class="cpt-command-situation-detail dialog1027">

    <div class="dialog-header-new" v-interact>
      <div class="dialog-header__title">任务详情</div>
      <img src="@/assets/img/i40.png" alt="" class="img" @click="flight_task_chage_view(-1)">
    </div>

    <div class="dialog-content dialog-content-new">
      <div class="dialog-content-item textarea">
        <span class="dialog-content__label">任务名称：</span>
        <div class="dialog-content__text">{{ flight_detail.taskTitle || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">添加时间：</span>
        <div class="dialog-content__text">{{ flight_detail.addtime || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">航线名称：</span>
        <div class="dialog-content__text">{{ flight_detail.airlineTitle || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">飞手：</span>
        <div class="dialog-content__text">
          {{
            flight_detail.team && flight_detail.team.length
            ? flight_detail.team.join('、')
            : '暂无'
          }}
        </div>
      </div>

      <div class="dialog-content-item">
        <span class="dialog-content__label">警情执行状态：</span>
        <div class="dialog-content__text">{{ get_status(flight_detail) }}</div>
      </div>

      <div class="dialog-content-item">
        <span class="dialog-content__label">预计开始时间：</span>
        <div class="dialog-content__text">{{ flight_detail.startedAt || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">责任单位：</span>
        <div class="dialog-content__text">{{ flight_detail.organizationTitle || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">任务类型：</span>
        <div class="dialog-content__text">{{ flight_detail.taskTypeTitle || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">任务场景：</span>
        <div class="dialog-content__text">{{ flight_detail.cenarioName || '暂无' }}</div>
      </div>
      <div class="dialog-content-item textarea">
        <span class="dialog-content__label">内容描述：</span>
        <div class="dialog-content__text">{{ flight_detail.remark || '暂无' }}</div>
      </div>
      <div class="dialog-content-item textarea">
        <span class="dialog-content__label">审批意见：</span>
        <div class="dialog-content__text">{{ flight_detail.approvalRemark || '暂无' }}</div>
      </div>

    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {}
  },
  inject: ['flight_task_chage_view'],
  computed: {
    ...mapGetters(['flight_detail']),
  },
  mounted () { },
  methods: {
    get_status (item) {
      let val = item
      let text = ''
      if (val.applyStatus == 1 && val.papplyStatus == 1) {
        text = '审批通过'

        if (val.executeStatus == 0) {
          text = '待执行'
        } else if (val.executeStatus == 1) {
          text = '已完成'
        } else {
          text = '执行中'
        }
      } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
        text = '审批不通过'
      } else {
        text = '待审批'
      }
      return text
    },
  },
}
</script>

<style lang="scss" scoped>
.cpt-command-situation-detail {
  position: absolute;
  width: 760px;
  top: 170px;
  left: calc(50% - 380px);
  box-sizing: border-box;
  background-color: #0A293A;
  // border-radius: 4px;

  .dialog-header-new {
    height: 36px;
    background-color: #224D68;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    // border-radius: 4px 4px 0 0;

    .dialog-header__title {
      color: #ffffff;
      font-weight: bold;
    }

    .img {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }

  .dialog-content {
    .dialog-content__text {
      border: 0;
      background-color: transparent;
      color: #D5DBE0;
    }
  }
}
</style>
