<template>
  <!-- 视频窗口 -->
  <div class="cpt-app-float-player">
    <div class="title-box" v-interact>
      <div class="title">{{ video && video.flightSortieName }}</div>
      <div class="el-icon-close" @click="() => $emit('close')"></div>
    </div>
    <div class="video-box">
      <div class="cpt-player-webrtc">
        <video autoplay controls>
          <source :src="video && video.urlList[0]" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      video: null,
    };
  },
  props: ["historyVideo"],
  mounted() {
    this.video = this.historyVideo;
  },
};
</script>

<style lang="scss" scoped>
.cpt-app-float-player {
  width: 362px;
  height: 242px;
  box-sizing: border-box;
  border: 1px solid red;
  position: fixed;
  top: 82px;
  left: 480px;
  background: #161d2d;
  border: 1px solid #178890;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  overflow: hidden;
  z-index: 9999;
  .title-box {
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 12px;
    .title {
      font-family: Microsoft YaHei;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      width: calc(100% - 60px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .el-icon-close {
      color: #8aabd4;
      font-size: 16px;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }
  }
  .video-box {
    height: calc(100% - 40px);
    box-sizing: border-box;
  }
}
.cpt-player-webrtc {
  height: 100%;
  width: 100%;
  background-color: #000;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
</style>