!<template>
  <div class="cpt-command-situation">
    <div class="title-head" v-interact>资源调度</div>
    <img
      src="~@/assets/images/accident/close.png"
      class="img-right"
      @click="() => $emit('closeSource')"
    />
    <el-form :model="ruleForm" ref="ruleForm" label-width="120px">
      <el-form-item label="警情事件内容" prop="content">
        <el-input v-model="ruleForm.content" clearable disabled></el-input>
      </el-form-item>
      <el-form-item label="搜索范围：" class="search" prop="search">
        <el-input
          v-model="ruleForm.search"
          @change="searchRangeFn"
          oninput="value=value.replace(/[^0-9]/g,'')"
          class="search"
        ></el-input
        ><span class="title ml10">KM</span>
        <span class="Schedul cp" @click="showOrganization">调度</span>
      </el-form-item>
    </el-form>

    <div class="tabBox">
      <div
        class="tab cp"
        :class="itemIndex == 'itemA' ? 'tabSelect' : 'notSelect'"
        @click="changeFn('itemA')"
      >
        无人机
      </div>

      <div
        class="second tab cp"
        :class="itemIndex == 'itemB' ? 'tabSelect' : 'notSelect'"
        @click="changeFn('itemB')"
      >
        鹰巣
      </div>

      <!-- <div
        class="tab cp"
        :class="itemIndex == 'itemC' ? 'tabSelect' : 'notSelect'"
        @click="changeFn('itemC')"
      >
        起降场
      </div> -->
    </div>
    <!-- 无人机 -->
    <el-table
      ref="multipleTable"
      :data="droneData"
      tooltip-effect="dark"
      height="250"
      style="width: 100%"
      :highlight-current-row="false"
      v-show="itemIndex == 'itemA'"
      @selection-change="droneSelectionChange"
      :default-sort="{ prop: 'distance', order: 'ascending' }"
    >
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column prop="name" label="无人机名称"></el-table-column>
      <el-table-column prop="organizationName" label="所属单位">
      </el-table-column>
      <el-table-column
        prop="distance"
        label="距警情距离（KM）"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{ scope.row.distance | num }}</template>
      </el-table-column>
      <el-table-column prop="deviceStatus" label="状态" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.status | status }}</template>

      </el-table-column>
    </el-table>
    <!-- 鹰巣 -->
    <el-table
      ref="multipleTable"
      :data="eagleData"
      tooltip-effect="dark"
      height="250"
      style="width: 100%"
      v-show="itemIndex == 'itemB'"
      @selection-change="eagleSelectionChange"
      :default-sort="{ prop: 'distance', order: 'ascending' }"
    >
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column prop="name" label="鹰巣名称"></el-table-column>
      <el-table-column prop="organizationName" label="所属单位">
      </el-table-column>
      <el-table-column
        prop="distance"
        label="距警情距离（KM）"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{ scope.row.distance | num }}</template>
      </el-table-column>
      <el-table-column prop="status" label="状态" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.status | status }}</template>
      </el-table-column>
    </el-table>
    <!-- 起降场 -->
    <!-- <el-table
      ref="multipleTable"
      :data="airportData"
      tooltip-effect="dark"
      height="250"
      style="width: 100%"
      v-show="itemIndex == 'itemC'"
      @selection-change="airportSelectionChange"
      :default-sort="{ prop: 'distance', order: 'ascending' }"
    >
      <el-table-column type="selection" width="30"> </el-table-column>
      <el-table-column prop="name" label="起降场名称"> </el-table-column>
      <el-table-column prop="organizationName" label="所属单位">
      </el-table-column>
      <el-table-column
        prop="distance"
        width="200"
        label="距警情距离（KM）"
        show-overflow-tooltip
      >
        <template slot-scope="scope">{{ scope.row.distance | num }}</template>
      </el-table-column>
      <el-table-column
        prop="deviceStatus"
        label="状态"
        width="50"
        show-overflow-tooltip
      >
      </el-table-column>
    </el-table> -->
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
import {
  AddRadarScanPostStage,
  AddCircleScanPostStage,
} from "@/utils/radar.js";
let radar = null;
let circle = null;
let droneBillborad = [];
let eagleBillborad = [];
let airportBillborad = [];
let longitude = null;
let latitude = null;
export default {
  data() {
    return {
      entitiesList: [],
      radar: null,
      ruleForm: { content: null, search: 10 },
      droneData: [],
      airportData: [],
      eagleData: [],
      itemIndex: "itemA",
      params: {
        longitude: "", //120.24235441789206
        latitude: "", //33.35542881718012
        gisx: "",
        gisy: "",
        distance: 10,
      },
      droneSelection: [],
      eagleSelection: [],
      airportSelection: [],
      taskLocation: [],
    };
  },
  computed: {
    ...mapGetters(["situation_detail"]),
  },
  filters: {
    num: function (value) {
      return value.toFixed(2);
    },
    status: function (value) {
      let obj = {
        0: "异常",
        1: "正常",
        2: "忙碌",
      };

      return obj[value];
    },
  },
  methods: {
    searchRangeFn(val) {
      this.ruleForm.search = val;
      this.params.distance = val;
      this.init();
    },
    showOrganization() {
      let resourceName = {};

      if (this.droneSelection) {
        (resourceName.devices = []),
          this.droneSelection.forEach((item) =>
            resourceName.devices.push(item)
          );
      }
      if (this.eagleSelection) {
        (resourceName.nests = []),
          this.eagleSelection.forEach((item) => resourceName.nests.push(item));
      }
      // if (this.airportSelection) {
      //   (resourceName.lands = []),
      //     this.airportSelection.forEach((item) =>
      //       resourceName.lands.push(item)
      //     );
      // }
      let arr = this.droneSelection.concat(
        this.eagleSelection
        // this.airportSelection
      );
      this.$emit("acceptSelect", arr, resourceName);
    },
    droneSelectionChange(val) {
      this.droneSelection = val;
    },
    eagleSelectionChange(val) {
      this.eagleSelection = val;
    },
    airportSelectionChange(val) {
      this.airportSelection = val;
    },
    // 雷达扫描方法
    showRadarScan() {
      let viewer = window.viewer;
      if (radar) {
        viewer.scene.postProcessStages.remove(radar);
        radar = null;
      }
      let data = {
        r: this.ruleForm.search * 1000,
        interval: 4000,
        scanColor: Cesium.Color.BLUE,
        center: new Cesium.Cartographic(
          Cesium.Math.toRadians(Number(this.situation_detail.gisx)),
          Cesium.Math.toRadians(Number(this.situation_detail.gisy)),
          0
        ),
      };
      viewer.scene.globe.depthTestAgainstTerrain = true; //防止移动、放大缩小会视觉偏移depthTestAgainstTerrain
      radar = AddRadarScanPostStage(
        viewer,
        data.center,
        data.r,
        data.scanColor,
        data.interval
      );
    },
    //警情地址扩散方法
    showRadarScan3() {
      let viewer = window.viewer;
      if (circle) {
        viewer.scene.postProcessStages.remove(circle);
        circle = null;
      }
      viewer.scene.globe.depthTestAgainstTerrain = true; //防止移动、放大缩小会视觉偏移depthTestAgainstTerrain
      var CartographicCenter = new Cesium.Cartographic(
        Cesium.Math.toRadians(Number(this.situation_detail.gisx)), //120.24235441789206
        Cesium.Math.toRadians(Number(this.situation_detail.gisy)), //33.35542881718012
        0
      );
      var scanColor = new Cesium.Color(1.0, 0.0, 0.0, 1);
      circle = AddCircleScanPostStage(
        viewer,
        CartographicCenter,
        1500,
        scanColor,
        500
      );
      if (this.droneData) {
        if (this.droneData) {
          droneBillborad.forEach((item, index) => viewer.entities.remove(item));
        }
        this.droneData.forEach((item, index) => {
          let f = viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(
              Number(item.longitude),
              Number(item.latitude)
            ),
            billboard: {
              image: "cesium/Assets/Images/drone.svg",
              width: 30,
              height: 30,
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
          });
          droneBillborad.push(f);
        });
      }
      if (this.eagleData) {
        if (this.eagleData) {
          eagleBillborad.forEach((item, index) => viewer.entities.remove(item));
        }
        this.eagleData.forEach((item, index) => {
          let f = viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(
              Number(item.longitude),
              Number(item.latitude)
            ),
            billboard: {
              image: "cesium/Assets/Images/eagle.svg",
              width: 30,
              height: 30,
              distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
          });
          eagleBillborad.push(f);
        });
      }
      // if (this.airportData) {
      //   if (airportBillborad) {
      //     airportBillborad.forEach((item, index) =>
      //       viewer.entities.remove(item)
      //     );
      //   }
      //   this.airportData.forEach((item, index) => {
      //     let f = viewer.entities.add({
      //       position: Cesium.Cartesian3.fromDegrees(
      //         item.longitude,
      //         item.latitude
      //       ),
      //       billboard: {
      //         image: "cesium/Assets/Images/airport.svg",
      //         width: 50,
      //         height: 50,
      //         distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
      //         disableDepthTestDistance: Number.POSITIVE_INFINITY,
      //       },
      //     });
      //     airportBillborad.push(f);
      //   });
      // }
    },
    // 飞向警情方法
    fly() {
      let viewer = window.viewer;

      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          Number(this.situation_detail.gisx),
          Number(this.situation_detail.gisy),
          //120.24235441789206,
          //33.35542881718012,
          30000
        ),
      });
    },
    // 获取警情经纬度
    async getLocation() {
      this.params.longitude = Number(
        this.situation_detail.gisx || this.situation_detail.sfjd
      );
      this.params.latitude = Number(
        this.situation_detail.gisy || this.situation_detail.sfwd
      );
      this.params.gisx = this.params.longitude;
      this.params.gisy = this.params.latitude;
      longitude = this.params.longitude;
      latitude = this.params.latitude;
    },

    changeFn(item) {
      this.itemIndex = item;
    },
    async init() {
      this.droneData = (await API.SITUATION.GetDrone(this.params)) || [];
      this.eagleData = (await API.SITUATION.GetEagle(this.params)) || [];
      // this.airportData = await API.SITUATION.GetAirport(this.params);
      this.fly();
      this.showRadarScan();
      this.showRadarScan3();
    },
  },
  mounted() {
    this.ruleForm.content = this.situation_detail.content;
    this.getLocation();
    this.init();
  },
  beforeDestroy() {
    if (this.droneData) {
      droneBillborad.forEach((item, index) => viewer.entities.remove(item));
    }
    if (this.eagleData) {
      eagleBillborad.forEach((item, index) => viewer.entities.remove(item));
    }
    // if (airportBillborad) {
    //   airportBillborad.forEach((item, index) => viewer.entities.remove(item));
    // }
    if (radar) {
      viewer.scene.postProcessStages.remove(radar);
      radar = null;
    }
    if (circle) {
      viewer.scene.postProcessStages.remove(circle);
      circle = null;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
/deep/ .el-checkbox__input.is-disabled .el-checkbox__inner {
  background-color: transparent;
}
.cpt-command-situation {
  position: absolute;
  right: -100px;
  top: 170px;
  width: 560px;
  height: 535px;
  box-sizing: border-box;
  background: url("~@/assets/images/plate/air_space_bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 0 25px;
}
.title-head {
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #dce9ff;
  font-weight: 400;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  line-height: 56px;
  padding-left: 132px;
}
.img-right {
  position: absolute;
  right: 30px;
  top: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.el-form {
  padding-top: 30px;
  ::v-deep {
    .el-textarea__inner {
      height: 70px;
    }
  }
}
.btn {
  margin-left: 139px;
  margin-top: 35px;
}
.el-icon-location-outline {
  color: rgb(179, 201, 203);
  font-size: 30px;
  margin-left: 0.3em;
  cursor: pointer;
}

.tabBox {
  display: flex;
  justify-content: flex-start;
}
.tab {
  text-align: center;
  line-height: 40px;
  height: 40px;
  width: calc(100% / 2);
}
.tabSelect {
  background: #129c9c;
  font-family: MicrosoftYaHeiUI;
  font-size: 18px;
  color: #ffffff;
  font-weight: 400;
}
.notSelect {
  background: #129c9c;
  opacity: 0.5;
}
/deep/[data-v-6daa2892] .el-input .el-input__inner {
  width: 200px;
}
.title {
  font-family: MicrosoftYaHeiUI;
  font-size: 16px;
  color: #ccedff;
  text-align: right;
  font-weight: 400;
}
.Schedul {
  margin-left: 20px;
  display: inline-block;
  background: #129c9c;
  width: 130px;
  height: 40px;
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #ffffff;
  text-align: center;
  font-weight: 400;
}
/*最外层透明*/
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #b3bbc5;
  font-weight: 400;
}

/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: transparent;
  border: none;
}
.search .el-input {
  width: 200px;
}
.second {
  border-left: 1px, solid, black;
  border-right: 1px, solid, black;
}
/deep/ .el-table thead {
  font-family: MicrosoftYaHeiUI;
  font-size: 16px;
  color: #129c9c;
  text-align: right;
  font-weight: 400;
}
/deep/ /* 去掉中间数据的分割线 */
.el-table__row>td {
  border: none;
}
/* 去掉上面的线 */
.el-table th.is-leaf {
  border: none;
}
/* 去掉最下面的那一条线 */
.el-table::before {
  height: 0px;
}
::v-deep.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: transparent;
}
::v-deep.el-table--enable-row-hover .el-table__body tr:hover>td{
  background: none;
}
</style>
