<!-- 
  智能空域组件
  包含备案航线和基站
 -->

<template>
  <div class="intelligent-airspace-container">

    <!-- <Transition name="panel-list-fade"> -->
    <div style="display: block; background-color: #0A293A; width: 100% height: 100%; border: 0 solid yellow" class="cc"
      v-show="foldFlag0" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="my-custom-loader"
      element-loading-background="rgba(0, 0, 0, 0)">

      <div class="header">
        <div class="t t1" :class="[activeName == 0 ? 't-selected' : '']" @click="typeClick(0)">备案航线</div>
        <div class="t t2" :class="[activeName == 1 ? 't-selected' : '']" @click="typeClick(1)">基站</div>
      </div>

      <div class="form">

        <div class="search">

          <el-input class="input-search" :style="[{ 'width': activeName == 0 ? '45%' : '90%' }]" v-model="form.input"
            :placeholder="activeName == 0 ? '请输入航线名称' : '请输入基站名称'" clearable @clear="clearSearchInput"></el-input>
          <div class="btn btn1" @click="searchHandleClick">
            <i class="el-icon-search"></i>
          </div>

          <div class="btn btn2" v-show="activeName == 0" @click="airwayDist"
            :class="[airwayShowFlag ? 'hedgehop-show' : '']">
            航线分布</div>

          <div class="btn btn3" v-show="activeName == 0" @click="gridShow" :class="[hedgehopFlag ? 'hedgehop-show' : '']">
            低空限制
          </div>
        </div>

        <div class="panel" v-show="activeName == 0">
          <!-- 执行中 -->
          <div class="p p1" @click="panelClick(0)" :class="[panelFlag == 0 ? 'panel-selected' : '']">
            <span class="iconfont icon-base91jiazai panel-icon"
              :class="[panelFlag == 0 ? 'panel-selected-icon' : '']"></span>
            <div class="text">
              <span class="num" :class="[panelFlag == 0 ? 'panel-selected-num' : '']">{{ pie_data.completeCount
              }}</span>
              <span class="name" :class="[panelFlag == 0 ? 'panel-selected-name' : '']">已完成</span>
            </div>
          </div>
          <el-divider class="divider" direction="vertical"></el-divider>
          <!-- 已完成 -->
          <div class="p p2" @click="panelClick(1)" :class="[panelFlag == 1 ? 'panel-selected' : '']">
            <span class="iconfont icon-basegouxuan panel-icon"
              :class="[panelFlag == 1 ? 'panel-selected-icon' : '']"></span>
            <div class="text">
              <span class="num" :class="[panelFlag == 1 ? 'panel-selected-num' : '']">{{ pie_data.executeCount }}</span>
              <span class="name" :class="[panelFlag == 1 ? 'panel-selected-name' : '']">执行中</span>
            </div>
          </div>
          <el-divider class="divider" direction="vertical"></el-divider>
          <!-- 待执行 -->
          <div class="p p3" @click="panelClick(2)" :class="[panelFlag == 2 ? 'panel-selected' : '']">

            <span class="iconfont icon-baseliuchengtixing panel-icon"
              :class="[panelFlag == 2 ? 'panel-selected-icon' : '']"></span>

            <div class="text">

              <span class="num" :class="[panelFlag == 2 ? 'panel-selected-num' : '']">{{ pie_data.expectationCount
              }}</span>
              <span class="name" :class="[panelFlag == 2 ? 'panel-selected-name' : '']">待执行</span>
            </div>

          </div>

        </div>

      </div>

      <!-- 备案航线 -->
      <div class="body custom-scrollbar" v-if="activeName == 0" id="inteAreaContainerBody"
        :style="[{ 'height': activeName == 0 ? 'calc(100% - 175px)' : 'calc(100% - 125px)' }]">

        <div class="card-body">
          <Card class="card" v-for="(item, index) in listData" :key="index" :data="item" @itemClick="airWayClick"
            @show-log="airway_show_log"></Card>
        </div>
        <!-- 分页 -->
        <div class="pagation">
          <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="airway.pagination.pageNo"
            :page-size="airway.pagination.pageSize" layout="prev, pager, next, total,  jumper"
            :total="airway.pagination.totalCount" @current-change="handleSizeChange" @size-change="handlePageSizeChange">
          </el-pagination>
        </div>

        <!-- <img id="scrollToTop" src="@/assets/img/i98.png" alt="" @click="scroll2Top" title="回到顶部"> -->
      </div>

      <!-- 基站 -->
      <div class="body-station custom-scrollbar" v-else id="inteAreaContainerBody"
        :style="[{ 'height': activeName == 0 ? 'calc(100% - 175px)' : 'calc(100% - 125px)' }]" v-loading="loading"
        element-loading-text="数据加载中" element-loading-spinner="my-custom-loader"
        element-loading-background="rgba(0, 0, 0, 0)">
        <div class="card-body">
          <CardBaseStation class="card" v-for="(item, index) in station.list" :key="index" :data="item"
            @itemClick="stationClick"></CardBaseStation>
        </div>
        <!-- 分页 -->
        <div class="pagation">
          <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="station.pagination.pageNo"
            :page-size="station.pagination.pageSize" layout="prev, pager, next, total,  jumper"
            :total="station.pagination.totalCount" @current-change="handleSizeChange_station"
            @size-change="handlePageSizeChange_station">
          </el-pagination>
        </div>
        <!-- <img id="scrollToTop" src="@/assets/img/i98.png" alt="" @click="scroll2Top" title="回到顶部"> -->
      </div>

      <!-- 鼠标滑动到航线上的提示框 -->

      <div id="airline-tips">

        <el-row>
          <el-col :span="6">任务名称：</el-col>
          <el-col :span="18">{{ airInfo.taskName }}</el-col>
        </el-row>

        <el-row>
          <el-col :span="6">任务ID：</el-col>
          <el-col :span="18">{{ airInfo.taskId }}</el-col>
        </el-row>

        <el-row>
          <el-col :span="6">添加时间：</el-col>
          <el-col :span="18">{{ airInfo.addtime }}</el-col>
        </el-row>

        <el-row>
          <el-col :span="6">飞行名称：</el-col>
          <el-col :span="18">{{ airInfo.flightName }}</el-col>
        </el-row>

      </div>
    </div>
    <!-- </Transition> -->

    <!-- 设备预警 告警 空域管理 三个图标 -->
    <div class="icon-panel">
      <el-tooltip class="item" effect="dark" content="设备预警" placement="top">
        <img src="@/assets/img/i118.png" alt="" class="img" @click="iconTableClick(0)">
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="告警" placement="top">
        <img src="@/assets/img/i119.png" alt="" class="img" @click="iconTableClick(1)">
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="空域管理" placement="top">
        <img src="@/assets/img/i120.png" alt="" class="img" @click="iconTableClick(2)">
      </el-tooltip>
    </div>

    <!-- 设备预警 告警 空域管理 面板 -->
    <div class="icon-panel-table" v-show="iconPanelTableShowFlag">
      <header class="icon-header">
        <div class="icon-title">{{ iconPanelTitle }}</div>
        <img class="icon-img" src="@/assets/img/i40.png" alt="" @click="closeIconPanel">
      </header>
      <!-- 设备预警 -->
      <div class="icon-body" v-show="iconPanelTitle == '设备预警'">

        <div class="icon-tab">
          <div class="tab-item" v-for="item in warning.tabs" :key="item.id"
            :class="{ 'tab-item-selected': warning.status.status == item.id }" @click="warning_change_tab(item.id)">
            {{ item.name }}</div>
        </div>

        <div class="icon-table">
          <Warning v-loading="warning.loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)" :list="warning.list" :pagination="warning.pagination"
            @search="warning_search" @change-status="warning_change_status" @click-item="warning_change"
            @size-change="warning_change_size" @page-change="warning_change_page" @distribution="warning_distribution">
          </Warning>
        </div>

      </div>

      <!-- 告警 -->

      <div class="icon-body" v-show="iconPanelTitle == '告警'">
        <div class="icon-table">
          <Alarm :list="alarm.list" :pagination="alarm.pagination" @search="alarm_search"
            @change-status="alarm_change_status" @click-item="alarm_change" @size-change="alarm_change_size"
            @page-change="alarm_change_page" @distribution="alarm_distribution" />
        </div>
      </div>

      <!-- 空域管理 -->
      <div class="icon-body" v-show="iconPanelTitle == '空域管理'">
        <div class="tr low-air-limit">
          <span class="name">低空限制</span>
          <el-switch v-model="lowAirLimitValue" active-color="#13ce66" inactive-color="#ff4949"
            @change="lowAirLimitChange">
          </el-switch>
        </div>
        <div class="icon-table">
          <AirspaceInfo class="icon-table-airspace"></AirspaceInfo>
        </div>
      </div>

      <!-- <div class="icon-foot"></div> -->
    </div>
    <Log v-if="airway.current_log_id" :id="airway.current_log_id" @close="airway_close_log"></Log>

    <!-- 折叠标志 -->

    <div class="foldFlag" :class="[foldFlag0 ? '' : 'un-foldFlag']" @click="foldFlag(0)">
      <img v-if="foldFlag0" class="img" src="@/assets/img/i26.png" alt="">
      <img v-else class="img" src="@/assets/img/i27.png" alt="">
    </div>
  </div>
</template>

<script>
import Card from './card.vue';
// 基站面板
import CardBaseStation from './cardBaseStation.vue';
import Warning from "@/components/command/new-home/warning";
import Alarm from "@/components/command/new-home/alarm";
// 空域管理
import AirspaceInfo from "@/pages/command/airspace/indexinfo.vue";

import API from "@/api";
import image from "@/assets/images/icons/station.svg"
import { mapGetters } from "vuex";
import data from "./data";
import methods from "./methods";

import Log from "@/components/command/new-home/log";
import Bus from '@/assets/ligature.js'
import _ from 'lodash';

let billboardsAirLine = null;
export default {
  components: {
    Card, CardBaseStation, Warning, Alarm, AirspaceInfo, Log,
  },
  data () {
    return {
      ...data,
      activeName: 0,
      form: {
        input: '',
        time: '',
        scroll: '',
        total: 0,
        pageNo: 1,
        pageSize: 10,
      },
      panelFlag: -1,
      cardData: [],
      pie_data: {},
      listData: [],
      airInfo: {},
      hedgehopFlag: false,
      airwayShowFlag: false, //航线分布开关
      iconPanelTitle: '设备预警',
      iconPanelTableShowFlag: false, //设备预警，告警， 空域管理 面板显示标志
      lowAirLimitValue: false, //低空限制开关
      loading: false,
      foldFlag0: true, //智能空域面板显示
    }
  },
  watch: {
    iconPanelTableShowFlag (newValue, oldValue) {
      console.log(newValue, oldValue, 'new value old value');
      if (!newValue) {
        this.warning_clear_entity();
      }
    }
  },
  async mounted () {
    this.g_bus.$on("watch_viewer", async () => {
      // 监测地图加载完毕
      this.airway_init();

      let handler = new Cesium.ScreenSpaceEventHandler(window.viewer.scene.canvas);
      let tipsDom = document.getElementById('airline-tips');
      handler.setInputAction((event) => {
        let pickedObjects = window.viewer.scene.drillPick(event.endPosition);
        if (Cesium.defined(pickedObjects)) {
          if (pickedObjects.length > 0) {
            let pick = pickedObjects[0];
            // console.log(pick, 'pickpickpickpick');
            if (pick.id?.flag && pick.id.flag == 'airline') {
              let panCoord = event.endPosition;
              tipsDom.style.display = 'block';
              let d = parseFloat(window.getComputedStyle(tipsDom).getPropertyValue('height'));
              let innerWidth = window.innerWidth;
              tipsDom.style.left = `${panCoord.x - 180}px`;
              if (panCoord.x < 180) {
                tipsDom.style.left = `${panCoord.x}px`;
              }
              if (innerWidth - panCoord.x < 180) {
                tipsDom.style.left = `${panCoord.x - 360}px`;
              }
              tipsDom.style.top = `${panCoord.y - 60 - parseFloat(window.getComputedStyle(tipsDom).getPropertyValue('height'))}px`;
              if (panCoord.y - parseFloat(d) < 130) {
                tipsDom.style.top = `${panCoord.y + 10}px`;
              }
              this.airInfo = pick.id.value;
            }
          } else {
            tipsDom.style.display = 'none';
          }
        } else {
          tipsDom.style.display = 'none';
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      this.$nextTick(() => {
        let a = document.querySelector('.qysStyleLeft');
        a && (a.style.display = 'none');
      });

      // 设备预警
      this.warning_list();
      // 告警
      this.alarm_list();

    });

    this.$nextTick(() => {
      // 获取执行中、已完成、待执行面板数据
      this.get_pie_data();
      // console.log(this.airway, '========================');
      this.airway_search(this.form);
    });

    Bus.$on('info-sidebar-selected', (v) => {
      // console.log('智能空域', '清除图上智能空域绘制的东西');
      // 航线分布
      this.airwayShowFlag = false;
      this.realHideAirLine();
      // 低空限制
      this.airway.hedgehop = true;
      this.hedgehopFlag = false;
      this.airway_hedgehop();
      // 航线
      this.airway_destroy_entity();
      // 基站
      this.station_clear_entity();
      // 设备预警、告警、空域管理面板
      this.iconPanelTableShowFlag = false;
    });

    // 首页安全管制跳转到智能空域界面，显示空域管理面板
    Bus.$on('airspace-info-show', (v) => {
      this.$nextTick(() => {
        this.iconPanelTableShowFlag = true;
        this.iconPanelTitle = '空域管理';
        this.foldFlag0 = true;
        this.foldFlag(0);
      });
    });

    // 滚动 取消 2023年11月6日 11:33:06
    {
      // let dom = document.getElementById('inteAreaContainerBody');
      // let domScroll = document.getElementById('scrollToTop');
      // dom.addEventListener('scroll', () => {
      //   let clientHeight = dom.clientHeight;
      //   let scrollTop = dom.scrollTop;
      //   let scrollHeight = dom.scrollHeight;
      //   domScroll.style.top = `${0 + clientHeight + 100}px`;
      //   domScroll.style.display = 'block';
      //   if (scrollTop == 0) {
      //     domScroll.style.display = 'none';
      //   }
      //   // console.log(parseInt(clientHeight + scrollTop));
      //   // console.log('parseInt(clientHeight + scrollTop)', scrollHeight);
      //   if (Math.abs(parseInt(clientHeight + scrollTop) - scrollHeight) <= 1) {
      //     console.log('竖向滚动条已经滚动到底部')
      //     // 再次请求新的数据
      //     this.form.scroll = 'scroll';
      //     // this.airway_search(this.form);
      //     if (this.activeName == 0) {
      //       // 备案航线
      //       this.airway_search(this.form);
      //     } else {
      //       // 基站
      //       console.log("请求基站数据");
      //       // this.station_searchScroll_throttle();
      //       this.station_searchScroll(this.form);
      //     }
      //   }
      // });
    }
  },
  methods: {
    ...methods,
    /**
     *@Description: 基站分页
    *@Date: 2023-11-06 13:44:13
    *@Params1: 
    *@Return1: 
    */
    handleSizeChange_station (v) {
      console.log(v, '当前页');
      this.station.pagination.pageNo = parseInt(v);
      this.station_search(this.form.input);

    },
    /**
     *@Description: 基站分页
    *@Date: 2023-11-06 13:44:29
    *@Params1: 
    *@Return1: 
    */
    handlePageSizeChange_station (v) {
      console.log(v, '每页多少条数据');
    },

    /**
     *@Description: 备案航线分页
    *@Date: 2023-11-06 10:33:20
    *@Params1: 
    *@Return1: 
    */
    handleSizeChange (v) {
      console.log(v, '分页handleSizeChange');
      this.airway.pagination.pageNo = parseInt(v);
      // 重新查詢
      this.airway_search(this.form);
    },
    /**
     *@Description: 备案航线分页
    *@Date: 2023-11-06 10:33:58
    *@Params1: 
    *@Return1: 
    */
    handlePageSizeChange (v) {
      console.log(v, '分页handlePageSizeChange');
      this.airway.pagination.pageSize = parseInt(v);
      // 重新查詢
      this.airway_search(this.form);
    },
    // station_searchScroll_throttle: _.debounce(function () {
    //   this.station_searchScroll(this.form);
    // }, 100),


    // 跳到顶部
    scroll2Top () {
      let dom = document.getElementById('inteAreaContainerBody');
      dom.scrollTop = 0;
      setTimeout(() => {
        let domScroll = document.getElementById('scrollToTop');
        domScroll.style.display = 'none';
      }, 100);
    },

    // 点击航线定位预览
    airWayClick (data) {
      this.airway_change(data);
    },
    // 点击基站定位
    stationClick (data) {
      this.station_change(data);
    },
    // 点击备案航线或基站按钮
    typeClick (flag) {
      this.activeName = flag;
      // console.log(this.listData, this.listData.length, '备案航线数据');
      // console.log(this.station.list, this.station.list.length, '基站数据');
      // console.log(flag, 'flagflagflag');
      if (flag == 0) { //请求备案航线数据列表
        if (this.listData.length == 0) {
          this.form.scroll = '';
          this.airway_search(this.form);
        }
      } else {
        // 请求基站数据列表
        if (this.station.list.length == 0) {
          this.station.search.scroll = '';
          this.getBaseStationList();
        }
      }
    },
    // 初始化备案航线 获取执行中，已完成，待执行数量
    async get_pie_data (flag) {
      let dt = new Date();
      let a = dt.getFullYear();
      let b = dt.getMonth();
      b = b + 1;
      b = b < 10 ? '0' + b : b;
      let c = dt.getDate();
      c = c < 10 ? '0' + c : c;
      // a + '-' + b + '-' + c
      let time = DEFAULT_CONFIG_getTaskFlightLineSelectTIME ? DEFAULT_CONFIG_getTaskFlightLineSelectTIME : '2023-07-18';
      this.form.time = time;
      let res = await API.TOWER.ListAirway({ time: time });
      // console.log(res, '备案航线数据');
      if (res && res.data) {
        const { completeCount, executeCount, expectationCount, list } = res.data;
        // this.listData = list;
        this.pie_data = { completeCount, executeCount, expectationCount, };
      }
    },
    // 获取基站列表
    async getBaseStationList () {
      await this.station_list();
    },
    handleClick (v) {
      console.log(v);
    },
    // 点击执行中、已完成、待执行 status分别为 3、1、0
    panelClick (flag) {
      this.form.scroll = '';
      if (this.panelFlag == flag) {
        this.panelFlag = -1;
        this.form.status = null;
        this.airway_search(this.form);
        return;
      }
      this.panelFlag = flag;
      if (this.panelFlag == 0) { // 已完成
        // this.form.status = 1;
        this.listData = _.filter(this.airway.list, ['taskStatus', 1]);
      } else if (this.panelFlag == 1) { // 执行中
        // this.form.status = 3;
        this.listData = _.filter(this.airway.list, ['taskStatus', 3]);
      } else if (this.panelFlag == 2) { // 待执行
        // this.form.status = 0;
        this.listData = _.filter(this.airway.list, ['taskStatus', 0]);
      }
      // this.airway_search(this.form);
    },
    // 把获取到的航线加载的球上
    addAirLine2Map (res) {
      res.list.forEach(item => {
        let a = JSON.parse(item.flightCourseJson);
        let b = a.points;
        let pointHeightArray = [];
        b.forEach(v => {
          // console.log(parseFloat(v.alt) + 600, ' parseFloat(v.alt)');
          pointHeightArray.push(parseFloat(v.lon), parseFloat(v.lat), parseFloat(v.alt) + 0);
        });
        if (pointHeightArray.length >= 2) {
          billboardsAirLine.add({
            show: true,
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(pointHeightArray),
            width: 4,
            material: Cesium.Material.fromType('Color', {
              color: Cesium.Color.fromRandom()
            }),
            id: { flag: 'airline', value: item }
          });
        }
      });
      window.viewer.scene.forceRender();
    },
    // 加载/显示/隐藏航线
    async hideAirLine (flag) {
      let loading = null;
      if (!billboardsAirLine) {
        loading = this.$loading({
          lock: true,
          text: '正在加载航线',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        // 开始加载航线
        billboardsAirLine = window.viewer.scene.primitives.add(new Cesium.PolylineCollection());
        let res = await this.airway_getAllairline();
        this.addAirLine2Map(res);
        loading.close();
      } else {
        if (flag) {
          loading = this.$loading({
            lock: true,
            text: '正在加载航线',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        }
        let len1 = billboardsAirLine.length;
        for (let i = 0; i < len1; ++i) {
          let b = billboardsAirLine.get(i);
          b.show = flag;
        }
        if (flag) {
          loading.close();
        }
      }
      window.viewer.scene.forceRender();
    },
    // 隐藏航线
    realHideAirLine () {
      let len1 = billboardsAirLine ? billboardsAirLine.length : 0;
      for (let i = 0; i < len1; ++i) {
        let b = billboardsAirLine.get(i);
        b.show = false;
      }
    },
    // 低空限制
    gridShow () {
      this.hedgehopFlag = !this.hedgehopFlag;
      this.airway_hedgehop();
    },
    // 航线分布
    airwayDist () {
      this.airwayShowFlag = !this.airwayShowFlag;
      this.hideAirLine(this.airwayShowFlag);
    },
    // 航线、基站查询
    searchHandleClick () {
      this.form.scroll = '';
      if (this.activeName == 0) {
        console.log('查询备案航线');
        this.airway_search(this.form);
      } else {
        console.log('查询基站');
        this.station_search(this.form.input);
      }
    },
    // 点击清空查询搜索框
    clearSearchInput () {
      this.form.scroll = '';
      if (this.activeName == 0) {
        console.log('查询备案航线');
        this.get_pie_data();
        this.airway_search(this.form);
      } else {
        console.log('查询基站');
        this.station_search('');
      }
    },
    /**
    * description: 点击右上角三个小图标
    * params: flag ,0 设备预警, 1 告警, 2 空域管理
    * createtime:2023/09/21 10:26:38
    */
    iconTableClick (flag) {
      // 面板显示
      this.iconPanelTableShowFlag = true;
      switch (flag) {
        case 0:
          this.iconPanelTitle = '设备预警';
          break;
        case 1:
          this.iconPanelTitle = '告警';
          break;
        case 2:
          this.iconPanelTitle = '空域管理';
          break;
      }
    },
    /**
    * description: 低空限制开关
    * createtime:2023/09/21 16:18:33
    */
    lowAirLimitChange (v) {
      this.hedgehopFlag = v;
      this.airway_hedgehop();
    },
    /**
     *@Description:关闭设备预警、告警、空域管理面板
    *@Date: 2023-10-27 14:04:08
    *@Params1: 
    *@Return1: 
    */
    closeIconPanel () {
      this.iconPanelTableShowFlag = false;
      // 清空图上的元素
      // this.warning_clear_entity();
    },
    /**
     *@Description:面板折叠
    *@Author: name 
    *@Date: 2023-11-20 16:15:39
    *@Params1: 
    *@Return1: 
    */
    foldFlag (index) {
      console.log(index, 'indexindexindexindexindex');
      this.foldFlag0 = !this.foldFlag0;
      let dom = document.querySelector('.intelligent-airspace');
      if (this.foldFlag0) {
        dom.style.backgroundColor = '#0A293A';
        dom.style.pointerEvents = 'all';
      } else {
        dom.style.backgroundColor = 'transparent';
        dom.style.pointerEvents = 'none';
      }
    }
  },
  destroyed () {
    // if (billboardsAirLine != null) {
    //   let len1 = billboardsAirLine.length;
    //   for (let i = 0; i < len1; ++i) {
    //     let b = billboardsAirLine.get(i);
    //     b.show = false;
    //   }
    // }
    // window.viewer.scene.forceRender();
  },
}
</script>

<style lang="scss" scoped>
.intelligent-airspace-container {
  width: 100%;
  height: 100%;
  color: #fff;
  // border: 10px solid red;

  // position: relative;
  .cc {
    width: 100%;
    height: 100%;
  }

  .header {
    width: 100%;
    height: 54px;
    background: #224D68;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0;
    box-sizing: border-box;
    color: #ffffff !important;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;

    .t {
      width: 30%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .t-selected {
      color: #00F5FF !important;
      border-bottom: 2px solid #00F5FF !important;
    }
  }

  .form {
    width: 100%;
    height: 121px;
    height: auto;
    background: #133E55;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 5px;

    .search {
      width: 100%;
      height: 45%;
      // background-color: #00F5FF;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .input-search {
        width: 45%;
        background-color: unset;

        /deep/ .el-input__inner {
          color: #ffffff;
        }
      }

      .btn {
        height: 40px;
        background: #4E87AC;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      .btn1 {
        width: 40px;
      }

      .btn2 {
        width: 78px;
      }

      .btn3 {
        width: 68px;
      }
    }

    .panel {
      width: 100%;
      height: 55%;
      // background-color: #0ac92a;
      display: flex;
      align-items: center;
      justify-content: center;

      .p {
        width: 30%;
        height: 100%;
        // background: #2B73EA;
        // background-color: rgba(43, 115, 234, 0.2);
        // opacity: 0.2;
        margin-top: 5px;
        display: flex;
        // justify-content: space-evenly;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 5px 0 !important;
        box-sizing: border-box;

        .panel-icon {
          font-size: 20px;
          color: #fff;
        }

        .panel-selected-icon,
        .panel-selected-num,
        .panel-selected-name {
          // 没有查询功能不做处理
          color: #00F5FF !important;
        }

        .text {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-left: 5px;

          .num {
            font-size: 16px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #fff;
          }

          .name {
            font-size: 12px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(97, 125, 141, 1);
            margin-top: 3px;
          }

          .panel-selected-num {
            // 没有查询功能不做处理
            // color: #00F5FF !important;
          }

          .panel-selected-name {
            // 没有查询功能不做处理
            // color: rgba(15, 140, 170, 1) !important;
          }
        }
      }

      .panel-selected {
        background-color: #4276a1 !important;
        border-bottom: 1px solid #00F5FF !important;
      }

      .divider {
        // height: 50%;
        height: 35px;
        width: 1.5px;
        margin-top: 5px;
        background-color: #c0c0c0;
      }
    }
  }

  .body {
    // 54+121=175
    box-sizing: border-box;
    padding: 0px 10px;
    height: calc(100% - 165px) !important;
    // height: calc(100% - 125px);
    width: 100%;
    // background-color: rgba(10, 41, 58, 1);
    // border: 1px solid greenyellow;

    // overflow-y: auto;
    .card-body {
      width: 100%;
      height: calc(100% - 30px);
      box-sizing: border-box;
      // border: 1px solid yellow !important;
      overflow-y: auto !important;
    }

    .card-body::-webkit-scrollbar {
      display: none !important;
    }

    .pagation {
      height: 30px;
      box-sizing: border-box;
      padding: 0 0px;
      height: 30px;
      width: 100%;
      background-color: #0E3449;
      padding-top: 5px;
      // border: 1px solid red;

      /deep/ .el-pagination--small {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 0 !important;
      }

      /deep/ .el-pagination__total {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        margin-left: 5px;
      }

      /deep/ .btn-prev {
        background-color: #253B5A !important;
        color: #ffffff;
        width: 22px;
      }

      /deep/ .btn-next {
        background-color: #253B5A !important;
        color: #ffffff;
        width: 22px;
        margin-left: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      /deep/ .el-pager {
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-left: 5px;

        .number {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        li {
          margin-left: 2px;
          background-color: #253B5A !important;
          color: #ffffff;
        }

        li.active {
          color: #FFFFFF;
          background-color: #409EFF !important;
        }

        li:hover {
          border: 1px solid #367DB4;
          background-color: #0C3549 !important;
        }
      }

      /deep/ .el-pagination__sizes .el-select--mini {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .el-input__inner {
          color: #ffffff;
        }
      }

      /deep/ .el-pagination__jump {
        margin-left: 5px !important;
        color: #ffffff;
        margin-top: -3px;
      }

      /deep/ .el-pagination__jump .el-input {
        width: 30px !important;
        background-color: #253B5A !important;

        .el-input__inner {
          background-color: #253B5A !important;
          color: #ffffff;
        }
      }
    }

    .card {
      margin-bottom: 5px;
    }

    // #scrollToTop {
    //   position: absolute;
    //   // bottom: 2%;
    //   right: 10px;
    //   z-index: 100;
    //   width: 28px;
    //   height: 28px;
    //   // float: right;
    //   // margin-top: -50px;
    //   cursor: pointer;
    //   display: none; //默认隐藏
    // }

    // #scrollToTop1 {
    //   position: absolute;
    //   // bottom: 2%;
    //   right: 10px;
    //   z-index: 100;
    //   width: 28px;
    //   height: 28px;
    //   // float: right;
    //   // margin-top: -50px;
    //   cursor: pointer;
    // }
  }

  .body-station {
    // 54+121=175
    box-sizing: border-box;
    padding: 0px 10px;
    height: calc(100% - 115px) !important;
    // height: calc(100% - 125px);
    width: 100%;
    background-color: rgba(10, 41, 58, 1);
    // border: 1px solid greenyellow;

    // overflow-y: auto;
    .card-body {
      width: 100%;
      height: calc(100% - 30px);
      box-sizing: border-box;
      // border: 1px solid yellow !important;
      overflow-y: auto !important;
    }

    .card-body::-webkit-scrollbar {
      display: none !important;
    }

    .pagation {
      height: 30px;
      box-sizing: border-box;
      padding: 0 0px;
      height: 30px;
      width: 100%;
      background-color: #0E3449;
      padding-top: 5px;
      // border: 1px solid red;

      /deep/ .el-pagination--small {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 0 !important;
      }

      /deep/ .el-pagination__total {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        margin-left: 5px;
      }

      /deep/ .btn-prev {
        background-color: #253B5A !important;
        color: #ffffff;
        width: 22px;
      }

      /deep/ .btn-next {
        background-color: #253B5A !important;
        color: #ffffff;
        width: 22px;
        margin-left: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      /deep/ .el-pager {
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-left: 5px;

        .number {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        li {
          margin-left: 2px;
          background-color: #253B5A !important;
          color: #ffffff;
        }

        li.active {
          color: #FFFFFF;
          background-color: #409EFF !important;
        }

        li:hover {
          border: 1px solid #367DB4;
          background-color: #0C3549 !important;
        }
      }

      /deep/ .el-pagination__sizes .el-select--mini {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .el-input__inner {
          color: #ffffff;
        }
      }

      /deep/ .el-pagination__jump {
        margin-left: 5px !important;
        color: #ffffff;
        margin-top: -3px;
      }

      /deep/ .el-pagination__jump .el-input {
        width: 30px !important;
        background-color: #253B5A !important;

        .el-input__inner {
          background-color: #253B5A !important;
          color: #ffffff;
        }
      }
    }

    .card {
      margin-bottom: 5px;
    }

    // #scrollToTop {
    //   position: absolute;
    //   // bottom: 2%;
    //   right: 10px;
    //   z-index: 100;
    //   width: 28px;
    //   height: 28px;
    //   // float: right;
    //   // margin-top: -50px;
    //   cursor: pointer;
    //   display: none; //默认隐藏
    // }

    // #scrollToTop1 {
    //   position: absolute;
    //   // bottom: 2%;
    //   right: 10px;
    //   z-index: 100;
    //   width: 28px;
    //   height: 28px;
    //   // float: right;
    //   // margin-top: -50px;
    //   cursor: pointer;
    // }
  }

  #airline-tips {
    width: 360px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.3);
    display: none;
    position: absolute;
    color: #ffffff;
    padding: 15px;
    border-radius: 6px;
    // text-align: center;
    z-index: 200;
  }

  .hedgehop-show {
    color: #00F5FF !important;
  }

  .icon-panel {
    position: absolute;
    top: 1vh;
    right: -71vw;
    width: 160px;
    height: 45px;
    background: #171717;
    border-radius: 12px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .img {
      width: 34px;
      height: 30px;
      cursor: pointer;
    }
  }

  .icon-panel-table {
    position: absolute;
    top: 1vh;
    right: -71vw;
    width: 520px;
    height: auto;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    padding: 5px 10px;
    background-color: #0A293A;
    // border: 1px solid yellow;


    .icon-header {
      height: 45px;
      width: 100%;
      // background-color: #00F5FF;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon-title {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      .icon-img {
        width: 26px;
        height: 26px;
        cursor: pointer;
      }
    }

    .icon-body {
      width: 100%;

      .icon-tab {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;

        .tab-item {
          height: 100%;
          width: 15%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
        }

        .tab-item-selected {
          color: #00F5FF;
          border-bottom: 2px solid #00F5FF;
        }
      }

      .low-air-limit {
        width: 100%;
        height: 40px;
        background-color: #163B53;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 10px;

        .name {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
        }

        /deep/ .el-switch__core::before {
          content: "关";
          position: absolute;
          top: -2px;
          right: 5px;
          color: #fff;
        }

        .is-checked /deep/ .el-switch__core::before {
          content: "开";
          position: absolute;
          top: -1px;
          right: 20px;
          color: #fff;
        }
      }

      .icon-table {
        width: 100%;
        height: auto;
      }
    }

    .icon-foot {
      height: 45px;
      width: 100%;
      // background-color: #00F5FF;
    }
  }

  .foldFlag {
    position: fixed;
    top: 120px;
    left: 492px;
    height: 30px;
    width: 24px;
    background-color: #214D68;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all;

    .img {
      width: 20px;
      height: 20px;

    }
  }

  .un-foldFlag {
    left: 82px;
  }

  /** 卡片列表显隐动画 **/
  @keyframes panel-list-comein {
    0% {
      transform: translateX(-500px)
    }

    100% {
      transform: translateX(0)
    }
  }

  @keyframes panel-list-comeout {
    0% {
      transform: translateX(0px)
    }

    100% {
      transform: translateX(-500px)
    }
  }

  .panel-list-fade-enter-active {
    animation: panel-list-comein 0 ease;
  }

  .panel-list-fade-leave-active {
    animation: panel-list-comeout .6s ease;
  }
}

.icon-table-airspace {
  // position: relative;
  // right: 0;
  // left: unset;
  // top: 100px;
  // display: flex;
}
</style>
<style lang="scss">
@import "@/styles/wuhuCommon.scss";
</style>