<template>
  <div class="cpt-command-device-battery">
    <div class="tb-box">
      <div class="tb-hd-box">
        <div class="tb-hd">电池名称</div>
        <div class="tb-hd">电池编号</div>
        <div class="tb-hd">归属无人机</div>
        <div class="tb-hd">所属单位</div>
        <!-- <div class="tb-hd special">电池累计时长</div> -->
        <div class="tb-hd special" style="padding-right: 4px">累计飞行架次</div>
        <!-- 隐藏操作按钮 -->
        <div class="tb-hd" style="flex: 0.5" v-if="false">操作</div>
      </div>
      <div class="tb-bd-box">
        <div class="tb-tr" :class="{ 'table-light': (i % 2) }" v-for="(item, i) in batterylist" :key="item.id">
          <div class="td">
            {{ item.batteryName || "暂无" }}
          </div>
          <div class="td">
            {{ item.battery_id || "暂无" }}
          </div>
          <div class="td">
            {{ item.deviceName || "暂无" }}
          </div>
          <div class="td">
            {{ item.organizationName && String(item.organizationName).replace('芜湖', '').replace('合肥', '') || "暂无" }}
          </div>
          <!-- <div class="td special">
            {{ item.duration_str || "暂无" }}
          </div> -->
          <div class="td special">
            {{ item.flightSorties || "暂无" }}
          </div>
          <!-- 隐藏掉操作按钮 -->
          <div style="flex: 0.5; display: flex; justify-content: center" v-if="false">
            <el-tooltip effect="dark" :content="item.cardStatus == 1 ? '已报备' : '报备'" placement="top">
              <div @click="handle_click(item, item.cardStatus)">
                <div class="bbjl"></div>
                <!-- <div class="yijian_baobei" :class="item.cardStatus == 1 ? 'gay' : ''">
                  <span class="iconfont icon-yijianbaobei"></span>
                </div> -->
              </div>
            </el-tooltip>
            <el-tooltip effect="dark" content="编辑" placement="top">
              <div style="flex: 0.5; display: flex; justify-content: center" @click="handle_edit(item.id)">
                <div class="bjjl"></div>
                <!-- <div class="yijian_baobei">
                  <span class="iconfont icon-a-bianji2px"></span>
                </div> -->
              </div>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top">
              <div @click="handle_del(item.id)">
                <div class="scjl"></div>
                <!-- <div class="yijian_baobei">
                  <span class="iconfont icon-shanchu"></span>
                </div> -->
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";

export default {
  props: {
    search: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      batterylist: [],
    };
  },
  watch: {
    search: {
      handler(value) {
        console.log("------value", value);
        this.getBatteryList();
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.getBatteryList();
  },
  methods: {
    async handle_click(e, num) {
      if (num == 1) {
        this.$message({
          message: "已经报备,请勿重复报备!",
          type: "warning",
        });
      } else {
        await API.USER.ResourceReport([{ deviceId: e.id, type: "3" }]);
        this.$el_message("报备成功");
        this.getBatteryList();
      }
    },
    async handle_edit(val) {
      const permissions = this.$store.state.user.user_info.permissions;
      const permissionList = permissions.map((item) => item.permissionSign);
      if (permissionList.indexOf("equipment:flight:battery:edit") == -1) {
        this.$message.error("暂无权限操作");
      } else {
        let res = await API.BATTERY.Detail(val);
        this.$emit("getDetail", res);
      }
    },
    handle_del(id) {
      const permissions = this.$store.state.user.user_info.permissions;
      const permissionList = permissions.map((item) => item.permissionSign);
      if (permissionList.indexOf("equipment:flight:battery:remove") == -1) {
        this.$message.error("暂无权限操作");
      } else {
        this.$el_confirm("确定要删除设备吗？", () => {
          API.BATTERY.EditStatus(id).then((res) => {
            this.$el_message("删除成功");
            this.getBatteryList();
          });
        });
      }
    },
    async getBatteryList() {
      let res = await API.DEVICE.batteryList(this.search);
      this.batterylist = res.data || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-device-battery {
  background: rgba(8, 26, 58, 0.8);
  border-radius: 4px;

  /* border: 1px solid #315EC7; */
  .tb-box {
    box-sizing: border-box;
    height: 100%;

    .tb-hd-box {
      display: flex;
      justify-content: space-between;
      line-height: 42px;
      background: #2f4369;

      .tb-hd {
        height: 100%;
        flex: 1;
        font-size: 16px;
        font-family: YouSheBiaoTiHei;
        color: #ebf9ff;
        line-height: 42px;
        letter-spacing: 1px;
        text-align: center;

        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }

      .special {
        flex: 1.2;
      }
    }

    .tb-bd-box {
      height: calc(100% - 52px);
      overflow: auto;

      .tb-tr {
        display: flex;
        box-sizing: border-box;
        margin-bottom: 2px !important;
        height: 40px;
        background: #283b5d;
        /* &.table-light {
            background: rgba(73, 135, 210, 0.2);
          } */

        &:hover {
          cursor: pointer;
          background: #394d5f;

          .yijian_baobei {
            background: transparent;
          }
        }

        .td {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          padding: 10px 1px;
          text-align: center;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #dce9ff;
          font-weight: 400;
          word-break: break-word;
          box-sizing: border-box;
        }

        .special {
          flex: 1.2;
        }

        .iconfont {
          font-size: 18px;
          color: #00aeff;
        }

        .disable {
          opacity: 0.7;
          pointer-events: none;
        }
      }
    }
  }

  /deep/.el-image__inner {
    width: 70px;
    height: 70px;
  }

  /deep/.el-image__error {
    width: 70px;
    height: 70px;
  }
}

.yijian_baobei {
  // background: rgba(28, 164, 195, 0.5);
  // padding: 9px 8px 9px 8px;
  // height: 19px;
  // width: 70px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #dce9ff;
  text-align: center;

  .iconfont {
    color: rgba(28, 164, 195, 0.5) !important;
  }
}

/deep/ .tb-tr:hover {
  // height: 56px;
  background: rgba(20, 45, 92, 0.6);
  box-shadow: inset 0px 0px 20px 0px rgba(0, 182, 255, 0.7);
  align-items: center !important;

  .td {
    font-family: MicrosoftYaHei !important;
    color: #00c6ff !important;
  }

  // .yijian_baobei {
  //   background: #1ca4c3;
  // }
}

/deep/ .tb-bd-box .tb-tr {
  align-items: center !important;
  margin-bottom: 0 !important;

  &.table-light {
    background: rgba(73, 135, 210, 0.2);
  }
}

/deep/ .tb-box {
  background: rgba(15, 11, 62, 0.5) !important;
}

/deep/ .gay {
  // background-color: gray !important;
  .iconfont {
    color: gray !important;
  }
}

/deep/ .gay :hover {
  // background-color: gray !important;
}

.baobei {
  display: flex;
  justify-content: center;
}

.wbjl {
  margin-top: 3px;
  width: 28px;
  height: 30px;
  background: red;
  // background: url("~@/assets/images/device/wbd.png") no-repeat;
  background: url("~@/assets/img/i136.png") no-repeat;
  background-position: 50%;
  background-size: 100% 100%;

  &:hover {
    // background: url("~@/assets/images/device/wb.png") no-repeat;
    background: url("~@/assets/img/i136-1.png") no-repeat;
    background-position: 50%;
  }
}

.bbjl {
  margin-top: 3px;
  width: 28px;
  height: 30px;
  background: red;
  // background: url("~@/assets/images/device/wbd.png") no-repeat;
  background: url("~@/assets/img/i139.png") no-repeat;
  background-position: 50%;
  background-size: 100% 100%;

  &:hover {
    // background: url("~@/assets/images/device/wb.png") no-repeat;
    background: url("~@/assets/img/i139-1.png") no-repeat;
    background-position: 50%;
  }
}

.bjjl {
  margin-top: 3px;
  width: 28px;
  height: 30px;
  background: red;
  // background: url("~@/assets/images/device/wbd.png") no-repeat;
  background: url("~@/assets/img/i137.png") no-repeat;
  background-position: 50%;
  background-size: 100% 100%;

  &:hover {
    // background: url("~@/assets/images/device/wb.png") no-repeat;
    background: url("~@/assets/img/i137-1.png") no-repeat;
    background-position: 50%;
  }
}

.scjl {
  margin-top: 5px;
  width: 26px;
  height: 28px;
  background: red;
  // background: url("~@/assets/images/device/wbd.png") no-repeat;
  background: url("~@/assets/img/i138.png") no-repeat;
  background-position: 50%;
  background-size: 100% 100%;

  &:hover {
    // background: url("~@/assets/images/device/wb.png") no-repeat;
    background: url("~@/assets/img/i138-1.png") no-repeat;
    background-position: 50%;
  }
}
</style>