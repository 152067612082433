<!-- 鹰视 -->
<!-- 无人机应用与机巢应用 -->
<template>
  <div
    class="cpt-box_sidebar pr"
    :class="{
      collapse,
      left: direction == 'left',
      right: direction == 'right',
      TrafficTask: bgImgStyle == 'TrafficTask',
      defaultImg: bgImgStyle != 'TrafficTasks',
    }"
    :style="containerStyle"
  >
    <div class="dingbu">
      <img src="~@/assets/newImage/diguang.png" alt="" />
    </div>
    <div class="diguang">
      <img src="~@/assets/newImage/diguang.png" alt="" />
    </div>
    <!-- bgImgStyle=='TrafficTask'? '':'' -->
    <div class="box-sidebar-inner-wrap">
      <div class="box-sidebar_inner">
        <slot></slot>
      </div>
    </div>
    <img
      v-if="showCollapseBtn"
      class="icon-collapse"
      :class="{ left: direction == 'left', right: direction == 'right' }"
      @click="handle_collapse(direction)"
      :src="collapse ? rightSrc : leftSrc"
      alt="collapse_l"
    />
  </div>
</template>

<script>
export default {
  props: {
    // 背景图片
    bgImgStyle: {
      type: String,
      default: () => "",
    },
    containerStyle: {
      type: Object | String,
      default: () => "",
    },
    direction: {
      type: String,
      default: () => "left",
    },
    isCollapse: {
      type: Boolean,
      default: () => false,
    },
    showCollapseBtn: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      collapse: false,
      leftSrc: require("@/assets/images/collapse_l.svg"),
      rightSrc: require("@/assets/images/collapse_r.svg"),
    };
  },
  inject: ["g_cesium_toolbar"],
  watch: {
    isCollapse: {
      handler(value) {
        this.collapse = Boolean(value);
      },
      immediate: true,
    },
  },
  methods: {
    handle_collapse(direction) {
      if (direction == "left") {
        this.g_cesium_toolbar().moveToolbar();
      }
      this.collapse = !this.collapse;
      this.$emit("collapse", this.collapse);
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-box_sidebar {
  position: absolute;
  top: 73px;
  bottom: 0;
  z-index: 9;
  padding: 2rem 1.6rem 1rem 2rem;
  // width: 400px;
  width: 480px;
  box-sizing: border-box;
  transform: translateX(0);
  transition: 0.3s;
  position: relative;
  &.left {
    left: 0;
    &.collapse {
      transform: translateX(calc(-100% + 8px));
    }
  }
  &.right {
    right: -6px;
    &.collapse {
      transform: translateX(100%);
    }
  }

  .box-sidebar-inner-wrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    // overflow-y: auto;
    // overflow-x: hidden;
    .box-sidebar_inner {
      width: 100%;
      height: 100%;
      // min-height: 950px;
      box-sizing: border-box;
    }
  }

  .icon-collapse {
    height: 80px;
    position: absolute;
    top: 50%;
    z-index: 9;
    opacity: 0.5;
    cursor: pointer;
    &.left {
      right: -8px;
      transform: translate(calc(100% - 12px), -50%);
    }
    &.right {
      left: 0;
      transform: rotateY(180deg) translate(calc(100% - 3px), -50%);
    }
  }
}
.defaultImg {
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  padding: 0px;
  /deep/.title_left_decorate {
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }
  /deep/.box-sidebar-item-bd {
    padding: 0 20px;
    margin-bottom: 10px;
    color: #b3f2f5;
    .tb-tr:hover {
      background: rgba(0, 23, 79, 0.3) !important;
      box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    }
  }
}
.diguang {
  position: absolute;
  bottom: -43px;
  left: 0;
  width: 100%;
  z-index: -98;
  img {
    width: 100%;
  }
}
.dingbu {
  position: absolute;
  top: -32px;
  left: 0;
  width: 100%;
  z-index: -98;
  img {
    width: 100%;
  }
}
</style>
