<template>
  <div class="card-container">
    <div class="line line1">
      <div class="item">
        <!-- <div class="f">{{ data.status == 1 ? '进行中' : '待执行' }}</div> -->
        <div class="f" :style="{ color: statusColor[get_status(data)], borderColor: statusColor[get_status(data)] }"> {{
          get_status(data) }}</div>
        <div class="n">{{ data.taskTitle }}</div>
      </div>
    </div>

    <div class="line line2">
      <div class="item item1">
        <span>航线名称：{{ data.airlineTitle }}</span>
      </div>
      <div class="item item2">
        <span>任务类型：{{ data.taskCateTitle }}</span>
      </div>
    </div>

    <div class="line line3">

      <div class="item item1">
        <div class="ww5">
          <img src="@/assets/img/i4.png" alt="">
          <span>{{ data.organizationTitle }}</span>
        </div>
        <div class="ww5">
          <img src="@/assets/img/i115.png" alt="">
          <span>{{ data.startedAt }}</span>
        </div>
      </div>

      <div class="item item2">
        <img src="@/assets/img/i8.png" alt="">
        <span>{{ data.team && data.team.length ? data.team.join("、") : "暂无" }}</span>
      </div>

    </div>

    <div class="line line5">
      <el-tooltip effect="dark" content="下载" placement="top">
        <!-- :style="{ cursor: data.taskStatus == 1 ? 'pointer' : 'not-allowed' }" -->
        <!-- :class="[imgFlag == 1 ? 'img-selected' : '']" -->
        <img src="@/assets/img/i9.png" alt="" @click="imgCilckHandle(0, data)">
      </el-tooltip>
      <el-tooltip effect="dark" content="详情" placement="top">
        <img src="@/assets/img/i10.png" alt="" @click="imgCilckHandle(1, data)">
      </el-tooltip>
      <el-tooltip effect="dark" content="定位" placement="top">
        <img src="@/assets/img/i6.png" alt="" @click="imgCilckHandle(2, data)">
      </el-tooltip>
      <el-tooltip effect="dark" content="航线预览" placement="top">
        <img src="@/assets/img/i11.png" alt="" @click="imgCilckHandle(3, data)">
      </el-tooltip>
      <el-tooltip effect="dark" content="图片" placement="top">
        <img src="@/assets/img/i5.png" alt="" @click="imgCilckHandle(4, data)">
      </el-tooltip>
      <el-tooltip effect="dark" content="历史轨迹" placement="top">
        <img src="@/assets/img/i12.png" @click="imgCilckHandle(5, data)">
      </el-tooltip>
      <el-tooltip effect="dark" content="删除任务" placement="top">
        <img src="@/assets/img/i13.png" @click="imgCilckHandle(6, data)">
      </el-tooltip>
    </div>

  </div>
</template>

<script>
import { downloadData } from "@/utils/download";
import { mapGetters } from "vuex";
import Bus from '@/assets/ligature';
import API from "@/api";
export default {
  inject: [
    "flight_task_chage_view",
    "handle_video_Player",
    "handle_air_line_preview",
    "handle_location",
    "handle_image",
    "handle_histoty_track",
    "change_cate_id",
  ],
  props: {
    data: {
      type: Object,
      default: {}
    },
    taskType: {
      type: String,
      default: '常态任务',
    }
  },
  computed: {
    ...mapGetters(["user_info"]),
    isJH () {
      return this.user_info && this.user_info.departmentId == 2;
    },
  },
  mounted () {

  },
  data () {
    return {
      imgFlag: -1,
      statusColor: {
        待执行: "#FFBD36",
        执行中: "#00B4FF",
        已完成: "#00D45C",
        待审批: "#FFBD36 ",
        审批不通过: "#F42E2E",
      },
    }
  },
  methods: {
    async imgCilckHandle (v, item) {
      console.log(item, '飞行任务，卡片数据');
      this.imgFlag = v;
      switch (v) {
        // 下载
        case 0:
          if (item.taskStatus != 1) {
            this.$message({
              type: 'info',
              message: '暂无下载数据'
            });
            return;
          }
          downloadData(
            `/hawksystemserver/export/normalcyDownload/${item.id}?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`,
            this.taskType
          );
          break;
        // 详情
        case 1:
          console.log('详情');
          let res = await API.TASK.FlightDetail(item.id);
          this.$store.commit("task/FLIGHT_DETAIL", res);
          this.flight_task_chage_view(8);
          break;
        // 定位
        case 2:
          this.handle_location(item, 2);
          break;
        // 航线预览
        case 3:
          this.handle_air_line_preview(item, 1);
          break;
        // 图片
        case 4:
          if (item.applyStatus == 1 &&
            item.papplyStatus == 1 &&
            item.executeStatus == 1) {
            this.handle_image(item);
          } else {
            this.$message({
              type: 'info',
              message: '无历史图片'
            });
          }
          break;
        // 历时轨迹
        case 5:
          if (item.applyStatus == 1 &&
            item.papplyStatus == 1 &&
            item.executeStatus == 1) {
            console.log('历时轨迹');
            this.handle_histoty_track(item);
          } else {
            this.$message({
              type: 'info',
              message: '无历史轨迹'
            });
          }
          break;
        // 删除
        case 6:
          this.$emit('delete', item);
          break;
      }
    },
    get_status (item) {
      let val = item;
      let text = "";
      if (val.applyStatus == 1 && val.papplyStatus == 1) {
        text = "审批通过";

        if (val.executeStatus == 0) {
          text = "待执行";
        } else if (val.executeStatus == 1) {
          text = "已完成";
        } else {
          text = "执行中";
        }
      } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
        text = "审批不通过";
      } else {
        text = "待审批";
      }
      return text;
    },
  },
}
</script>

<style lang="scss" scoped>
.card-container {
  width: 100%;
  height: 169px;
  background: #163B53;
  border-radius: 4px;
  padding: 5px 0 0 0;

  .line {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  .line1 {
    height: 18%;
    // background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;

    .item {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .f {
        border: 1px solid #3CE171;
        padding: 2px 5px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3CE171;
        width: 11%;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
      }

      .n {
        // margin-lef;
        margin-left: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D5DBE0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .item-right {
      display: flex;
      justify-content: flex-end;

      .f1 {
        border: 1px solid #3CE171;
        padding: 2px 5px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3CE171;
      }
    }
  }

  .line2 {
    height: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .item {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #D5DBE0;
      }
    }

    .item1 {
      justify-content: flex-end;
    }

    .item2 {
      justify-content: flex-start;
      margin-top: 8px;
    }
  }

  .line3 {
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .item {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 18px;
        height: 18px;
      }

      span {
        margin-left: 5px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(99, 130, 163, 1);
      }
    }

    .item1 {
      width: 100%;
      // background-color: #3CE171;
      display: flex;
      align-items: center;
      justify-content: center;

      .ww5 {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .item2 {
      margin-top: 2px;
    }
  }

  .line5 {
    height: 30%;
    // background-color: rgb(200, 255, 0);
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      // width: 34px;
      width: 28px;
      // height: 34px;
      height: 28px;
      // background-color: #3CE171;
      box-sizing: border-box;
      padding: 5px;
      border-radius: 4px;
      cursor: pointer;
    }

    .img-selected {
      background: #274F69;
      border-radius: 4px;
    }
  }
}
</style>