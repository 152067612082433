<template>
  <div class="cpt-command-device-status distance-between-1">
    <div class="flexs justify-between items-center my-header">
      <div class="flexs height-">
        <div class="waikuang" @click="comeAndGout">
          <!-- <img src="~@/assets/images/equipmentImges/sbcrktj.png" style="width: 16px; height: 16px; margin-right: 4px"
            alt="" /> -->
          <span>设备出入库统计</span>
        </div>
        <div class="waikuang active waikuang-selected">
          <!-- <img src="~@/assets/images/equipmentImges/sbzttj.png" style="width: 15px; height: 15px; margin-right: 4px"
            alt="" /> -->
          <span>设备状态统计</span>
        </div>
      </div>
      <div class="header">
        <!-- <div class="hd-box">设备状态统计</div> -->
        <el-select popper-class="popper-select-panel-info-person" v-model="type" class="input-excute"
          placeholder="请选择设备类型">
          <el-option label="无人机" :value="1"></el-option>
          <el-option label="机巢" :value="2"></el-option>
          <el-option label="挂载" :value="3"></el-option>
          <el-option label="电池" :value="4"></el-option>
        </el-select>
      </div>
    </div>
    <div class="xian"></div>
    <div class="bd-box-chart">
      <div id="state_container"></div>
    </div>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import API from "@/api";
export default {
  data () {
    return {
      stateData: [],
      type: 1,
      chart: null,
    };
  },
  async mounted () {
    await this.getStateInfo();
    this.init(this.stateData, "state_container");
  },
  watch: {
    async type (newVal) {
      await this.getStateInfo();
      this.chart.destroy();
      this.init(this.stateData, "state_container");
    },
  },
  methods: {
    comeAndGout () {
      this.$parent.stateTong();
    },
    async getStateInfo () {
      const obj = {
        repair: "维修",
        normal: "正常",
        damage: "损坏",
        care: "保养",
      };
      let res = await API.DEVICE.StateInfo({ type: this.type });
      res.forEach((item) => {
        item.status = obj[item.status];
      });
      this.stateData = res;
    },
    init (data, dataName) {
      let _this = this;
      let count_arr = [];
      let tickInterval = 2;
      data.forEach((item) => {
        count_arr.push(item.count);
      });
      let max_count = Math.max(...count_arr);
      if (max_count <= 10) {
        tickInterval = 2;
      } else if (max_count > 10 && max_count <= 20) {
        tickInterval = 4;
      } else if (max_count > 20 && max_count <= 30) {
        tickInterval = 6;
      } else {
        tickInterval = 8;
      }
      const chart = (this.chart = new Chart({
        container: dataName,
        autoFit: true,
        height: 200,
      }));

      chart.data(data);

      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });

      chart.scale("count", {
        nice: true,
        tickInterval: tickInterval,
      });

      chart.legend({
        position: "top",
        itemName: {
          style: {
            fill: "#fff",
          },
        },
      });

      chart.axis("count", {
        grid: {
          line: {
            type: "line",
            show: false,
            style: {
              // fill:'#ff0000',
              stroke: "#fff", //网格线颜色
              opacity: 0.0, //网格线透明度
              lineDash: [3, 3], //虚线
            },
          },
        },
      });

      chart
        .interval()
        .position("name*count")
        .color("status", [
          "l(90) 0:#94FAFD 1:#3370EE",
          "l(90) 0:#ACFFFE 1:#10C37F ",
          "l(90) 0:#F6D56B 1:#D86810 ",
          "l(90) 0:#EFFB8C 1:#F6BD16",
        ])
        .adjust([
          {
            type: "dodge",
            marginRatio: 0,
          },
        ])
        .size(12);

      chart.interaction("active-region");
      chart.interaction("element-single-selected", {
        start: [{ trigger: "element:mousemove", action: "cursor:pointer" }],
        end: [{ trigger: "element:mouseleave", action: "cursor:default" }],
      });
      chart.render();

      chart.on("element:click", async (ev) => {
        console.log("---------ev?.data?.data", ev.data.data);
        const obj1 = {
          1: 4,
          2: 5,
          3: 6,
          4: 7,
        };
        // 无人机保养字段对应的是5,这里不做更改，在请求接口的时候处理
        const obj2 = {
          正常: 1,
          损坏: 2,
          维修: 3,
          保养: 4,
        };
        if (ev?.data?.data) {
          let {
            data: {
              data: { id, status },
            },
          } = ev;
          console.log(id, obj1[_this.type], obj2[status], "status");
          _this.$emit("handleChart", id, obj1[_this.type], obj2[status]);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-device-status {
  // height: 318px;
  // background: rgba(12, 34, 73, 0.7);
  // border-radius: 10px;
  // border: 1px solid rgba(26, 92, 246, 0.5);
  // -webkit-backdrop-filter: blur(1px);
  // backdrop-filter: blur(1px);
  // padding: 16px 16px 34px;
  // box-sizing: border-box;
  width: 100%;
  height: 100%;

  .header {
    // display: flex;
    // justify-content: space-between;
    // margin-top: 25px;
  }

  .my-header {
    height: 36px;
    width: 100%;
    // border: 1px solid yellow;
    // border: 1px solid yellow;

    .flexs {
      width: 100%;
      height: 100%;
    }
  }

  .hd-box {
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
  }

  ::v-deep {
    .el-select {
      // width: 162px;

      .el-input__inner {
        width: 170px;
        height: 32px;
        background: #02173d;
        border-radius: 2px;
        border: 1px solid #315ec7;
      }
    }
  }
}

.bd-box-chart {
  // height: 100%;
  // margin-top: 24px;
  overflow: auto;
  // border: 1px solid yellow;
  height: calc(100% - 36px);
  padding: 15px 30px 15px 30px;
  box-sizing: border-box;

  #state_container {
    width: 100%;
    height: 100%;
  }
}

.flexs {
  display: flex;
  padding: 0px 7px;
  box-sizing: border-box;
}

.waikuang {
  height: 36px;
  // border: 1px rgba(49, 94, 199, 1) solid;
  // border-radius: 3px;
  // background-color: rgba(2, 23, 61, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2d9ff;
  cursor: pointer;
  // padding: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 14px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #9197A6;
  width: 40%;
  background-image: url('~@/assets/img/i128.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // border: 1px solid yellow;

  &.active {
    // background-image: url("~@/assets/images/monitoring/btn_active.png");
    // background-size: 100% 100%;
  }

  &:hover {
    // background-image: url("~@/assets/images/monitoring/btn_active.png");
    // background-size: 100% 100%;
  }
}

.waikuang-selected {
  background-image: url('~@/assets/img/i65.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #ffffff;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.btn-start {
  background: rgba(20, 45, 92, 0.6);
  box-shadow: 1px 1px 2px 0px rgba(3, 16, 50, 0.5);
  border-radius: 6px;
  opacity: 0.6;
  border: 1px solid #43deff;
}

.xian {
  // width: 100%;
  // height: 1px;
  // background: linear-gradient(207deg,
  //     rgba(36, 146, 252, 0) 0%,
  //     #2492fc 53%,
  //     rgba(36, 146, 252, 0) 100%);
  // margin: 16px 0 0 0;
}

.distance-between {
  padding: 16px 24px 0 24px;
}

.imgs {
  width: 24px;
  height: 24px;
}
</style>
