import API from "@/api";
import dayjs from "dayjs";

export default {
  async list() {
    // console.log("123456");
    let { pagination } = this.airway;
    let { data, labelName, pageNo, pageSize, totalCount } =
      await API.AIRWAY.List({
        ...pagination,
      });
    let list = data || [];
    if (list && list.length) {
      list = list.map((item) => ({
        ...item,
        // addTime: dayjs(item.addTime).format("YYYYMMDD"),
      }));
    }

    this.airway.list = list;
    this.labelName = labelName;
    this.airway.pagination.pageNo = pageNo;
    this.airway.pagination.pageSize = pageSize;
    this.airway.pagination.totalCount = totalCount;
  },

  async add(options, type) {
    if (!options.destination && type !== 'autoPlan') {
      this.$message.warning("请选择目标位置");
      return;
    }
    let num = parseInt(JSON.parse(options.flightCourseJson).line.baseSpeed);
    let _this = this;
    let situationDetail = this.situation_detail;
    let optionsObj = Object.assign({}, options);
    let newData = JSON.parse(optionsObj.flightCourseJson);
    newData.line.baseSpeed = num;
    optionsObj.flightCourseJson = JSON.stringify(newData);
    let { status, message, data } = await API.AIRWAY.Add(optionsObj);
    situationDetail.flight_line_id = data;
    this.$store.commit("situation/SITUATION_DETAIL", situationDetail);
    if (status == 1) {
      this.$el_message("创建成功", () => {
        this.airway.add.visible = false;
        this.airway_list();
        if (this.is_from_situation_edit) {
          this.change_flightTask_view();
        }
      });
    } else if (status == 102) {
      this.$confirm("是否申请跨电子围栏空域飞行?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let { status } = await API.AIRWAY.ApplyFlightLineAuth({
            id: data.flight_line_span_id,
            status: 1,
          });
          if (status == 0) {
            this.$message({
              type: "success",
              message: "申请成功!",
            });
          }
          this.airway.add.visible = false;
          this.airway_list();
          if (this.is_from_situation_edit) {
            this.airway.add.visible = false;
            this.change_flightTask_view();
          }
        })
        .catch(async () => {
          let { status } = await API.AIRWAY.Delete({
            id: data.flight_line_span_id,
          });
          this.airway.add.visible = false;
          this.airway_list();
          if (status == 1) {
            this.$message('取消申请,航线未规划');
          }
          if (this.is_from_situation_edit) {
            this.airway.add.visible = false;
            this.change_flightTask_view();
          }
        });
    } else if (status == 101) {
      this.$message.error(message);
    }
  },

  page_change(page) {
    this.airway.pagination.pageNo = page;
    this.airway_list();
  },

  size_change(size) {
    this.airway.pagination.pageSize = size;
    this.airway_list();
  },

  search(value) {
    this.airway.pagination.flightName = value.flightName;
    // this.airway.pagination.labelName = Flight
    this.airway.pagination.sourceType = value.sourceType;
    this.airway.pagination.isSafe = value.isSafe;
    this.airway.pagination.type = value.type;
    this.airway.pagination.labelName = value.labelName;
    this.airway.pagination.userName = value.userName;
    this.airway_list();
  },
};
