import airway from "./airway"
import station from "./station"
import warning from "./warning"
import alarm from "./alarm"

export default {
    airway,
    station,
    warning,
    alarm
}