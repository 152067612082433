<template>
  <div class="wih100 ht100">
    <Situation
      v-if="situation.edit.visible == 0"
      :pageNo="number"
      @handleClick="handle_click"
      @handleLocation="situation_handle_position"
    />
    <SituationEdit
      v-show="situation.edit.visible == 1"
      ref="SituationEditRef"
      @handleClick="situation.edit.visible = 0"
      @handleLocation="situation_handle_position"
    />
    <SituationDetail
    :status="task_status"
      v-if="situation.edit.visible == 2"
      @handleClick="situation.edit.visible = 0"
    />
  </div>
</template>

<script>
import Situation from "@/components/command/situation";
import SituationEdit from "@/components/command/situation/edit";
import SituationDetail from "@/components/command/situation/detail";
import data from "./data";
import methods from "./methods";

export default {
  data() {
    return {
      task_status:null,
      ...data,
      number: 1,
    };
  },
  methods: {
    ...methods,
    handle_click(item, state, flag, pageNo,status) {
      console.log(status,'222');
      this.number = pageNo;
      this.task_status=status
      this.$emit("handle_click");
      this.$refs.SituationEditRef.getDetail();
      this.situation.edit.visible = state;
      this.$refs.SituationEditRef.flag = flag;
      if (item) {
        this.$refs.SituationEditRef.ruleForm.applyStatus =
          item && item.applyStatus;
        this.$refs.SituationEditRef.ruleForm.papplyStatus =
          item && item.papplyStatus;
      }
    },
  },
  components: { Situation, SituationEdit, SituationDetail },
  inject: ["g_cesium_toolbar", "g_cesium_layer"],
  mounted() {
    this.g_cesium_toolbar().moveToolbar("left");
    this.situation.edit.visible = 0;
  },
};
</script>

<style lang="scss" scoped></style>
