<template>
  <div class="cpt-command-airtask-edit">
    <div class="title-box" v-interact>任务编辑</div>
    <img
      src="~@/assets/images/accident/close.png"
      class="img-close"
      @click="change_page"
    />
    <div class="form-box">
      <div class="form-inner-box">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="任务名称" prop="taskTitle">
            <el-input
              v-model="form.taskTitle"
              placeholder="请输入任务名称"
              clearable
            />
          </el-form-item>
          <el-form-item label="航线名称" prop="flightLineId">
            <el-select
              v-model="form.flightLineId"
              placeholder="请选择航线名称"
              clearable
            >
              <el-option
                v-for="item in drd.airway"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              <span style="float: left">{{ item.title }}</span>
              <span style="float: left; color: #00b900; font-size: 13px">{{ item.issafe == 1 ? '（安全）' : '' }}</span>
              </el-option>
              </el-select>
          </el-form-item>
          <!-- <el-form-item label="任务类别" prop="taskCateId">
            <el-select
              v-model="form.taskCateId"
              placeholder="请选择任务类别"
              clearable
            >
              <el-option
                v-for="cate in drd.cate"
                :key="cate.id"
                :label="cate.title"
                :value="cate.id"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item label="任务类型" prop="taskTypeId">
            <el-select
              v-model="form.taskTypeId"
              placeholder="请选择任务类型"
              clearable
            >
              <el-option
                v-for="type in drd.type"
                :key="type.id"
                :label="type.title"
                :value="type.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="责任单位" prop="organizationId">
            <el-cascader
              placeholder="请选择所属机构"
              v-model="form.organizationId"
              :options="drd.department"
              :show-all-levels="false"
              :props="{
                children: 'children',
                label: 'orgName',
                value: 'id',
                checkStrictly: true,
                emitPath: false,
              }"
              clearable
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="飞手" prop="flyIds">
            <el-select
              v-model="form.flyIds"
              placeholder="请选择飞手"
              multiple
              clearable
            >
              <el-option
                v-for="team in drd.team"
                :key="team.id"
                :label="team.title"
                :value="team.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="电池编号" prop="batteryNumbers">
            <el-select
              v-model="form.batteryNumbers"
              clearable
              placeholder="请选择电池编号"
              :popper-append-to-body="false"
              multiple
              :multiple-limit="2"
            >
              <el-option
                v-for="item in drd.batteryList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预计开始时间" prop="started_at">
            <el-date-picker
              v-model="form.started_at"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择预计开始时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="内容描述" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="2"
              placeholder="请输入内容描述"
            >
            </el-input>
          </el-form-item>
          <el-form-item class="btns">
            <el-button @click="handle_edit">重新提交</el-button>
            <el-button @click="handle_cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
export default {
  props: ["taskCateId"],

  data() {
    return {
      drd: {
        airway: [],
        handle_type: [
          { title: "申报", id: 0 },
          { title: "报备", id: 1 },
        ],
        cate: [
          { title: "常态任务", id: 2 },
          { title: "临时紧急任务", id: 3 },
        ],
        type: [],
        department: [],
        team: [],
        batteryList: [],
      },
      form: {
        taskTitle: undefined,
        flightLineId: undefined,
        taskCateId: undefined,
        taskTypeId: undefined,
        organizationId: undefined,
        flyIds: [],
        batteryNumbers: [],
        remark: undefined,
        started_at: null,
        applyStatus: null,
        papplyStatus: null,
        id: null,
        userId: null,
      },
    };
  },

  computed: {
    ...mapGetters(["user_info", "flight_detail"]),
  },

  methods: {
    getDetail() {
      const keys = Object.keys(this.form);
      if (this.flight_detail.batteryNumbers) {
        this.flight_detail.batteryNumbers =
          this.flight_detail.batteryNumbers.split(",") || [];
      }

      this.flight_detail.taskTypeId = this.flight_detail.taskTypeTittleId;
      keys.forEach((item) => {
        this.form[item] = this.flight_detail[item];
      });
    },
    async drd_batteryList() {
      let data = await API.BATTERY.List();
      this.drd.batteryList = data || [];
    },
    async drd_airway() {
      let res = await API.AIRWAY.Drd();
      this.drd.airway = res || [];
    },
    async drd_type() {
      let res = await API.TASK.DrdType();
      this.drd.type = res || [];
    },
    async drd_department() {
      let res = await API.DEPARTMENT.Drd();
      this.drd.department = [res] || [];
    },
    async drd_team() {
      let res = await API.USER.DrdPilot();
      this.drd.team = res || [];
    },

    async handle_edit() {
      try {
        const keys = Object.keys(this.form);
        const form = JSON.parse(JSON.stringify(this.form));
        form.batteryNumbers = form.batteryNumbers.join(",");
        keys.forEach((item) => {
          if (form[item] == this.flight_detail[item]) {
            if (
              item != "id" &&
              item != "applyStatus" &&
              item != "papplyStatus"
            ) {
              delete form[item];
            }
          }
        });
        await API.TASK.FlightEdit({ ...form, taskCateId: this.taskCateId });
        this.$emit("cancel");
        this.$el_message("修改成功");
      } catch (error) {
        this.$el_message("修改失败", () => {}, "error");
      }
    },

    handle_cancel() {
      this.$refs["form"] && this.$refs["form"].resetFields();
      this.$emit("cancel");
    },
  },
  async mounted() {
    await this.drd_airway();
    await this.drd_type();
    await this.drd_department();
    await this.drd_team();
    await this.drd_batteryList();
    this.getDetail();

    // this.user_info && (this.form.organizationId = this.user_info.departmentId);
  },

  inject: ["change_page"],
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixins.scss";
.cpt-command-airtask-edit {
  position: absolute;
  width: 640px;
  height: 746px;
  box-sizing: border-box;
  background: url("~@/assets/images/plate_bg_01.png") no-repeat;
  background-size: 100% 100%;
  .img-close {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .title-box {
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
    height: 56px;
    box-sizing: border-box;
    display: flex;
    line-height: 3.2;
    padding-left: 132px;
    @include linear_gradient_border(auto, 0, 0, 0);
  }
  .form-box {
    height: calc(100% - 54px);
    box-sizing: border-box;
    padding: 20px 80px 20px 60px;
    .form-inner-box {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      ::v-deep .el-form {
        .el-form-item {
          .el-form-item__label {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #ccedff;
            text-align: right;
            font-weight: 400;
          }
          .el-form-item__content {
            background: #000000;
            border: 1px solid #08c2d1;
            .field-group {
              display: flex;
              align-items: center;
              .el-icon-location-outline {
                color: #08c2d1;
                font-size: 26px;
                cursor: pointer;
                width: 34px;
              }
            }
            .el-input {
              .el-input__inner {
                background: transparent;
                border: none;
                color: #08c2d1;
              }
            }
            .el-textarea {
              background: #000000;
              border: 1px solid #08c2d1;
              .el-textarea__inner {
                background: transparent;
                border: none;
                color: #08c2d1;
              }
            }
            .el-button {
              width: 114px;
              height: 40px;
              background: #129c9c;
              font-family: PangMenZhengDao;
              font-size: 22px;
              color: #ffffff;
              text-align: center;
              font-weight: 400;
              border: none;
              border-radius: 0;
            }
          }
          &.btns {
            .el-form-item__content {
              background: none;
              border: none;
            }
          }
          .el-date-editor.el-input {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
