<!-- 鹰控 -->
<!-- 空域管理 -->
<!-- 首页安全管制 更多跳转页面 -->
<template>
  <div class="page-command-situation pr">

    <!-- <transition name="panel-data-fade"> -->
    <div class="con" v-show="flodFlag">
      <div class="header" v-show="false">

        <div class="flex" v-if="false">
          <img class="header__icon" src="@/assets/zong_lan_ye_img/frame/icon.png" alt="" />
          <div class="hd-box">空域管理</div>
        </div>

        <div class="jcsb">

          <div class="mr16" style="color: #fff">低空限制</div>
          <img class="header__add-icon" :title="gridTitle" v-show="gridShowFlag"
            src="~@/assets/images/command/airspace_icon.png" @click="gridShowFun" />
          <img class="header__add-icon" :title="gridTitle" v-show="!gridShowFlag"
            src="~@/assets/images/command/airspace_icon_active.png" @click="gridShowFun" />
          <div class="header__close" @click="change_page" v-show="false">关闭</div>
        </div>
        <img src="@/assets/img/i38.png" alt="" class="img-flod" @click="flod(false)">
      </div>
      <div class="bd-box">
        <div class="tb-box">
          <div class="tb-hd-box">
            <div class="tb-hd tb-hd1">空域名称</div>
            <div class="tb-hd tb-hd2">空域类型</div>
            <div class="tb-hd tb-hd3">空域状态</div>
            <div class="tb-hd tb-hd4 shijian">更新时间</div>
            <div class="tb-hd tb-hd5 jj">操作</div>
          </div>
          <div class="tb-bd-box">
            <div class="tb-tr" :class="i % 2 == 0 ? 'tr-odd' : 'tr-even'" v-for=" (item, i) in list" :key="item.id">
              <div class="td td1">
                {{ item.name || "暂无" }}
              </div>
              <div class="td td2">{{ item.typeName || "暂无" }}</div>
              <div class="td td3 biaozhun" :class="{ caizi: item.status != 1 }">
                {{ item.status == 1 ? "正常" : "禁用" }}
              </div>
              <div class="td td4 shijian">{{ item.editTime || "暂无" }}</div>
              <div class="td td5">
                <!-- <div class="btn1027" @click="handleClick(item)">预览</div> -->
                <div class="action">
                  <el-tooltip content="查看" placement="top">
                    <span :class="{ active: item.__selected }" class="el-icon-view" @click="handleClick(item)"></span>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
          <Airspace v-show="airspaceFlag" ref="AirspaceRef" @getList="getList" @close="airspaceFlag = false"></Airspace>
        </div>
        <div class="tb-pagination">
          <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="pagination.pageNo"
            :page-size="pagination.pageSize" layout="prev, pager, next,  sizes,  jumper" :total="pagination.totalCount"
            @size-change="handle_size_change" @current-change="handle_page_change">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- </transition> -->
    <div class="div-img-un-flod" v-show="!flodFlag">
      <img src="@/assets/img/i37.png" alt="" class="img-un-flod" @click="flod(true)">
    </div>
  </div>
</template>

<script>
import API from "@/api";
import Airspace from "./airspace.vue";
import { Utils } from "@/lib/cesium";
import { nanoid } from "nanoid";
import { lang } from "moment";
let positions = [];
let handler = null;
let data_srouce = null;
let location_icon = null;
let noFlyEntities = [];
let electricFenceEntities = [];
let point_index = null;

let isGetAddress = false;
let point_uuid = null;
let point_uuid_next = null;
let isEditting = true;
export default {
  data () {
    return {
      qianVal: "", // 页码跳转输入框
      list: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      airline: {
        filename: null,

        line: {
          // baseSpeed: 10,
          baseSpeed: 2,
        },
        points: [],
      },
      form: {},
      visibleFlag: true,
      airspaceFlag: false,
      action: {
        visible: false,
        types: [
          {
            label: "悬停(s)",
            input: "el-input-number",
            min: 0,
            max: 32000,
            actionType: "STAY",
            actionParam: 10,
          },
          {
            label: "拍照",
            actionType: "START_TAKE_PHOTO",
            actionParam: 0,
          },
          {
            label: "开始录像",
            actionType: "START_RECORD",
            actionParam: 0,
          },
          {
            label: "停止录像",
            actionType: "STOP_RECORD",
            actionParam: 0,
          },
        ],
        list: [],
        curr_index: 0,
      },
      gridShowFlag: true,
      gridTitle: '显示网格',
      flodFlag: true,
    };
  },
  inject: ["change_page", "g_cesium_layer"],
  components: { Airspace },
  destroyed () {
    // this.destroy_cesium_handler();
    // this.clear_entities();
  },
  methods: {
    // 计算总页码
    zongye (num) {
      let a = num / 10;
      var result = a.toString().indexOf(".");
      if (result != -1) {
        let b = parseInt(a);
        return b + 1;
      } else {
        return a;
      }
    },
    async init_cesium_handler () {
      this.airline = {
        filename: null,
        line: {
          // baseSpeed: 10,
          baseSpeed: 2,
        },
        points: [],
      };
      this.destroy_cesium_handler();
      let viewer = window.viewer;
      let currentPoint;
      // 修复规划航线时
      viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );

      if (data_srouce) {
        data_srouce.entities.removeAll();
        viewer.dataSources.remove(data_srouce, true);
        data_srouce = null;
        positions = [];
      }
      // 创建实体
      data_srouce = new Cesium.CustomDataSource("airway_edit");

      await viewer.dataSources.add(data_srouce);

      let entities = data_srouce.entities;

      let polyline = entities.add({
        polyline: {
          material: Cesium.Color.RED,
          width: 3,
          positions: new Cesium.CallbackProperty(() => {
            return positions.map((item) => {
              let wgs84 = Utils.transformCartesian2WGS84(item);
              return Utils.transformWGS842Cartesian({
                lng: wgs84.lng,
                lat: wgs84.lat,
                alt: wgs84.alt + 10,
              });
            });
          }, false),
        },
      });

      // viewer.scene.globe.depthTestAgainstTerrain = true;
      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction(async (movement) => {
        let windowPosition = movement.position;
        let pickedObject = viewer.scene.pick(windowPosition);
        if (pickedObject) {
        }
        if (
          Cesium.defined(pickedObject) &&
          pickedObject.id?.name === "airline_point"
        ) {
          // 航点编辑
          // let entity = pickedObject.id;
          // point_uuid = entity.id;
          // let points = this.airline.points;
          // let index = points.findIndex((item) => item.uuid === entity.id);
          // if (index > -1) {
          //   point_index = index;
          //   if (index + 1 < points.length) {
          //     point_uuid_next = points[index + 1].uuid;
          //   }
          //   this.handler_current_point(index);
          // }
          // currentPoint = entity;
          // isEditting = true;
          // viewer.scene.screenSpaceCameraController.enableRotate = false;
          // viewer.scene.screenSpaceCameraController.enableZoom = false;
        } else {
          // 航点添加
          let position = Utils.getCartesian3FromPX(viewer, movement.position);
          if (Cesium.defined(position) && this.visibleFlag) {
            // this.reset_form();
            if (positions.length + 1) {
              let points = this.airline.points;
              if (this.action.curr_index <= points.length + 1) {
                this.action.curr_index = points.length + 1;
              } else {
                this.action.curr_index += 1;
              }
            }

            positions.push(position);
            // let wgs84 = Utils.transformCartesian2WGS84(position);
            // this.form.lon = wgs84.lng;
            // this.form.lat = wgs84.lat;
            let uuid = (this.form.uuid = nanoid());
            // console.log(uuid, "uuid");
            this.handle_save_point();

            if (positions.length > 1) {
              let before = positions[positions.length - 2];
              let after = positions[positions.length - 1];

              this.create_label(uuid, before, after);
            }
            this.create_point(uuid, position);
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      let __this = this;
      handler.setInputAction(function (event) {
        let positionsArr = JSON.parse(JSON.stringify(positions));
        let arr = [];
        let str = "";
        if (positionsArr.length <= 2)
          return __this.$message.info("航点必须在三个以上!");
        for (let i = 0; i < positionsArr.length; i++) {
          str += __this.transformationCartesian3(positionsArr[i]) + " ";
          arr.push(...__this.transformationCartesian3(positionsArr[i]));
        }

        __this.$refs.AirspaceRef.form = {
          name: "", //空域名称
          color: "red", //颜色
          category: 15,
          pid: 246158,
          region: str,
          regionList: arr,
          regionType: "POLYGON",
          tier: 1, //层
          typeId: 1,
          noFlyStartTime: "", //开始时间
          noFlyEndTime: "", //结束时间
        };
        __this.airspaceFlag = true;
        __this.airline = {
          filename: null,
          line: {
            // baseSpeed: 10,
            baseSpeed: 2,
          },
          points: [],
        };
        __this.destroy_cesium_handler();
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
      // 对鼠标移动事件的监听
      // handler.setInputAction((event) => {
      //   console.log('aaa',currentPoint)
      //   if (isEditting && currentPoint) {
      //     // let windowPosition = event.startPosition;
      //     // let ellipsoid = viewer.scene.globe.ellipsoid;
      //     // let cartesian = viewer.camera.pickEllipsoid(windowPosition, ellipsoid);
      //     let cartesian = Utils.getCartesian3FromPX(
      //       viewer,
      //       event.startPosition
      //     );

      //     if (!cartesian) {
      //       return;
      //     }
      //     currentPoint.position = cartesian;

      //     if (!(typeof point_index == undefined) && positions?.length > 1) {
      //       positions[point_index] = cartesian;
      //       let current_wgs84 = Utils.transformCartesian2WGS84(cartesian);
      //       this.airline.points[point_index].lon = current_wgs84.lng;
      //       this.airline.points[point_index].lat = current_wgs84.lat;
      //       if (point_index === this.action.curr_index) {
      //         this.form.lon = current_wgs84.lng;
      //         this.form.lat = current_wgs84.lat;
      //       }

      //       if (point_index > 0) {
      //         this.update_label(
      //           point_uuid,
      //           cartesian,
      //           positions[point_index - 1]
      //         );
      //       }
      //       if (point_uuid_next) {
      //         this.update_label(
      //           point_uuid_next,
      //           cartesian,
      //           positions[point_index + 1]
      //         );
      //       }
      //     }
      //   }
      // }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      // // 对鼠标抬起事件的监听
      // handler.setInputAction((event) => {
      //   console.log(event,'321312312');
      //   point_index = null;
      //   point_uuid_next = null;
      //   isEditting = false;
      //   currentPoint = undefined;
      //   // viewer.render()
      //   setTimeout(() => {
      //     viewer.scene.screenSpaceCameraController.enableRotate = true;
      //     viewer.scene.screenSpaceCameraController.enableZoom = true;
      //   }, 300);
      // }, Cesium.ScreenSpaceEventType.LEFT_UP);
    },
    clear_entities () {
      noFlyEntities.forEach((item) => {
        viewer.entities.remove(item);
      });
      electricFenceEntities.forEach((item) => {
        viewer.entities.remove(item);
      });
      noFlyEntities = [];
      electricFenceEntities = [];
    },
    transformationCartesian3 (positions) {
      let ellipsoid = viewer.scene.globe.ellipsoid;
      let cartesian3 = new Cesium.Cartesian3(
        positions.x,
        positions.y,
        positions.z
      );
      let cartographic = ellipsoid.cartesianToCartographic(cartesian3);
      let lng = Cesium.Math.toDegrees(cartographic.longitude);
      let lat = Cesium.Math.toDegrees(cartographic.latitude);
      return [lng, lat];
    },
    update_label (uuid, before, after) {
      let entities = data_srouce.entities;
      let id = `label_${uuid}`;
      let entity = entities.getById(id);

      let before_wgs84 = Utils.transformCartesian2WGS84(before);
      let after_wgs84 = Utils.transformCartesian2WGS84(after);

      let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      let position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10,
      });
      let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      entity.position = position;
      entity.label.text = text;
    },
    create_label (uuid, before, after, alt) {
      if (data_srouce) {
        let entities = data_srouce.entities;
        let before_wgs84 = Utils.transformCartesian2WGS84(before);
        let after_wgs84 = Utils.transformCartesian2WGS84(after);

        let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
        let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
        let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

        let position = Utils.transformWGS842Cartesian({
          lng: center_lng,
          lat: cetner_lat,
          alt: alt + 10,
        });

        let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

        entities.add({
          id: `label_${uuid}`,
          position: position,
          label: {
            text: text,
            scale: 0.5,
            font: "bold 30px Microsoft YaHei",
            fillColor: Cesium.Color.fromCssColorString("#fff"),
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: true,
            backgroundPadding: new Cesium.Cartesian2(10, 10),
          },
        });
      }
    },

    destroy_cesium_handler () {
      let viewer = window.viewer;
      viewer.scene.globe.depthTestAgainstTerrain = false;

      if (handler) {
        // handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        handler && handler.destroy() && (handler = null);
        handler = null;
      }

      if (data_srouce) {
        data_srouce.entities.removeAll();
        viewer.dataSources.remove(data_srouce, true);
        data_srouce = null;
        positions = [];
      }
    },
    create_point (uuid, position) {
      let wgs84 = Utils.transformCartesian2WGS84(position);
      let points = this.airline.points;
      if (data_srouce && this.visibleFlag) {
        let entities = data_srouce.entities;
        entities.add({
          name: "airline_point",
          id: uuid,
          position: Utils.transformWGS842Cartesian({
            lng: wgs84.lng,
            lat: wgs84.lat,
            alt: wgs84.alt + 10,
          }),
          point: {
            pixelSize: 20,
            color: Cesium.Color.RED,
            fillColor: Cesium.Color.RED,
            // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND, // supermap版本会导致拖动显示错误
          },
          label: {
            text: new Cesium.CallbackProperty(() => {
              let index = points.findIndex((item) => item.uuid === uuid);
              return String(index + 1);
            }, false),
            scale: 0.5,
            font: "bold 24px Microsoft YaHei",
            fillColor: Cesium.Color.WHITE,
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            showBackground: false,
            outlineWidth: 0,
          },
        });
      }
    },
    handle_size_change (size) {
      this.pagination.pageSize = size;
      this.getList();
    },
    handler_current_point (index) {
      let points = this.airline.points;
      this.action.curr_index = index;
      this.init_point(points[this.action.curr_index]);
    },
    handle_page_change (page) {
      this.pagination.pageNo = page;
      this.getList();
    },
    init_point (data) {
      Object.keys(data).map((key) => {
        if (this.form.hasOwnProperty(key)) {
          this.form[key] = data[key];
        }
      });
    },
    handleClick (item) {
      console.log('handleClick', item)
      item.visible = false;
      this.$emit("handleShow", item);
    },
    handle_save_point () {
      this.airline.points.push({ ...this.form });
    },
    handleHide (item) {
      item.visible = true;
      this.$emit("handleHide", item);
    },
    async getList () {
      let { data, pageNo, pageSize, totalCount } = await API.AIRSPACE.List({
        ...this.pagination,
      });
      this.list =
        data.map(
          (item) =>
          (item = {
            ...item,
            visible: true,
          })
        ) || [];
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
    },
    /**
     * 点击低空限制 显示或者隐藏网格
     * 2023年6月28日10:06:44
     * **/
    gridShowFun () {
      this.gridShowFlag = !this.gridShowFlag;
      // 如果显示，则定位到网格处
      if (!this.gridShowFlag) {
        window.viewer.camera.flyTo({
          // destination: Cesium.Cartesian3.fromDegrees(120.218241, 33.165767, 21812.321476599747),
          destination: Cesium.Cartesian3.fromDegrees(120.02073, 32.5, 80000.321476599747),
          orientation: {
            heading: 0.006918732929549698,
            pitch: -0.6928665494487145,
            roll: 7.638334409421077e-14,
          },
          time: 3,
        });
      }
      if (this.gridTitle == '显示网格') {
        this.gridTitle = '隐藏网格'
      } else {
        this.gridTitle = '显示网格'
      }
      this.$emit('hedgehop');
      window.viewer.scene.forceRender();
    },
    /**
     *@Description: 展开或者关闭低空限制面板
     *@Date: 2023-11-01 10:36:32
     *@Params1: false 关闭， true 打开
     *@Return1: 
    */
    flod (flag) {
      this.flodFlag = flag;
    }
  },

  mounted () {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.page-command-situation {
  transform: none !important;
  width: 100%;
  height: auto;
  // position: fixed;
  top: 0;
  left: 0;
  // border: 1px solid yellow;
  box-sizing: border-box;
  background-color: #0A293A !important;
  background-color: transparent;
  // padding: 0 5px;
  display: flex;

  .con {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    // transform: translate(1088.96px, 421.636px);


    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      // padding-left: 15px;
      background-color: #224D68;
      box-sizing: border-box;
      padding: 0 5px;

      .icon-linshijinfeiqu {
        font-size: 18px;
        color: rgba(151, 151, 151, 1);
      }

      .hd-box {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        background-image: -webkit-linear-gradient(right, #e3aa77, #f5cda9, #f9ecd3, #fcdbb1, #edb07a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 0;
        font-weight: 700;
      }

      .img-flod {
        // position: fixed;
        // top: 0;
        // left: 500px;
        width: 24px;
        height: 20px;
        cursor: pointer;
      }
    }

    .bd-box {
      height: auto;
      box-sizing: border-box;
      padding: 0;
      // border: 1px solid red;
      width: 100%;

      .tb-box {
        box-sizing: border-box;
        margin: 5px 0;

        .tb-hd-box {
          display: flex;
          justify-content: space-between;
          height: 40px;
          font-size: 16px;
          background-color: #163B53;
          box-sizing: border-box;
          padding: 0 5px;
          width: 100%;


          .tb-hd {
            // flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
          }

          .tb-hd1 {
            width: 25%;
          }

          .tb-hd2 {
            width: 18%;
          }

          .tb-hd3 {
            width: 18%;
          }

          .tb-hd4 {
            width: 25%;
          }

          .tb-hd5 {
            width: 14%;
            justify-content: center;
          }
        }

        .tb-bd-box {
          // height: 385px;
          overflow: auto;

          .tb-tr {
            display: flex;
            box-sizing: border-box;
            height: 40px;
            padding: 0 5px;

            &.tb-tr--color {
              background: rgba(73, 135, 210, 0.2);
            }

            .td {
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              box-sizing: border-box;

              font-size: 12px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #EBF9FF;

              .iconfont {
                font-size: 20px;
                cursor: pointer;
                margin-right: 10px;
              }
            }

            .td1 {
              width: 25%;
            }

            .td2 {
              width: 18%;
            }

            .td3 {
              width: 18%;
            }

            .td4 {
              width: 25%;
            }

            .td5 {
              width: 14%;
              justify-content: center;
            }

            .el-icon-view {
              font-size: 15px !important;
            }
          }

          .tr-odd {
            background-color: #133144;
          }

          .tr-even {
            background-color: #163B53;
          }

        }
      }
    }

    .tb-pagination {
      height: 30px;
      display: flex;
      align-items: center;
      margin: 16px 0 11px 0px;

      ::v-deep .el-pagination {
        height: 30px;
        display: flex;
        align-items: center;

        button {
          background: transparent;
          font-size: 12px;
          color: #889fb2;
          text-align: center;
          font-weight: 400;

          &:disabled {
            background: transparent;
            color: #889fb2;
            opacity: 0.3;
          }
        }

        .el-pagination__total {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #00ffff;
          letter-spacing: 0;
          line-height: 16px;
          font-weight: 400;

          text-align: center;
          height: 30px;
          line-height: 30px;
          display: inline-block;
          margin: 0px;
        }

        .el-pagination__sizes {
          height: 30px;
          line-height: 30px;
          margin: 0px;

          .el-select {
            .el-input {
              width: 60px;
              padding-right: 20px;
              margin: 0;

              .el-input__inner {
                background: transparent;
                border: none;
                line-height: 30px;
                font-size: 12px;
                color: #b5e5ff;
                text-align: center;
                font-weight: 400;
                padding: 0px;
              }
            }
          }
        }

        .el-pager {
          .number {
            background: transparent;
            font-size: 12px;
            color: #889fb2;
            text-align: center;
            font-weight: 400;

            &.active {
              // background: #00b6ff;
              background: #1890FF;
              border-radius: 2px;
              color: #ffffff !important;
            }
          }

          .more {
            background: transparent;
            color: #889fb2;
          }
        }

        .el-pagination__jump {
          font-size: 12px;
          color: #889fb2;
          text-align: center;
          font-weight: 400;
          height: 30px;
          line-height: 30px;
          margin-left: 6px;

          .el-input {
            .el-input__inner {
              border: 1px solid rgba(36, 146, 252, 0.3);
              border-radius: 2px;
              background: rgba(12, 13, 20, 0.5);
              color: #889fb2;
            }
          }
        }
      }

      ._jumper {
        font-size: 12px;
        color: #889fb2;
        text-align: center;
        font-weight: 400;
        display: flex;
        align-items: center;
        white-space: nowrap;

        ::v-deep .el-input {
          .el-input__inner {
            background: rgba(12, 13, 20, 0.5);
            border: 1px solid rgba(36, 146, 252, 0.3);
            border-radius: 2px;
            width: 33px;
            height: 24px;
            color: #889fb2;
            padding: 0;
            text-align: center;
          }
        }

        ._jumper_btn {
          width: 51px;
          height: 22px;
          background: #333c57;
          border: 1px solid $primary-border-color;
          border-radius: 2px;
          padding: 0px;
          font-size: 14px;
          color: $primary-color;
          letter-spacing: -0.08px;
          text-align: center;
          font-weight: 400;
          margin-left: 4px;
        }
      }
    }
  }

  .div-img-un-flod {
    position: fixed;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #224D68;
    cursor: pointer;
    top: 0;
    left: 0;

    .img-un-flod {
      width: 24px;
      height: 20px;
    }
  }


}

// ------------------------------------------------
.tb-bd-box::-webkit-scrollbar {
  width: 0 !important;
}

.flex {
  display: flex;
}

.xian {
  width: 3px;
  height: 15px;
  background: #ffbd36;
  margin: 3px 0 0 0;
}

.header__close {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #D2DFFF;
  margin-right: 16px;
  cursor: pointer;
}

.jcsb {
  display: flex;
  align-items: center;
}

.iconfont {
  margin: 0 40px 0 0px;
}

.tb-tr:hover {
  // background: rgba(2, 19, 96, 0.2);
  // box-shadow: inset 0px 0px 10px 2px #3fcbff;
  // border: 1px solid #70f6f9;
}

.caizi {
  color: #ffbd36 !important;
}

.biaozhun {
  font-size: 14px !important;
  color: #00d45c !important;
  line-height: 19px;
}

.shijian {
  // width: 34%;
  white-space: nowrap;
}

.jj {
  // margin: 0 6px 0 0;
}

// 动画
.animate {
  padding-left: 20px;
  // font-size: 12px;
  // color: #000;
  display: inline-block;
  white-space: nowrap;
  animation: 5s wordsLoop linear infinite normal;
}

@keyframes wordsLoop {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
    // -webkit-transform: translateX(-100%);
  }
}

// 页签样式
.zongji {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #b5e5ff;
  line-height: 16px;

  .tiaoshu {
    color: #43deff;
  }
}

.douhao {
  margin: 0 5px 0 5px;
}

.dangqianye {
  margin: 0 20px 0 0;
}

/deep/.el-pager .number:hover {
  background: #00b6ff !important;
  border-radius: 2px;
  color: #000 !important;
  width: 14px;
  height: 22px;
  line-height: 22px;
}

/deep/ .el-pagination--small .el-pager .number {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
}

/deep/.active {
  color: #000 !important;
}

.btnqueding {
  margin: 0 0 0 8px;
  width: 79px;
  height: 32px;
  background: rgba(23, 70, 216, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
    inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
    inset 0px 0px 4px 0px #00a7ff;
  border-radius: 6px;
  border: 0px solid;
  border-image: linear-gradient(180deg,
      rgba(138, 218, 255, 1),
      rgba(82, 179, 255, 0)) 1 1;
  line-height: 1px;
}

.tb-pagination {
  margin: 0 0 19px 0;
}

.zhuan {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #889fb2;
  line-height: 16px;

  input {
    width: 48px;
    min-width: 48px;
    max-width: auto;
    text-align: center;
    height: 28px;
    background: rgba(12, 13, 20, 0.5);
    border-radius: 2px;
    border: 1px solid rgba(36, 146, 252, 0.3);
    margin: 0 5px 0 5px;
    color: #fff;
    outline: 0px solid;
  }
}

.header__icon {
  width: 19px;
  height: 25px;
  margin-right: 4px;
}

.btn1027 {
  padding-left: 16px;
  padding-right: 16px;
  ;
}

.header__add-icon {
  width: 20px;
  height: 14px;
  margin-right: 39px;
  cursor: pointer;
}
</style>
