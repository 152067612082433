<template>
  <div class="cpt-command-device">
    <div class="dcbj"></div>
    <div class="bjk">

      <div class="box top" v-show="true">
        <!-- 飞行统计 -->
        <div class="card fly-static">
          <div class="top-line"></div>

          <div class="container">
            <div class="title">飞行统计</div>
            <div class="body">
              <div class="item item1">
                <div class="corn corn1"></div>
                <div class="corn corn2"></div>
                <div class="corn corn3"></div>
                <div class="corn corn4"></div>
                <img class="img" src="@/assets/img/i73.png" alt="">
                <div class="t" v-loading='flyStatic.distance == null' element-loading-text="正在请求中"
                  element-loading-spinner="my-custom-loader" element-loading-background="rgba(0, 0, 0, 0.0)">
                  <span class="value" v-show="flyStatic.distance != null">{{ (flyStatic.distance / 1).toFixed(2)
                  }}</span>
                  <span class="name" v-show="flyStatic.distance != null">累计飞行里程(km)</span>
                </div>
              </div>
              <div class="item item2">
                <div class="corn corn1"></div>
                <div class="corn corn2"></div>
                <div class="corn corn3"></div>
                <div class="corn corn4"></div>
                <img class="img" src="@/assets/img/i71.png" alt="">
                <div class="t" v-loading='flyStatic.count == null' element-loading-text="正在请求中"
                  element-loading-spinner="my-custom-loader" element-loading-background="rgba(0, 0, 0, 0.0)">
                  <span class="value" v-show="flyStatic.count != null">{{ flyStatic.count }}</span>
                  <span class="name" v-show="flyStatic.count != null">累计飞行架次(次)</span>
                </div>
              </div>
              <div class="item item3">
                <div class="corn corn1"></div>
                <div class="corn corn2"></div>
                <div class="corn corn3"></div>
                <div class="corn corn4"></div>
                <img class="img" src="@/assets/img/i72.png" alt="">
                <div class="t" v-loading='flyStatic.time == null' element-loading-text="正在请求中"
                  element-loading-spinner="my-custom-loader" element-loading-background="rgba(0, 0, 0, 0.0)">
                  <span class="value" v-show="flyStatic.time != null">{{ flyStatic.time }}</span>
                  <span class="name" v-show="flyStatic.time != null">累计飞行时长(h)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 工作管理 -->
        <div class="card work-manage">

          <div class="top-line"></div>
          <div class="container">
            <div class="title">工作管理</div>
            <div class="body">
              <div class="item item1" v-loading='!workManageLoad1Flag' element-loading-text="正在请求中"
                element-loading-spinner="my-custom-loader" element-loading-background="rgba(0, 0, 0, 0.0)">
                <div class="corn corn1"></div>
                <div class="corn corn2"></div>
                <div class="corn corn3"></div>
                <div class="corn corn4"></div>
                <div class="name" v-show="workManageLoad1Flag">无人机工作状态统计</div>
                <div class="t" id="t1">
                </div>
              </div>
              <el-divider class="divider" direction="vertical"></el-divider>
              <div class="item item2" v-loading='!workManageLoad2Flag' element-loading-text="正在请求中"
                element-loading-spinner="my-custom-loader" element-loading-background="rgba(0, 0, 0, 0.0)">
                <div class="corn corn1"></div>
                <div class="corn corn2"></div>
                <div class="corn corn3"></div>
                <div class="corn corn4"></div>
                <div class="name" v-show="workManageLoad2Flag">机巢工作状态统计</div>
                <div class="t" id="t2">
                </div>
              </div>
              <el-divider class="divider" direction="vertical"></el-divider>
              <div class="item item3" v-loading='!workManageLoad3Flag' element-loading-text="正在请求中"
                element-loading-spinner="my-custom-loader" element-loading-background="rgba(0, 0, 0, 0.0)">
                <div class="corn corn1"></div>
                <div class="corn corn2"></div>
                <div class="corn corn3"></div>
                <div class="corn corn4"></div>
                <div class="name" v-show="workManageLoad3Flag">无人机执行任务统计</div>
                <div class="t" id="t3">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 设备出入库设备状态统计 -->
        <div class="card device-static">

          <div class="top-line"></div>
          <div class="container">
            <!-- <div class="title-con">
              <div class="title title1">设备出入库统计</div>
              <div class="title title2">设备状态统计</div>
            </div> -->
            <div class="body">
              <div v-if="storageOfEquipment" style="width: 100%;
              height: 100%;">
                <Library @handleChart="handle_chart" />
              </div>
              <div v-if="stateStatistics" style="width: 100%;
              height: 100%;">
                <DeviceStatus @handleChart="handle_chart" />
              </div>
              <!-- <DeviceStatus @handleChart="handle_chart" /> -->
            </div>
          </div>
        </div>
        <div style="width: 49.635%" v-show="false">
          <div>
            <Flight @clickChart="click_chart" />
          </div>
          <div>
            <Status @clickChart="click_chartV2" />
          </div>
        </div>
        <div style="width: 49.5%" v-show="false">
          <div>
            <Library @handleChart="handle_chart" />
          </div>
          <div>
            <DeviceStatus @handleChart="handle_chart" />
          </div>
        </div>
      </div>
      <div class="box center">
        <Common v-show="zhuangtai" ref="_zhuangtai" />
        <DroneAndNest v-show="uav" ref="_uav" />
        <div class="top-line"></div>
      </div>
      <div class="box bottom">
        <div></div>
      </div>
      <!-- 无人机设备详情 -->
      <LeftDronePopup :organId="organId" @handleClose="active = 0" v-if="active == 2" @handleClick="handle_click" />

      <!--  -->
      <DroneMountPopup @handleClose="isShow = false" v-if="isShow" :deviceId="deviceId" />

      <LeftNestPopup :organId="organId" @handleClose="active = 0" v-if="active == 1" />
      <LeftMountPopup :organId="organId" @handleClose="active = 0" v-if="active == 3" />
      <RightDronePopup :organId="organId" :state="state" @handleClose="active = 0" v-if="active == 4" />
      <RightNestPopup :organId="organId" :state="state" @handleClose="active = 0" v-if="active == 5" />
      <RightMountPopup :organId="organId" :state="state" @handleClose="active = 0" v-if="active == 6" />
      <RightBatteryPopup :organId="organId" :state="state" @handleClose="active = 0" v-if="active == 7" />

      <FlightCountPopup @handleClose="active = 0" :orgId="changeorganId" v-if="active == 8" />
      <FlightLengthPopup @handleClose="active = 0" :orgId="changeorganId" v-if="active == 9" />
      <FlightTimePopup @handleClose="active = 0" :orgId="changeorganId" v-if="active == 10" />

      <DroneLine :organId="changeorganId" :status_info="status_info" @handleClose="active = 0" v-if="active == 11" />
      <NestLine :status_info="status_info" @handleClose="active = 0" v-if="active == 12" />
      <FlightTask :organId="changeorganId" :status_info="status_info" @handleClose="active = 0" v-if="active == 13" />
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";
import Flight from "./flight";
import Status from "./status";
import Library from "./library";
import DeviceStatus from "./device-status";
import Common from "./common";
import DroneAndNest from "./drone-and-nest";
import Bus from '@/assets/ligature'

// 无人机设备详情
import LeftDronePopup from "./library/dronePopup";
// 机巢设备详情
import LeftNestPopup from "./library/nestPopup";
// 挂载设备详情
import LeftMountPopup from "./library/mountPopup";

import DroneMountPopup from "./library/dronePopup/drone-mount-popup";

import RightDronePopup from "./device-status/dronePopup";
import RightNestPopup from "./device-status/nestPopup";
import RightMountPopup from "./device-status/mountPopup";
import RightBatteryPopup from "./device-status/batteryPopup";

import FlightCountPopup from "./flight/flight-count";
import FlightLengthPopup from "./flight/flight-length";
import FlightTimePopup from "./flight/flight-time";

import DroneLine from "./status/drone-line";
import NestLine from "./status/nest-line";
import FlightTask from "./status/flight-task";
import echarts from 'echarts'

export default {
  components: {
    Flight,
    Status,
    Library,
    DeviceStatus,
    Common,
    DroneAndNest,

    LeftDronePopup,
    LeftNestPopup,
    LeftMountPopup,

    DroneMountPopup,

    RightDronePopup,
    RightNestPopup,
    RightMountPopup,
    RightBatteryPopup,

    FlightCountPopup,
    FlightLengthPopup,
    FlightTimePopup,

    DroneLine,
    NestLine,
    FlightTask,
  },
  data () {
    return {
      active: 0,
      organId: null,
      state: null,
      isShow: false,
      deviceId: null,
      status_info: null,
      flight_info: null,
      //状态栏切换
      statistics: true,
      status: false,
      storageOfEquipment: true,
      stateStatistics: false,
      zhuangtai: true,
      uav: false,
      changeorganId: null,
      flyStatic: {
        distance: null,
        count: null,
        time: null
      },
      filter: {
        organId: null,
        type: 3,
      },
      workManage: {},
      workManageLoad1Flag: false,
      workManageLoad2Flag: false,
      workManageLoad3Flag: false,
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },

  async mounted () {
    Bus.$on('fly-static', (v) => {
      console.log('v，飞行统计', v);
      
      this.flyStatic = v;
    });
    Bus.$on('work-manage', (v) => {
      this.workManage = v;
      this.workmanageT1();
      this.workmanageT2();
      this.workmanageT3();
    });
  },

  methods: {
    //无人机飞行统计和设备工作管理切换
    circle_diagram_status () {
      this.statistics = false;
      this.status = true;
    },
    circle_diagram_statistics () {
      this.statistics = true;
      this.status = false;
    },
    Equipment () {
      this.stateStatistics = true;
      this.storageOfEquipment = false;
      console.log('切换');
    },
    stateTong () {
      this.stateStatistics = false;
      this.storageOfEquipment = true;
    },
    uav_zhuangtai (num) {
      this.zhuangtai = false;
      this.uav = true;
      if (num == "1") {
        this.$refs._uav.uav_guanl();
      } else {
        this.$refs._uav.yingchao();
      }
    },
    guanli (num) {
      this.zhuangtai = true;
      this.uav = false;
      if (num == "1") {
        this.$refs._zhuangtai.dianchi();
      } else if (num == "2") {
        this.$refs._zhuangtai.guazai();
      } else {
        this.$refs._zhuangtai.jizhan();
      }
    },
    click_chart (active, data) {
      this.active = active;
      this.changeorganId = data;
    },
    click_chartV2 (active, data, orgId) {
      this.active = active;
      this.status_info = data;
      this.changeorganId = orgId;
    },
    handle_chart (id, status, state) {
      // console.log(id,status,state) 1无人机 2机巢 3挂载
      this.organId = id;
      this.active = status;
      this.state = state;
    },
    handle_click (e) {
      this.deviceId = e;
      this.isShow = true;
    },
    // 无人机工作状态统计环状图
    workmanageT1 () {
      let data = this.workManage.dataDrone;
      let total = data[0].value + data[1].value;
      let dom = document.getElementById('t1');
      let colorList = ['#66DE69', '#CBD26D'];
      let seriesdata1 = [
        {
          name: '在线',
          value: data[0].value,
          itemStyle: {
            normal: {
              color: {
                colorStops: [
                  {
                    offset: 1,
                    color: "#007AFF" // 0% 处的颜色
                  },
                  {
                    offset: 0.8,
                    color: "#038EFF" // 0% 处的颜色
                  },
                  {
                    offset: 0.4,
                    color: "#09AFFF" // 0% 处的颜色
                  },
                  {
                    offset: 0,
                    color: "#0ECAFF" // 100% 处的颜色
                  }
                ]
              },
            },
          },
        },
        {
          name: '离线',
          value: data[1].value,
          itemStyle: {
            normal: {
              color: {
                colorStops: [
                  {
                    offset: 1,
                    color: "#FFBA60" // 0% 处的颜色
                  },
                  {
                    offset: 0.8,
                    color: "#FFA747" // 0% 处的颜色
                  },
                  {
                    offset: 0.4,
                    color: "#FF9630" // 0% 处的颜色
                  },
                  {
                    offset: 0,
                    color: "#FF8014" // 100% 处的颜色
                  }
                ]
              },
            },
          },
        }
      ];

      // console.log(this.workManage, 'workManage');
      let myChart = echarts.init(dom);
      let option = {
        // backgroundColor: '#000',
        grid: {
          top: '10%',
          left: '10%',
          right: '10%',
          bottom: '10%',
          // borderWidth: 1,
          // borderColor: '#f0f0f0',
        },
        title: [{
          text: '无人机总数',
          top: '52%',
          left: '36%',
          // padding: [-15, 0, 0, -15],
          textStyle: {
            color: '#fff',
            fontSize: 12,
            fontWeight: 'bold',
          },
        },
        {
          text: total,
          top: '42%',
          left: '41%',
          // padding: [15, 0, 0, -20],
          textStyle: {
            color: '#fff',
            fontSize: 18,
            fontWeight: '400',
          },
        }],
        legend: {
          show: true,
          orient: 'horizontal',
          bottom: '2%',
          left: '10%',
          itemGap: 15,
          itemWidth: 10,
          itemHeight: 10,
          data: seriesdata1,
          formatter: function (name) {
            console.log(name, 'name');
            let str = '';
            if (name == '在线') {
              str = data[0].value;
            } else {
              str = data[1].value;
            }
            return '{a|' + name + '}{c|' + str + '}';
          },
          textStyle: {
            rich: {
              a: {
                align: 'left',
                fontSize: 12,
                color: 'rgba(255,255,255,1)',
                width: 40,
                padding: [-3, 0, 0, 0],
              },
              c: {
                align: 'left',
                fontSize: 12,
                color: 'rgba(255,255,255,1)',
                width: 30,
                padding: [-3, 0, 0, 0],
              },
            },
          },
        },
        series: {
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['40%', '45%'],
          clockwise: true,
          avoidLabelOverlap: true,
          hoverOffset: 15,
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex];
              },
            },
          },
          label: {
            show: false,
            position: 'outside',
            formatter: function (data) {
              //console.log('data',data)
              return (
                '{name|' + data.name + ':' + '\n}' + ' \n{value|' + data.percent.toFixed(0) + '%}'
              );
            },
            rich: {
              name: {
                fontSize: 10,
                color: '#ffffff',
              },
              value: {
                fontSize: 10,
                color: '#ffffff',
              },
            },
          },
          labelLine: {
            show: false,
            normal: {
              length: 5,
              length2: 20,
              align: 'right',
              lineStyle: {
                width: 1,
              },
            },
          },
          data: seriesdata1,
          seriesIndex: 0,
        },
      };
      this.workManageLoad1Flag = true;
      myChart.setOption(option)
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 机巢工作状态统计
    workmanageT2 () {
      let data = this.workManage.dataNest;
      let total = data[0].value + data[1].value;
      let dom = document.getElementById('t2');
      let colorList = ['#66DE69', '#CBD26D'];
      let seriesdata1 = [
        {
          name: '在线',
          value: data[0].value,
          itemStyle: {
            normal: {
              color: {
                colorStops: [
                  {
                    offset: 1,
                    color: "#007AFF" // 0% 处的颜色
                  },
                  {
                    offset: 0.8,
                    color: "#038EFF" // 0% 处的颜色
                  },
                  {
                    offset: 0.4,
                    color: "#09AFFF" // 0% 处的颜色
                  },
                  {
                    offset: 0,
                    color: "#0ECAFF" // 100% 处的颜色
                  }
                ]
              },
            },
          },
        },
        {
          name: '离线',
          value: data[1].value,
          itemStyle: {
            normal: {
              color: {
                colorStops: [
                  {
                    offset: 1,
                    color: "#FFBA60" // 0% 处的颜色
                  },
                  {
                    offset: 0.8,
                    color: "#FFA747" // 0% 处的颜色
                  },
                  {
                    offset: 0.4,
                    color: "#FF9630" // 0% 处的颜色
                  },
                  {
                    offset: 0,
                    color: "#FF8014" // 100% 处的颜色
                  }
                ]
              },
            },
          },
        }
      ];

      // console.log(this.workManage, 'workManage');
      let myChart = echarts.init(dom);
      let option = {
        // backgroundColor: '#000',
        grid: {
          top: '10%',
          left: '10%',
          right: '10%',
          bottom: '10%',
          // borderWidth: 1,
          // borderColor: '#f0f0f0',
        },
        title: [{
          text: '机巢总数',
          top: '52%',
          left: '39%',
          // padding: [-15, 0, 0, -15],
          textStyle: {
            color: '#fff',
            fontSize: 12,
            fontWeight: 'bold',
          },
        },
        {
          text: total,
          top: '42%',
          left: '44%',
          // padding: [15, 0, 0, -20],
          textStyle: {
            color: '#fff',
            fontSize: 18,
            fontWeight: '400',
          },
        }],
        legend: {
          show: true,
          orient: 'horizontal',
          bottom: '2%',
          left: '10%',
          itemGap: 15,
          itemWidth: 10,
          itemHeight: 10,
          data: seriesdata1,
          formatter: function (name) {
            console.log(name, 'name');
            let str = '';
            if (name == '在线') {
              str = data[0].value;
            } else {
              str = data[1].value;
            }
            return '{a|' + name + '}{c|' + str + '}';
          },
          textStyle: {
            rich: {
              a: {
                align: 'left',
                fontSize: 12,
                color: 'rgba(255,255,255,1)',
                width: 40,
                padding: [-3, 0, 0, 0],
              },
              c: {
                align: 'left',
                fontSize: 12,
                color: 'rgba(255,255,255,1)',
                width: 30,
                padding: [-3, 0, 0, 0],
              },
            },
          },
        },
        series: {
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['40%', '45%'],
          clockwise: true,
          avoidLabelOverlap: true,
          hoverOffset: 15,
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex];
              },
            },
          },
          label: {
            show: false,
            position: 'outside',
            formatter: function (data) {
              //console.log('data',data)
              return (
                '{name|' + data.name + ':' + '\n}' + ' \n{value|' + data.percent.toFixed(0) + '%}'
              );
            },
            rich: {
              name: {
                fontSize: 10,
                color: '#ffffff',
              },
              value: {
                fontSize: 10,
                color: '#ffffff',
              },
            },
          },
          labelLine: {
            show: false,
            normal: {
              length: 5,
              length2: 20,
              align: 'right',
              lineStyle: {
                width: 1,
              },
            },
          },
          data: seriesdata1,
          seriesIndex: 0,
        },
      };
      this.workManageLoad2Flag = true;
      myChart.setOption(option)
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 无人机执行任务统计
    workmanageT3 () {
      let data = this.workManage.dataTask;
      let total = data[0].value + data[1].value + data[2].value;
      let dom = document.getElementById('t3');
      let colorList = ['#66DE69', '#CBD26D'];
      let seriesdata1 = [
        {
          name: '临时任务',
          value: data[0].value,
          itemStyle: {
            normal: {
              color: {
                colorStops: [
                  {
                    offset: 1,
                    color: "#007AFF" // 0% 处的颜色
                  },
                  {
                    offset: 0.8,
                    color: "#038EFF" // 0% 处的颜色
                  },
                  {
                    offset: 0.4,
                    color: "#09AFFF" // 0% 处的颜色
                  },
                  {
                    offset: 0,
                    color: "#0ECAFF" // 100% 处的颜色
                  }
                ]
              },
            },
          },
        },
        {
          name: '常态任务',
          value: data[1].value,
          itemStyle: {
            normal: {
              color: {
                colorStops: [
                  {
                    offset: 1,
                    color: "#FFBA60" // 0% 处的颜色
                  },
                  {
                    offset: 0.8,
                    color: "#FFA747" // 0% 处的颜色
                  },
                  {
                    offset: 0.4,
                    color: "#FF9630" // 0% 处的颜色
                  },
                  {
                    offset: 0,
                    color: "#FF8014" // 100% 处的颜色
                  }
                ]
              },
            },
          },
        },
        {
          name: '警情任务',
          value: data[2].value,
          itemStyle: {
            normal: {
              color: {
                colorStops: [
                  {
                    offset: 1,
                    color: "#2AE889" // 0% 处的颜色
                  },
                  {
                    offset: 0.8,
                    color: "#1DE47B" // 0% 处的颜色
                  },
                  {
                    offset: 0.4,
                    color: "#0DE069" // 0% 处的颜色
                  },
                  {
                    offset: 0,
                    color: "#00DD5B" // 100% 处的颜色
                  }
                ]
              },
            },
          },
        }
      ];

      // console.log(this.workManage, 'workManage');
      let myChart = echarts.init(dom);
      let option = {
        // backgroundColor: '#000',
        grid: {
          top: '10%',
          left: '10%',
          right: '10%',
          bottom: '10%',
          // borderWidth: 1,
          // borderColor: '#f0f0f0',
        },
        title: [{
          text: '无人机任务总数',
          top: '52%',
          left: '31%',
          // padding: [-15, 0, 0, -15],
          textStyle: {
            color: '#fff',
            fontSize: 12,
            fontWeight: 'bold',
          },
        },
        {
          text: total,
          top: '42%',
          left: '37%',
          // padding: [15, 0, 0, -20],
          textStyle: {
            color: '#fff',
            fontSize: 18,
            fontWeight: '400',
          },
        }],
        legend: {
          show: true,
          orient: 'horizontal',
          bottom: '2%',
          left: '10%',
          itemGap: 15,
          itemWidth: 10,
          itemHeight: 10,
          data: seriesdata1,
          formatter: function (name) {
            console.log(name, 'name');
            let str = '';
            if (name == '常态任务') {
              str = data[0].value;
            } else if (name == '临时任务') {
              str = data[1].value;
            } else {
              str = data[2].value;
            }
            return '{a|' + name + '}{c|' + str + '}';
          },
          textStyle: {
            rich: {
              a: {
                align: 'left',
                fontSize: 12,
                color: 'rgba(255,255,255,1)',
                width: 40,
                padding: [-3, 0, 0, 0],
              },
              c: {
                align: 'left',
                fontSize: 12,
                color: 'rgba(255,255,255,1)',
                width: 30,
                padding: [-3, 0, 0, 20],
              },
            },
          },
        },
        series: {
          type: 'pie',
          center: ['50%', '50%'],
          radius: ['40%', '45%'],
          clockwise: true,
          avoidLabelOverlap: true,
          hoverOffset: 15,
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex];
              },
            },
          },
          label: {
            show: false,
            position: 'outside',
            formatter: function (data) {
              //console.log('data',data)
              return (
                '{name|' + data.name + ':' + '\n}' + ' \n{value|' + data.percent.toFixed(0) + '%}'
              );
            },
            rich: {
              name: {
                fontSize: 10,
                color: '#ffffff',
              },
              value: {
                fontSize: 10,
                color: '#ffffff',
              },
            },
          },
          labelLine: {
            show: false,
            normal: {
              length: 5,
              length2: 20,
              align: 'right',
              lineStyle: {
                width: 1,
              },
            },
          },
          data: seriesdata1,
          seriesIndex: 0,
        },
      };
      this.workManageLoad3Flag = true;
      myChart.setOption(option)
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

  },


};
</script>

<style lang="scss" scoped>
.dcbj {
  position: absolute;
  top: -90px;
  left: -15px;
  width: 102vw;
  height: 90vh;
  z-index: -99;
}

.cpt-command-device {
  background: url("~@/assets/images/command/device_bk.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 73px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  z-index: 9;
  width: 100%;
  height: calc(100% - 64px);

  .box {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .top {
    justify-content: space-between;
  }

  .center {
    box-sizing: border-box;
    width: 100%;
    margin: 14px 0 0 0;
    display: flex;
    // justify-content: space-between;
    padding: 16px;
    background: rgba(12, 34, 73, 0.7);
    border-radius: 10px;
    border-radius: 0;
    border: 1px solid rgba(26, 92, 246, 0.5);
    backdrop-filter: blur(1px);
    background-color: rgba(35, 47, 79, 0.9);
    border: 0;
    position: relative;

    .top-line {
      position: absolute;
      height: 2px;
      width: 100%;
      background-image: url('~@/assets/img/i66.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      top: 0;
    }
  }

  .bjk {
    padding: 14px 10px 14px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .top {
      // border: 1px solid yellow;
      box-sizing: border-box;
      width: 100%;
      // height: 100%;
      height: 40%;

      .card {
        height: 100%;
        box-sizing: border-box;

        .top-line {
          height: 2px;
          width: 100%;
          background-image: url('~@/assets/img/i66.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .container {
          width: 100%;
          height: 100%;
          background: rgba(35, 47, 79, 0.9);
          padding: 0 15px 10px 15px;
          box-sizing: border-box;

          .title {
            width: 100px;
            height: 10%;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 20px;
            background-image: url('~@/assets/img/i65.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .body {
            width: 100%;
            height: 89%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;

          }
        }
      }

      .fly-static {
        width: 20%;

        .container {
          width: 100%;
          height: 100%;
          background: rgba(35, 47, 79, 0.9);
          padding: 0 15px 10px 15px;
          box-sizing: border-box;

          .title {
            width: 100px;
            // height: 20px;
            height: 10%;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 20px;
            background-image: url('~@/assets/img/i65.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .body {
            width: 100%;
            height: 90%;
            box-sizing: border-box;
            // border: 1px solid yellow;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            flex-direction: column;
            background: rgba(35, 47, 79, 0.9);

            .item {
              width: 100%;
              height: 24%;
              // border: 1px solid yellow;
              // width: 340px;
              height: 68px;
              // background: linear-gradient(351deg, #0B517C 0%, rgba(58, 212, 224, 0.91) 100%, rgba(58, 212, 224, 0.91) 100%, #00ECFF 100%);

              background: linear-gradient(351deg, #0B517C 0%, rgba(58, 212, 224, 0.91) 100%, rgba(58, 212, 224, 0.91) 100%, #00ECFF 100%);
              position: relative;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 10px;
              box-sizing: border-box;

              .img {
                width: 25px;
                height: 25px;
              }

              .t {
                margin-left: 10px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;

                .value {
                  // width: 61px;
                  height: 24px;
                  font-size: 20px;
                  font-family: DINAlternate-Bold, DINAlternate;
                  font-weight: bold;
                  color: #00F5FF;
                  line-height: 24px;
                }

                .name {
                  // width: 96px;
                  height: 17px;
                  font-size: 12px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  font-weight: 600;
                  color: #FFFFFF;
                  line-height: 17px;
                }
              }

              /deep/ .el-loading-mask {
                width: 80px !important;

                .el-loading-spinner i {
                  // color: rgba(255, 255, 0, 0.5) !important;
                  color: #2B72EA !important;
                }

                .el-loading-text {
                  // color: rgba(255, 255, 0, 0.5) !important;
                  color: #2B72EA !important;
                  font-size: 12px;
                }
              }

              .corn {
                height: 20px;
                width: 20px;
                position: absolute
              }

              .corn1 {
                top: -1px;
                left: -1px;
                background-image: url('~@/assets/img/i67.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
              }

              .corn2 {
                top: -1px;
                right: -1px;
                background-image: url('~@/assets/img/i69.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
              }

              .corn3 {
                bottom: -1px;
                left: -1px;
                background-image: url('~@/assets/img/i68.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
              }

              .corn4 {
                bottom: -1px;
                right: -1px;
                background-image: url('~@/assets/img/i70.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
              }
            }

            .item1 {}
          }
        }
      }

      .work-manage {
        width: 46%;
        // border: 1px solid yellow;
        position: relative;

        .body {
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex-direction: row !important;

          .item {
            height: 96%;
            width: 32%;
            position: relative;
            z-index: 0;

            .t {
              width: 100%;
              height: 100%;
            }

            .name {
              position: absolute;
              top: 16%;
              left: 27%;
              z-index: 1;
              color: #fff;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
            }

            /deep/ .el-loading-mask {
              // width: 80px !important;

              .el-loading-spinner i {
                // color: rgba(255, 255, 0, 0.5) !important;
                color: #2B72EA !important;
              }

              .el-loading-text {
                // color: rgba(255, 255, 0, 0.5) !important;
                color: #2B72EA !important;
                font-size: 12px;
              }
            }
          }

          .divider {
            height: 85%;
            opacity: 0.2;
            background-color: #FFFFFF;
          }
        }
      }

      .device-static {
        width: 32%;
        // border: 1px solid yellow;

        .container {
          // padding: 0 15px 0 15px;
          padding: 0;

          .title-con {
            width: 100%;
            height: 10%;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            // line-height: 20px;
            // background-image: url('~@/assets/img/i65.png');
            // background-repeat: no-repeat;
            // background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .title {
              cursor: pointer;
            }

            .title1 {
              width: 115px;
              height: 100%;
              background-image: url('~@/assets/img/i65.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
            }

            .title2 {
              width: 115px;
              height: 100%;
              background-image: url('~@/assets/img/i65.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              filter: hue-rotate(60deg);
            }
          }

          .body {
            height: 100%;
            // border: 1px solid yellow;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.el-image-viewer__canvas {
  z-index: 999;
}
</style>
