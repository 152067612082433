<template>
  <div>
    <AirtaskList
      :taskCateId="taskCateId"
      @approve="task_approve"
      @add="task.add.visible = 1"
      @resubmit="task.add.visible = 2"
      @detail="task.add.visible = 3"
      v-if="task.add.visible == 0"
    />
    <AirTaskEdit
      :taskCateId="taskCateId"
      @submit="task_add"
      @cancel="task.add.visible = 0"
      v-if="task.add.visible == 1"
    />
    <AirTaskResubmit
      @submit="task_add"
      @cancel="task.add.visible = 0"
      :taskCateId="taskCateId"
      v-if="task.add.visible == 2"
    />
    <AirTaskDetail
      @cancel="task.add.visible = 0"
      v-if="task.add.visible == 3"
    />
  </div>
</template>

<script>
import Popup from "@/components/popup";
import AirtaskList from "@/components/command/airtask";
import AirTaskEdit from "@/components/command/airtask/edit";
import AirTaskResubmit from "@/components/command/airtask/resubmit";
import AirTaskDetail from "@/components/command/airtask/detail";

import data from "./data";
import methods from "./methods";

export default {
  props: ["taskCateId"],
  data() {
    return {
      // taskCateId:this.taskCateId,
      ...data,
    };
  },
  methods: {
    ...methods,
  },
  components: {
    Popup,
    AirtaskList,
    AirTaskEdit,
    AirTaskResubmit,
    AirTaskDetail,
  },
  inject: ["g_cesium_toolbar"],
  mounted() {
    this.g_cesium_toolbar().moveToolbar("left");
    this.task.add.visible = 0;
  },
};
</script>

<style lang="scss" scoped></style>
