
import API from "@/api"
import warning_image from "@/assets/images/icons/warning.svg"

let alarm_entity = {}
let entity_data_map = {}
let is_all = false

function getRandomColor() {
  const colors = [
    '8a2be2ff', 'ff7f50ff', 'dc143cff', '008b8bff',
    '1e90ffff', 'adff2fff', 'ffa500ff', 'db7093ff',
    'ff6347ff', '48d1ccff', 'f0e68cff', '6495edff',
    '9370dbff', 'da70d6ff', '2e8b57ff', 'ff7f50ff'
  ]
  return colors[Math.floor(Math.random() * colors.length)]
}

// 颜色转换
function fromCssColorString(param) {
  if (typeof (param) === 'object') {
    const { r, g, b, a } = param
    return Cesium.Color.fromCssColorString(`rgba(${r}, ${g}, ${b}, ${a})`)
  } else {
    let r = Number.parseInt(param.substring(0, 2), 16)
    let g = Number.parseInt(param.substring(2, 4), 16)
    let b = Number.parseInt(param.substring(4, 6), 16)
    let a = Number.parseInt(param.substring(6, 8), 16)
    return Cesium.Color.fromBytes(r, g, b, a)
  }
}

export default {

  async list(params) {
    let { pagination, search, status, selected_list } = this.alarm
    this.alarm.loading = true
    let { pageNo, pageSize, totalCount, data } = await API.TOWER.JingQingList({
      // unfinish: 1,
      ...pagination,
      // ...search,
      jingqing_title: search.name,
      ...status,
      ...params
    })
    this.alarm.loading = false
    if (data) {
      data = data.map(item => ({
        ...item,
        __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
      }))
    }

    this.alarm.list = data || []
    this.alarm.pagination.pageNo = pageNo || 1
    this.alarm.pagination.pageSize = pageSize || 10
    this.alarm.pagination.totalCount = totalCount || 0
  },
  update_list_selected() {
    let { selected_list } = this.alarm
    this.alarm.list = this.alarm.list.map(item => ({
      ...item,
      __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
    }))
  },
  change_status(params) {
    this.alarm.status = params
    this.alarm_list(params)
  },

  change_size(size) {
    this.alarm.pagination.pageSize = size
    this.alarm_list()
  },
  change_page(page) {
    this.alarm.pagination.pageNo = page
    this.alarm_list()
  },

  search(name) {
    this.alarm.search.name = name
    this.alarm_list()
  },

  change(alarm) {
    console.log(alarm)
    alarm.__selected = !alarm.__selected
    if (alarm.__selected) {
      this.alarm.selected_list.push(alarm.id)
      this.alarm_show(alarm)
    } else {
      let index = this.alarm.selected_list.findIndex(id => id === alarm.id)
      if (index !== -1) {
        this.alarm.selected_list.splice(index, 1)
      }
      this.alarm_hide(alarm)
    }

    let { list, selected_list } = this.alarm
    list = list.map(item => ({
      ...item,
      __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
    }))
    this.alarm.list = list || []
  },

  /**
   * 显示基站
   * @param {*} alarm 
   */
  show(alarm, is_all) {
    // console.log("show alarm:", alarm);
    let id = alarm.id
    let entity_data = {
      id: alarm.id,
      name: alarm.typeTitle,
      address: alarm.incidentAddress,
      content: alarm.name,
      longitude: alarm.sfjd || alarm.gisx,
      latitude: alarm.sfwd || alarm.gisy,
      addtime: alarm.addtime,
      date: new Date().getTime()
    }
    // console.log(id, alarm_entity)
    if (alarm_entity[id] && alarm_entity[id].billboard) {
      // console.log('存在')
      if (!is_all) {
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(Number(entity_data.longitude), Number(entity_data.latitude), 5000),
        })
      }
    } else {
      if (entity_data.longitude && entity_data.latitude) {
        entity_data_map[id] = entity_data
        let entity_id = JSON.stringify(entity_data)
        let position = Cesium.Cartesian3.fromDegrees(Number(entity_data.longitude), Number(entity_data.latitude))
        alarm_entity[id] = {}
        alarm_entity[id].billboard = viewer.entities.add({
          id: entity_id,
          name: 'warning',
          position,
          billboard: {
            width: 32,
            height: 32,
            image: warning_image,
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
        });
        if (!is_all) {
          this.alarm_clear_ellipse_entity()
          alarm_entity[id].ellipse = viewer.entities.add({
            name: "CircleWaveMaterialProperty",
            position: position,
            ellipse: {
              // height: 200,
              semiMinorAxis: 500,
              semiMajorAxis: 500,
              material: new Cesium.CircleWaveMaterialProperty({
                duration: 2e3,
                gradient: 0,
                color: new Cesium.Color(1.0, 0.0, 0.0, 1.0),
                count: 3
              })
            }
          });
        }
        if (!is_all) {
          console.log(Number(entity_data.longitude), Number(entity_data.latitude))
          viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(Number(entity_data.longitude), Number(entity_data.latitude), 5000.0),
          });
        }
      }
    }
  },

  /**
   * 隐藏基站
   * @param {*} alarm 
   */
  hide(alarm) {
    let id = alarm.id
    this.alarm_close(id)
  },

  /**
   * 基站分布
   */
  async distribution() {
    let data = await API.TOWER.JingQingList({
      unfinish: 1,
      pageSize: 100000
    })

    if (!is_all) {
      data.data.map(item => {
        this.alarm_show(item, true)
        return item.id
      })
      is_all = true
    } else {
      is_all = false
      this.alarm_clear_entity()
    }
  },

  close(id) {
    Object.values(alarm_entity[id]).forEach(item => {
      viewer.entities.remove(item)
    })
    alarm_entity[id] = null
  },
  clear_ellipse_entity() {
    for (let key in alarm_entity) {
      viewer.entities.remove(alarm_entity[key].ellipse)
    }
  },
  clear_entity() {
    for (let key in alarm_entity) {
      this.alarm_close(key)
    }
    alarm_entity = {}
    this.alarm.selected_list = []
    this.alarm_update_list_selected()
  }
}