<template>
  <div class="cpt-command-device-mount cpt-command-device-common">
    <div class="head">
      <!-- <span :class="Highlight ? 'active' : ''" @click="btn">执飞员报备</span>
      <span :class="!Highlight ? 'active' : ''" @click="btn">飞行助理报备</span> -->

      <div class="left-img" @click="btn" :class="[Highlight ? 'head-img-selected' : 'head-img-un-selected']">
        <span>执飞员报备</span>
      </div>
      <div class="left-img" @click="btn" :class="[!Highlight ? 'head-img-selected' : 'head-img-un-selected']">
        <span>飞行助理报备</span>
      </div>
    </div>
    <div class="search-box">
      <div class="seaLeft"></div>
      <div class="seaRight">
        <div v-if="visible" @click="userShow" class="button-nomal" style="padding: 0 15px;">
          人员登记
        </div>
        <el-cascader popper-class="personal-cascader" class="w170" v-model="filter.orgId" :options="options" clearable placeholder="请选择" style="margin-top: -3px" :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"></el-cascader>

        <el-select :popper-append-to-body="false" class="w110" v-model="filter.status" clearable placeholder="请选择" @change="handle_search">
          <el-option label="已报备" :value="1"> </el-option>
          <el-option label="未报备" :value="0"> </el-option>
        </el-select>
        <div @click="handle_search" class="button-nomal">搜索</div>
      </div>
    </div>
    <div class="content">
      <div class="containLeft" id="containLeftID">
        <div class="bd-box">
          <div class="tb-box">
            <div class="tb-hd-box" style="justify-content: space-around; padding-right: 4px">
              <div class="tb-hd-item">飞行员姓名</div>
              <div class="tb-hd-item">报备位置</div>
              <div class="tb-hd-item">报备状态</div>
              <div class="tb-hd-item">手机号</div>
              <div class="tb-hd-item">所属单位</div>
              <div class="tb-hd-item">报备时间</div>
              <!-- 隐藏掉操作按钮 v-if="visible2" 换成false-->
              <div class="tb-hd-item" v-if="false">操作</div>
            </div>
            <div class="tb-bd-box">
              <div class="tb-tr" v-for="(item, index) in list" :key="index">
                <div class="td">
                  {{ item.name || "暂无" }}
                </div>
                <div class="td">
                  {{ item.position || "暂无" }}
                </div>
                <div class="td baobei td-bb-status" :class="{ weibaobei: item.status == 1 }">
                  <div class="circle" :class="{ 'weibaobei-circle': item.status == 1 }"></div>
                  {{ item.status == 1 ? "已报备" : "未报备" || "暂无" }}
                </div>
                <div class="td">
                  {{ item.phone || "暂无" }}
                </div>
                <div class="td">
                  {{ item.anotherName || "暂无" }}
                </div>
                <div class="td" style="padding: 0 0 0 0">
                  {{ item.addtime || "暂无" }}
                </div>
                <!-- 隐藏掉操作按钮 v-if="visible2" 换成false-->
                <div class="td" v-if="false">
                  <el-tooltip effect="dark" content="编辑" placement="top">
                    <div style="flex: 0.5; display: flex; justify-content: center" @click="handle_edit(item.id)">
                      <div class="yijian_baobei">
                        <!-- <span class="iconfont icon-a-bianji2px"></span> -->
                        <span class="iconfont icon-basebianji2"></span>
                        <!-- 一键报备 -->
                      </div>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="tb-pagination">
            <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="filter.pageNo" :page-size="filter.pageSize" layout="total, prev, pager, next, sizes, jumper" :total="total" @current-change="handleSizeChange" @size-change="handlePageSizeChange">
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="containRight" id="containRight">
        <div class="tuli" v-if="false">
          <div class="li" v-for="(item, index) in tuli" :key="index">
            <div class="yuan" :style="{ background: item.color }"></div>
            <div class="fontO">{{ item.name }}</div>
          </div>
        </div>
        <div id="echart" style="height: 355px; width: 100%"></div>
      </div>
    </div>
    <!-- <UserInfo v-if="userVisible" @closeuser="shuaxin" :form="form" /> -->
  </div>
</template>

<script>
import API from "@/api";
import UserInfo from "../userinfo.vue";
import { Chart } from "@antv/g2";
import { mapGetters } from "vuex";
import { DOM } from '@/utils/dom.js';
export default {
  components: {
    UserInfo,
  },
  data() {
    return {
      Highlight: false,
      list: [],
      filter: {
        pageNo: 1,
        pageSize: 10,
        name: null,
        phone: null,
        status: null,
        type: 2,
        orgId: null,
      },
      total: 0, //表格数据总数
      options: [],
      dataFlight: [],
      chart: null,
      echartFilter: {
        type: 2,
        status: null,
        orgId: null,
      },
      xzhou: [], //x周数据
      yzhou: [], //y轴数据
      userVisible: false,
      form: null,
      visible: false,
      visible2: false,
      tuli: [],
      yanse: [
        "#1228E4FF",
        "#17ACFFFF",
        "#6DFFCBFF",
        "#2107BFFF",
        "#FAFF23FF",
        "#FFB036FF",
        "#FF9A00FF",
        "#00ff66",
        "#ffff33",
        "#66CC66",
        "#990033",
        "#99CCFF",
        "#CC9933",
        "#009933",
        "#FF9966",
        "#6699CC",
        "#66CC66",
        "#CCCCFF",
      ], //颜色库
      guoxiao: [],
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  created() {
    const permissions = this.$store.state.user.user_info.permissions;
    const permissionList = permissions.map((item) => item.permissionSign);
    if (permissionList.indexOf("system:user:add") > -1) {
      this.visible = true;
    }
    if (permissionList.indexOf("system:user:edit") > -1) {
      this.visible2 = true;
    }
  },
  async mounted() {
    this.filter.orgId = this.user_info.departmentId;
    this.echartFilter.orgId = this.user_info.departmentId;
    await this.getechartData();
    // this.init(this.dataFlight, "flight_echart");
    await this.getOptions();
    await this.getFlightCheck();
    // this.drawChart();
  },
  methods: {
    shuaxin() {
      this.userVisible = false;
      this.form = null;
      this.getFlightCheck();
    },
    userShow() {
      this.$emit("user", this.form);
    },
    /**
     *@Description: 飞行助理报备操作
     *@Author: name 
     *@Date: 2023-12-07 11:15:11
     *@Params1: 
     *@Return1: 
    */
    async handle_edit(id) {
      let res = await API.DEVICE.userDetail(id);
      // console.log(res, '飞行助理报备根据id请求返回值');
      this.form = res;
      this.$emit("user", this.form);
      this.userVisible = true;
    },
    extension(chart) {
      // 注意这里，是以X轴显示内容过长为例，如果是y轴的话，需要把params.componentType == 'xAxis'改为yAxis
      // 判断是否创建过div框,如果创建过就不再创建了
      // 该div用来盛放文本显示内容的，方便对其悬浮位置进行处理
      var elementDiv = document.getElementById("extension");
      if (!elementDiv) {
        var div = document.createElement("div");
        div.setAttribute("id", "extension");
        div.style.display = "block";
        document.querySelector("html").appendChild(div);
      }
      chart.on("mouseover", function (params) {
        if (params.componentType == "xAxis") {
          var elementDiv = document.querySelector("#extension");
          //设置悬浮文本的位置以及样式
          var elementStyle =
            "position: absolute;z-index: 99999;color: #fff;font-size: 12px;padding: 5px;display: inline;border-radius: 4px;background-color: #303133;box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px";
          elementDiv.style.cssText = elementStyle;
          elementDiv.innerHTML = params.value;
          document.querySelector("html").onmousemove = function (event) {
            var elementDiv = document.querySelector("#extension");
            var xx = event.pageX + 10;
            var yy = event.pageY - 30;
            elementDiv.style.top = yy + "px";
            elementDiv.style.left = xx + "px";
          };
        }
      });
      chart.on("mouseout", function (params) {
        //注意这里，我是以X轴显示内容过长为例，如果是y轴的话，需要改为yAxis
        if (params.componentType == "xAxis") {
          var elementDiv = document.querySelector("#extension");
          elementDiv.style.cssText = "display:none";
        }
      });
    },

    // ecahrts
    drawChart() {
      // 提炼数据
      // let a = this.dataFlight;
      // console.log(a, "下面的值");t
      this.dataFlight.forEach((item, index) => {
        this.xzhou.push(item.name);
        this.yzhou.push(item.value);
        // if (item.value == 0) {
        //   this.yzhou.push("-");
        //   // this.yzhou.push(0);
        // } else {
        //   this.yzhou.push(item.value);
        // }
      });
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      // 颜色
      // #region

      let colorNew = new this.$echarts.graphic.LinearGradient(
        0,
        0,
        1,
        0,
        [
          { offset: 0, color: "#0078F4" },
          { offset: 0.1, color: "#0078F4" },
          { offset: 1, color: "#2AE7C8" },
        ],
        false
      );
      // #endregion
      let barTopColor = [
        "#1228E4FF",
        "#17ACFFFF",
        "#6DFFCBFF",
        "#2107BFFF",
        "#FAFF23FF",
        "#FFB036FF",
        "#FF9A00FF",
        "#00ff66",
        "#ffff33",
        "#66CC66",
        "#990033",
        "#99CCFF",
        "#CC9933",
        "#009933",
        "#FF9966",
        "#6699CC",
        "#66CC66",
        "#CCCCFF",
      ];
      let barBottomColor = [
        "#1765FFFF",
        "#4FE1FFFF",
        "#18DD9BFF",
        "#A278FCFF",
        "#DE8D28FF",
        "#FF7917FF",
        "#FF0000FF",
        "#00ffcc",
        "#ffff99",
        "#99CC66",
        "#CC3366",
        "#6699FF",
        "#996633",
        "#006633",
        "#CC6600",
        "#666699",
        "#006600",
        "#9966CC",
      ];
      const myChart = this.$echarts.init(document.getElementById("echart"));
      myChart.on("click", (params) => {
        let obj = this.dataFlight.find((o) => o.name == params.name);
        this.echartFilter.orgId = obj.orgId;
        this.filter.orgId = this.echartFilter.orgId;
        this.getechartData();
        this.getFlightCheck();
      });

      console.log(this.xzhou, 'yyyyyyyyyyy');
      console.log(this.yzhou, 'z');

      // this.extension(myChart)
      let option = {
        color: [colorNew],
        grid: {
          top: "15%",
          left: "2%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },
        legend: {
          show: false,
        },
        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          type: "value",
          data: this.xzhou,
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            // show: function (v) {
            //   console.log(v, 'vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv');
            //   return true;
            // },
            // show: {
            //   formatter (v) {
            //     console.log(v, 'vvvv');
            //     return true;
            //   }
            // }
          },
          // offset: 5,
          axisLine: {
            show: false,
            // symbol: ["none", "arrow"], // 是否显示轴线箭头
            lineStyle: {
              color: "#FFFFFF",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 0, //倾斜的程度
            textStyle: {
              color: "#FFFFFF",
            },
            margin: 10, //刻度标签与轴线之间的距离。
            formatter: function (value) {
              if (value.length > 4) {
                return value.slice(0, 4) + "...";
              }
              return value;
            },
          },
          // min: 2,
        },
        yAxis: {
          type: "category",
          name: '',
          nameTextStyle: {
            padding: [0, 0, 0, -70],
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#888",
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          // offset: 5,
          axisLine: {
            show: false,
            onZero: false,
            lineStyle: {
              color: "#252D42",
              color: "#FFFFFF",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            textStyle: {
              color: "#FFFFFF",
            },
          },
          data: this.xzhou,
          // min: 2,
        },
        series: [
          {
            type: "bar",
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorNew;
                },
              },
            },
            barWidth: 5,
            data: this.yzhou,

          },
        ],
      };
      // let option = {
      //   color: [color1, color2, color3, color4, color5],
      //   backgroundColor: "transparent",
      //   grid: {
      //     top: "20%",
      //     left: "5%",
      //     right: "20%",
      //     bottom: "11%",
      //     containLabel: true,
      //   },
      //   xAxis: {
      //     data: this.xzhou,

      //     axisTick: {
      //       show: false,
      //     },
      //     axisLine: {
      //       show: true,
      //       lineStyle: {
      //         color: "#545278",
      //       },
      //     },
      //     axisLabel: {
      //       show: true,
      //       margin: 20,
      //       textStyle: {
      //         fontSize: 14,
      //         color: "#ffffff",
      //       },
      //     },
      //     interval: 0,
      //   },
      //   yAxis: {
      //     splitLine: {
      //       show: false,
      //     },
      //     axisTick: {
      //       show: false,
      //     },
      //     axisLine: {
      //       show: true,
      //       lineStyle: {
      //         color: "#545278",
      //       },
      //     },
      //     axisLabel: {
      //       show: true,
      //       margin: 10,
      //       textStyle: {
      //         fontSize: 14,
      //         color: "#ffffff",
      //       },
      //     },
      //   },
      //   series: [
      //     {
      //       name: "柱顶部",
      //       type: "pictorialBar",
      //       symbolSize: [34, 10],
      //       symbolOffset: [0, -5],

      //       z: 12,
      //       itemStyle: {
      //         normal: {
      //           color: function (params) {
      //             return barBottomColor[params.dataIndex];
      //           },
      //         },
      //       },
      //       label: {
      //         show: true,
      //         position: "top",
      //         fontSize: 16,
      //       },
      //       symbolPosition: "end",
      //       data: this.yzhou,
      //     },
      //     {
      //       name: "柱底部",
      //       type: "pictorialBar",
      //       symbolSize: [34, 10],
      //       symbolOffset: [0, 5],
      //       z: 12,
      //       itemStyle: {
      //         normal: {
      //           color: function (params) {
      //             return barTopColor[params.dataIndex];
      //           },
      //         },
      //       },
      //       data: this.yzhou,
      //     },
      //     {
      //       type: "bar",
      //       itemStyle: {
      //         normal: {
      //           color: function (params) {
      //             var colorList = [color1, color2, color3, color4, color5];
      //             return colorList[params.dataIndex];
      //           },
      //         },
      //       },
      //       z: 16,
      //       silent: true,
      //       barWidth: 34,
      //       barGap: "-100%",
      //       data: this.yzhou,
      //     },
      //   ],
      // };
      /// 不同
      // let abc = {
      //   grid: {
      //     top: "20%",
      //     left: "5%",
      //     right: "20%",
      //     bottom: "11%",
      //     containLabel: true,
      //   },
      //   xAxis: {
      //     show: true,
      //     type: "category",
      //     data: this.xzhou,
      //     axisLabel: {
      //       color: "#AAB7C3",
      //       fontSize: "13",
      //       interval: 0,
      //     },
      //     axisLine: {
      //       lineStyle: {
      //         type: "dashed", // 坐标轴线线的类型（'solid'，实线类型；'dashed'，虚线类型；'dotted',点状类型）
      //       },
      //     },
      //     axisTick: {
      //       alignWithLabel: true,
      //     },
      //   },
      //   yAxis: {
      //     type: "value",
      //     axisLabel: {
      //       //y轴文字的配置
      //       textStyle: {
      //         color: "#AAB7C3", //Y轴内容文字颜色
      //         fontSize: "12",
      //       },
      //     },
      //     axisLine: {
      //       show: false,
      //     },
      //     splitLine: {
      //       show: true,
      //       lineStyle: {
      //         type: "dashed",
      //         color: ["#AAB7C3"],
      //       },
      //     },
      //   },

      //   color: [color1, color2, color3, color4, color5],
      //   series: [
      //     {
      //       // name:,
      //       data: this.yzhou,
      //       type: "bar",
      //       backgroundStyle: {
      //         //   color: [color1, color2, color3, color4, color5],
      //       },
      //       itemStyle: {
      //         normal: {
      //           color: function (params) {
      //             var colorList = [color1, color2, color3, color4, color5];
      //             return colorList[params.dataIndex];
      //           },
      //         },
      //       },
      //       barWidth: 20,
      //     },
      //   ],
      // };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // echarts

    btn() {
      this.$emit("cut");
      this.Highlight = !this.Highlight;
    },
    init(data, dataName) {
      const chart = (this.chart = new Chart({
        container: dataName,
        autoFit: true,
        height: 280,
      }));
      chart.data(data);
      chart.coordinate("theta", {
        radius: 0.75,
      });
      chart.tooltip({
        showTitle: false,
        showMarkers: false,
      });
      chart.legend({
        pageNavigator: {
          marker: {
            style: {
              // 非激活，不可点击态时的填充色设置
              inactiveFill: "#fff",
              inactiveOpacity: 0.45,
              // 默认填充色设置
              fill: "#fff",
              opacity: 0.8,
              size: 16,
            },
          },
        },
        position: "left",
        itemName: {
          style: {
            fill: "#fff",
            fontSize: 16,
          },
        },
        itemWidth: 120,
      });
      chart.interval().adjust("stack").position("value").color("name");
      // 点击饼图
      chart.on("interval:click", async (ev) => {
        this.echartFilter.orgId = ev.data.data.orgId;
        this.filter.orgId = this.echartFilter.orgId;
        await this.getechartData();
        this.getFlightCheck();
        this.$nextTick(() => {
          this.chart.data(this.dataFlight);
          this.chart.render();
        });
        //
      });
      chart.interaction("element-active");
      chart.render();
    },
    // 更新图表数据
    async getechartData() {
      let loadingInstance = DOM.createLoading("正在加载数据", 'containRight');
      try {
        let res = await API.PERSON.getechartData(this.echartFilter);
        this.dataFlight = res.map(
          (item) =>
          (item = {
            value: item.count,
            name: item.name,
            orgId: item.organizationId,
          })
        );
        this.xzhou = [];
        this.yzhou = [];
        this.tuli = [];
        res.forEach((item, index) => {
          this.tuli.push({ name: item.name, color: this.yanse[index] });
        });
        this.drawChart();
      } catch (error) {
        console.log(error);
      } finally {
        loadingInstance.close();
      }

    },
    async getOptions() {
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
      let a = res;
      // 获取x轴数据
    },
    async handle_inform(item) {
      if (item.noticeStatus == 1) {
        return false;
      }
      let sendId = this.user_info.uid;
      let receiveId = item.id;
      await API.PERSON.Inform({ sendId, receiveId });
      this.$el_message("通知成功");
      this.getFlightCheck();
    },
    async handle_search() {
      this.getFlightCheck();
      this.echartFilter.orgId = this.filter.orgId;
      this.echartFilter.status = this.filter.status;
      await this.getechartData();
      this.chart.data(this.dataFlight);
      this.chart.render();
    },
    async getFlightCheck() {
      let loadingInstance = DOM.createLoading("正在加载数据", 'containLeftID');
      try {
        let res = await API.PERSON.Check(this.filter);
        this.list = res.data || [];
        this.total = res.totalCount;
        console.log("飞行", res);
      } catch (error) {
        console.log(error);
      } finally {
        loadingInstance.close();
      }
    },
    handleSizeChange(pageNo) {
      this.getFlightCheck();
    },
    /**
     *@Description: 页码数量发生变化
     *@Date: 2023-10-13 17:46:42
     *@Params1: 
     *@Return1: 
    */
    handlePageSizeChange(v) {
      this.filter.pageSize = parseInt(v);
      this.getFlightCheck();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/person1027.scss";

::v-deep .el-select {
  position: relative !important;

  .el-popper {
    position: absolute !important;
    top: 30px !important;
    left: 0 !important;
    z-index: 1 !important;
  }
}

.cpt-command-device-mount {
  // display: flex;
  // flex-direction: column;
  // padding: 16px;

  display: flex;
  flex-direction: column;
  // padding: 16px;
  width: 100%;
  height: 100%;
  position: relative;

  .head {
    // position: absolute;
    // left: 0px;
    // top: 0px;
    // width: 420px;
    // height: 42px;
    // background: url("~@/assets/img/content_box_title_bg.png") no-repeat;
    // background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding-left: 20px;

    span {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 42px;
      cursor: pointer;
    }

    height: 42px;
    width: 100%;
    // border: 1px solid yellow;
    box-sizing: border-box;
    padding: 0 10px;

    .left-img {
      width: 6%;
      height: 100%;

      span {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .unit-img {
      background: url("~@/assets/img/i65.png") no-repeat;
      background-size: 100% 100%;
    }

    .person-img {
      background: url("~@/assets/img/i128.png") no-repeat;
      background-size: 100% 100%;
    }

    .head-img-selected {
      background: url("~@/assets/img/i65.png") no-repeat;
      background-size: 100% 100%;
    }

    .head-img-un-selected {
      background: url("~@/assets/img/i128.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 28px;
  }

  .content {
    display: flex;
    flex-direction: row;
    // height: 388.5px;
    gap: 16px;
    width: 100%;
    margin-top: 10px;
    height: calc(100% - 52px);

    .containRight {
      width: 50%;
      height: 100%;
      background: rgba(99, 177, 245, 0.09);
      border-radius: 4px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .tuli {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        box-sizing: border-box;
        margin-top: 40px;

        .li {
          display: flex;
          align-items: center;

          .yuan {
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }

          .fontO {
            font-size: 14px;
            font-family: MicrosoftYaHeiUI;
            color: #dce9ff;
            margin: 0 0 0 11px;
          }
        }
      }
    }

    .containLeft {
      width: 50%;
      height: 100%;

      .td {
        width: calc(100% / 7);
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
      }

      .tb-hd-item {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 20px;
        width: calc(100% / 7);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .bd-box {
        // height: 390px;
        margin-top: 0px;
        background: rgba(99, 177, 245, 0.09);
        border-radius: 4px;

        width: 100%;
        height: 100%;

        .tb-box {
          height: calc(100% - 36px);
          width: 100%;
          padding: 0 12px !important;

          .tb-bd-box {
            height: calc(100% - 36px) !important;
          }
        }

        .tb-pagination {
          width: 100%;
          height: 36px;

          /deep/ .el-pagination__sizes .el-select--mini {
            width: 90px !important;
          }

          /deep/ .el-pagination__jump .el-input {
            width: 36px !important;
          }
        }
      }

      .td-bb-status {
        .circle {
          border-radius: 50%;
          width: 9px;
          height: 9px;
          background: #ffbd36;
          margin-right: 5px;
        }
      }
    }
  }
}

.search-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .seaLeft {
    display: flex;
    flex-direction: row;
  }

  .seaRight {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}

.search-box {
  position: absolute;
  top: 10px;
  right: 0;
  // border: 1px solid yellow;
}

.echarts {
  width: 100% !important;
  height: 100% !important;
}

/deep/ .el-button {
  font-family: MicrosoftYaHei !important;
  color: #dce9ff !important;
}

.tb-hd {
  font-size: 16px;
  font-family: YouSheBiaoTiHei;
  color: #ebf9ff;
  letter-spacing: 1px;
}

.baobei {
  color: #ffbd36 !important;
}

.weibaobei {
  color: #04cc0f !important;
}

.weibaobei-circle {
  background-color: #04cc0f !important;
}

.btn {
  background: url("~@/assets/images/command/personN.png") no-repeat;
  background-size: 100% 100%;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2d9ff;
  line-height: 33px;
  text-align: center;
  width: 64px !important;
  height: 33px !important;

  &:hover {
    background: url("~@/assets/images/command/twobg.png");
  }
}

.cpt-command-device-common .bd-box .tb-box .tb-bd-box {
  height: calc(100% - 60px) !important;
}

.tb-box {
  padding: 17px 0 0 0;
}

.button-nomal {
  height: 32px;
  background: #495e93;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 1px 0px 0px rgba(255, 255, 255, 0.29);
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 32px;
  cursor: pointer;
  padding: 0px 8px;
}
</style>
