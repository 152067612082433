<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-form-item label="无人机名称:" prop="deviceName">
        <el-input v-model="ruleForm.deviceName" clearable placeholder="请输入无人机名称"></el-input>
      </el-form-item>
      <el-form-item label="无人机厂商:" prop="firmId">
        <el-select popper-class="popper-select-panel-info-person" v-model="firmId" clearable @change="changFirmId"
          placeholder="请选择无人机厂商">
          <el-option v-for="item in firmList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="无人机型号:" prop="goodsId">
        <el-select popper-class="popper-select-panel-info-person" v-model="ruleForm.goodsId" clearable
          placeholder="请选择无人机型号">
          <el-option v-for="item in dronetypeList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="无人机类型:" prop="cateId">
        <el-select popper-class="popper-select-panel-info-person" v-model="ruleForm.cateId" clearable
          placeholder="请选择无人机类型">
          <el-option v-for="item in cateList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="无人机ID:" prop="deviceHardId">
        <el-input v-model="ruleForm.deviceHardId" clearable placeholder="请输入无人机ID"></el-input>
      </el-form-item>
      <el-form-item label="无人机颜色:" prop="colour">
        <el-input v-model="ruleForm.colour" clearable placeholder="请输入无人机颜色"></el-input>
      </el-form-item>
      <el-form-item label="所属飞手:" prop="teamId">
        <el-select popper-class="popper-select-panel-info-person" v-model="ruleForm.teamId" clearable
          placeholder="请选择所属飞手">
          <el-option v-for="item in flyerList" :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属机构:" prop="organizationId">
        <el-cascader popper-class="personal-cascader" v-model="ruleForm.organizationId" :options="options" clearable
          filterable :showAllLevels="false" placeholder="请选择所属机构" :props="{
            value: 'id',
            label: 'orgName',
            children: 'children',
            emitPath: false,
            checkStrictly: true,
          }"></el-cascader>
      </el-form-item>
      <el-form-item label="无人机状态:" prop="state">
        <el-select popper-class="popper-select-panel-info-person" v-model="ruleForm.state" clearable
          placeholder="请选择无人机状态">
          <el-option v-for="item in stateList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="异常信息:" prop="abnormalContent">
          <el-input
            v-model="ruleForm.abnormalContent"
            clearable
            placeholder="请输入异常信息"
          ></el-input>
        </el-form-item> -->
      <el-form-item label="配发状态:" prop="store">
        <el-radio-group v-model="ruleForm.store">
          <el-radio :label="2">入库</el-radio>
          <el-radio :label="1">出库</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="设备类型:" prop="stationType">
        <el-radio-group v-model="ruleForm.stationType">
          <div style="display: flex">
            <el-radio :label="1">飞控中心设备</el-radio>
            <el-radio :label="0">地面站设备</el-radio>
            <el-radio :label="2">机巢设备</el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="序列号:" prop="deviceSerial">
        <el-input v-model="ruleForm.deviceSerial" clearable placeholder="请输入序列号"></el-input>
      </el-form-item>
      <el-form-item label="国标编码" prop="gbbh">
        <el-input v-model="ruleForm.gbbh" clearable placeholder="请输入国标编码"></el-input>
      </el-form-item>
      <!-- <el-form-item label="上传图片:" prop="image">
          <el-upload
            :action="url"
            :http-request="handle_http_request"
            :multiple="false"
            accept=".png, .jpg"
            :file-list="fileList"
            :on-change="changePic"
            :on-remove="onRemove"
          >
            <el-button size="small" type="primary" style="margin: 0"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">图片支持jpg/png格式</div>
          </el-upload>
        </el-form-item> -->
      <el-form-item label="备注说明:" prop="comment">
        <el-input v-model="ruleForm.comment" clearable type="textarea" placeholder="请输入备注说明"></el-input>
      </el-form-item>
    </el-form>
    <div class="btn-box1027">
      <div class="btn1027" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: ["form"],
  data () {
    return {
      url: "",
      ruleForm: {
        imgFrom: 1,
        deviceipId: 1,
        store: 2,
        deviceName: null,
        goodsId: null,
        cateId: null,
        deviceHardId: null,
        organizationId: null,
        stationType: 0,
        teamId: null,
        state: 1,
        gbbh: null,
        deviceSerial: null,
        cateName: null,
        image: null,
        comment: null,
        udpStatus: 1,
      },
      rules: {
        deviceName: [
          {
            required: true,
            // validator: formRules.validateDeviceName,
            message: "请输入无人机名称",
            trigger: "blur",
          },
        ],
        // TCP: [
        //   {
        //     required: true,
        //     message: "请输入TCP协议",
        //     trigger: "blur",
        //   },
        // ],
        deviceSerial: [
          {
            required: true,
            message: "请输入序列号",
            trigger: "blur",
          },
        ],
        deviceHardId: [
          {
            required: true,
            message: "请输入无人机ID",
            trigger: "blur",
          },
        ],
        goodsId: [
          {
            required: true,
            message: "请选择无人机型号",
            trigger: "change",
          },
        ],
        organizationId: [
          {
            required: true,
            message: "请选择所属机构",
            trigger: "change",
          },
        ],
      },
      stateList: [
        {
          id: 1,
          name: "正常",
        },
        {
          id: 3,
          name: "维修",
        },
        {
          id: 2,
          name: "损坏",
        },
        {
          id: 5,
          name: "保养",
        },
      ],
      dronetypeList: [],
      cateList: [],
      flyerList: [],
      options: [],
      fileList: [],
      firmId: 1,
      firmList: [],
    };
  },
  methods: {
    async getFirmNameList () {
      let res = await API.DEVICE.getFirmNameList();
      // console.log(res,'厂商1' );
      this.firmList = res;
    },
    async changFirmId (id) {
      let data = {
        firmId: id,
      };
      let res = await API.DEVICE.getGoodsNameListByFirm(data);
      this.dronetypeList = res;
    },
    /*无人机类型 */
    // async getType() {
    //   let res = await API.DEVICE.ListTypeAll();
    //   this.dronetypeList = res;
    // },
    /*无人机型号 */
    async getCate () {
      let res = await API.DEVICE.ListCate();
      this.cateList = res;
    },
    /*机构 */
    async getOptions () {
      let res = await API.DEPARTMENT.Drd();
      // each(res);
      this.options = res || [];
    },
    //飞手列表
    async getFlyerList () {
      let data = await API.USER.DrdPilot();
      this.flyerList = data || [];
    },
    async handle_http_request (e) {
      let file = e.file,
        fd = new FormData();
      fd.append("uploadFiles", file);
      let res = await API.MAPMANAGE.fileUpload(fd);
      this.ruleForm.image = res[0].url;
    },
    submit () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleForm.id) {
            // if(res == 1){
            API.DEVICE.Edit(this.ruleForm).then((res) => {
              this.$message.success("编辑成功!");
              this.$emit("closedevice");
            });
            // }
          } else {
            API.DEVICE.Add(this.ruleForm).then((res) => {
              // if (res == 1) {
              this.$message.success("创建成功!");
              this.$emit("closedevice");
              // }
            });
          }
        } else {
          return false;
        }
      });
    },
    changePic (file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; //这一步，是 展示最后一次选择文件
      }
    },
    onRemove () {
      this.ruleForm.image = null;
    },
  },
  mounted () {
    let baseUrl = process.env.VUE_APP_BASE_API;
    this.url = baseUrl + "/upload/v2_uploads";
    if (this.form != null) {
      this.ruleForm = this.form;
    }
    // this.getType();
    this.getCate();
    this.getOptions();
    this.getFlyerList();
    this.getFirmNameList();
    this.changFirmId(this.firmId);
  },
};
</script>

<style lang="scss" scoped>
.btn {
  margin-left: 120px;
  width: 94px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  background: rgba(23, 70, 216, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(23, 33, 60, 0.5),
    inset 0px 0px 16px 0px rgba(33, 137, 255, 0.4),
    inset 0px 0px 4px 0px #00a7ff;
  border-radius: 6px;
  // border: 1px solid;
  border-image: linear-gradient(180deg,
      rgba(138, 218, 255, 1),
      rgba(82, 179, 255, 0)) 1 1;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #00d3ff;
}

.el-form-item {
  margin-bottom: 18px;
}

/deep/ .el-form-item__label {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #92d9ff;
  letter-spacing: 0;
  font-weight: 700;
}

/deep/ .el-select .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/ .el-cascader .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/ .el-textarea .el-textarea__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  // height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/ .el-input .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 316px;
  height: 38px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #43deff;
  letter-spacing: 0;
  font-weight: 400;
}

.el-select,
.el-input,
.el-cascader {
  width: 316px;
}
</style>
