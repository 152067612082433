<template>
  <div>
    <div class="page-command-situation">
      <img
        src="~@/assets/images/accident/close.png"
        class="img-close mr20"
        @click="change_page"
      />
      <IntelligenceMissionNav />
      <div class="bd-box">
        <div class="search-box">
          <el-input
            v-model="search.jingqing_title"
            class="input-name"
            placeholder="请输入事件"
            clearable
          />
          <el-cascader
            class="input-orgname"
            placeholder="请选择责任单位"
            v-model="search.orgId"
            :options="unitList"
            :show-all-levels="false"
            :props="{
              children: 'children',
              label: 'orgName',
              value: 'id',
              checkStrictly: true,
              emitPath: false,
            }"
            clearable
          >
          </el-cascader>
          <!-- <el-select
          clearable
        >
          <el-option label=
          v-model="search.type"
          class="input-status"
          placeholder="请选择任务方式""报备" :value="1"></el-option>
          <el-option label="申报" :value="0"></el-option>
        </el-select> -->
          <el-select
            v-model="search.status"
            class="input-excute"
            placeholder="请选择执行状态"
            clearable
          >
            <el-option label="待派发" :value="-1"></el-option>
            <el-option label="待审批" :value="0"></el-option>
            <el-option label="审批不通过" :value="1"></el-option>
            <el-option label="待执行" :value="2"></el-option>
            <el-option label="执行中" :value="3"></el-option>
            <el-option label="已完成" :value="4"></el-option>
          </el-select>
          <el-button @click="handle_search" class="btn-submit">搜索</el-button>
        </div>
        <div class="tb-box">
          <div class="tb-hd-box w100%">
            <div class="tb-hd w20"></div>
            <div class="tb-hd w180">事件内容</div>
            <div class="tb-hd w180">航线名称</div>
            <!-- <div class="tb-hd">任务方式</div> -->
            <div class="tb-hd w100">警情类型</div>
            <div class="tb-hd w100">执行状态</div>
            <div class="tb-hd w100">调度状态</div>
            <div class="tb-hd w180">责任单位</div>
            <div class="tb-hd w100">协助单位</div>
            <div class="tb-hd w180">飞手</div>
            <div class="tb-hd w180">操作</div>
          </div>
          <div class="tb-bd-box h460">
            <div v-for="(item, index) in list" :key="index">
              <div class="tb-tr">
                <div class="w20 cp center">
                  <img
                    class="w16 h16"
                    v-if="item.childTasks"
                    :class="item.visible ? 'collapse' : ''"
                    src="@/assets/images/command/sright.svg"
                    @click="expand(item)"
                    alt=""
                  />
                </div>

                <div class="td w180">{{ item.name || "暂无" }}</div>
                <div class="td w180">{{ item.flightLineName || "暂无" }}</div>
                <!-- <div class="td">{{ map.type[item.type] || '暂无' }}</div> -->
                <div class="td w100">{{ item.typeTitle || "暂无" }}</div>
                <div
                  class="td w100"
                  :style="{ color: statusColor[get_status(item)] }"
                >
                  {{ get_status(item) }}
                </div>
                <div class="td w100">{{ item.approvalStatus || "暂无" }}</div>
                <div class="td w180">{{ item.policeUnitTitle || "暂无" }}</div>
                <div class="td w100">{{ item.assistUnit || "暂无" }}</div>
                <div class="td w180">
                  {{
                    item.team && item.team.length
                      ? item.team.join("、")
                      : "暂无"
                  }}
                </div>
                <div class="td w180">
                  <el-tooltip
                    v-if="isJH && item.applyStatus == 0"
                    effect="dark"
                    content="审批"
                    placement="top"
                  >
                    <div
                      @click="handle_show_approve(item)"
                      class="iconfont icon-shenhe"
                    ></div>
                  </el-tooltip>

                  <el-tooltip
                    effect="dark"
                    content="派发"
                    placement="top"
                    v-if="item.applyStatus != 1 && item.taskSituation == 5"
                  >
                    <div
                      class="iconfont icon-a-bianji2px"
                      @click="handle_edit(item, 1)"
                    ></div>
                  </el-tooltip>
                  <el-tooltip
                    effect="dark"
                    content="下载"
                    placement="top"
                    v-if="item.taskStatus == 1"
                  >
                    <div
                      class="iconfont icon-a-xiazai2px"
                      @click="handle_upload(item)"
                    ></div>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="详情" placement="top">
                    <div
                      @click="handle_edit(item, 2)"
                      class="iconfont icon-xiangqing"
                    ></div>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="定位" placement="top">
                    <div
                      @click="() => $emit('handleLocation', item)"
                      class="iconfont icon-dingwei"
                    ></div>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="航线预览" placement="top">
                    <div
                      @click="handleAirLinePreview(item)"
                      class="iconfont icon-guiji"
                    ></div>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="历史视频" placement="top">
                    <img
                      @click="handleVideoPlayer(item)"
                      class="h20 w20 mr5"
                      :class="
                        item.taskSituation == 4 ? '' : 'preventClickImage'
                      "
                      src="@/assets/historyvideo.svg"
                      alt=""
                    />
                  </el-tooltip>
                  <el-tooltip effect="dark" content="历史图片" placement="top">
                    <img
                      @click="handleImage(item)"
                      class="h20 w20 mr5"
                      :class="
                        item.taskSituation == 4 ? '' : 'preventClickImage'
                      "
                      src="@/assets/images/historyImage.svg"
                      alt=""
                    />
                  </el-tooltip>
                  <el-tooltip effect="dark" content="历史轨迹" placement="top">
                    <img
                      @click="handleHistotyTrack(item)"
                      class="h20 w20"
                      :class="
                        item.taskSituation == 4 ? '' : 'preventClickImage'
                      "
                      src="@/assets/historyTrack.svg"
                      alt=""
                    />
                  </el-tooltip>
                </div>
              </div>
              <div v-show="item.visible" class="ml10">
                <div
                  v-for="(child, index) in item.childTasks"
                  class="tb-tr"
                  :key="index"
                >
                  <div class="tb-hd w20"></div>

                  <div class="td w180">{{ child.taskTitle || "暂无" }}</div>
                  <div class="td w180">
                    {{ child.flightLineId || "暂无" }}
                  </div>
                  <!-- <div class="td">{{ map.type[child.type] || '暂无' }}</div> -->
                  <div class="td w100">{{ item.typeTitle || "暂无" }}</div>
                  <div class="td w100">
                    {{ child.taskStatus | status }}
                  </div>
                  <div class="td w100">
                    {{ child.approvalStatus | resource }}
                  </div>
                  <div class="td w180">
                    {{ child.organizationName || "暂无" }}
                  </div>
                  <div class="td w100">{{ child.assistUnit || "暂无" }}</div>
                  <div class="td w180">
                    {{
                      child.flyUserNames && child.flyUserNames.length
                        ? child.flyUserNames.join("、")
                        : "暂无"
                    }}
                  </div>
                  <div class="td w180">
                    <!-- <el-tooltip
                      effect="dark"
                      content="下载"
                      placement="top"
                      v-if="child.taskStatus == 1"
                    >
                      <div class="iconfont icon-a-xiazai2px"></div>
                    </el-tooltip> -->
                    <!-- <el-tooltip effect="dark" content="详情" placement="top">
                      <div class="iconfont icon-xiangqing"></div>
                    </el-tooltip> -->
                    <el-tooltip
                      effect="dark"
                      content="历史视频"
                      :class="
                        child.taskSituation == 4 ? '' : 'preventClickImage'
                      "
                      placement="top"
                    >
                      <img
                        @click="handleVideoPlayer(child, 2)"
                        class="h20 w20 mr5"
                        src="@/assets/historyvideo.svg"
                        alt=""
                      />
                    </el-tooltip>
                    <el-tooltip
                      effect="dark"
                      content="历史图片"
                      :class="
                        child.taskSituation == 4 ? '' : 'preventClickImage'
                      "
                      placement="top"
                    >
                      <img
                        @click="handleImage(child, 2)"
                        class="h20 w20 mr5"
                        src="@/assets/images/historyImage.svg"
                        alt=""
                      />
                    </el-tooltip>
                    <el-tooltip
                      effect="dark"
                      content="历史轨迹"
                      :class="
                        child.taskSituation == 4 ? '' : 'preventClickImage'
                      "
                      placement="top"
                    >
                      <img
                        @click="handleHistotyTrack(child, 2)"
                        class="h20 w20"
                        src="@/assets/historyTrack.svg"
                        alt=""
                      />
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tb-pagination">
            <el-pagination
              small
              v-if="pageshow"
              :page-sizes="[10, 20, 30, 40]"
              :current-page.sync="pagination.pageNo"
              :page-size="pagination.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination.totalCount"
              @size-change="handle_size_change"
              @current-change="handle_page_change"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <el-dialog
        :show-close="false"
        center
        title="审批"
        :close-on-click-modal="false"
        :visible.sync="approve_form.visible"
        :append-to-body="true"
        top="20em"
        width="30%"
      >
        <div>
          <div
            class="w20 h20 pa top20 right20 cp"
            @click="approve_form.visible = false"
          >
            <img
              class="dib wih100 ht100"
              src="~@/assets/images/close.png"
              alt=""
            />
          </div>
          <el-form label-width="80px">
            <el-form-item label="备注">
              <el-input v-model="approve_form.approvalRemark" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" style="display: flex; justify-content: center">
          <div class="jcsb">
            <div
              @click="handle_approve(0)"
              class="w114 h40 lh40 cf f22 cp mr40"
              style="background-color: #c24848"
            >
              不通过
            </div>
            <div
              @click="handle_approve(1)"
              class="w114 h40 lh40 cf f22 cp ml40"
              style="background-color: #129c9c"
            >
              通过
            </div>
          </div>
        </span>
      </el-dialog>
    </div>
    <VideoMedia
      v-if="videoVisible"
      :historyVideo="video"
      @close="videoVisible = false"
    ></VideoMedia>
    <ImageMedia
      :historyImage="imageList"
      v-if="mediaVisible"
      @closeMedia="mediaVisible = false"
    ></ImageMedia>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImageMedia from "./history";
import IntelligenceMissionNav from "@/components/command/intelligenceMissionNav"; //警情
import VideoMedia from "./history/video";

import { downloadData } from "@/utils/download";
import API from "@/api";
let history_entity = null;
let history_Line = null;
export default {
  components: {
    VideoMedia,
    ImageMedia,
    IntelligenceMissionNav,
  },
  data() {
    return {
      icollapse: false,
      sdown: require("@/assets/images/command/sdown.svg"),
      sright: require("@/assets/images/command/sright.svg"),
      pageshow: true,
      statusColor: {
        待执行: "#FF972B",
        执行中: "#2F76E4",
        已完成: "#89EC50",
        待审批: "#FF972B",
        待派发: "#19DFDF",
        审批不通过: "#DC143C",
      },
      curr_task: null,
      approve_form: {
        approvalRemark: null,
        visible: false,
      },
      search: {
        taskTitle: null,
        orgId: null,
        type: null,
        executeStatus: null,
      },
      list: [],
      unitList: [],
      pagination: {
        pageNo: null,
        pageSize: 10,
        totalCount: 0,
      },
      map: {
        type: {
          0: "申报",
          1: "报备",
        },
      },
      imageList: null,
      video: null,
      videoVisible: false,
      mediaVisible: false,
    };
  },
  inject: ["change_page", "g_cesium_layer"],
  props: ["pageNo"],
  computed: {
    ...mapGetters(["user_info"]),
    isJH() {
      return this.user_info && this.user_info.departmentId == 2;
    },
  },
  methods: {
    expand(item) {
      this.icollapse = !this.icollapse;
      item.visible = !item.visible;
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async handle_edit(item, state) {
      let res = await API.SITUATION.Detail(item.id);
      //调用vuex里面的mutations
      this.$store.commit("situation/SITUATION_DETAIL", res);
      this.$emit(
        "handleClick",
        false,
        state,
        false,
        this.pagination.pageNo,
        item.taskSituation
      );
    },
    // 移除轨迹
    removeEntities() {
      if (history_entity) {
        viewer.entities.remove(history_entity);
      }
      if (history_Line) {
        viewer.entities.remove(history_Line);
      }
    },
    // 历史轨迹
    async handleHistotyTrack(item, state) {
      this.removeEntities();
      try {
        let viewer = window.viewer;
        let res = null;
        if (state == 2) {
          res = await API.SITUATION.HistoryTrack({ taskId: item.id });
        } else {
          res = await API.SITUATION.HistoryTrack({ taskId: item.taskId });
        }

        if (res[Object.keys(res)[0]]) {
          let polyline = res[Object.keys(res)[0]];
          let positions = [];
          polyline.forEach((item, index) => {
            positions.push(Number(item.longitude), Number(item.latitude), 100);
            history_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                Number(item.longitude),
                Number(item.latitude)
              ),
            });
          });

          history_Line = viewer.entities.add({
            name: "Red line on terrain",
            polyline: {
              positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
              width: 5,
              material: Cesium.Color.RED,
              clampToGround: true,
            },
          });
          viewer.flyTo(history_Line);
        }
      } catch (error) {
        this.$el_message("无历史轨迹", () => {}, "error");
      }
    },
    // 历史视频
    async handleVideoPlayer(item, state) {
      try {
        if (this.videoVisible) {
          this.videoVisible = false;
        } else {
          if (state == 2) {
            let res = await API.SITUATION.HistoryVideo({ taskId: item.id });
          } else {
            let res = await API.SITUATION.HistoryVideo({ taskId: item.taskId });
          }

          this.video = res.object.replay[0];
          this.videoVisible = true;
        }
      } catch (error) {
        // this.videoVisible = false;
        this.$el_message("无视频回放", () => {}, "error");
      }
    },
    // 历史图片
    async handleImage(item, state) {
      try {
        let res = null;
        if (this.mediaVisible) {
          this.mediaVisible = false;
        }
        if (state == 2) {
          res = await API.SITUATION.HistoryPhoto(item.id); //12
        } else {
          res = await API.SITUATION.HistoryPhoto(item.taskId); //12
        }
        if (res && res.length) {
          this.imageList = res;
          this.mediaVisible = true;
        } else {
          this.$el_message("无历史图片", () => {}, "error");
        }
      } catch (error) {
        // this.videoVisible = false;
        this.$el_message("无历史图片", () => {}, "error");
      }
    },
    handle_upload(item) {
      downloadData(
        `/hawksystemserver/jingqing/bgdownload2/${item.taskId}?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}`,
        "警情报告"
      );
    },
    isSelf(id) {
      return this.user_info && this.user_info.departmentId == id;
    },
    handle_search() {
      this.get_list();
    },
    async get_list() {
      let { data, pageNo, pageSize, totalCount } = await API.TASK.List({
        cate: 4,
        ...this.pagination,
        ...this.search,

        // orgid
      });
      this.list = [];
      data.map((ele) => {
        // this.list=[]
        this.list.push({
          ...ele,
          visible: false,
        });
      });
      // this.list.forEach(function (item) {
      //     item={
      //       ...item,
      //        visible:false
      //     }
      // })
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.pagination.totalCount = totalCount;
    },
    get_status(item) {
      if (item.taskSituation == -1) {
        item.taskSituation = 5;
      }
      const obj = {
        0: "待审批",
        1: "审批不通过",
        2: "待执行",
        3: "执行中",
        4: "已完成",
        5: "待派发",
      };
      return obj[item.taskSituation];
    },
    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.get_list();
      //   (e) => $emit("size-change", e);
    },
    handle_page_change(page) {
      this.pagination.pageNo = page;
      this.get_list();
      //   (e) => $emit("page-change", e);
    },
    handle_show_approve(val) {
      this.curr_task = val;
      this.approve_form.visible = true;
    },
    handle_approve(status) {
      this.$el_confirm(
        "确定提交么？",
        async () => {
          await API.TASK.Edit({
            id: this.curr_task.taskId,
            status,
            approvalRemark: this.approve_form.approvalRemark,
          });
          this.$el_message("审批成功");
          this.get_list();
          this.approve_form.visible = false;
        },
        () => {
          this.approve_form.approvalRemark = null;
          this.approve_form.visible = false;
        }
      );
    },
    start_timer() {
      this.timer = window.setInterval(() => {
        this.get_list();
      }, 60000);
    },
    stop_timer() {
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = null;
      }
    },

    async handleAirLinePreview(item) {
      // let cesium_layer = this.g_cesium_layer();
      // let viewer = cesium_layer.viewer();
      let viewer = window.viewer;
      let res = await API.TASK.JingQingTrack({
        taskId: item.taskId,
      });
      if (res?.flightCourseJson) {
        let polyline = JSON.parse(res.flightCourseJson);
        let positions = [];
        polyline.points.forEach((item, index) => {
          positions.push(item.lon, item.lat, item.alt);
          viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(item.lon, item.lat),
            point: {
              pixelSize: 20,
              color: Cesium.Color.RED,
              fillColor: Cesium.Color.RED,
              heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
            },
            // label: {
            //   // text: index,
            //   text: "Philadelphia",
            //   font: "24px Helvetica",
            //   fillColor: Cesium.Color.SKYBLUE,
            //   outlineColor: Cesium.Color.BLACK,
            //   outlineWidth: 2,
            //   style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            // },
            label: {
              text: String(index + 1),
              scale: 0.5,
              font: "bold 24px Microsoft YaHei",
              fillColor: Cesium.Color.WHITE,
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.CENTER,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              showBackground: false,
              outlineWidth: 0,
            },
          });
        });
        let redLine = viewer.entities.add({
          name: "Red line on terrain",
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
            width: 5,
            material: Cesium.Color.RED,
            clampToGround: true,
          },
        });
        viewer.flyTo(redLine);
      }
    },
    initPage() {
      this.pagination.pageNo = this.pageNo;
      this.pageshow = false;
      this.$nextTick(() => {
        this.pageshow = true;
      });
    },
  },
  filters: {
    status: function (value) {
      let obj = {
        0: "待执行",
        1: "执行完成",
        3: "执行中",
      };

      return obj[value];
    },
    resource: function (value) {
      let obj = {
        0: "待审批",
        1: "通过",
        2: "驳回",
        3: "执无需审批行中",
      };
      return obj[value];
    },
  },
  mounted() {
    this.initPage();
    this.get_list();
    this.start_timer();
    this.getUnitList();
  },
  destroyed() {
    this.stop_timer();
    this.removeEntities();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";
.page-command-situation {
  z-index: 0;
  width: 1400px;
  min-height: 740px;
  box-sizing: border-box;
  padding: 0 30px 50px 41px;
  background: url("~@/assets/images/plate/bg_04.png");
  background-size: 100% 100%;
  .img-close {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .hd-box {
    height: 56px;
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
    line-height: 60px;
    padding-left: 120px;
    overflow: hidden;
  }
  .bd-box {
    height: calc(100% - 56px);
    box-sizing: border-box;
    ::v-deep .search-box {
      box-sizing: border-box;
      height: 76px;
      display: flex;
      align-items: center;
      margin-left: 564px;
      .btn-add,
      .btn-submit {
        width: 134px;
        height: 40px;
        line-height: 44px;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
        background-color: #129c9c;
        border: none;
        border-radius: 0;
        padding: 0;
      }
      .btn-add {
        margin-right: 41px;
      }
      .input-name {
        width: 186px;
        margin-right: 10px;
        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }
      .input-orgname {
        width: 186px;
        margin-right: 10px;
        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }
      .input-status {
        width: 186px;
        margin-right: 10px;
        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }
      .input-excute {
        width: 186px;
        margin-right: 51px;
        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }
    }
    .tb-box {
      box-sizing: border-box;
      height: calc(100% - 76px);
      .tb-hd-box {
        display: flex;
        // justify-content: space-between;
        height: 52px;
        background-color: #10181f;

        .tb-hd {
          height: 100%;
          // flex: 1;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #129c9c;
          font-weight: 400;
          line-height: 52px;
          text-align: center;
        }
      }
      .tb-bd-box {
        height: calc(100% - 52px);
        // overflow: auto;
        overflow-y: auto;
        background-color: #263542;
        .tb-tr {
          .collapse {
            transform: rotate(90deg);
            transition: 0.5s;
          }
          .uncollapse {
            transform: rotate(-90deg);
            transition: 0.5s;
          }
          // height: 60px;
          display: flex;
          box-sizing: border-box;
          border-bottom: 1px solid #263542;
          .td {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: calc(100% / 9);
            padding: 10px 0;
            text-align: center;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #b3bbc5;
            font-weight: 400;
            word-break: break-word;
            box-sizing: border-box;
            .iconfont {
              font-size: 20px;
              cursor: pointer;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .tb-pagination {
    height: 30px;
    display: flex;
    align-items: center;
    ::v-deep .el-pagination {
      margin-top: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      button {
        background: transparent;
        font-size: 12px;
        color: #889fb2;
        text-align: center;
        font-weight: 400;
        &:disabled {
          background: transparent;
          color: #889fb2;
          opacity: 0.3;
        }
      }
      .el-pagination__total {
        font-size: 12px;
        color: #889fb2;
        text-align: center;
        font-weight: 400;
        height: 30px;
        line-height: 30px;
        display: inline-block;
        margin: 0px;
      }
      .el-pagination__sizes {
        height: 30px;
        line-height: 30px;
        margin: 0px;
        .el-select {
          .el-input {
            width: 60px;
            padding-right: 20px;
            margin: 0;
            .el-input__inner {
              background: transparent;
              border: none;
              line-height: 30px;
              font-size: 12px;
              color: #889fb2;
              text-align: center;
              font-weight: 400;
              padding: 0px;
            }
          }
        }
      }
      .el-pager {
        .number {
          background: transparent;
          font-size: 12px;
          color: #889fb2;
          text-align: center;
          font-weight: 400;
          &.active {
            background: $primary-color;
            border-radius: 2px;
            color: #ffffff;
          }
        }
        .more {
          background: transparent;
          color: #889fb2;
        }
      }
      .el-pagination__jump {
        font-size: 12px;
        color: #889fb2;
        text-align: center;
        font-weight: 400;
        height: 30px;
        line-height: 30px;
        margin-left: 6px;
        .el-input {
          .el-input__inner {
            border: 1px solid rgba(36, 146, 252, 0.3);
            border-radius: 2px;
            background: transparent;
            color: #889fb2;
          }
        }
      }
    }
    ._jumper {
      font-size: 12px;
      color: #889fb2;
      text-align: center;
      font-weight: 400;
      display: flex;
      align-items: center;
      white-space: nowrap;
      ::v-deep .el-input {
        .el-input__inner {
          background: rgba(12, 13, 20, 0.5);
          border: 1px solid rgba(36, 146, 252, 0.3);
          border-radius: 2px;
          width: 33px;
          height: 24px;
          color: #889fb2;
          padding: 0;
          text-align: center;
        }
      }
      ._jumper_btn {
        width: 51px;
        height: 22px;
        background: #333c57;
        border: 1px solid $primary-border-color;
        border-radius: 2px;
        padding: 0px;
        font-size: 14px;
        color: $primary-color;
        letter-spacing: -0.08px;
        text-align: center;
        font-weight: 400;
        margin-left: 4px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.el-dialog {
  background-image: url("~@/assets/images/approve.png");
  background-size: 100% 100% !important;
  // background: transparent;
  background-repeat: no-repeat;
}
.el-dialog__title {
  color: #fff;
  font-weight: 700;
  text-align: left;
}
.el-dialog__header {
  text-align: left;
  margin-left: 100px;
}
.el-textarea__inner {
  color: #ffff;
  background-color: #000000;
  border: 1px solid #08c2d1;
}
.el-form-item__label {
  color: #fff;
}
.cpt-app-float-player {
  width: 362px;
  height: 242px;
  box-sizing: border-box;
  border: 1px solid red;
  position: fixed;
  top: 82px;
  left: 480px;
  background: #161d2d;
  border: 1px solid #178890;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  overflow: hidden;
  z-index: 9999;
  .title-box {
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 12px;
    .title {
      font-family: Microsoft YaHei;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: 400;
      width: calc(100% - 60px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .el-icon-close {
      color: #8aabd4;
      font-size: 16px;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }
  }
  .video-box {
    height: calc(100% - 40px);
    box-sizing: border-box;
  }
}
.cpt-player-webrtc {
  height: 100%;
  width: 100%;
  background-color: #000;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
</style>
