<template>
  <div>
    <flightLog></flightLog>
  </div>
</template>

<script>
import flightLog from "@/components/command/flightlog";
export default {
  components: {
    flightLog,
  },
};
</script>
<style>
</style>