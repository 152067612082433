<template>
  <div class="cpt-command-device-drone-popup">
    <div class="header" v-interact>
      <div class="hd-box">机巢设备详情</div>
      <div class="close_box" @click="() => $emit('handleClose')">关闭</div>
    </div>
    <div class="content_box">
      <div class="popup-box">
        <div class="popup-box-left">
          <el-cascader
            v-model="organId"
            :options="options"
            :show-all-levels="false"
            placeholder="请选择单位"
            :props="{
              children: 'children',
              label: 'orgName',
              value: 'id',
              checkStrictly: true,
              emitPath: false,
            }"
          >
          </el-cascader>
        </div>
        <div id="container"></div>
        <div class="bd-box">
          <div class="tb-box">
            <div class="tb-hd-box">
              <div class="tb-hd">机巢名称</div>
              <div class="tb-hd">机巢ID</div>
              <div class="tb-hd">机巢图片</div>
              <div class="tb-hd">所属单位</div>
              <div class="tb-hd">设备状态</div>
              <div class="tb-hd">位置</div>
              <div class="tb-hd">入库时间</div>
              <div class="tb-hd">出库时间</div>
            </div>
            <div class="tb-bd-box">
              <div class="tb-tr" v-for="item in list" :key="item.id">
                <div class="td">
                  {{ item.name || "暂无" }}
                </div>
                <div class="td">
                  {{ item.netId || "暂无" }}
                </div>
                <div class="td">
                  <el-image
                    :src="baseUrl + item.image"
                    :preview-src-list="[baseUrl + item.image]"
                  >
                  </el-image>
                </div>
                <div class="td">市巡特警支队</div>
                <div class="td" :class="`tdclass${item.state}`">
                  {{ changeState(item.state) || "暂无" }}
                </div>
                <div class="td">
                  {{ item.address || "暂无" }}
                </div>
                <div class="td">
                  {{ item.inTime || "暂无" }}
                </div>
                <div class="td">
                  {{ item.outTime || "暂无" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page_num">
        共计<span>{{ totalCount }}</span
        >条
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { Chart } from "@antv/g2";

export default {
  props: {
    organId: {
      type: Number,
      default: () => 1,
    },
  },
  watch: {
    organId() {
      this.getNestList();
    },
  },
  data() {
    return {
      list: [],
      baseUrl: process.env.VUE_APP_IMG_URL,
      totalCount: 0,
      info: [],
      options: [],
    };
  },
  async mounted() {
    this.getNestList();
    await this.getContainerInfo();
    this.init(this.info, "container");
    await this.getOptions();
  },
  methods: {
    async getOptions() {
      let { organId } = this;
      let res = await API.DEVICE.GetPopupUnitList({ organId });
      this.options = [res] || [];
    },
    async getContainerInfo() {
      let { organId } = this;
      let res = await API.DEVICE.PopupNestChartInfo({
        organId: organId,
      });
      this.info = res || [];
    },
    async getNestList() {
      let { organId } = this;
      let res = await API.DEVICE.PopupListNest({
        pageSize: 0,
        organId: organId,
      });
      this.totalCount = res.length;
      this.list = res || [];
    },
    init(data, dataName) {
      let _this = this;
      if (!this[dataName]) {
        const chart = (this[dataName] = new Chart({
          container: dataName,
          width: 900,
          height: 240,
          padding: [0, 0, 0, 300],
        }));

        chart.data(data);

        chart.coordinate("theta", {
          radius: 0.75,
          innerRadius: 0.5,
        });

        chart.tooltip({
          showTitle: false,
          showMarkers: false,
        });

        chart.legend({
          position: "right",
          itemName: {
            style: {
              fill: "#fff",
            },
          },
          itemWidth: 150,
          // itemHeight:50
        });
        chart
          .annotation()
          .text({
            position: ["50%", "50%"],
            content: "○",
            style: {
              fontSize: 20,
              fill: "#8c8c8c",
              textAlign: "center",
            },
          })
          .text({
            position: ["50%", "50%"],
            content: "▽",
            style: {
              fontSize: 14,
              fill: "#8c8c8c",
              textAlign: "center",
            },
            offsetY: -34,
          })
          .text({
            position: ["50%", "50%"],
            content: "△",
            style: {
              fontSize: 14,
              fill: "#8c8c8c",
              textAlign: "center",
            },
            offsetY: 36,
          })
          .text({
            position: ["50%", "50%"],
            content: "▷",
            style: {
              fontSize: 9,
              fill: "#8c8c8c",
              textAlign: "center",
            },
            offsetX: -34,
          })
          .text({
            position: ["50%", "50%"],
            content: "◁",
            style: {
              fontSize: 9,
              fill: "#8c8c8c",
              textAlign: "center",
            },
            offsetX: 34,
          });

        chart.interval().adjust("stack").position("count").color("name");

        chart.interaction("element-active");
        chart.render();

        chart.on("element:click", async (ev) => {
          // console.log('------ev', ev)
          _this.organId = ev.data.data.id;
          _this.getNestList();
        });
      } else {
        this[dataName].data(data);
        this[dataName].render();
      }
    },
    changeState(val) {
      const obj = { 1: "正常", 2: "损坏", 3: "维修", 4: "保养" };
      return obj[val];
    },
  },
};
</script>

<style lang="scss" scoped>
.tdclass1 {
  color: #00b900 !important;
}
.tdclass3 {
  color: yellow !important;
}
.tdclass2 {
  color: red !important;
}
.tdclass4 {
  color: #b3bbc5 !important;
}
.cpt-command-device-drone-popup {
  width: 1267px;
  height: 654px;
  box-sizing: border-box;
  position: absolute;
  top: 200px;
  left: 20%;
  z-index: 10;
  background: rgba(16, 30, 62, 0.9);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  .header {
    height: 64px;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
  }
  .content_box {
    padding: 0 10px 15px;
    box-sizing: border-box;
    height: calc(100% - 66px);
  }
  .close_box {
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #00ffff;
    position: absolute;
    right: 28px;
    top: 22px;
    opacity: 0.8;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .popup-box {
    // display: flex;
    // justify-content: space-between;
    // height: calc(100% - 24px);
    .popup-box-right {
      padding-top: 20px;
    }
  }
  .hd-box {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #00ffff;
    letter-spacing: 0;
    font-weight: 700;
    line-height: 64px;
    padding-left: 30px;
  }
  ::v-deep {
    .el-cascader {
      margin-top: 20px;
      // margin-bottom: 50px;
      width: 212px;
    }
    .el-input__inner {
      background: #212a40;
      border: 1px solid #00aeff;
      font-family: MicrosoftYaHeiUI;
      font-size: 16px;
      color: #00aeff;

      background: transparent;

      border: 1px solid #00b6ff;
      box-shadow: inset 0 0 3px 0 #00b6ff;
      border-radius: 6px;

      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: rgba(0, 255, 255, 0.8);
    }
  }
  .bd-box {
    box-sizing: border-box;
    width: 100%;
    height: 260px;
    .tb-box {
      box-sizing: border-box;
      height: 100%;
      .tb-hd-box {
        display: flex;
        justify-content: space-between;
        height: 52px;
        .tb-hd {
          height: 100%;
          flex: 1;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          line-height: 52px;
          text-align: center;
          font-size: 20px;
          color: #00aeff;
        }
      }
      .tb-bd-box {
        height: calc(100% - 52px);
        overflow: auto;
        .tb-tr {
          display: flex;
          box-sizing: border-box;
          border-bottom: 1px solid #263542;
          &:hover {
            cursor: pointer;
            background: rgba(0, 34, 140, 0.2);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
            border-radius: 5px;
          }
          .td {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(100% / 8);
            padding: 10px 0;
            text-align: center;
            font-family: MicrosoftYaHei;
            word-break: break-word;
            box-sizing: border-box;
            font-size: 16px;
            color: #dce9ff;
            font-weight: 400;
          }
        }
      }
    }
  }
  .page_num {
    text-align: right;
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #dce9ff;
    line-height: 24px;
    margin: 6px 20px 0 0;
    & > span {
      color: #00aeff;
    }
  }
  /deep/.el-image__inner {
    width: 100px;
    height: 100px;
  }
  /deep/.el-image__error {
    width: 90px;
    height: 70px;
  }
}
</style>
