<template>
  <div class="monitoringRightTop jcsb">
    <div class="monitoringRightTop-status" v-if="status">
      <div
        class="monitoringRightTop-status__btn"
        :class="item=='A'? 'active':''"
        @click="change('A')"
      >全部</div>
      <div
        class="monitoringRightTop-status__btn"
        :class="item=='B'? 'active':''"
        @click="change('B',1)"
      >在线</div>
      <!-- <span class="dib ml4">{{count.exception}}</span> -->
      <div
        class="monitoringRightTop-status__btn"
        :class="item=='C'? 'active':''"
        @click="change('C',0)"
      >离线</div>
      <!-- <span class="dib ml4"> {{count.noException}}</span> -->
    </div>
    <div v-else />
    <div class="monitoringRightTop-sum" style="order">
      共计{{sl}}{{title.id == 6 ? '块' : title.id == 5 ? '个' : title.id == 1 ? '架' : '座'}}
    </div>
    <div class="monitoringRightTop-filter">
      <div
        style="color:green; font-size: 20px;"
        class="iconfont icon-yichangliuchengbeifen"
        @click="$emit('changeFlag',true)"
      ></div>
      <div
        style="color:red; font-size: 18px;"
        class="iconfont icon-zhihangyichang"
        @click="$emit('changeFlag',false)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: "A",
      online: null,
    };
  },
  props: {
    status: {
      type: Boolean,
      default: true,
    },
    sl: {
      type: Number,
      default: 0,
    },
      count: {
      type: Object,
      default() {
        return {};
      },
    },
    title: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    status(val) {
      this.item = "A";
    },
  },
  methods: {
    change(item, val) {
      this.$emit("change", val);
      this.item = item;
    },
  },
  mounted() {},
};
</script>

<style scoped lang='scss'>
.monitoringRightTop {
  box-sizing: border-box;
  position: relative;
  height: 64px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .monitoringRightTop-status {
    padding-left: 2px;
    display: flex;

    .monitoringRightTop-status__btn {
      width: 64px;
      height: 32px;
      border: 1px solid rgba(49, 94, 199, 1);
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2d9ff;
      margin-left: 14px;
      background-color: rgba(2, 23, 61, 1);

      &.active {
        background-image: url('~@/assets/images/monitoring/btn_active.png');
        background-size: 100% 100%;
      }

      &:hover {
        background-image: url('~@/assets/images/monitoring/btn_active.png');
        background-size: 100% 100%;
      }
    }
  }

  .monitoringRightTop-sum {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #D2D9FF;
  }

  .monitoringRightTop-filter {
    display: flex;
    align-items: center;
    margin-right: -2px;
  
    .iconfont{
      margin-right: 20px;
    }
  }
}
</style>