<!-- 
  TODO: 航空航线组件
 -->
<template>
  <div class="air-line-container">
    <!-- 航空航线列表面板 -->
    <div class="air-line-list-panel" v-show="airLineListPanelShowFlag">
      <div class="header">
        <div class="right">
          <div class="title title1" :class="[titleFlag == 0 ? 'title-selected' : '']" @click="titleClickHandle(0)">
            <span>航空航线</span>
          </div>
        </div>
        <div class="icon-plus">
          <!-- 创建航线，点击显示航线设置对话框 -->
          <img src="@/assets/img/plus.png" alt @click="createNewLine" />
        </div>
      </div>

      <div class="form" @click="formBodyClick">
        <div class="line line1">
          <el-input class="input-search input-search1" v-model="form.flightName" clearable placeholder="请输入航线名称"></el-input>
          <el-input class="input-search input-search2" v-model="form.userName" clearable placeholder="请输入创建人"></el-input>
          <el-input class="input-search input-search3" v-model="form.labelName" clearable placeholder="标签"></el-input>
          <div class="btn btn1" ref="airLineListBtn" id="airLineListBtnID" @click="searchClick">
            <i class="el-icon-search"></i>
          </div>
        </div>

        <div class="line line2">
          <el-select v-model="form.sourceType" class="item-input" placeholder="搜索类型" clearable popper-class="input-excute-panel">
            <el-option label="无人机航线" :value="1"></el-option>
            <el-option label="机巢航线" :value="2"></el-option>
          </el-select>
          <el-select v-model="form.isSafe" class="item-input" placeholder="安全状态" clearable popper-class="input-excute-panel">
            <el-option label="安全" :value="1"></el-option>
            <el-option label="待确定" :value="0"></el-option>
          </el-select>

          <el-select v-model="form.type" class="item-input" placeholder="空域状态" clearable popper-class="input-excute-panel">
            <el-option label="可用" :value="1"></el-option>
            <el-option label="待审批" :value="3"></el-option>
            <el-option label="通过" :value="4"></el-option>
            <el-option label="驳回" :value="5"></el-option>
          </el-select>

          <!-- <div class="item item1" @click.stop="typePanelClick">
            <span> {{ form.sourceType == '' ? '类型' : form.sourceType }} </span>
            <img class="img" v-if="typePanelShow" src="@/assets/img/i22.png" alt="">
            <img class="img" v-else src="@/assets/img/i21.png" alt="">
          </div>
          <div class="item item2" @click.stop="safePanelClick">
            <span>{{ form.isSafe == '' ? '安全状态' : form.isSafe }}</span>
            <img class="img" v-if="safePanelShow" src="@/assets/img/i22.png" alt="">
            <img class="img" v-else src="@/assets/img/i21.png" alt="">
          </div>
          <div class="item item3" @click.stop="airPanelClick">
            <span>{{ form.type == '' ? '空域状态' : form.type }}</span>
            <img class="img" src="@/assets/img/i21.png" alt="">
                    </div>-->

          <!-- 类型面板 -->
          <!-- <div class="panel type" v-show="typePanelShow">
            <div class="item" :class="[typeSelectFlag == 0 ? 'item-selected' : '']" @click.stop="typeClickHandle(0)">无人机航线
            </div>
            <div class="item" :class="[typeSelectFlag == 1 ? 'item-selected' : '']" @click.stop="typeClickHandle(1)">机巢航线
            </div>
            <div class="triangle triangle_border" />
            <div class="triangle triangle_bg" />
                    </div>-->
          <!-- 安全状态面板 -->
          <!-- <div class="panel safe-status" v-show="safePanelShow">
            <div class="item" :class="[safeSelectFlag == 0 ? 'item-selected' : '']" @click.stop="safeClickHandle(0)">安全
            </div>
            <div class="item" :class="[safeSelectFlag == 1 ? 'item-selected' : '']" @click.stop="safeClickHandle(1)">待确定
            </div>
            <div class="triangle triangle_border" />
            <div class="triangle triangle_bg" />
                    </div>-->
          <!-- 空域状态面板 -->
          <!-- <div class="panel air-status" v-show="airPanelShow">
            <div class="item" :class="[airSelectFlag == 0 ? 'item-selected' : '']" @click.stop="airClickHandle(0)">可用
            </div>
            <div class="item" :class="[airSelectFlag == 1 ? 'item-selected' : '']" @click.stop="airClickHandle(1)">待审批
            </div>
            <div class="item" :class="[airSelectFlag == 2 ? 'item-selected' : '']" @click.stop="airClickHandle(2)">通过
            </div>
            <div class="item" :class="[airSelectFlag == 3 ? 'item-selected' : '']" @click.stop="airClickHandle(3)">驳回
            </div>
            <div class="triangle triangle_border" />
            <div class="triangle triangle_bg" />
                    </div>-->
        </div>
      </div>

      <div id="airLineContainerBody" class="body custom-scrollbar" v-loading="loading" element-loading-text="数据加载中" element-loading-spinner="my-custom-loader"
        element-loading-background="rgba(0, 0, 0, 0)">
        <div class="card-body">
          <Card class="card" v-for="(item, index) in airway.list" :key="index" :data="item" @deleted="airLineDeleted" @chanegSafety="chanegSafety"
            @showRouteLabel="showRouteLabel" @editRouteData="editRouteData"></Card>
        </div>
        <div class="pagation">
          <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="airway.pagination.pageNo" :page-size="airway.pagination.pageSize"
            layout="prev, pager, next, total,  jumper" :total="airway.pagination.totalCount" :pager-count="5"
            @current-change="handleSizeChange_station"></el-pagination>
        </div>

        <!-- <img id="scrollToTopAL" src="@/assets/img/i98.png" alt="" @click="scroll2Top" title="回到顶部"> -->
      </div>
    </div>

    <!-- 创建航线面板 -->
    <Manual :panelForm="createAirlineDialogFormCopy" v-if="airLineCreatePanel" @close="closeCreatePanel" :lablistL="lablistL" :editData="editData"
      @editCreateAriLinePanel="editCreateAriLinePanelClick" @showListPanel="showListPanel" @createWay="createWay"></Manual>

    <!-- 创建新航线弹框-航线设置 -->
    <el-dialog title="航线设置" :visible.sync="newLineDialogVisible" width="25%" :modal="false" :center="true" custom-class="create-new-line-dialog">
      <div class="main-body">
        <el-form ref="creatAirlineForm" :rules="rules" :model="createAirlineDialogForm" label-width="0" label-position="top">
          <el-form-item label="请输入航线名称" prop="name">
            <el-input v-model="createAirlineDialogForm.name" placeholder="请输入航线名称"></el-input>
          </el-form-item>
          <!-- 起始点和终止点 -->
          <div class="land">
            <el-form-item label="起始点" prop="startLandingFieldId">
              <el-select popper-class="popper-select-panel" v-model="createAirlineDialogForm.startLandingFieldId" clearable placeholder="请选择起始点">
                <el-option v-for="item in landList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="终止点" prop="endLandingFieldId">
              <el-select popper-class="popper-select-panel" v-model="createAirlineDialogForm.endLandingFieldId" clearable placeholder="请选择终止点">
                <el-option v-for="item in landList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="请选择执行方式" prop="actionWay">
            <el-select popper-class="popper-select-panel" v-model="createAirlineDialogForm.actionWay" placeholder="请选择执行方式" @change="handle_actionWayChange">
              <el-option label="无人机" value="uav"></el-option>
              <el-option label="机巢" value="nest"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择机巢" v-show="nestShowFlag">
            <el-select popper-class="popper-select-panel" v-model="createAirlineDialogForm.nest" placeholder="请选择选择机巢">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="高度模式" prop="altitudeMode">
            <el-select popper-class="popper-select-panel" v-model="createAirlineDialogForm.altitudeMode" placeholder="请选择高度模式">
              <el-option v-for="item in altitudeMode_list" :key="item.id" :label="item.name" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="跟随航线" prop="isFollowAirLine">
            <!-- <el-radio style="margin-left: 10px" v-model="createAirlineDialogForm.isFollowAirLine" :label="true">是</el-radio>
            <el-radio v-model="createAirlineDialogForm.isFollowAirLine" :label="false">否</el-radio> -->
            <el-radio-group v-model="createAirlineDialogForm.isFollowAirLine" @input="airway_handleIsFollowAirLineChange">
              <el-radio style="margin-left: 10px" :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- (1-500m) -->
          <el-form-item label="全局飞行高度" prop="baseHeight">
            <div class="body average-height-body">
              <div class="btn btn1" @click="flyHeightClickHandle(-50)">-50</div>
              <div class="btn btn2" @click="flyHeightClickHandle(-10)">-10</div>
              <div class="btn btn3" @click="flyHeightClickHandle(-1)">-1</div>
              <el-input size="small" v-model="createAirlineDialogForm.baseHeight" placeholder @input="flyHeightInputHandle"></el-input>
              <div class="btn btn4" @click="flyHeightClickHandle(1)">+1</div>
              <div class="btn btn5" @click="flyHeightClickHandle(10)">+10</div>
              <div class="btn btn6" @click="flyHeightClickHandle(50)">+50</div>
            </div>
          </el-form-item>
          <el-form-item label="全局飞行速度(1-20m/s)" prop="baseSpeed">
            <div class="body body-base-speed">
              <img class="img" src="@/assets/img/i79.png" alt @click="flySpeedClickHandle(-1)" />
              <el-slider class="slider" v-model="createAirlineDialogForm.baseSpeed" :min="1" :max="20"></el-slider>
              <img class="img" src="@/assets/img/i80.png" alt @click="flySpeedClickHandle(1)" />
              <el-input class="speed-input" size="small" v-model.number="createAirlineDialogForm.baseSpeed
                " placeholder></el-input>
              <span class="unit">m/s</span>
            </div>
          </el-form-item>
          <el-form-item label="完成动作" prop="finishedAction">
            <el-select popper-class="popper-select-panel" v-model="createAirlineDialogForm.finishedAction" placeholder="请选择完成动作"
              @change="airway_handleFinishedActionChange">
              <el-option v-for="item in finishedAction_list" :key="item.id" :label="item.name" :value="item.value" />
            </el-select>
          </el-form-item>
          <!-- 如果完成动作选择原地降落，则需要设置绝对降落高度 -->
          <el-form-item v-if="createAirlineDialogForm.finishedAction == 'AutoLand'" label="绝对降落高度(m)" prop="finishedActionParam">
            <el-input v-model="createAirlineDialogForm.finishedActionParam" placeholder="请输入绝对降落高度"></el-input>
          </el-form-item>
          <el-form-item label="场景" prop="scene">
            <el-select popper-class="popper-select-panel" v-model="createAirlineDialogForm.scene" placeholder="请选择场景">
              <el-option v-for="item in scene_list" :key="item.id" :label="item.name" :value="item.name" />
            </el-select>
          </el-form-item>
          <!-- <el-form-item class="create-way-item" label="创建方式">
            <div class="create-way create-way-auto"
              :class="[createAirlineDialogForm.createLineManualOrAuto == 1 ? 'create-way-selected' : '']"
              @click="createLineWay(1)">自动创建</div>
            <div class="create-way create-way-manual"
              :class="[createAirlineDialogForm.createLineManualOrAuto == 0 ? 'create-way-selected' : '']"
              @click="createLineWay(0)">手动创建</div>
                    </el-form-item>-->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="createLineDialogCancel">取 消</el-button>
        <el-button type="primary" @click="createLineDialogSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Card from './card.vue';
import methods from './methods';
import data from './data';
import Manual from './manual';
import API from '@/api';
import Bus from '@/assets/ligature';
import _ from 'lodash';
import dayjs from 'dayjs'
import airway from './methods/airway';
let numReg = new RegExp("^-?[0-9]*.?[0-9]*$");
export default {
  components: {
    Card,
    Manual
  },
  data() {
    return {
      // ...data,
      titleFlag: 0,
      form: {
        flightName: '',
        userName: '',
        labelName: '',
        sourceType: '',
        isSafe: '',
        type: '' //空域状态
      },
      cardData: [],
      typePanelShow: false,
      typeSelectFlag: -1, //类型下拉框选中标志
      safePanelShow: false,
      safeSelectFlag: -1, //安全状态下拉框选中标志
      airPanelShow: false,
      airSelectFlag: -1, //空域状态选中标志
      airway: {
        columns: [
          {
            title: '航线ID',
            prop: 'id'
          },
          {
            title: '航线名称',
            prop: 'flightName'
          },
          {
            title: '航点数量(个)',
            prop: 'pointCount'
          },
          {
            title: '航线长度(m)',
            prop: 'distance'
          },
          {
            title: '预计时长(s)',
            prop: 'time'
          },
          {
            title: '创建时间',
            prop: 'addTime'
          },
          {
            title: '空域状态',
            prop: 'statuscss'
          },
          {
            title: '航线标签',
            prop: 'labelName'
          },
          {
            title: '操作',
            slot: 'operate'
          }
        ],
        pagination: {
          flightName: '',
          type: null,
          isSafe: null,
          sourceType: null,
          labelName: '',
          pageNo: 1,
          pageSize: 10,
          totalCount: 0
        },
        list: [],
        add: {
          visible: false
        }
      },
      newLineDialogVisible: false, //创建新航线弹出框
      createAirlineDialogForm: {
        name: '', // 航线名称
        actionWay: '', //执行方式 无人机 uav，机巢 nest
        nest: '', //机巢，执行方式选择机巢时候有效
        scene: '', //场景
        createLineManualOrAuto: 1, // 创建方式，手动创建 0， 自动创建 1
        // altitudeMode: 'relativeToGround', //高度模式，默认相对高度
        altitudeMode: '', //高度模式，默认相对高度
        // finishedAction: 'GoHome', //返航模式 默认分回到Home点
        finishedAction: '', //返航模式 默认分回到Home点
        isFollowAirLine: true, //跟随航线模式，布尔类型，默认为true；当为true时，生效航线高度、速度设置，同时禁止编辑航点速度；当为false时，生效高度、航点速度。
        baseHeight: 100, //全局高度
        baseSpeed: 5, //全局速度
        startLandingFieldId: '',
        endLandingFieldId: '',
        finishedActionParam: '',
      }, //弹框表单
      createAirlineDialogFormCopy: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入航线名称',
            trigger: 'blur'
          }
        ],
        actionWay: [
          {
            required: true,
            message: '请选择执行方式',
            trigger: 'change'
          }
        ],
        altitudeMode: [
          {
            required: true,
            message: '请选择高度模式',
            trigger: 'change'
          }
        ],
        finishedAction: [
          {
            required: true,
            message: '请选择返航模式',
            trigger: 'change'
          }
        ],
        scene: [{ required: true, message: '请选择场景', trigger: 'change' }],
        finishedActionParam: [{ required: false, message: '请输入绝对降落高度', trigger: 'change' }],
      }, //弹窗表单校验
      scene_list: [
        { name: '巡查', id: 1 },
        { name: '预警', id: 2 },
        { name: '侦察', id: 3 },
        { name: '服务', id: 4 },
        { name: '救援', id: 5 },
        { name: '处置', id: 6 },
        { name: '打击', id: 7 },
        { name: '宣传', id: 8 },
        { name: '物流', id: 9 }
      ],
      altitudeMode_list: [
        { name: '相对高度', id: 1, value: 'relativeToGround' },
        { name: '绝对高度', id: 2, value: 'absolute' }
      ], //高度模式
      finishedAction_list: [
        { name: '返回到Home点', id: 1, value: 'GoHome' },
        { name: '悬停，无动作', id: 2, value: 'Hover' },
        { name: '原地降落', id: 3, value: 'AutoLand' }
      ], //返航模式
      nestShowFlag: false,
      airLineListPanelShowFlag: true, //航空航线列表面板
      airLineManualPanelShowFlag: false, //航空航线手动规划面板
      airLineCreatePanel: false, //手动规划，自动规划航线面板
      lablistL: null, //航线规划标签
      loading: false,
      editData: null, //编辑航线时候选中卡片数据
      landList: []
    };
  },
  mounted() {
    // let dom = document.getElementById('airLineContainerBody');
    // let domScroll = document.getElementById('scrollToTopAL');
    // dom.addEventListener('scroll', () => {
    //   let clientHeight = dom.clientHeight;
    //   let scrollTop = dom.scrollTop;
    //   let scrollHeight = dom.scrollHeight;

    //   domScroll.style.top = `${scrollTop + clientHeight - 50}px`;
    //   domScroll.style.display = 'block';

    //   if (scrollTop == 0) {
    //     domScroll.style.display = 'none';
    //   }
    //   // console.log(clientHeight + scrollTop);
    //   // console.log('scrollHeight', scrollHeight);
    //   if (parseInt(clientHeight + scrollTop) === scrollHeight) {
    //     // console.log('竖向滚动条已经滚动到底部')
    //     this.airway_list('scroll');
    //   }
    // });
    this.$nextTick(() => {
      Promise.all([this.airway_list()]);
    });
    // Bus.$on('info-sidebar-selected', (v) => {
    //   if (v == 2) {
    //     this.$nextTick(() => {
    //       Promise.all([this.airway_list()])
    //     });
    //   }
    // });

    this.getLandList() // 获取起降点列表
  },
  methods: {
    ...methods,
    /**
     *@Description: 分页
     *@Date: 2023-11-06 14:54:27
     *@Params1:
     *@Return1:
     */
    handleSizeChange_station(v) {
      this.airway.pagination.pageNo = parseInt(v);
      this.airway_list();
    },
    // 回到顶部
    scroll2Top() {
      let dom = document.getElementById('airLineContainerBody');
      dom.scrollTop = 0;
      setTimeout(() => {
        let domScroll = document.getElementById('scrollToTopAL');
        domScroll.style.display = 'none';
      }, 100);
    },
    titleClickHandle(v) {
      this.titleFlag = v;
      if (v == 0) {
        this.cardData.forEach((cardDatum) => {
          cardDatum.a2 = String(cardDatum.a2).split('*')[0];
        });
      } else {
        this.cardData.forEach((cardDatum) => {
          cardDatum.a2 += '*临时';
        });
      }
    },
    // 点击类型面板
    typePanelClick() {
      this.safePanelShow = false;
      this.airPanelShow = false;
      this.typePanelShow = !this.typePanelShow;
      // this.typeSelectFlag = -1;
    },
    // 类型选择
    typeClickHandle(v) {
      this.typeSelectFlag = v;
      this.typePanelShow = false;
      if (v == 0) {
        this.form.sourceType = 1;
      } else {
        this.form.sourceType = 2;
      }
    },
    // 点击安全状态按钮
    safePanelClick() {
      this.typePanelShow = false;
      this.airPanelShow = false;
      this.safePanelShow = !this.safePanelShow;
      // this.safeSelectFlag = -1;
    },
    // 安全状态选择
    safeClickHandle(v) {
      this.safeSelectFlag = v;
      this.safePanelShow = false;
      if (v == 0) {
        this.form.isSafe = 1;
      } else {
        this.form.isSafe = 0;
      }
    },
    // 点击空域状态按钮
    airPanelClick() {
      this.typePanelShow = false;
      this.safePanelShow = false;
      this.airPanelShow = !this.airPanelShow;
    },
    // 空域状态选择
    airClickHandle(v) {
      this.airSelectFlag = v;
      this.airPanelShow = false;
      if (v == 0) {
        this.form.type = 1;
      } else if (v == 1) {
        this.form.type = 3;
      } else if (v == 2) {
        this.form.type = 4;
      } else if (v == 3) {
        this.form.type = 5;
      }
    },
    // 点击查询库其他位置，弹出面板收起
    formBodyClick() {
      // console.log('1212121');
      this.typePanelShow = false;
      this.safePanelShow = false;
      this.airPanelShow = false;
    },
    // 点击加号创建新航线
    createNewLine() {
      this.newLineDialogVisible = !this.newLineDialogVisible;
      this.$nextTick(() => {
        // 重置数据
        this.$refs['creatAirlineForm'].resetFields();
        // 初始化航线名称 YYYYMMDDHHMMSS
        this.createAirlineDialogForm.name = dayjs(new Date()).format('YYYYMMDDHHmmss');
        // 获取航线标签
        this.getlable();
      });
    },
    // 选择执行方式
    handle_actionWayChange(v) {
      // console.log(v, '选择执行的方式');
      // 如果是无人机，隐藏选择机巢，如果是机巢，则显示选择机巢
      if (v == 'uav') {
        this.nestShowFlag = false;
      } else {
        this.nestShowFlag = true;
      }
    },
    // 航线创建方式
    createLineWay(index) {
      this.createAirlineDialogForm.createLineManualOrAuto = index;
    },
    // 创建航线，点击确定按钮
    createLineDialogSubmit() {
      this.$refs['creatAirlineForm'].validate((valid) => {
        if (valid) {
          if (this.createAirlineDialogForm.finishedAction == 'AutoLand') {
            if (!this.createAirlineDialogForm?.finishedActionParam) {
              this.$message.error('请设置绝对高度');
              return;
            }
            if (isNaN(this.createAirlineDialogForm?.finishedActionParam)) {
              this.$message.error('绝对高度格式设置错误');
              return;
            }
          }
          // alert('submit!');
          this.editData = null;
          // 创建航线弹窗隐藏
          this.newLineDialogVisible = false;
          // console.log(this.createAirlineDialogForm, '创建航线面板数据');
          // 隐藏航空航线列表面板
          this.airLineListPanelShowFlag = false;
          // 显示创建航线面板
          this.airLineCreatePanel = true;
          this.$nextTick(() => {
            this.createAirlineDialogFormCopy = _.cloneDeep(this.createAirlineDialogForm);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //创建航线设置，点击取消按钮
    createLineDialogCancel() {
      this.newLineDialogVisible = false;
    },

    // 关闭创建航线面板
    closeCreatePanel() {
      // 显示航空航线列表面板
      this.airLineListPanelShowFlag = true;
      // 隐藏创建航线面板
      this.airLineCreatePanel = false;
    },
    // 在规划的时候点击编辑按钮
    editCreateAriLinePanelClick(item) {
      this.$nextTick(() => {
        // console.log(v, 'cc-f-t');
        // console.log('航线设置数据回显', item);
        let v = item.editData;
        if (v) {
          // 起始点和终止点
          this.createAirlineDialogForm.startLandingFieldId = v.startLandingFieldId; // 起始点
          this.createAirlineDialogForm.endLandingFieldId = v.endLandingFieldId; // 终止点
          let flightCourseJson = JSON.parse(v.flightCourseJson);
          // console.log('flightCourseJson', flightCourseJson);

          //航线设置数据回填
          this.createAirlineDialogForm.name = flightCourseJson.filename; // 航线名称
          this.createAirlineDialogForm.actionWay = flightCourseJson.actionWay; //执行方式 无人机 uav ，机巢 nest
          // this.createAirlineDialogForm.nest = '' //机巢，执行方式选择机巢时候有效
          this.createAirlineDialogForm.scene = flightCourseJson.scene; //场景
          this.createAirlineDialogForm.createLineManualOrAuto = flightCourseJson.manualOrAuto; // 创建方式，手动创建 0， 自动创建 1
          // 初始化高度模式
          this.createAirlineDialogForm.altitudeMode = flightCourseJson.altitudeMode;
          // 初始化完成模式
          this.createAirlineDialogForm.finishedAction = flightCourseJson.finishedAction;
          if (flightCourseJson.finishedAction == 'AutoLand') {
            this.createAirlineDialogForm.finishedActionParam = flightCourseJson.finishedActionParam || 0;
          }
          this.createAirlineDialogForm.isFollowAirLine = flightCourseJson.isFollowAirLine;
          this.createAirlineDialogForm.baseSpeed = flightCourseJson.baseSpeed;
          this.createAirlineDialogForm.baseHeight = flightCourseJson.line.baseHeight;
          this.createAirlineDialogForm.baseSpeed = flightCourseJson.line.baseSpeed;
        }
        // console.log(this.createAirlineDialogForm, 'this.createAirlineDialogForm');
      });
      this.newLineDialogVisible = true;
    },
    // 获取航线标签
    async getlable() {
      this.lablistL = await API.AIRWAY.labelList();
    },
    // 获取起降场下拉列表
    async getLandList() {
      this.landList = await API.AIRWAY.landList();
    },
    // 显示航空航线列表面板
    showListPanel() {
      this.airLineListPanelShowFlag = true;
      // this.airway.pagination.pageNo = 1;
      // this.airway.pagination.pageSize = 10;
      // this.airway.pagination.totalCount = 0;
      Promise.all([this.airway_list()]);
    },
    // 切换手动规划和自动规划
    createWay() {
      // console.log(this.createAirlineDialogForm, 'this.createAirlineDialogForm');
      // if (this.createAirlineDialogForm.createLineManualOrAuto == 0) {
      //   this.createAirlineDialogForm.createLineManualOrAuto = 1
      // } else {
      //   this.createAirlineDialogForm.createLineManualOrAuto = 0;
      // }
    },
    // 删除之后要重新刷新一下数据
    airLineDeleted() {
      Promise.all([this.airway_list()]);
    },
    // 点击搜索按钮
    searchClick() {
      // this.pagination.pageNo = 1
      this.form.scroll = '';
      this.airway_search(this.form);
    },
    /**
     *@Description: 修改为安全航线
     *@Date: 2023-10-10 16:33:50
     *@Params1:
     *@Return1:
     */
    chanegSafety(item) {
      this.$confirm(`确认改为安全航线吗?`, '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await API.AIRWAY.EditIssafe({
            id: item.id,
            issafe: 1
          });
          this.$message({
            type: 'success',
            message: '修改成功'
          });
          // 重新请求数据
          this.airway_list();

          // console.log('res安全航线', res);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    /**
     *@Description: 航线标签
     *@Date: 2023-10-10 17:00:10
     *@Params1:
     *@Return1:
     */
    showRouteLabel(item) {
      this.$emit('showRouteLabel', item);
    },
    /**@Description : 编辑航线，隐藏航线列表面板，显示规划航线面板
     *@Date : 2024-01-19 09:56:23
     *@Param :
     *@Return :
     */
    editRouteData(data) {
      // console.log(data, 'data');
      this.$nextTick(() => {
        // 隐藏航空航线列表面板
        this.airLineListPanelShowFlag = false;
        // 显示创建航线面板
        this.airLineCreatePanel = true;
        this.editData = data;
        let flightCourseJson = JSON.parse(data.flightCourseJson);
        // 设置场景
        this.createAirlineDialogForm.scene = flightCourseJson.scene;
        // 设置航线名称
        this.createAirlineDialogForm.name = data.flightName;
        // 设置执行方式，无人机还是机巢
        this.createAirlineDialogForm.actionWay = data?.actionWay ? data.actionWay : 'uav';
        // 初始化高度模式
        this.createAirlineDialogForm.altitudeMode = flightCourseJson.altitudeMode;
        // 初始化完成模式
        this.createAirlineDialogForm.finishedAction = flightCourseJson.finishedAction;
        // 全局高度
        this.createAirlineDialogForm.baseHeight = flightCourseJson.line.baseHeight;
        // 全局速度
        this.createAirlineDialogForm.baseSpeed = flightCourseJson.line.baseSpeed;
        // 跟随模式
        this.createAirlineDialogForm.isFollowAirLine = flightCourseJson.isFollowAirLine;
        // 绝对降落高度
        if (flightCourseJson.finishedAction == 'AutoLand') {
          this.createAirlineDialogForm.finishedActionParam = flightCourseJson.finishedActionParam || 0;
        }
        this.createAirlineDialogFormCopy = _.cloneDeep(this.createAirlineDialogForm);
        // console.log(data, '当前选中卡片数据');
        // console.log('当前选中卡片数据flightCourseJson', flightCourseJson);
        // console.log('当前选中卡片数据 this.createAirlineDialogFormCopy', this.createAirlineDialogFormCopy);
      });
    },
    // 设置全局高度
    flyHeightClickHandle(v, index) {
      // console.log('v', v);
      let c = parseFloat(this.createAirlineDialogForm.baseHeight) + parseFloat(v);
      if (c < 1) {
        this.$message({
          type: 'warning',
          message: '飞行高度小于1m，无法设置'
        });
      } else if (c > 500) {
        this.$message({
          type: 'warning',
          message: '飞行高度大于500m，请谨慎设置'
        });
        this.createAirlineDialogForm.baseHeight = c;
      }
      else {
        this.createAirlineDialogForm.baseHeight = c;
      }
    },
    //全局高度输入框
    flyHeightInputHandle(v) {
      if (!numReg.test(v)) {
        v = 1;
        this.$message({
          type: 'warning',
          message: '请输入数字'
        });
        return;
      }
      v = parseInt(v)
      if (isNaN(v)) {
        v = 1;
      }
      let c = parseFloat(v);
      if (c < 1) {
        this.$message({
          type: 'warning',
          message: '飞行高度小于1m，无法设置'
        });
        // if (v > 500) {
        //   this.createAirlineDialogForm.baseHeight = 500;
        // }
        if (v < 1) {
          this.createAirlineDialogForm.baseHeight = 1;
        }
      } else if (c > 500) {
        this.$message({
          type: 'warning',
          message: '飞行高度大于500m，请谨慎设置'
        });
        this.createAirlineDialogForm.baseHeight = v;
      }
      else {
        this.createAirlineDialogForm.baseHeight = v;
      }
      // console.log(v, 'vvvv');
    },
    //设置全局速度
    flySpeedClickHandle(v) {
      // if (!numReg.test(v)) {
      //   v = 1;
      //   this.$message({
      //     type: 'warning',
      //     message: '请输入数字'
      //   });
      //   return;
      // }
      // v = parseInt(v)
      // if (isNaN(v)) {
      //   v = 1;
      // }
      let a = parseInt(this.createAirlineDialogForm.baseSpeed) + parseInt(v);
      if (a < 1 || a > 20) {
        this.$message({
          type: 'warning',
          message: '飞行速度不在[1-20]范围内，无法设置'
        });
      } else {
        this.createAirlineDialogForm.baseSpeed = a;
      }
    },
    //设置全局速度输入框
    flySpeedInputHandle(v) {
      let a = parseInt(v);
      if (a < 1 || a > 20) {
        this.$message({
          type: 'warning',
          message: '飞行速度不在[1-15]范围内，无法设置'
        });
        if (a < 1) {
          this.createAirlineDialogForm.baseSpeed = 1;
        }
        if (a > 20) {
          this.createAirlineDialogForm.baseSpeed = 20;
        }
      } else {
        this.createAirlineDialogForm.baseSpeed = a;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.air-line-container {
  width: 100%;
  height: 100%;
  color: #fff;

  .air-line-list-panel {
    width: 100%;
    height: 100%;

    .header {
      width: 100%;
      height: 54px;
      background: #224d68;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .right {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          width: 50%;
          height: 98%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .title-selected {
          // border-bottom: 2px solid #00F5FF !important;

          span {
            color: #00f5ff !important;
          }
        }

        .title2 {
          margin-left: 5px;
        }
      }

      .icon-plus {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }

    .form {
      width: 100%;
      height: 121px;
      background-color: #133e55;
      box-sizing: border-box;
      padding: 15px 5px 0 5px;

      .line {
        height: 50%;
        width: 100%;
      }

      .line1 {
        // background-color: #6aa5a7;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .input-search1 {
          width: 35%;
        }

        .input-search2 {
          width: 27%;
          margin-left: 2px;
        }

        .input-search3 {
          width: 18%;
          margin-left: 2px;
        }

        .btn1 {
          width: 12%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          background: #4e87ac;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .line2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .item {
          height: 100%;
          width: 24%;
          display: flex;
          align-items: center;
          justify-content: center;
          // background-color: gold;
          margin-left: 10px;
          cursor: pointer;

          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }

          .img {
            width: 11px;
            height: 6px;
            margin-left: 10px;
          }
        }

        .panel {
          position: absolute;
          // border: 1px solid #16868F;
          top: 40px;
          // background-color: #0A293A;
          border-radius: 6px;
          padding: 5px 0 5px 0;
          display: flex;
          flex-direction: column;
          height: 60px;
          width: 120px;
          height: auto;
          background-image: url("~@/assets/img/i-card.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          z-index: 100;

          .item {
            height: 30px;
            width: 100%;
            text-align: left;
            margin-left: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 400;
            padding: 0 10px;
            box-sizing: border-box;
            color: #ffffff;
          }

          .item-selected {
            background-color: #064a89;
          }

          // .triangle {
          //   position: absolute;
          //   top: -20px;
          //   overflow: hidden;
          //   width: 0;
          //   height: 0;
          //   border-width: 10px;
          //   border-style: solid dashed dashed dashed;
          // }

          // .triangle_bg {
          //   left: 20px;
          //   z-index: 3;
          //   border-color: transparent transparent #0A293A transparent;
          // }

          // .triangle_border {
          //   left: 20px;
          //   border-color: transparent transparent #ffffff transparent;
          // }
        }

        .type {
          left: 10px;
          width: 100px;
        }

        .safe-status {
          left: 120px;
          width: 100px;
        }

        .air-status {
          left: 230px;
          height: 100px;
        }

        .item-input {
          width: 32%;
        }
      }

      /deep/ .el-input__inner {
        color: #fff;
        padding: 0 10px;
        box-sizing: border-box;
      }
    }

    .body {
      // 54+121=175
      box-sizing: border-box;
      padding: 5px 10px;
      height: calc(100% - 175px);
      width: 100%;
      background-color: rgba(10, 41, 58, 1);
      overflow-y: auto;
      position: relative;

      .card {
        margin-top: 10px;
      }

      #scrollToTopAL {
        position: absolute;
        // bottom: 2%;
        right: 10px;
        z-index: 100;
        width: 28px;
        height: 28px;
        // float: right;
        // margin-top: -50px;
        // top: 300px;
        cursor: pointer;
      }

      .card-body {
        width: 100%;
        height: calc(100% - 30px);
        box-sizing: border-box;
        // border: 1px solid yellow !important;
        overflow-y: auto !important;
      }

      .card-body::-webkit-scrollbar {
        display: none !important;
      }

      .pagation {
        height: 30px;
        box-sizing: border-box;
        padding: 0 0px;
        height: 30px;
        width: 100%;
        background-color: #0e3449;
        padding-top: 5px;
        // border: 1px solid red;

        /deep/ .el-pagination--small {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          padding: 0 !important;
        }

        /deep/ .el-pagination__total {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          margin-left: 5px;
        }

        /deep/ .btn-prev {
          background-color: #253b5a !important;
          color: #ffffff;
          width: 22px;
          border-radius: 2px;
        }

        /deep/ .btn-next {
          background-color: #253b5a !important;
          color: #ffffff;
          width: 22px;
          margin-left: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
        }

        /deep/ .el-pager {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;

          .number {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          li {
            margin-left: 2px;
            background-color: #253b5a !important;
            color: #ffffff;
            border-radius: 2px;
          }

          li.active {
            color: #ffffff;
            background-color: #409eff !important;
          }

          li:hover {
            border: 1px solid #367db4;
            background-color: #0c3549 !important;
          }
        }

        /deep/ .el-pagination__sizes .el-select--mini {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .el-input__inner {
            color: #ffffff;
          }
        }

        /deep/ .el-pagination__jump {
          margin-left: 5px !important;
          color: #ffffff;
          margin-top: -3px;
        }

        /deep/ .el-pagination__jump .el-input {
          width: 30px !important;
          background-color: #253b5a !important;

          .el-input__inner {
            background-color: #253b5a !important;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@/styles/wuhuCommon.scss";

.air-line-container {
  .create-new-line-dialog {
    background-color: #0a293a;

    .el-dialog__header {
      height: 40px;
      background: #133e55;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-dialog__title {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }

      .el-dialog__headerbtn {
        display: none;
      }
    }

    .el-dialog__body {
      padding: 10px 25px 10px;
      max-height: 700px;
      overflow: auto;
    }

    .el-dialog__footer {
      text-align: end;

      .el-button {
        width: 114px;
        height: 40px;
        background: #133d54;
        border-radius: 4px;
        border: 0;
        color: #fff;
      }

      .el-button--primary {
        width: 114px;
        height: 40px;
        background: #007aff;
        border-radius: 4px;
      }
    }

    .el-form-item__content {
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      width: 100%;
      // background-color: #007aff;
      height: auto !important;
      margin-top: -8px;

      .el-input__inner {
        color: #fff;
        // border: 1px solid #c0c0c0;
      }
    }

    .el-form-item__label {
      color: #fff;
      padding: 0;
      // height: 32px;
      margin-top: 6px;
    }

    .el-form-item {
      margin-bottom: 0px;
      // height: 40px;
    }

    .create-way-item {
      .el-form-item__content {
        border: 0;
        display: flex;
      }

      .create-way {
        width: 100px;
        // height: 40px;
        background: #133e55;
        border-radius: 4px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .create-way-manual {
        margin-left: 10px;
      }

      .create-way-selected {
        border: 1px solid #007aff;
      }
    }

    .average-height-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 4px;

      .btn {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        // border: 1px solid yellow;
        height: 32px;
        background-color: #1f526e;
        cursor: pointer;
        border-radius: 4px;
      }

      .el-input {
        width: 75px;
        // height: 30px;
      }

      .el-input__inner {
        background-color: #043957;
      }
    }

    .body-base-speed {
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: space-around;

      .img {
        height: 24px;
        width: 24px;
        cursor: pointer;
      }

      .slider {
        width: 45%;
      }

      .speed-input {
        width: 70px;
      }

      .el-input__inner {
        background-color: #043957;
      }

      .unit {
        color: #fff;
      }

      .el-slider__runway {
        height: 3px;
      }

      .el-slider__bar {
        height: 3px;
      }

      .el-slider__button {
        height: 12px;
        width: 12px;
        background-color: #2b72ea;
        border: 2px solid #fff;
      }
    }

    .card {
      background-color: #133e55;
      margin-bottom: 8px;
    }

    .card1,
    .card3 {
      width: 100%;
      height: 170px;
      background: #133e55;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .item {
        height: 50%;
        width: 100%;
        // border: 1px solid red;
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-direction: column;

        .title {
          color: #fff;
          // width: 125px;
          // height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          // line-height: 20px;
        }

        .body {
          width: 100%;
          height: auto;
        }
      }

      .item1 {
        .body {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #0a293a;

          .btn {
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            // border: 1px solid yellow;
            height: 40px;
            background-color: #1f526e;
            cursor: pointer;
          }

          .el-input {
            width: 75px;
            // height: 30px;
          }
        }
      }

      .item2 {
        .title {
          margin-top: 10px;
        }

        .body {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-content: space-around;

          .img {
            height: 24px;
            width: 24px;
            cursor: pointer;
          }

          .slider {
            width: 45%;
          }

          .speed-input {
            width: 70px;
          }

          .unit {
            color: #fff;
          }

          .el-slider__runway {
            height: 3px;
          }

          .el-slider__bar {
            height: 3px;
          }

          .el-slider__button {
            height: 20px;
            width: 20px;
            background-color: #2b72ea;
            border: 2px solid #fff;
          }
        }
      }
    }

    .card1 {
      .body {
        height: 30px;

        .btn {
          height: 30px !important;
        }

        .el-input__inner {
          height: 30px;
        }
      }
    }

    // .land {
    //     display: grid;
    //     grid-gap: 10px;
    //     gap: 10px;
    //     grid-template-columns: repeat(2, minmax(0, 1fr));
    // }
  }
}
</style>
