<template>
  <div class="cpt-new_home-tabs">
    <div
      v-for="item in options"
      :key="item.id"
      class="tab-item"
      :class="{ active: curr_data && curr_data.id === item.id }"
      @click="handle_change_tab(item)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      curr_data: null,
    };
  },
  watch: {
    value: {
      handler(value) {
        this.curr_data = value;
        console.log(
          "this.curr_datathis.curr_datathis.curr_data:",
          this.curr_data
        );
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handle_change_tab(item) {
      this.curr_data = item;
      this.$emit("input", this.curr_data);
      this.$emit("change", this.curr_data);
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-new_home-tabs {
  flex-shrink: 0;
  height: 57px;
  display: flex;
  .tab-item {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.3s;
    &.active,
    &:hover {
      background: #065a63;
      font-family: Microsoft YaHei;
      font-size: 16px;
      color: #fff;
      letter-spacing: 0;
      text-align: center;
    }
  }
}
</style>