<template>
  <div class="cpt-command-situation-detail dialog1027">
    <div class="dialog-header" v-interact>
      <div class="dialog-header__icon"/>
      <div class="dialog-header__title">警情详情</div>
      <div class="dialog-header__close" @click="flight_task_chage_view(1)">关闭</div>
    </div>

    <div class="flight_detail_box dialog-content">
      <div class=" dialog-content-item  textarea">
        <span class="dialog-content__label">警情事件内容：</span>
        <div class="dialog-content__text">{{ situation_detail.content || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">报警时间：</span>
        <div class="dialog-content__text">{{ situation_detail.receiveTime || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">航线名称：</span>
        <div class="dialog-content__text">{{ situation_detail.flight_line_name || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">接警员(分机)：</span>
        <div class="dialog-content__text">{{ situation_detail.receiveNumber || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">飞手：</span>
        <div class="dialog-content__text">
          {{
            situation_detail.team && situation_detail.team.length
              ? situation_detail.team.join('、')
              : '暂无'
          }}
        </div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">更新时间：</span>
        <div class="dialog-content__text">{{ situation_detail.edittime || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">警情执行状态：</span>
        <div class="dialog-content__text">{{ get_status(status) }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">调度状态：</span>
        <div class="dialog-content__text">{{ situation_detail.approvalStatus || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">添加时间：</span>
        <div class="dialog-content__text">{{ situation_detail.addTime || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">责任单位：</span>
        <div class="dialog-content__text">{{ situation_detail.policeUnitTitle || '暂无' }}</div>
      </div>
      <div class=" dialog-content-item">
        <span class="dialog-content__label">协助单位：</span>
        <div class="dialog-content__text">{{ situation_detail.assistUnitTitle || '暂无' }}</div>
      </div>
      <div class=" textarea dialog-content-item">
        <span class="dialog-content__label">内容描述：</span>
        <div class="dialog-content__text">{{ situation_detail.remark || '暂无' }}</div>
      </div>

      <div class="btn-box1027">
        <div class="btn1027" @click="flight_task_chage_view(1)">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  inject: ['flight_task_chage_view'],
  computed: {
    ...mapGetters(['situation_detail']),
  },
  methods: {
    get_status(item) {
      if (item == -1) {
        item = 5
      }
      const obj = {
        0: '待审批',
        1: '审批不通过',
        2: '待执行',
        3: '执行中',
        4: '已完成',
        5: '待派发',
      }
      return obj[item]
    },
  },
}
</script>

<style lang="scss" scoped>
.cpt-command-situation-detail {
  position: absolute;
  width: 760px;
  // height: 608px;
  box-sizing: border-box;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  left: 100px;
  top: 170px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 44px;
  background: rgba(16, 65, 215, 0.2);
  box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
  border-radius: 10px 10px 0 0;
}

.hd-box {
  font-family: MicrosoftYaHei-Bold;
  font-size: 18px;
  color: #00ffff;
  letter-spacing: 0;
  font-weight: 700;
  margin-left: 20px;
}
.el-button {
  border: 0;
  opacity: 0.8;
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #00ffff;
  text-align: center;
  font-weight: 400;
  width: 114px;
  height: 40px;
  padding: 10px 20px;
  background: rgba(0, 34, 140, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 20px;
}
.flight_detail_box {
  box-sizing: border-box;
  // width: 1050px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .textarea {
    div {
      height: 80px;
      line-height: normal;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>
