<template>
  <div class="content">
    <div class="content-head" v-interact>
      <div class="left">
        <!-- <img class="content-head__icon" src="@/assets/zong_lan_ye_img/frame/icon.png" alt="" /> -->
        {{ form ? '编辑设备' : '注册设备' }}
      </div>
      <div class="right" @click="() => $emit('closedevice')">
        <img src="@/assets/img/i40.png" alt="" class="img">
      </div>
    </div>
    <div class="content_box">
      <Battery v-if="active == 1" :form="form" @closedevice="$emit('closedevice')" />
      <Mount v-if="active == 2" :form="form" @closedevice="$emit('closedevice')" />
      <BaseStation v-if="active == 3" :form="form" @closedevice="$emit('closedevice')" />
      <Uav v-if="active == 4" :form="form" @closedevice="$emit('closedevice')" />
      <Nest v-if="active == 5" :form="form" @closedevice="$emit('closedevice')" />
    </div>
  </div>
</template>

<script>
import API from "@/api";
import Uav from "./form/uav.vue"
import Battery from "./form/battery.vue"
import Mount from "./form/mount.vue"
import BaseStation from "./form/baseStation.vue"
import Nest from "./form/nest.vue"
export default {
  props: ['active', 'form'],
  components: {
    Uav,
    Battery,
    Mount,
    BaseStation,
    Nest,
  },
  data () {
    return {
      url: "",
    };
  },
  methods: {
    // /*无人机类型 */
    // async getType() {
    //   let res = await API.DEVICE.ListTypeAll();
    //   this.dronetypeList = res;
    // },
    // /*无人机型号 */
    // async getCate() {
    //   let res = await API.DEVICE.ListCate();
    //   this.cateList = res;
    // },
    // /*机构 */
    // async getOptions() {
    //   let res = await API.DEPARTMENT.Drd();
    //   // each(res);
    //   this.options = res || [];
    // },
    // //飞手列表
    // async getFlyerList() {
    //   let data = await API.USER.DrdPilot();
    //   this.flyerList = data || [];
    // },
    // async handle_http_request(e) {
    //   let file = e.file,
    //     fd = new FormData();
    //   fd.append("uploadFiles", file);
    //   let res = await API.MAPMANAGE.fileUpload(fd);
    //   if (res.status == 1) {
    //     this.ruleForm.image = res.data[0].url;
    //   } else {
    //     this.$message.error(res?.message);
    //   }
    // },
    // submit() {
    //   this.$refs["ruleForm"].validate((valid) => {
    //     if (valid) {
    //       API.DEVICE.Add(this.ruleForm).then((res) => {
    //         if (res == 1) {
    //           this.$message.success("创建成功!");
    //           this.$emit("closedevice");
    //         }
    //       });
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    // changePic(file, fileList) {
    //   if (fileList.length > 0) {
    //     this.fileList = [fileList[fileList.length - 1]]; //这一步，是 展示最后一次选择文件
    //   }
    // },
    // onRemove() {
    //   this.ruleForm.image = null;
    // },
  },
  mounted () {
    let baseUrl = process.env.VUE_APP_BASE_API;
    this.url = baseUrl + "/upload/v2_uploads";
    console.log(this.form, 'jhnsdhuifigagfhaghfgh');
    // this.getType();
    // this.getCate();
    // this.getOptions();
    // this.getFlyerList();
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  position: fixed;
  // left: 596px;
  left: 32%;
  // bottom: 0px;
  // top: -80%;
  top: -65%;
  // bottom: 2%;
  width: 676px;
  height: auto;
  max-height: 650px;
  // height: auto;
  // background: rgba(12, 34, 73, 0.7);
  // border-radius: 10px;
  // border: 1px solid rgba(26, 92, 246, 0.5);
  // backdrop-filter: blur(1px);
  z-index: 1230;
  box-sizing: border-box;
  background-color: #0A293A;
  overflow-y: auto;

  .content-head {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 32px;
    // background: linear-gradient(180deg, #9198FF 0%, rgba(45, 81, 153, 0.45) 40%, #05091A 100%);
    // box-shadow: inset 0px 0px 10px 2px #3F9DFF;
    // border-radius: 10px 10px 0px 0px;
    // border: 1px solid #427DFF;
    padding: 0 12px;
    height: 36px;
    background-color: #224D68;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;

    .left {
      display: flex;
      align-items: center;
      font-size: 20px;
      // font-family: YouSheBiaoTiHei;
      // background-image: -webkit-linear-gradient(right, #e3aa77, #f5cda9, #f9ecd3, #fcdbb1, #edb07a);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      letter-spacing: 0;
      font-weight: 700;
      color: #ffffff;
    }

    .right {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #D2DFFF;
      line-height: 19px;

      .img {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }

    .content-head__icon {
      width: 19px;
      height: 25px;
      margin-right: 4px;
    }
  }

  .content_box {
    flex: 1;
    width: 643px;
    padding: 16px;
    overflow-y: auto;
    overflow-x: hidden;

  }

}
</style>