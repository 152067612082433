var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content dialog1027"},[_c('div',{directives:[{name:"interact",rawName:"v-interact"}],staticClass:"content_head dialog-header-new"},[_c('div',{staticClass:"head_left"},[_c('div',{staticClass:"header-title"},[_vm._v(_vm._s(_vm.id ? "编辑" : "新建"))])]),_c('div',{staticClass:"close header-close  dialog-header__close",on:{"click":_vm.close}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/img/i40.png"),"alt":""}})])]),_c('div',{staticClass:"content_box dialog-content"},[_c('el-form',{ref:"ToneForm",attrs:{"model":_vm.ToneForm,"rules":_vm.rules,"label-width":"60px"}},[_c('el-form-item',{attrs:{"label":"名称:","prop":"name"}},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入名称"},model:{value:(_vm.ToneForm.name),callback:function ($$v) {_vm.$set(_vm.ToneForm, "name", $$v)},expression:"ToneForm.name"}})],1),_c('el-form-item',{attrs:{"label":"时间:","prop":"time"}},[_c('el-date-picker',{attrs:{"prefix-icon":"ccc","type":"datetimerange","range-separator":"至","value-format":"yyyy-MM-dd HH:mm:ss","start-placeholder":"开始日期","end-placeholder":"结束日期","popper-class":"flight-log","collapse-tags":"","picker-options":_vm.changePicker},model:{value:(_vm.pickTime),callback:function ($$v) {_vm.pickTime=$$v},expression:"pickTime"}})],1)],1),_c('div',{staticClass:"org_list"},[_c('div',{staticClass:"head"},[_vm._v("参调单位:")]),_vm._m(0),_c('div',{staticClass:"list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"tbody"},[_c('div',{staticClass:"td"},[_c('el-cascader',{attrs:{"options":_vm.unitList,"clearable":"","showAllLevels":false,"placeholder":"请选择所属机构","props":{
                value: 'id',
                label: 'orgName',
                children: 'children',
                emitPath: false,
                checkStrictly: true,
              }},on:{"change":function (val) { return _vm.onChange(val, index); }},model:{value:(item.organizationId),callback:function ($$v) {_vm.$set(item, "organizationId", $$v)},expression:"item.organizationId"}})],1),_c('div',{staticClass:"td"},[_c('el-select',{attrs:{"clearable":""},model:{value:(item.uavId),callback:function ($$v) {_vm.$set(item, "uavId", $$v)},expression:"item.uavId"}},_vm._l((item.dronelist),function(item2){return _c('el-option',{key:item2.id,attrs:{"label":item2.deviceName,"value":item2.id}})}),1)],1),_c('div',{staticClass:"td td-autocomplete"},[_c('el-autocomplete',{staticClass:"autocomplete-dd",attrs:{"fetch-suggestions":_vm.handle_query_address_async,"placeholder":"请输入目标位置","trigger-on-focus":false,"clearable":""},on:{"select":function (val) { return _vm.handle_query_select_async(val, item); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var item = ref.item;
return [_c('div',[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(item.name))])])]}}],null,true),model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1),_c('div',{staticClass:"td"},[_c('div',{staticClass:"delbtn btn10272",on:{"click":function($event){return _vm.deleteOrg(index)}}},[_vm._v("删除")])])])}),0),_c('div',{staticClass:"add",on:{"click":_vm.addOrg}},[_c('span',{staticClass:"iconfont icon-tianjia add-org-icon"})])]),_c('div',{staticClass:"btn-box1027"},[_c('div',{staticClass:" btn10271",on:{"click":_vm.sumbit}},[_vm._v("提交")])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"th"},[_c('div',{staticClass:"td"},[_vm._v("单位名称")]),_c('div',{staticClass:"td"},[_vm._v("无人机编号")]),_c('div',{staticClass:"td"},[_vm._v("目的地")]),_c('div',{staticClass:"td"},[_vm._v("操作")])])}]

export { render, staticRenderFns }