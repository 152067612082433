<template>
  <div class="cpt-command-airtask-edit">
    <div class="title-box" v-interact>创建任务</div>
    <img
      src="~@/assets/images/accident/close.png"
      class="img-close"
      @click="change_page"
    />
    <div class="form-box">
      <div class="form-inner-box">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="任务名称" prop="taskTitle">
            <el-input
              v-model="form.taskTitle"
              placeholder="请输入任务名称"
              clearable
            />
          </el-form-item>
          <!-- <el-form-item label="目标位置" prop="destination">
            <div class="field-group" :title="form.destination">
              <el-autocomplete
                v-model="form.destination"
                :fetch-suggestions="handle_query_address_async"
                placeholder="请输入目标位置地址"
                :trigger-on-focus="false"
                @select="handle_select_address"
                clearable
                style="width: 100%"
              >
                <template slot-scope="{ item }">
                  <div>
                    <span style="font-size: 14px; color: #333">{{
                      item.name
                    }}</span>
                    <span
                      style="font-size: 12px; color: #999; margin-left: 12px"
                      >{{ item.address }}</span
                    >
                  </div>
                </template>
              </el-autocomplete>
              <div @click="handle_pick" class="el-icon-location-outline"></div>
            </div>
          </el-form-item> -->
          <el-form-item label="航线名称" prop="flightLineId">
            <el-select
              v-model="form.flightLineId"
              placeholder="请选择航线名称"
              :popper-append-to-body="false"
              clearable
            >
              <el-option
                v-for="item in drd.airway"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              <span style="float: left">{{ item.title }}</span>
              <span style="float: left; color: #00b900; font-size: 13px">{{ item.issafe == 1 ? '（安全）' : '' }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="任务类别" prop="cateId">
            <el-select
              v-model="form.cateId"
              placeholder="请选择任务类别"
              clearable
            >
              <el-option
                v-for="cate in drd.cate"
                :key="cate.id"
                :label="cate.title"
                :value="cate.id"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item label="任务类型" prop="taskTypeId">
            <el-select
              v-model="form.taskTypeId"
              placeholder="请选择任务类型"
              clearable
            >
              <el-option
                v-for="type in drd.type"
                :key="type.id"
                :label="type.title"
                :value="type.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="任务场景" prop="taskTypeId">
            <el-select
              v-model="form.cenarioName"
              placeholder="请选择任务场景"
              :popper-append-to-body="false"
              clearable
            >
              <el-option
                v-for="(item, index) in cenarioList"
                :key="index"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="责任单位" prop="organizationId">
            <!-- <el-select
              v-model="form.organizationId"
              placeholder="请选择责任单位"
              clearable
            >
              <el-option
                v-for="department in drd.department"
                :key="department.id"
                :label="department.title"
                :value="department.id"
              />
            </el-select> -->
            <el-cascader
              placeholder="请选择所属机构"
              v-model="form.organizationId"
              :options="drd.department"
              :show-all-levels="false"
              :props="{
                children: 'children',
                label: 'orgName',
                value: 'id',
                checkStrictly: true,
                emitPath: false,
              }"
              clearable
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="飞手" prop="userIds">
            <el-select
              v-model="form.userIds"
              placeholder="请选择飞手"
              :popper-append-to-body="false"
              multiple
              clearable
            >
              <el-option
                v-for="team in drd.team"
                :key="team.id"
                :label="team.title"
                :value="team.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="电池编号" prop="batteryNumbers">
            <el-select
              v-model="form.batteryNumbers"
              clearable
              placeholder="请选择电池编号"
              :popper-append-to-body="false"
              multiple
              :multiple-limit="2"
            >
              <el-option
                v-for="item in drd.batteryList"
                :key="item.id"
                :label="item.title"
                :value="item.title"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预计开始时间" prop="startedAt">
            <el-date-picker
              :picker-options="pickerOptions"
              v-model="form.startedAt"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择预计开始时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="内容描述" prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              :rows="2"
              placeholder="请输入内容描述"
            >
            </el-input>
          </el-form-item>
          <el-form-item class="btns">
            <el-button @click="handle_edit">创建</el-button>
            <el-button @click="handle_cancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { Utils } from "@/lib/cesium";
import { mapGetters } from "vuex";
import data from "@/pages/command/airtask/data";
let handler = null;
export default {
  props: ["taskCateId"],
  inject: ["task_add"],
  data() {
    return {
      // 阻止点击事件
      ...data,
      flag: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; // 设置选择今天以及今天之后的日
          // return time.getTime() > Date.now(); //设置选择今天以及今天以前的日期
          // return time.getTime() < Date.now();//设置选择今天之后的日期（不能选择当天时间）
          // return time.getTime() > Date.now() - 8.64e7 //设置选择今天之前的日期（不能选择当天）
          // 设置当天23：59：59可选
          // let currentTime = this.getNowMonthDay() + ` 23:59:59`
          // return time.getTime() > new Date(currentTime).getTime()
        },
              selectableRange:`${Date().split(" ")[4]} - 23:59:59`

        // selectableRange: '18:30:00 - 24:00:00'
      },
      drd: {
        airway: [],
        handle_type: [
          { title: "申报", id: 0 },
          { title: "报备", id: 1 },
        ],
        cate: [
          { title: "常态任务", id: 2 },
          { title: "临时紧急任务", id: 3 },
        ],
        type: [],
        department: [],
        team: [],
        batteryList: [],
      },
      cenarioList: [],
      timer: null,
      form: {
        taskTitle: null,
        destination: null,
        flightLineId: null,
        cenarioName: null, //任务场景名称
        cateId: undefined,
        taskTypeId: undefined,
        organizationId: undefined,
        userIds: [],
        remark: undefined,
        startedAt: null,
      },
    };
  },

  computed: {
    ...mapGetters(["user_info"]),
  },

  methods: {
    // 任务场景数据
    async getAllTaskCenarioList() {
      let data = await API.BATTERY.getAllTaskCenarioList();
      this.cenarioList = data;
    },
    async drd_batteryList() {
      let data = await API.BATTERY.List();
      this.drd.batteryList = data || [];
    },
    async drd_airway() {
      let res = await API.AIRWAY.Drd();
      this.drd.airway = res || [];
    },
    async drd_type() {
      let res = await API.TASK.DrdType();
      this.drd.type = res || [];
    },
    async drd_department() {
      let res = await API.DEPARTMENT.Drd();
      this.drd.department = [res] || [];
    },
    async drd_team() {
      let res = await API.USER.DrdPilot();
      this.drd.team = res || [];
    },
    throttle(fn, wait) {
      var context = this;
      var args = arguments;

      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(function () {
        fn.apply(context, args);
      }, wait);
    },
    async handle_edit() {
      this.throttle(this.fn, 1000);
    },
    async fn() {
      let res = await API.TASK.Add(this.form);
      // this.$emit("submit", this.form);
      // this.flag=true
      if (res) {
        this.$el_message("创建成功", () => {
          this.task.add.visible = 0;
        });
      } else {
        this.$message.error("请填写完整信息！");
      }
    },
    handle_cancel() {
      this.$refs["form"] && this.$refs["form"].resetFields();
      this.$emit("cancel");
    },

    handle_pick() {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();

      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction(async (movement) => {
        let position = viewer.scene.camera.pickEllipsoid(
          movement.position,
          viewer.scene.globe.ellipsoid
        );
        let wgs84 = Utils.transformCartesian2WGS84(position);

        let address = await API.MAP.Regeo({
          location: `${wgs84.lng},${wgs84.lat}`,
        });

        if (address) {
          this.form.destination = address;
          this.form.lng = wgs84.lng;
          this.form.lat = wgs84.lat;

          handler.destroy();
          handler = null;
        } else {
          this.$el_message("地址解析失败", () => {}, "error");
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      this.$el_message("请在地图上选择目标位置");
    },

    handle_query_address_async(address, cb) {
      API.MAP.Geo({ keywords: address })
        .then((res) => {
          cb(res.data.data.rows);
        })
        .catch((e) => cb([]));
    },

    handle_select_address(item) {
      if (item) {
        let location = item.location.split(",");
        this.form.destination = item.name;
        this.form.lng = Number(location[0]);
        this.form.lat = Number(location[1]);

        let cesium_layer = this.g_cesium_layer();
        let viewer = cesium_layer.viewer();
        viewer.camera.flyTo({
          destination: Utils.transformWGS842Cartesian({
            lng: this.form.lng,
            lat: this.form.lat,
            alt: 10000,
          }),
        });
      }
    },
  },
  async mounted() {
    //Promise.all([
    this.form.cateId = this.taskCateId;
    await this.drd_airway();
    await this.drd_type();
    await this.drd_department();
    await this.drd_team();
    await this.drd_batteryList();
    this.getAllTaskCenarioList();
    //]);

    this.user_info && (this.form.organizationId = this.user_info.departmentId);
  },

  destroyed() {
    handler && handler.destroy() && (handler = null);
  },

  inject: ["g_cesium_layer", "change_page"],
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixins.scss";
.cpt-command-airtask-edit {
  position: absolute;
  width: 640px;
  height: 786px;
  box-sizing: border-box;
  background: url("~@/assets/images/plate_bg_01.png") no-repeat;
  background-size: 100% 100%;
  .img-close {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .title-box {
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
    height: 56px;
    box-sizing: border-box;
    display: flex;
    line-height: 3.2;
    padding-left: 132px;
    margin-right: 80px;
    @include linear_gradient_border(auto, 0, 0, 0);
  }
  .form-box {
    height: calc(100% - 54px);
    box-sizing: border-box;
    padding: 20px 80px 20px 60px;
    .form-inner-box {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      ::v-deep .el-form {
        .el-form-item {
          .el-form-item__label {
            font-family: Microsoft YaHei;
            font-size: 16px;
            color: #ccedff;
            text-align: right;
            font-weight: 400;
          }
          .el-form-item__content {
            background: #000000;
            border: 1px solid #08c2d1;
            .field-group {
              display: flex;
              align-items: center;
              .el-icon-location-outline {
                color: #08c2d1;
                font-size: 26px;
                cursor: pointer;
                width: 34px;
              }
            }
            .el-input {
              .el-input__inner {
                background: transparent;
                border: none;
                color: #08c2d1;
              }
            }
            .el-textarea {
              background: #000000;
              border: 1px solid #08c2d1;
              .el-textarea__inner {
                background: transparent;
                border: none;
                color: #08c2d1;
              }
            }
            .el-button {
              width: 114px;
              height: 40px;
              background: #129c9c;
              font-family: PangMenZhengDao;
              font-size: 22px;
              color: #ffffff;
              text-align: center;
              font-weight: 400;
              border: none;
              border-radius: 0;
            }
          }
          &.btns {
            .el-form-item__content {
              background: none;
              border: none;
            }
          }
          .el-date-editor.el-input {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
