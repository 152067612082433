<template>
  <div class="cpt-command-situation-detail">
    <div class="title-box" v-interact>警情详情</div>
    <img
      src="~@/assets/images/accident/close.png"
      class="img-close"
      @click="change_page"
    />
    <div class="flight_detail_box">
      <div class="flight_detail_list textarea">
        <span>警情事件内容</span>
        <div>{{ situation_detail.content || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>报警时间</span>
        <div>{{ situation_detail.receiveTime || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>航线名称</span>
        <div>{{ situation_detail.flight_line_name || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>接警员(分机)</span>
        <div>{{ situation_detail.receiveNumber || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>飞手</span>
        <div>
          {{
            situation_detail.team && situation_detail.team.length
              ? situation_detail.team.join("、")
              : "暂无"
          }}
        </div>
      </div>
      <div class="flight_detail_list">
        <span>更新时间</span>
        <div>{{ situation_detail.edittime || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>警情执行状态</span>
        <div>{{ get_status(status) }}</div>
      </div>
      <div class="flight_detail_list">
        <span>调度状态</span>
        <div>{{ situation_detail.approvalStatus || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>添加时间</span>
        <div>{{ situation_detail.addTime || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>责任单位</span>
        <div>{{ situation_detail.policeUnitTitle || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>协助单位</span>
        <div>{{ situation_detail.assistUnitTitle || "暂无" }}</div>
      </div>
      <div class="flight_detail_list textarea">
        <span>内容描述</span>
        <div>{{ situation_detail.remark || "暂无" }}</div>
      </div>
    </div>
    <el-button @click="$emit('handleClick')">关闭</el-button>
  </div>
</template>

<script>
import Popup from "@/components/popup";
import PopupItem from "@/components/popup-item";
import { mapGetters } from "vuex";

export default {
  components: {
    Popup,
    PopupItem,
  },
  data() {
    return {};
  },
  props:["status"],
  inject: ["change_page"],
  computed: {
    ...mapGetters(["situation_detail"]),
  },
  mounted(){
  },
  methods: {
    changeStatus(item) {
      const obj = { 0: "待执行", 1: "已完成", 3: "执行中" };
      return obj[item];
    },
    get_status(item) {
      if (item == -1) {
        item = 5;
      }
      const obj = {
        0: "待审批",
        1: "审批不通过",
        2: "待执行",
        3: "执行中",
        4: "已完成",
        5: "待派发",
      };
      return obj[item];
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-situation-detail {
  position: absolute;
  width: 1220px;
  height: 696px;
  box-sizing: border-box;
  background: url("~@/assets/images/plate/flight_bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 0 25px;
}
.title-box {
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #dce9ff;
  font-weight: 400;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  line-height: 3.2;
  padding-left: 132px;
}
.img-close {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.el-button {
  width: 114px;
  height: 40px;
  padding: 10px 20px;
  background: #129c9c;
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #ffffff;
  border: none;
  margin-left: 530px;
  margin-top: 20px;
}
.flight_detail_box {
  box-sizing: border-box;
  margin: auto;
  padding-top: 35px;
  font-family: MicrosoftYaHeiUI;
  font-size: 16px;
  color: #ccedff;
  width: 1050px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .flight_detail_list {
    width: 46%;
    display: flex;
    line-height: 40px;
    margin-bottom: 23px;
    > span {
      margin-right: 16px;
      display: inline-block;
      width: 105px;
      text-align: right;
    }
    > div {
      box-sizing: border-box;
      width: 396px;
      // height: 40px;
      background: #000000;
      border: 1px solid #08c2d1;
      padding: 0 15px;
    }
  }
  .textarea {
    div {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 80px;
      line-height: normal;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>
