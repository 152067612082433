<!-- 头部左右跳转栏 -->
<template>
  <div class="cpt-command-page-tabs">
    <!-- <div class="left_tabs tab">
      <div
        v-for="item in optionsLeft.slice(0, showNum)"
        :key="item.id"
        :class="{
          selected: item.id == curr_value,
        }"
        @click="handle_tab_click(item)"
      >
        {{ item.title }}
      </div>
      <span class="triangle" @click="handleClick(0)"></span>
    </div> -->
    <!-- <div class="center_tabs"></div> -->
    <!-- <div class="right_tabs tab">
      <div
        v-for="item in optionsRight.slice(0, showNum)"
        :key="item.id"
        :class="{
          selected: item.id == curr_value,
        }"
        @click="handle_tab_click(item)"
      >
        {{ item.title }}
      </div>
      <span class="triangle" @click="handleClick(1)"></span>
    </div> -->
  </div>
</template>

<script>
import Bus from '@/assets/ligature'
export default {
  props: {
    value: {
      type: Number | String,
      default: () => 1,
    },
  },
  data() {
    return {
      curr_value: this.value,
      showNum: 3,
      optionsLeft: [
        // { id: 2, title: '警情中心' },
        { id: 1, title: "首页" },
        { id: 3, title: "空域管理" },
        { id: 11, title: "设备监测" },
        { id: 4, title: "航空航线" },
        
      ],
      optionsRight: [
        { id: 5, title: "飞行任务" },
        { id: 6, title: "人员管理" },
        { id: 7, title: "设备管理" },
        { id: 12, title: '影像管理' },
        // { id: 8, title: '地图管理' },
        { id: 9, title: "飞控中心" },
      ],
    };
  },
  watch: {
    value: {
      handler(value) {
        this.curr_value = value;
        // console.log(value,"什么值");
      },
      immediate: true,
    },
  },
  created() {
    // 由于尽量不动原代码,采用bus从其他页面调用此方法,用这个方法调用原方法
    // 调用路径@/components/command/header/index.vue
    Bus.$on('tap', (item) => {
      this.curr_value = item.id
      this.handle_tab_click(item)
    })

    // 点击按钮进行显示隐藏控制
    Bus.$on('faketap', (item) => {
      this.curr_value = item.id
      this.handle_tab_click(item)
    })

  },
  methods: {
    handle_tab_click (item) {
      console.log(item, 'handle_tab_click');

      this.$emit("isN")
      if (item.id == 9) {
        let { href } = this.$router.resolve({ path: "/fckernel" });
        window.open(href, "_blank");
      } else {
        this.curr_value = item.id;
        this.$emit("input", this.curr_value);
        this.$emit("tabChange", item);
      }
    },
    handleClick (val) {
      if (val == 0) {
        let brr = [].concat(this.optionsLeft);
        for (let i = 0; i < this.optionsLeft.length; i++) {
          if (i == this.optionsLeft.length - 1) {
            brr[i] = this.optionsLeft[0];
          } else {
            brr[i] = this.optionsLeft[i + 1];
          }
        }
        this.optionsLeft = brr;
      } else {
        let brr = [].concat(this.optionsRight);
        for (let i = 0; i < this.optionsRight.length; i++) {
          if (i == 0) {
            brr[i] = this.optionsRight[this.optionsRight.length - 1];
          } else {
            brr[i] = this.optionsRight[i - 1];
          }
        }
        this.optionsRight = brr;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-page-tabs {
  position: absolute;
  display: flex;
  top: 18px;
  left: 50%;
  transform: translateX(calc(-50% + 1px));
  z-index: 99;
  .tab {
    width: 320px;
    height: 35px;
    display: flex;
    line-height: 35px;
    justify-content: space-between;
    box-sizing: border-box;
    font-family: PangMenZhengDao;
    font-size: 18px;
    color: #ffffff;
    & > div {
      cursor: pointer;
      &:hover {
        color: #0ee5f8;
      }
    }
    .selected {
      color: #0ee5f8;
    }
  }
  .left_tabs {
    background: url("~@/assets/images/tabs/tab_left.png") no-repeat;
    padding-left: 40px;
    padding-right: 22px;
    .triangle {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid #fff;
      border-bottom: 10px solid transparent;
      transform: rotateZ(-45deg);
      position: absolute;
      left: 22px;
      top: 12px;
      cursor: pointer;
    }
    .triangle:hover {
      border-left: 10px solid #ffc15b;
    }
  }
  .center_tabs {
    width: 175px;
    height: 35px;
  }
  .right_tabs {
    background: url("~@/assets/images/tabs/tab_right.png") no-repeat;
    padding-left: 24px;
    padding-right: 40px;
    .triangle {
      content: "";
      width: 0;
      height: 0;
      border-right: 10px solid #fff;
      border-bottom: 10px solid transparent;
      transform: rotateZ(45deg);
      position: absolute;
      right: 22px;
      top: 12px;
      cursor: pointer;
    }
    .triangle:hover {
      border-right: 10px solid #ffc15b;
    }
  }
}
</style>
