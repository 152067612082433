<!-- 手动规划 -->
<template>
  <div>
    <div class="right-box mt10">
      <div class="before-point" :class="{ disabled: action.curr_index <= 0 }" @click="handle_before_point">
        上一航点
      </div>
      <div class="no">
        <template v-if="airline.points.length === 0">
          <span class="current">0</span>
          /
          <span class="total">0</span>
        </template>
        <template v-else>
          <span class="current">{{ action.curr_index + 1 }}</span>
          /
          <span class="total">{{ airline.points.length }}</span>
        </template>
      </div>
      <div class="after-point" :class="{
        disabled: action.curr_index >= airline.points.length,
      }" @click="handle_after_point">
        下一航点
      </div>
    </div>
    <div class="ctx-box">
      <div class="form-box">
        <el-form ref="form" :model="form" :rules="rules" label-width="96px">
          <el-form-item label="航线名称">
            <el-input :disabled="true" v-model="airline.filename" />
          </el-form-item>
          <el-form-item label="航线速度">
            <div class="item-group">
              <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model="airline.line.baseSpeed" />
              <span class="unit">( m/s )</span>
            </div>
          </el-form-item>
          <el-form-item label="目标位置" prop="destination">
            <div class="item-group" :title="form.destination">
              <el-autocomplete :popper-append-to-body="false" v-model="destination.name"
                :fetch-suggestions="handle_query_address_async" placeholder="请输入目标位置" :trigger-on-focus="false"
                @select="handle_select_address" clearable>
                <template slot-scope="{ item }">
                  <div>
                    <span style="font-size: 14px">{{ item.name }}</span>
                    <span style="font-size: 12px; color: #999; margin-left: 12px">{{ item.address }}</span>
                  </div>
                </template>
              </el-autocomplete>
              <div @click="handle_pick('address')" class="el-icon-location-outline"></div>
            </div>
          </el-form-item>
          <el-form-item label="选择机巢" v-if="flag" prop="nestId">
            <el-select v-model="form.nestId" :popper-append-to-body="false">
              <el-option v-for="item in getNestListForLineList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="场景" prop="scene">
            <el-select v-model="scene" :popper-append-to-body="false" @change="handle_change">
              <el-option v-for="item in scene_list" :key="item.id" :label="item.name" :value="item.name" />
            </el-select>
          </el-form-item>

          <el-form-item label="起点" prop="startPoint" class="start-point">
            <div class="item-group">
              <!-- <el-autocomplete :popper-append-to-body="false" v-model="destStart.name" -->
              <el-autocomplete :popper-append-to-body="false" v-model="form.startPoint"
                :fetch-suggestions="handle_query_address_async" placeholder="请输入起点位置" :trigger-on-focus="false"
                @select="handle_select_address_start" clearable>
                <template slot-scope="{ item }">
                  <div>
                    <span style="font-size: 14px">{{ item.name }}</span>
                    <span style="font-size: 12px; color: #999; margin-left: 12px">{{ item.address }}</span>
                  </div>
                </template>
              </el-autocomplete>
              <div @click="handle_pick('startPoint')" class="el-icon-location-outline"
                :class="{ active: this.getStartPoint }"></div>
            </div>
            <div class="btn1027" @click="onCreateRoute" v-loading="createPlanLoading">
              {{ createPlanLoading ? "取消" : "生成航线" }}
            </div>
          </el-form-item>
          <el-form-item label="终点" prop="endPoint">
            <div class="item-group">
              <!-- <el-autocomplete :popper-append-to-body="false" v-model="destEnd.name" -->
              <el-autocomplete :popper-append-to-body="false" v-model="form.endPoint"
                :fetch-suggestions="handle_query_address_async" placeholder="请输入终点位置" :trigger-on-focus="false"
                @select="handle_select_address_end" clearable>
                <template slot-scope="{ item }">
                  <div>
                    <span style="font-size: 14px">{{ item.name }}</span>
                    <span style="font-size: 12px; color: #999; margin-left: 12px">{{ item.address }}</span>
                  </div>
                </template>
              </el-autocomplete>
              <div @click="handle_pick('endPoint')" class="el-icon-location-outline"
                :class="{ active: this.getEndPoint }"></div>
            </div>
          </el-form-item>

          <el-form-item label="高度" prop="alt">
            <div class="item-group">
              <el-input @change="(val) => handle_change_field('alt', val)" v-model.number="form.alt"
                oninput="value=value.replace(/[^0-9.]/g,'')" />
              <span class="unit">( m )</span>
            </div>
          </el-form-item>

          <el-form-item label="偏航角">
            <div class="item-group">
              <el-input-number @change="(val) => handle_change_field('pitch', val)" v-model="form.pitch" :min="-90"
                :max="90" label="描述文字" oninput="value=value.replace(/[^0-9.]/g,'')"></el-input-number>
              <span class="unit">( ° )</span>
            </div>
          </el-form-item>
          <el-form-item label="俯仰角">
            <div class="item-group">
              <el-input-number @change="(val) => handle_change_field('gimbalPitch', val)" v-model="form.gimbalPitch"
                :min="-90" :max="90" label="描述文字" oninput.native="value=value.replace(/[^0-9.]/g,'')"></el-input-number>
              <span class="unit">( ° )</span>
            </div>
          </el-form-item>
          <el-form-item label="航线标签" prop="lat">
            <el-select v-model="labvalue" multiple placeholder="请选择航线标签">
              <el-option v-for="item in LablistL" :key="item.id" :label="item.labelName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="航点动作" prop="actions">
            <el-link @click="handle_show_action" style="color: #00d9f9">
              {{ form.actions.length }}个动作
              <i class="el-icon-d-arrow-right"></i>
            </el-link>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="bottom-box h60">
      <!-- <div class="iconfont icon-zengjia" title="添加"></div> -->
      <div v-if="btnSaveVisible" @click="handle_save_line" class="manual1027Btn tc cp" title="保存航线">
        保存
      </div>
      <div v-else>
        <button @click="handle_save_continue">继续保存</button>
        <button @click="handle_save_cancle">取消</button>
      </div>

      <!-- <div @click="handle_remove_point" class="iconfont icon-shanchu" title="删除航点"></div> -->
    </div>

    <!-- <el-dialog
      center
      title="新增航点动作"
      :visible.sync="action.visible"
      width="500px"
      top="20em"
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="
        action.visible = false;
        action.list = [];
      "
    >-->
    <div class="w500 action-list" v-if="action.visible">
      <div class="header">
        <div class="hd-box">新增航点动作</div>
        <div class="hd-box cp mr20" @click="
          action.visible = false;
        action.list = [];
        ">
          关闭
        </div>
      </div>
      <div class="actions-box">
        <div class="title-box">
          <div class="title">航点动作</div>
          <el-dropdown trigger="click">
            <el-button size="small" type="primary" icon="el-icon-plus">增加</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in action.types" :key="index">
                <div @click="handle_add_action(item)">{{ item.label }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="list-box">
          <div class="action-item" v-for="(item, index) in action.list" :key="index">
            <div class="item-label">{{ item.label }}</div>
            <div v-if="item.input" class="item-input">
              <component :is="item.input" :min="item.min" :max="item.max" v-model="item.actionParam" />
            </div>
            <div @click="handle_delete_action(index, item)" class="iconfont icon-shanchu" title="删除"></div>
          </div>
        </div>
      </div>
      <span class="footer">
        <el-button size="small" @click="
          action.visible = false;
        action.list = [];
        ">取 消</el-button>
        <el-button size="small" type="primary" @click="handle_save_action">确 定</el-button>
      </span>
    </div>
    <!-- </el-dialog> -->
  </div>
</template>

<script>
  import API from "@/api";
  import { Utils } from "@/lib/cesium";
  import { nanoid } from "nanoid";
  import dayjs from "dayjs";
  import { mapGetters } from "vuex";
  import { isNull } from "@antv/util";

  let positions = []; //画线的坐标
  let pointers = []; //画点的id
  let handler = null;
  let data_srouce = null;
  let location_icon = null;

  let noFlyEntities = [];
  let electricFenceEntities = [];

  let point_index = null;
  let point_uuid = null;
  let point_uuid_next = null;
  let isEditting = false;

  export default {
    components: {},
    props: {
      flag: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      let validateLonLat = (rule, value, callback) => {
        console.log("validateLonLat", value);
        let arr = value.split(",");
        let lon = arr[0];
        let lat = arr[1];
        if (lon < -180 || lon > 180) {
          callback(new Error("请输入正确经度值（-180.0-180.0）"));
        } else if (lat < -90 || lat > 90) {
          callback(new Error("请输入正确纬度值（-90.0-90.0）"));
        } else {
          callback();
        }
      };
      let validateAlt = (rule, value, callback) => {
        if (value > 150) {
          callback(new Error("航点高度不可超过150m"));
        } else {
          callback();
        }
      };
      let validateNestId = (rule, value, callback) => {
        if (!value) {
          callback(new Error("请选择机巢"));
        } else {
          callback();
        }
      };
      return {
        btnSaveVisible: true,
        flightParams: {},

        labvalue: [],
        LablistL: [],
        getNestListForLineList: [],
        rules: {
          startPoint: [{ validator: validateLonLat, trigger: "change" }],
          endPoint: [{ validator: validateLonLat, trigger: "change" }],
          alt: [{ validator: validateAlt, trigger: "change" }],
          nestId: [{ validator: validateNestId, trigger: "change" }],
        },
        time: null,
        visibleFlag: true,
        form: {
          startPoint: "",
          endPoint: "",
          lon: 0,
          lat: 0,
          alt: 100,
          pitch: 0, //-180~180
          gimbalPitch: 0, //0~-90
          actions: [],
          nestId: null,
        },
        destination: {
          name: "",
          lng: 0,
          lat: 0,
        },
        destStart: {
          name: "",
          lng: 0,
          lat: 0,
        },
        destEnd: {
          name: "",
          lng: 0,
          lat: 0,
        },
        airline: {
          filename: null,
          line: {
            // baseSpeed: 10,
            baseSpeed: 2,
          },
          points: [],
        },
        action: {
          visible: false,
          types: [
            {
              label: "悬停(s)",
              input: "el-input-number",
              min: 0,
              max: 32000,
              actionType: "STAY",
              actionParam: 10,
            },
            {
              label: "拍照",
              actionType: "START_TAKE_PHOTO",
              actionParam: 0,
            },
            {
              label: "开始录像",
              actionType: "START_RECORD",
              actionParam: 0,
            },
            {
              label: "停止录像",
              actionType: "STOP_RECORD",
              actionParam: 0,
            },
          ],
          list: [],
          curr_index: 0,
        },
        scene: "巡查",
        scene_list: [
          { name: "巡查", id: 1 },
          { name: "预警", id: 2 },
          { name: "侦察", id: 3 },
          { name: "服务", id: 4 },
          { name: "救援", id: 5 },
          { name: "处置", id: 6 },
          { name: "打击", id: 7 },
          { name: "宣传", id: 8 },
        ],
        getStartPoint: false, //获取起点位置
        getEndPoint: false, //获取终点位置
        startPointId: "", //起点实体id
        endPointId: "", //终点实体id
        createPlanLoading: false, //创建航线中
        cancelCreatePlan: false, //取消创建航线
      };
    },

    // watch: {
    //   labvalue (val, value) {
    //     console.log("val", val)
    //     console.log("value", value)
    //   },
    //   deep: true
    // },
    async mounted() {
      // this.getlable();

      this.init_cesium_handler();
      let filename = `${this.user_info.parentDepName ? this.user_info.parentDepName + "-" : ""
        }${this.user_info.departmentName}-${this.scene}`;
      let res = await API.AIRSPACE.GetFlightLineCount({ flightName: filename });
      // 对res进行判断,有时候会传来对象,但是值是对的,所以手动把值提取出来
      if (typeof res == "object") {
        res = res.data;
      }
      let filename_count = res + 1;
      filename_count =
        filename_count < 10 ? "0" + filename_count : filename_count;
      this.airline.filename = filename + filename_count;
      // this.get_air_space_list();
      // this.get_electric_fence_list();
      // this.get_institutional_fence_list();
      this.getNestListForLine();
    },
    beforeDestroy() {
      this.destroy_cesium_handler();
      this.clear_entities();
    },
    methods: {
      async getNestListForLine() {
        this.getNestListForLineList = await API.AIRWAY.getNestListForLine();
      },
      async handle_change() {
        let filename = `${this.user_info.parentDepName ? this.user_info.parentDepName + "-" : ""
          }${this.user_info.departmentName}-${this.scene}`;
        let res = await API.AIRSPACE.GetFlightLineCount({ flightName: filename });
        // 对res进行判断,有时候会传来对象,但是值是对的,所以手动把值提取出来
        if (typeof res == "object") {
          res = res.data;
        }
        let filename_count = res + 1;
        filename_count =
          filename_count < 10 ? "0" + filename_count : filename_count;
        this.airline.filename = filename + filename_count;
      },
      init_point(data) {
        Object.keys(data).map((key) => {
          if (this.form.hasOwnProperty(key)) {
            this.form[key] = data[key];
          }
        });
      },

      testEmit(item) {
        this.$emit("add", item);
      },

      reset_form() {
        let alt = this.form.alt;
        let nestId = this.form.nestId;

        this.$refs["form"] && this.$refs["form"].resetFields();
        //继承上一个点的高度
        this.form.alt = alt;
        //继承上一个点的机巢id
        this.form.nestId = nestId;
      },
      // 上一航点
      handle_before_point() {
        let points = this.airline.points;
        if (this.action.curr_index - 1 >= 0) {
          this.action.curr_index -= 1;
          this.init_point(points[this.action.curr_index]);
        }
      },
      // 下一航线点
      handle_after_point() {
        try {
          let points = this.airline.points;
          if (this.action.curr_index + 1 < points.length) {
            this.action.curr_index += 1;
            this.init_point(points[this.action.curr_index]);
          }
        } catch (error) { }
      },
      handler_current_point(index) {
        let points = this.airline.points;
        this.action.curr_index = index;
        this.init_point(points[this.action.curr_index]);
      },

      handle_save_point() {
        this.airline.points.push({ ...this.form });
      },
      handle_remove_point() {
        let index = this.action.curr_index - 1;
        let points = this.airline.points;
        let point = points[index];
        if (point) {
          points.splice(index, 1);
          positions.splice(index, 1);
          this.remove_point(point);
          this.remove_label(point);

          /**
           * 如果后面没有点
           */
          let after = points[index];
          if (!after) {
            if (index - 1 < 0) {
              this.action.curr_index = 0;
            } else {
              this.action.curr_index -= 1;
            }
          }

          points.length
            ? this.init_point(points[this.action.curr_index - 1])
            : this.reset_form();
        }
      },

      /**
       * 显示动作弹窗
       */
      handle_show_action() {
        this.action.list = [];
        this.action.list.push(...this.form.actions);
        this.action.visible = true;
      },

      handle_add_action(item) {
        this.action.list.push({ ...item });
      },
      handle_delete_action(index, item) {
        this.action.list.splice(index, 1);
      },

      /**
       * 关闭动作弹窗
       */
      handle_save_action() {
        this.form.actions = [];
        this.form.actions.push(...this.action.list);
        this.action.visible = false;
        let point = this.airline.points[this.action.curr_index - 1];
        point.actions = [];
        point.actions.push(...this.action.list);
      },

      handle_change_field(key, value) {
        let point = this.airline.points[this.action.curr_index];
        if (point) {
          point[key] = value * 1;
        }
      },

      remove_point(point) {
        data_srouce.entities.removeById(point.uuid);
      },
      create_point(uuid, position, wgs84, label) {
        if (!wgs84) {
          wgs84 = Utils.transformCartesian2WGS84(position);
          wgs84.alt = this.form.alt;
        }
        console.log("wgs84", wgs84);
        let points = this.airline.points;
        if (data_srouce && this.visibleFlag) {
          let entities = data_srouce.entities;
          entities.add({
            name: "airline_point",
            id: uuid,
            position: Utils.transformWGS842Cartesian({
              lng: wgs84.lng,
              lat: wgs84.lat,
              alt: wgs84.alt,
            }),
            point: {
              pixelSize: 20,
              color: Cesium.Color.RED,
              fillColor: Cesium.Color.RED,
              // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND, // supermap版本会导致拖动显示错误
            },
            label: {
              text: label,
              scale: 0.5,
              font: "bold 24px Microsoft YaHei",
              fillColor: Cesium.Color.WHITE,
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.CENTER,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              showBackground: false,
              outlineWidth: 0,
            },
          });
        }
      },

      remove_label(point) {
        data_srouce.entities.removeById(`label_${point.uuid}`);
      },
      //创建距离标签
      create_distance_label(uuid, before, after, alt) {
        if (data_srouce) {
          let entities = data_srouce.entities;
          let before_wgs84 = Utils.transformCartesian2WGS84(before);
          let after_wgs84 = Utils.transformCartesian2WGS84(after);

          let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
          let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
          let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

          let position = Utils.transformWGS842Cartesian({
            lng: center_lng,
            lat: cetner_lat,
            alt: alt + 10,
          });

          let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

          entities.add({
            id: `label_${uuid}`,
            position: position,
            label: {
              text: text,
              scale: 0.5,
              font: "bold 30px Microsoft YaHei",
              fillColor: Cesium.Color.fromCssColorString("#fff"),
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              showBackground: true,
              backgroundPadding: new Cesium.Cartesian2(10, 10),
            },
          });
        }
      },
      update_label(uuid, before, after) {
        let entities = data_srouce.entities;
        let id = `label_${uuid}`;
        let entity = entities.getById(id);

        let before_wgs84 = Utils.transformCartesian2WGS84(before);
        let after_wgs84 = Utils.transformCartesian2WGS84(after);

        let center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
        let cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
        let alt = (after_wgs84.alt + before_wgs84.alt) / 2;

        let position = Utils.transformWGS842Cartesian({
          lng: center_lng,
          lat: cetner_lat,
          alt: alt + 10,
        });
        let text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

        entity.position = position;
        entity.label.text = text;
      },
      // 延时调用
      debounce(fn, wait) {
        // 问题:卡顿时页面不进行跳转,进而可以存储多次
        // 关闭定时器
        clearTimeout(this.time);
        // 定时器置空
        this.time = null;
        // 启动定时器
        this.time = setTimeout(() => {
          fn.apply(this, arguments);
          // 解决思路:因为程序里有跳转功能,所以可以置空必填项,这样即使卡顿也不会多次存储
          this.destination.name = "";
        }, wait);
      },

      handle_save_continue() {
        //alert("此处是继续保存业务")
        this.$emit("add", this.flightParams);
        this.$emit("exitNest_list");
      },
      handle_save_cancle() {
        alert("此处需要处理取消业务")
        //


      },
      async save_line() {
        let sourceType = null;
        let nestId = null;
        if (this.flag) {
          sourceType = 2;
          nestId = this.form.nestId;
        } else {
          sourceType = 1;
        }
        let points = this.airline.points;
        let distance = points.reduce((total, item, index) => {
          let dist = 0;
          if (points[index + 1]) {
            let cd = Cesium.Cartesian3.distance(
              Utils.transformWGS842Cartesian({ lng: item.lon, lat: item.lat }),
              Utils.transformWGS842Cartesian({
                lng: points[index + 1].lon,
                lat: points[index + 1].lat,
              })
            );
            // dist = total + cd;
            dist = cd;
          }
          return total + parseInt(dist);
        }, 0);
        this.airline.baseSpeed = Number(this.airline.baseSpeed);

        let options = {
          distance,
          flightCourseJson: JSON.stringify(this.airline),
          flightName: this.airline.filename,
          pointCount: points.length,
          time: parseInt(distance / this.airline.line.baseSpeed),
          destination: this.destination.name,
          lng: this.destination.lng,
          lat: this.destination.lat,
          labelIds: this.labvalue,
          sourceType,
          nestId,
        };

        let checkRes = await API.AIRWAY.checkFlightLineWithDLL(options);
        console.log("checkFlightLineWithDLL======", checkRes);
        if (checkRes && checkRes.length > 0) {
          //改变按钮
          this.btnSaveVisible = false;
          this.flightParams = options;
          //TODO: 在地图上绘出 相交的航线，及展示任务时间。
          //this.drawLine......
          //在地图上绘出 相交的网格码
          //this.drawGeoSotGrids..........
          alert("此处需要画航线及网格码")

        } else {
          this.$emit("add", options);
          this.$emit("exitNest_list");
        }
      },
      // 提交表格
      handle_save_line() {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            // 调用提交
            if (this.airline.points.length === 0) {
              return false;
            }
            this.save_line();
          } else {
            return false;
          }
        });
      },

      async init_cesium_handler() {
        let viewer = window.viewer;
        let currentPoint;
        // 修复规划航线时
        viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
          Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
        );

        if (data_srouce) {
          data_srouce.entities.removeAll();
          viewer.dataSources.remove(data_srouce, true);
          data_srouce = null;
          positions = [];
        }
        data_srouce = new Cesium.CustomDataSource("airway_edit");

        await viewer.dataSources.add(data_srouce);

        let entities = data_srouce.entities;
        let polyline = entities.add({
          polyline: {
            material: Cesium.Color.RED,
            width: 3,
            positions: new Cesium.CallbackProperty(() => {
              return positions.map((wgs84) => {
                return Utils.transformWGS842Cartesian({
                  lng: wgs84.lng,
                  lat: wgs84.lat,
                  alt: wgs84.alt + 10,
                });
              });
            }, false),
          },
        });

        // viewer.scene.globe.depthTestAgainstTerrain = true;
        handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);

        handler.setInputAction(async (movement) => {
          let windowPosition = movement.position;
          let pickedObject = viewer.scene.pick(windowPosition);

          if (this.isGetAddress) {
            let position = Utils.getCartesian3FromPX(viewer, movement.position);
            let wgs84 = Utils.transformCartesian2WGS84(position);
            let address = await API.MAP.Regeo({
              location: `${wgs84.lng},${wgs84.lat}`,
            });

            if (address) {
              this.destination.name = address;
              this.destination.lng = wgs84.lng;
              this.destination.lat = wgs84.lat;
            } else {
              this.$el_message("地址解析失败", () => { }, "error");
            }

            this.isGetAddress = false;
            return;
          }

          console.log("LEFT_DOWN  pickedObject", pickedObject);
          if (
            Cesium.defined(pickedObject) &&
            pickedObject.id?.name === "airline_point"
          ) {
            // 航点编辑
            let entity = pickedObject.id;
            point_uuid = entity.id;
            let points = this.airline.points;
            let index = points.findIndex((item) => item.uuid === entity.id);

            if (index > -1) {
              point_index = index;
              if (index + 1 < points.length) {
                point_uuid_next = points[index + 1].uuid;
              }
              this.handler_current_point(index);
            }

            currentPoint = entity;
            isEditting = true;
            viewer.scene.screenSpaceCameraController.enableRotate = false;
            viewer.scene.screenSpaceCameraController.enableZoom = false;
          }
        }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

        handler.setInputAction(async (movement) => {
          let windowPosition = movement.position;
          let pickedObject = viewer.scene.pick(windowPosition);
          // if (pickedObject) {
          // }


          console.log("LEFT_CLICK  pickedObject", pickedObject);

          if (this.isGetAddress) {
            let position = Utils.getCartesian3FromPX(viewer, movement.position);
            let wgs84 = Utils.transformCartesian2WGS84(position);
            let address = await API.MAP.Regeo({
              location: `${wgs84.lng},${wgs84.lat}`,
            });

            if (address) {
              this.destination.name = address;
              this.destination.lng = wgs84.lng;
              this.destination.lat = wgs84.lat;
            } else {
              this.$el_message("地址解析失败", () => { }, "error");
            }

            this.isGetAddress = false;
            return;
          }

          if (
            Cesium.defined(pickedObject) &&
            pickedObject.id?.name === "airline_point"
          ) {
            // 航点编辑
            // let entity = pickedObject.id;
            // point_uuid = entity.id;
            // let points = this.airline.points;
            // let index = points.findIndex((item) => item.uuid === entity.id);
            // if (index > -1) {
            //   point_index = index;
            //   if (index + 1 < points.length) {
            //     point_uuid_next = points[index + 1].uuid;
            //   }
            //   this.handler_current_point(index);
            // }
            // currentPoint = entity;
            // isEditting = true;
            // viewer.scene.screenSpaceCameraController.enableRotate = false;
            // viewer.scene.screenSpaceCameraController.enableZoom = false;
          } else {
            // 航点添加
            let position = Utils.getCartesian3FromPX(viewer, movement.position);
            if (
              Cesium.defined(position) &&
              (this.getStartPoint || this.getEndPoint)
            ) {
              let wgs84 = Utils.transformCartesian2WGS84(position);

              const lonlat = `${wgs84.lng},${wgs84.lat}`;
              let uuid = (this.form.uuid = nanoid());

              if (this.getStartPoint) {
                this.form.startPoint = lonlat;
                if (this.startPointId) {
                  data_srouce.entities.removeById(this.startPointId);
                }
                this.startPointId = uuid;
                this.create_point(uuid, position, null, "起");
                this.getStartPoint = false;
                this.getEndPoint = true;

                let address = await API.MAP.Regeo({
                  location: `${wgs84.lng},${wgs84.lat}`,
                });

                this.destStart.lng = wgs84.lng;
                this.destStart.lat = wgs84.lat;

                if (address) {
                  this.destStart.name = address;
                } else {
                  this.$el_message("地址解析失败", () => { }, "error");
                  this.destStart.name = `${this.destStart.lng},${this.destStart.lat}`;
                }
              } else {
                this.form.endPoint = lonlat;
                if (this.endPointId) {
                  data_srouce.entities.removeById(this.endPointId);
                }
                this.endPointId = uuid;
                this.create_point(uuid, position, null, "终");
                this.getEndPoint = false;

                let address = await API.MAP.Regeo({
                  location: `${wgs84.lng},${wgs84.lat}`,
                });

                this.destEnd.lng = wgs84.lng;
                this.destEnd.lat = wgs84.lat;

                if (address) {
                  this.destEnd.name = address;
                } else {
                  this.$el_message("地址解析失败", () => { }, "error");
                  this.destEnd.name = `${this.destEnd.lng},${this.destEnd.lat}`;
                }
              }
            }
          }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

        // 对鼠标移动事件的监听
        handler.setInputAction((event) => {
          if (isEditting && currentPoint) {
            // let windowPosition = event.startPosition;
            // let ellipsoid = viewer.scene.globe.ellipsoid;
            // let cartesian = viewer.camera.pickEllipsoid(windowPosition, ellipsoid);
            /* let cartesian = Utils.getCartesian3FromPX(
              viewer,
              event.startPosition
            );
  
            if (!cartesian) {
              return;
            }
            currentPoint.position = cartesian;
            console.log('currentPoint', currentPoint) */
            /* if (!(typeof point_index == undefined) && positions?.length > 1) {
              positions[point_index] = cartesian;
              let current_wgs84 = Utils.transformCartesian2WGS84(cartesian);
              this.airline.points[point_index].lon = current_wgs84.lng;
              this.airline.points[point_index].lat = current_wgs84.lat;
              if (point_index === this.action.curr_index) {
                this.form.lon = current_wgs84.lng;
                this.form.lat = current_wgs84.lat;
              }
  
              if (point_index > 0) {
                this.update_label(
                  point_uuid,
                  cartesian,
                  positions[point_index - 1]
                );
              }
              if (point_uuid_next) {
                this.update_label(
                  point_uuid_next,
                  cartesian,
                  positions[point_index + 1]
                );
              }
            } */
            /* if (this.getStartPoint) {
                this.form.startPoint = lonlat;
                if (this.startPointId) {
                  data_srouce.entities.removeById(this.startPointId);
                }
                this.startPointId = uuid;
                this.create_point(uuid, position, null, "起");
                this.getStartPoint = false;
                this.getEndPoint = true;
  
                let address = await API.MAP.Regeo({
                  location: `${wgs84.lng},${wgs84.lat}`,
                });
  
                this.destStart.lng = wgs84.lng;
                this.destStart.lat = wgs84.lat;
  
                if (address) {
                  this.destStart.name = address;
                } else {
                  this.$el_message("地址解析失败", () => {}, "error");
                  this.destStart.name = `${this.destStart.lng},${this.destStart.lat}`;
                }
              } else {
                this.form.endPoint = lonlat;
                if (this.endPointId) {
                  data_srouce.entities.removeById(this.endPointId);
                }
                this.endPointId = uuid;
                this.create_point(uuid, position, null, "终");
                this.getEndPoint = false;
  
                let address = await API.MAP.Regeo({
                  location: `${wgs84.lng},${wgs84.lat}`,
                });
  
                this.destEnd.lng = wgs84.lng;
                this.destEnd.lat = wgs84.lat;
  
                if (address) {
                  this.destEnd.name = address;
                } else {
                  this.$el_message("地址解析失败", () => {}, "error");
                  this.destEnd.name = `${this.destEnd.lng},${this.destEnd.lat}`;
                }
              } */
          }
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

        // 对鼠标抬起事件的监听
        handler.setInputAction((event) => {

          console.log("LEFT_UP  pickedObject");

          point_index = null;
          point_uuid_next = null;
          isEditting = false;
          currentPoint = undefined;
          // viewer.render()
          setTimeout(() => {
            viewer.scene.screenSpaceCameraController.enableRotate = true;
            viewer.scene.screenSpaceCameraController.enableZoom = true;
          }, 300);
        }, Cesium.ScreenSpaceEventType.LEFT_UP);
      },

      destroy_cesium_handler() {
        try {
          let viewer = window.viewer;
          viewer.scene.globe.depthTestAgainstTerrain = false;
          if (handler) {
            // handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
            handler && handler.destroy() && (handler = null);
            handler = null;
          }

          if (data_srouce) {
            data_srouce.entities.removeAll();
            // viewer.dataSources.remove(data_srouce, true);
            data_srouce = null;
            positions = [];
          }
        } catch (error) { }
      },

      /**
       * 获取起始点点击事件
       */
      handle_pick(key) {
        switch (key) {
          case "startPoint":
            this.getStartPoint = !this.getStartPoint;
            this.getEndPoint = false;
            break;

          case "endPoint":
            this.getStartPoint = false;
            this.getEndPoint = !this.getEndPoint;
            break;

          case "address":
            this.isGetAddress = true;
            break;
        }
      },

      //模糊搜索地址
      handle_query_address_async(address, cb) {
        API.MAP.Geo({ keywords: address })
          .then((res) => {
            cb(res.data.data.rows);
          })
          .catch((e) => cb([]));
      },

      //选择地址
      handle_select_address(item) {
        if (item) {
          let location = item.location.split(",");
          this.destination.name = item.name;
          this.destination.lng = Number(location[0]);
          this.destination.lat = Number(location[1]);

          let cesium_layer = this.g_cesium_layer();
          let viewer = cesium_layer.viewer();
          let potiion = Utils.transformWGS842Cartesian({
            lng: this.destination.lng,
            lat: this.destination.lat,
            alt: 1000,
          });
          viewer.camera.flyTo({
            destination: potiion,
          });
          this.init_location_icon(potiion);
        }
      },
      handle_select_address_start(item) {
        if (item) {
          let location = item.location.split(",");
          this.destStart.name = item.name;
          this.destStart.lng = Number(location[0]);
          this.destStart.lat = Number(location[1]);

          let cesium_layer = this.g_cesium_layer();
          let viewer = cesium_layer.viewer();
          let position = Utils.transformWGS842Cartesian({
            lng: this.destStart.lng,
            lat: this.destStart.lat,
            alt: 1000,
          });
          viewer.camera.flyTo({
            destination: position,
          });
          this.form.startPoint = `${this.destStart.lng},${this.destStart.lat}`;
          if (this.startPointId) {
            data_srouce.entities.removeById(this.startPointId);
          }
          let uuid = (this.form.uuid = nanoid());
          this.startPointId = uuid;
          this.create_point(uuid, position, null, "起");
          this.getStartPoint = false;
          this.getEndPoint = true;
        }
      },
      handle_select_address_end(item) {
        if (item) {
          let location = item.location.split(",");
          this.destEnd.name = item.name;
          this.destEnd.lng = Number(location[0]);
          this.destEnd.lat = Number(location[1]);

          let cesium_layer = this.g_cesium_layer();
          let viewer = cesium_layer.viewer();
          let position = Utils.transformWGS842Cartesian({
            lng: this.destEnd.lng,
            lat: this.destEnd.lat,
            alt: 1000,
          });
          viewer.camera.flyTo({
            destination: position,
          });
          this.form.startPoint = `${this.destEnd.lng},${this.destEnd.lat}`;
          if (this.endPointId) {
            data_srouce.entities.removeById(this.endPointId);
          }
          let uuid = (this.form.uuid = nanoid());
          this.endPointId = uuid;
          this.create_point(uuid, position, null, "终");
          this.getStartPoint = false;
          this.getEndPoint = true;
        }
      },

      init_location_icon(position) {
        let cesium_layer = this.g_cesium_layer();
        let viewer = cesium_layer.viewer();

        if (location_icon) {
          location_icon.position = position;
        } else {
          location_icon = viewer.entities.add({
            position,
            billboard: {
              width: 36,
              height: 36,
              image: require("@/assets/images/icons/icon_location.png"),
              horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.Bottom,
              heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            },
          });
        }
      },
      clear_entities() {
        noFlyEntities.forEach((item) => {
          viewer.entities.remove(item);
        });
        electricFenceEntities.forEach((item) => {
          viewer.entities.remove(item);
        });
        noFlyEntities = [];
        electricFenceEntities = [];
        positions = [];
        pointers.forEach((id) => {
          data_srouce.entities.removeById(id);
          data_srouce.entities.removeById("label_" + id);
        });
        data_srouce.entities.removeById(this.startPointId);
        data_srouce.entities.removeById(this.endPointId);
        this.startPointId = null;
        this.endPointId = null;
      },
      /**
       * 创建航线
       */
      async onCreateRoute() {
        this.getStartPoint = false;
        this.getEndPoint = false;

        if (this.createPlanLoading) {
          this.cancelCreatePlan = true;
          this.createPlanLoading = false;
          return;
        }

        this.cancelCreatePlan = false;
        if (!this.form.startPoint || !this.form.endPoint) {
          return;
        }
        this.createPlanLoading = true;
        try {
          let start_point = this.form.startPoint.split(",");
          let end_point = this.form.endPoint.split(",");

          // console.log("form", this.form)
          //规划航线
          const res = await API.AIRWAY.autoBuildFlightLine({
            points: start_point + ";" + end_point,
            height: this.form.alt
          });

          console.log("创建任务", res);

          this.createLine(res.pnts);
        } catch (e) {
          console.log("自动创建航线失败", e);
          this.$message.error("自动规划航线失败，" + e);
          this.createPlanLoading = false;
        }
      },
      createLine: function (pntList) {

        //删除所有点和线
        /*  data_srouce.entities.removeAll();
              positions = []; */
        //删除起点与终点标记, 由接口返回的值代替
        /*  data_srouce.entities.removeById(this.startPointId);
        data_srouce.entities.removeById(this.endPointId);
        pointers.forEach(id => {
           data_srouce.entities.removeById(id)
        })
        pointers = []; */
        this.clear_entities();
        //画 自动规划的航线
        let points = pntList.map((item, i) => {
          let label = "";
          const uuid = nanoid();
          label = String(i + 1);

          //画距离 (可能由于点太多，此方法会导致浏览器压力过大)
          // if (i != 0) {
          //   // console.log(i);
          //   let lastItem = pntList[i - 1];
          //   this.create_distance_label(
          //     uuid,
          //     Cesium.Cartesian3.fromDegrees(
          //       lastItem.x,
          //       lastItem.y,
          //       lastItem.z
          //     ),
          //     Cesium.Cartesian3.fromDegrees(item.t, item.y, item.z)
          //   );
          // }

          pointers.push(uuid);
          //地图中画点
          this.create_point(
            uuid,
            null,
            {
              lat: item.x,
              lng: item.y,
              alt: item.z,
            },
            label
          );

          return {
            ...this.form,
            lon: item.x,
            lat: item.y,
            alt: item.z,
          };
        });

        //更新当前节点信息
        this.init_point(points[0]);

        this.airline.points = [...points];
        positions = this.airline.points.map((item) => {
          return {
            lng: Number(item.lon),
            lat: Number(item.lat),
            alt: Number(item.alt),
          };
        });
        this.action.curr_index = 0;
        // console.log("positions", positions);
        this.createPlanLoading = false;

      },
    },
    computed: {
      ...mapGetters(["user_info"]),
      cesium_layer() {
        return this.g_cesium_layer();
      },
    },

    inject: ["g_cesium_layer"],
  };
</script>

<style lang="scss" scoped>
  @import "~@/styles/var.scss";
  @import "~@/styles/mixins.scss";
  @import "~@/styles/form.scss";

  // /deep/.el-scrollbar {
  //   width: 355px !important;
  // }
  // /deep/.el-select-dropdown {
  //   /deep/.el-scrollbar {
  //     /deep/.el-select-dropdown__wrap {
  //       width: 355px !important;
  //     }
  //   }
  // }
  .manual1027Btn {
    width: 84px;
    height: 32px;
    line-height: 32px;
    background: rgba(12, 34, 73, 0.7);
    border-radius: 5px;
    border: 1px solid rgba(26, 92, 246, 0.5);
    backdrop-filter: blur(1px);
    color: #fff;
  }

  ::v-deep {
    .el-select {
      width: 355px;

      .el-input {
        height: 100%;
      }
    }
  }

  .cpt-command-airway-edit {
    position: absolute;
    left: 100px;
    top: 140px;
    width: 600px;
    height: 770px;
    box-sizing: border-box;
    background: url("~@/assets/images/plate_bg_01.png") no-repeat;
    background-size: 100% 100%;

    .img-close {
      position: absolute;
      right: 30px;
      top: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    .title-box {
      height: 56px;
      box-sizing: border-box;
      padding-left: 112px;
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-right: 80px;

      .title {
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #dce9ff;
        font-weight: 400;
        // line-height: 3.2;
        margin-right: 30px;
      }

      .select {
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #00ffff;
        text-align: center;
        font-weight: 400;
      }

      &::before {
        content: " ";
        display: block;
        position: absolute;
        height: 1px;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: linear-gradient(270deg,
            rgba(0, 117, 221, 0) 0%,
            #3ea4d5 50%,
            rgba(0, 117, 221, 0) 100%);
      }
    }

    .ctx-box {
      margin-top: 10px;
      height: calc(100% - 56px - 80px);
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;

      .form-box {
        ::v-deep .el-form {
          .divider {
            @include linear_gradient_border(0, auto, 0, 0);
            height: 12px;
          }

          .el-form-item {
            margin-bottom: 16px;

            .item-group {
              display: flex;
              align-items: center;

              .unit {
                font-family: MicrosoftYaHeiUI;
                font-size: 18px;
                color: rgb(179, 201, 203);
                font-weight: 400;
                margin-left: 12px;
              }

              .iconfont {
                color: rgb(179, 201, 203);
                font-size: 30px;
                margin-left: 0.3em;
                cursor: pointer;
              }

              .el-icon-location-outline {
                color: rgb(179, 201, 203);
                font-size: 30px;
                margin-left: 0.3em;
                cursor: pointer;
              }
            }

            .el-form-item__label {
              font-family: Microsoft YaHei;
              font-size: 16px;
              color: #ccedff;
              text-align: right;
              font-weight: 400;
            }

            .el-select-dropdown {
              min-width: 356px !important;
            }

            .el-input {
              width: 356px;
              height: 40px;

              .el-input__inner {
                width: 100%;
                font-family: MicrosoftYaHeiUI;
                font-size: 15px;
                color: #08c2d1;

                background: #02173d;
                border: 1px solid #2952ad;
              }
            }

            .el-input-number {
              width: 356px;

              .el-input-number__decrease,
              .el-input-number__increase {
                bottom: 1px;
                background: #183675;
                color: #fff;
                border-radius: 0;
                border: none;
              }

              .el-input-number__decrease {
                left: 1px;
              }

              .el-input-number__increase {
                right: px;
              }
            }
          }
        }
      }
    }

    .bottom-box {
      box-sizing: border-box;
      // padding-bottom: 20px;
      // height: 88px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      @include linear_gradient_border(0, auto, 0, 0);

      .iconfont {
        cursor: pointer;
        font-size: 44px;
        color: #08c2d1;
      }
    }

    // 动作列表
    .action-list {
      position: fixed;
      left: 40%;
      top: 30%;
      background: rgba(0, 23, 79, 0.7);
      box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
        inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
      border-radius: 13px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 44px;
        background: rgba(16, 65, 215, 0.2);
        box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
        border-radius: 10px 10px 0 0;
      }

      .hd-box {
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        color: #00ffff;
        letter-spacing: 0;
        font-weight: 700;
        margin-left: 20px;
      }

      .actions-box {
        .title-box {
          display: flex;
          align-items: center;

          .title {
            font-size: 24px;
            width: 120px;
          }
        }

        .list-box {
          max-height: 210px;
          overflow: auto;
          margin-top: 12px;
          padding: 0 20px;

          ::v-deep {
            .el-input-number {
              margin-left: 180px;

              .el-input {
                width: 180px;
              }
            }
          }

          .action-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #fff;
            box-sizing: border-box;
            padding: 12px;

            &:hover {
              background-color: #ccc;
            }

            .item-inner {
              display: flex;
              align-items: center;
            }

            .iconfont {
              color: red;
              cursor: pointer;
              font-size: 20px;
            }
          }
        }
      }

      .footer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }

    .right-box {
      display: flex;
      justify-content: center;

      align-items: center;
      text-align: center;
      font-weight: 400;
      // line-height: 3.8;
      margin-top: 10px;

      font-family: PangMenZhengDao;
      font-size: 18px;
      color: #00ffff;
      text-align: right;
      font-weight: 400;

      .before-point {
        margin-right: 12px;
      }

      .before-point,
      .after-point {
        cursor: pointer;
        user-select: none;
        margin-left: 15px;

        &.disabled {
          cursor: not-allowed;
        }
      }

      .no {
        margin: 0 10px;
      }
    }
  }

  .start-point {
    position: relative;

    .btn1027 {
      position: absolute;
      right: 10px;
      top: 40px;
      padding: 5px 5px;
      font-size: 12px;
      line-height: 1;
      width: 66px;
    }
  }

  .el-icon-location-outline {
    &.active {
      color: red !important;
    }
  }
</style>