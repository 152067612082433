<template>
  <div>
    <div class="page-command-flight">
      <img
        src="~@/assets/images/accident/close.png"
        class="img-close mr20"
        @click="change_page"
      />
      <IntelligenceMissionNav />
      <div class="bd-box">
        <div class="search-box">
          <el-input
            v-model="search.deviceName"
            class="input-name"
            placeholder="请输入无人机名称"
            clearable
          />
          <el-date-picker
            v-model="pickTime"
            type="datetimerange"
            range-separator="至"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <el-input
            v-model="search.taskTitle"
            class="input-name ml10"
            placeholder="请输入所属任务"
            clearable
          />
          <el-select
            v-model="search.taskCateId"
            class="input-excute"
            placeholder="请选择任务类别"
            clearable
          >
            <el-option label="警情任务" :value="1"></el-option>
            <el-option label="常态任务" :value="2"></el-option>
            <el-option label="临时紧急任务" :value="3"></el-option>
          </el-select>
          <el-cascader
            class="input-orgname"
            placeholder="请选择责任单位"
            v-model="search.organizationId"
            :options="unitList"
            :show-all-levels="false"
            :props="{
              children: 'children',
              label: 'orgName',
              value: 'id',
              checkStrictly: true,
              emitPath: false,
            }"
            clearable
          >
          </el-cascader>
          <el-button @click="handle_search" class="btn-submit">搜索</el-button>
          <el-button @click="handle_upload" class="btn-submit"
            >导出日志 </el-button
          >
        </div>
        <div class="tb-box">
          <div class="tb-hd-box w100%">
            <div class="tb-hd w120">无人机名称</div>
            <div class="tb-hd w160">飞行开始时间</div>
            <!-- <div class="tb-hd">任务方式</div> -->
            <div class="tb-hd w160">飞行结束时间</div>
            <div class="tb-hd w120">飞行时长</div>
            <div class="tb-hd w180">所属任务</div>
            <div class="tb-hd w100">任务类别</div>
            <div class="tb-hd w100">任务类型</div>
            <div class="tb-hd w150">责任单位</div>
            <div class="tb-hd w100">飞手</div>
            <div class="tb-hd w130">操作</div>
          </div>
          <div class="tb-bd-box h460">
            <div class="tb-tr" v-for="item in list" :key="item.id">
              <div class="td w120">{{ item.deviceName || "暂无" }}</div>
              <div class="td w160 airtime">
                {{ item.executeTime || "暂无" }}
              </div>
              <!-- <div class="td">{{ map.type[item.type] || '暂无' }}</div> -->
              <div class="td w160 airtime">{{ item.endTime || "暂无" }}</div>
              <div class="td w120">
                {{ formatSeconds(item.flightDuration) }}
              </div>
              <div class="td w180 airtitle">{{ item.taskTitle || "暂无" }}</div>
              <div class="td w100">{{ item.taskCateName || "暂无" }}</div>
              <div class="td w100">{{ item.taskTypeName || "暂无" }}</div>
              <div class="td w150">
                {{ item.organizationName || "暂无" }}
              </div>
              <div class="td w100">
                {{ item.flyUserNames.join("、") || "暂无" }}
              </div>
              <div class="td w130">
                <el-tooltip effect="dark" content="历史视频" placement="top">
                  <img
                    @click="handleVideoPlayer(item)"
                    class="h20 w20 mr5"
                    src="@/assets/historyvideo.svg"
                    alt=""
                  />
                </el-tooltip>
                <el-tooltip effect="dark" content="历史图片" placement="top">
                  <img
                    @click="handleImage(item)"
                    class="h20 w20 mr5"
                    src="@/assets/images/historyImage.svg"
                    alt=""
                  />
                </el-tooltip>
                <el-tooltip effect="dark" content="历史轨迹" placement="top">
                  <img
                    @click="handleHistotyTrack(item)"
                    class="h20 w20"
                    src="@/assets/historyTrack.svg"
                    alt=""
                  />
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="tb-pagination">
            <el-pagination
              small
              :page-sizes="[10, 20, 30, 40]"
              :current-page.sync="pagination.pageNo"
              :page-size="pagination.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagination.totalCount"
              @size-change="handle_size_change"
              @current-change="handle_page_change"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <VideoMedia
      v-if="videoVisible"
      :historyVideo="video"
      @close="videoVisible = false"
    ></VideoMedia>
    <ImageMedia
      :historyImage="imageList"
      v-if="mediaVisible"
      @closeMedia="mediaVisible = false"
    ></ImageMedia>
  </div>
</template>

<script>
//
import { mapGetters } from "vuex";
import ImageMedia from "@/components/command/situation/history";
import IntelligenceMissionNav from "@/components/command/intelligenceMissionNav"; //警情
import VideoMedia from "@/components/command/situation/history/video";
import { downloadData } from "@/utils/download";
import API from "@/api";
let history_entity = null;
let history_Line = null;
export default {
  components: {
    VideoMedia,
    ImageMedia,
    IntelligenceMissionNav,
  },
  data() {
    return {
      pickTime: null,
      pageshow: true,
      statusColor: {
        待执行: "#FF972B",
        执行中: "#2F76E4",
        已完成: "#89EC50",
        待审批: "#FF972B",
        待派发: "#19DFDF",
        审批不通过: "#DC143C",
      },
      curr_task: null,
      approve_form: {
        approvalRemark: null,
        visible: false,
      },
      search: {
        deviceName: null,
        taskTitle: null,
        taskCateId: null,
        organizationId: null,
        executeTime: null,
        endTime: null,
      },
      list: [],
      unitList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      map: {
        type: {
          0: "申报",
          1: "报备",
        },
      },
      imageList: null,
      video: null,
      videoVisible: false,
      mediaVisible: false,
    };
  },
  inject: ["change_page", "g_cesium_layer"],
  computed: {
    ...mapGetters(["user_info"]),
    isJH() {
      return this.user_info && this.user_info.departmentId == 2;
    },
  },

  methods: {
    formatSeconds(value) {
      if (!value) {
        return "暂无";
      } else {
        var secondTime = parseInt(value); // 秒
        var minuteTime = 0; // 分
        var hourTime = 0; // 小时
        if (secondTime >= 60) {
          minuteTime = parseInt(secondTime / 60);
          secondTime = parseInt(secondTime % 60);
          if (minuteTime >= 60) {
            hourTime = parseInt(minuteTime / 60);
            minuteTime = parseInt(minuteTime % 60);
          }
        }
        var result =
          "" +
          (parseInt(secondTime) < 10
            ? "0" + parseInt(secondTime)
            : parseInt(secondTime));

        // if (minuteTime > 0) {
        result =
          "" +
          (parseInt(minuteTime) < 10
            ? "0" + parseInt(minuteTime)
            : parseInt(minuteTime)) +
          ":" +
          result;
        // }
        // if (hourTime > 0) {
        result =
          "" +
          (parseInt(hourTime) < 10
            ? "0" + parseInt(hourTime)
            : parseInt(hourTime)) +
          ":" +
          result;
        // }
        return result;
      }
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async handle_edit(item, state) {
      let res = await API.SITUATION.Detail(item.id);
      this.$store.commit("situation/SITUATION_DETAIL", res);
      this.$emit("handleClick", false, state, false, this.pagination.pageNo);
    },
    // 历史视频
    async handleVideoPlayer(item) {
      try {
        let res = null;

        if (this.videoVisible) {
          this.videoVisible = false;
        } else {
          if (item.taskCateId == 1) {
            res = await API.SITUATION.HistoryVideo({ taskId: item.id });
          } else {
            res = await API.TASK.airtaskVideo(item.id);
          }

          this.video = res.object.replay[0];
          this.videoVisible = true;
        }
      } catch (error) {
        this.$el_message("无视频回放", () => {}, "error");
      }
    },
    // 历史图片
    async handleImage(item) {
      let res = null;
      if (this.mediaVisible) {
        this.mediaVisible = false;
      } else {
        if (item.taskCateId == 1) {
          res = await API.SITUATION.HistoryPhoto(item.id); //12
        } else {
          res = await API.TASK.airtaskPhoto(item.id); //12
        }

        if (res && res.length) {
          this.imageList = res;
          this.mediaVisible = true;
        } else {
          this.$el_message("无历史图片", () => {}, "error");
        }
      }
    },
    handle_upload(item) {
      downloadData(
        `/hawksystemserver/flightLog/exportData?FLYINGSESSIONID=${
          this.user_info.FLYINGSESSIONID
        }&mmc-identity=${this.user_info["mmc-identity"]}&deviceName=${
          this.search.deviceName || ""
        }&taskTitle=${this.search.taskTitle || ""}&taskCateId=${
          this.search.taskCateId || ""
        }&organizationId=${this.search.organizationId || ""}&executeTime=${
          this.search.executeTime || ""
        }&endTime=${this.search.endTime || ""}`,
        "飞行日志"
      );
    },
    isSelf(id) {
      return this.user_info && this.user_info.departmentId == id;
    },
    handle_search() {
      this.get_list();
    },
    async get_list() {
      // ...this.search,
      if (this.pickTime) {
        this.search.executeTime = this.pickTime[0];
        this.search.endTime = this.pickTime[1];
      } else {
        this.search.executeTime = null;
        this.search.endTime = null;
      }
      let { records, current, size, total } = await API.TASK.flightlog(
        this.search,
        this.pagination.pageNo,
        this.pagination.pageSize
      );
      this.list = records || [];
      this.pagination.pageNo = current;
      this.pagination.pageSize = size;
      this.pagination.totalCount = total;
    },

    handle_size_change(size) {
      this.pagination.pageSize = size;
      this.get_list();
    },
    handle_page_change(page) {
      this.pagination.pageNo = page;
      this.get_list();
    },
    handle_show_approve(val) {
      this.curr_task = val;
      this.approve_form.visible = true;
    },
    start_timer() {
      this.timer = window.setInterval(() => {
        this.get_list();
      }, 60000);
    },
    stop_timer() {
      if (this.timer) {
        window.clearInterval(this.timer);
        this.timer = null;
      }
    },
    removeEntities() {
      if (history_entity) {
        viewer.entities.remove(history_entity);
      }
      if (history_Line) {
        viewer.entities.remove(history_Line);
      }
    },
    // 历史轨迹
    async handleHistotyTrack(item) {
      this.removeEntities();
      try {
        let viewer = window.viewer;
        let res = null;

        if (item.taskCateId == 1) {
          res = await API.SITUATION.HistoryTrack({ taskId: item.id });
        } else {
          res = await API.TASK.airtaskTrack(item.id);
        }
        if (res[Object.keys(res)[0]]) {
          let polyline = res[Object.keys(res)[0]];
          let positions = [];
          polyline.forEach((item, index) => {
            positions.push(Number(item.longitude), Number(item.latitude), 100);
            history_entity = viewer.entities.add({
              position: Cesium.Cartesian3.fromDegrees(
                Number(item.longitude),
                Number(item.latitude)
              ),
            });
          });

          history_Line = viewer.entities.add({
            name: "Red line on terrain",
            polyline: {
              positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
              width: 5,
              material: Cesium.Color.RED,
              clampToGround: true,
            },
          });
          viewer.flyTo(history_Line);
        }
      } catch (error) {
        this.$el_message("无历史轨迹", () => {}, "error");
      }
    },
    async handleAirLinePreview(item) {
      // let cesium_layer = this.g_cesium_layer();
      // let viewer = cesium_layer.viewer();
      let viewer = window.viewer;
      let res = await API.TASK.JingQingTrack({
        taskId: item.taskId,
      });
      if (res?.flightCourseJson) {
        let polyline = JSON.parse(res.flightCourseJson);
        let positions = [];
        polyline.points.forEach((item, index) => {
          positions.push(item.lon, item.lat, item.alt);
          viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(item.lon, item.lat),
            point: {
              pixelSize: 20,
              color: Cesium.Color.RED,
              fillColor: Cesium.Color.RED,
              heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
            },
            label: {
              text: String(index + 1),
              scale: 0.5,
              font: "bold 24px Microsoft YaHei",
              fillColor: Cesium.Color.WHITE,
              horizontalOrigin: Cesium.VerticalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.CENTER,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              showBackground: false,
              outlineWidth: 0,
            },
          });
        });
        let redLine = viewer.entities.add({
          name: "Red line on terrain",
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
            width: 5,
            material: Cesium.Color.RED,
            clampToGround: true,
          },
        });
        viewer.flyTo(redLine);
      }
    },

    initPage() {
      // this.pagination.pageNo = this.pageNo;
      this.pageshow = false;
      this.$nextTick(() => {
        this.pageshow = true;
      });
    },
  },
  mounted() {
    // console.log('this.pagination.pageNo', this.pagination.pageNo);
    this.initPage();
    this.get_list();
    this.start_timer();
    this.getUnitList();
  },
  destroyed() {
    this.stop_timer();
    this.removeEntities();
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.page-command-flight {
  z-index: 0;
  width: 1400px;
  min-height: 740px;
  box-sizing: border-box;
  padding: 0 30px 50px 41px;
  background: url("~@/assets/images/plate/bg_04.png");
  background-size: 100% 100%;
  .img-close {
    position: absolute;
    right: 30px;
    top: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .bd-box {
    height: calc(100% - 56px);
    box-sizing: border-box;
    ::v-deep .search-box {
      box-sizing: border-box;
      height: 76px;
      display: flex;
      align-items: center;
      .btn-add,
      .btn-submit {
        width: 134px;
        height: 40px;
        line-height: 44px;
        font-family: PangMenZhengDao;
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
        background-color: #129c9c;
        border: none;
        border-radius: 0;
        padding: 0;
      }
      .btn-add {
        margin-right: 41px;
      }
      .input-name {
        width: 186px;
        margin-right: 10px;
        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }
      .input-orgname {
        width: 186px;
        margin-right: 10px;
        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }
      .input-status {
        width: 186px;
        margin-right: 10px;
        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }
      .input-excute {
        width: 186px;
        margin-right: 10px;
        .el-input__inner {
          width: 186px;
          height: 40px;
          background: #000000;
          border: 1px solid #08c2d1;
          color: #08c2d1;
          border-radius: 0;
        }
      }
    }
    .tb-box {
      box-sizing: border-box;
      height: calc(100% - 76px);
      .tb-hd-box {
        display: flex;
        // justify-content: space-between;
        height: 52px;
        background-color: #10181f;

        .tb-hd {
          height: 100%;
          // flex: 1;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #129c9c;
          font-weight: 400;
          line-height: 52px;
          text-align: center;
        }
      }
      .tb-bd-box {
        height: calc(100% - 52px);
        // overflow: auto;
        overflow-y: auto;
        background-color: #263542;
        .tb-tr {
          height: 60px;
          display: flex;
          box-sizing: border-box;
          border-bottom: 1px solid #263542;
          .td {
            display: flex;
            align-items: center;
            justify-content: center;
            // width: calc(100% / 9);
            padding: 10px 0;
            text-align: center;
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #b3bbc5;
            font-weight: 400;
            word-break: break-word;
            box-sizing: border-box;
            .iconfont {
              font-size: 20px;
              cursor: pointer;
              margin-right: 5px;
            }
          }
        }
      }
      .tb-pagination {
        height: 30px;
        display: flex;
        align-items: center;
        ::v-deep .el-pagination {
          margin-top: 50px;
          height: 30px;
          display: flex;
          align-items: center;
          button {
            background: transparent;
            font-size: 12px;
            color: #889fb2;
            text-align: center;
            font-weight: 400;
            &:disabled {
              background: transparent;
              color: #889fb2;
              opacity: 0.3;
            }
          }
          .el-pagination__total {
            font-size: 12px;
            color: #889fb2;
            text-align: center;
            font-weight: 400;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            margin: 0px;
          }
          .el-pagination__sizes {
            height: 30px;
            line-height: 30px;
            margin: 0px;
            .el-select {
              .el-input {
                width: 60px;
                padding-right: 20px;
                margin: 0;
                .el-input__inner {
                  background: transparent;
                  border: none;
                  line-height: 30px;
                  font-size: 12px;
                  color: #889fb2;
                  text-align: center;
                  font-weight: 400;
                  padding: 0px;
                }
              }
            }
          }
          .el-pager {
            .number {
              background: transparent;
              font-size: 12px;
              color: #889fb2;
              text-align: center;
              font-weight: 400;
              &.active {
                background: $primary-color;
                border-radius: 2px;
                color: #ffffff;
              }
            }
            .more {
              background: transparent;
              color: #889fb2;
            }
          }
          .el-pagination__jump {
            font-size: 12px;
            color: #889fb2;
            text-align: center;
            font-weight: 400;
            height: 30px;
            line-height: 30px;
            margin-left: 6px;
            .el-input {
              .el-input__inner {
                border: 1px solid rgba(36, 146, 252, 0.3);
                border-radius: 2px;
                background: transparent;
                color: #889fb2;
              }
            }
          }
        }
        ._jumper {
          font-size: 12px;
          color: #889fb2;
          text-align: center;
          font-weight: 400;
          display: flex;
          align-items: center;
          white-space: nowrap;
          ::v-deep .el-input {
            .el-input__inner {
              background: rgba(12, 13, 20, 0.5);
              border: 1px solid rgba(36, 146, 252, 0.3);
              border-radius: 2px;
              width: 33px;
              height: 24px;
              color: #889fb2;
              padding: 0;
              text-align: center;
            }
          }
          ._jumper_btn {
            width: 51px;
            height: 22px;
            background: #333c57;
            border: 1px solid $primary-border-color;
            border-radius: 2px;
            padding: 0px;
            font-size: 14px;
            color: $primary-color;
            letter-spacing: -0.08px;
            text-align: center;
            font-weight: 400;
            margin-left: 4px;
          }
        }
      }
    }
  }
}
::v-deep .el-input__inner {
  background-color: #000000;
  border-radius: 0px;
  border: 1px solid #08c2d1;
}
::v-deep .el-date-editor .el-range-input {
  background-color: #000000;
}
::v-deep .el-date-editor .el-range-separator {
  color: #ffffff;
}
.airtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.airtime {
  word-wrap: break-word;
  word-break: break-all;
}
</style>
