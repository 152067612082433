
import API from "@/api"
// import image from "@/assets/images/icons/warning.svg"
import image1 from "@/assets/images/factor/zysb/a.svg"
import image2 from "@/assets/images/factor/zysb/b.svg"
import image3 from "@/assets/images/factor/zysb/c.svg"

let warning_entity = {}
let is_all = false

export default {

  async list (params) {
    // console.log('deviceWarning')
    let { pagination, search, status, selected_list } = this.warning
    let _params = {
      ...pagination,
      ...search,
      ...status,
      ...params
    }
    let res
    this.warning.loading = true
    if (status.status == 1) {
      res = await API.TOWER.DeviceWarning(_params)
      if (res && res.data) {
        res.data = res.data.map(item => ({
          ...item,
          name: item.deviceName,
          __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
        }))
      }
    } else if (status.status == 2) {
      res = await API.TOWER.NestWarning(_params)
      if (res && res.data) {
        res.data = res.data.map(item => ({
          ...item,
          name: item.nestName,
          __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
        }))
      }
    } else {
      res = await API.TOWER.BaseWarning(_params)
      if (res && res.data) {
        res.data = res.data.map(item => ({
          ...item,
          name: item.baseName,
          __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
        }))
      }
    }
    this.warning.loading = false
    // console.log(res, '设备预警')

    let { pageNo, pageSize, totalCount } = res
    this.warning.list = res?.data || []
    this.warning.pagination.pageNo = pageNo || 1
    this.warning.pagination.pageSize = pageSize || 10
    this.warning.pagination.totalCount = totalCount || 0
  },
  change_tab (status) {
    this.warning.status.status = status
    this.warning_list()
  },
  change_status (params) {
    this.warning.status = params
    this.warning_list(params)
  },
  change_size (size) {
    this.warning.pagination.pageSize = size
    this.warning_list()
  },
  change_page (page) {
    this.warning.pagination.pageNo = page
    this.warning_list()
  },

  search (name) {
    this.warning.search.name = name
    this.warning_list()
  },

  change (warning) {
    console.log('warning', warning);
    warning.__selected = !warning.__selected
    if (warning.__selected) {
      this.warning.selected_list.push(warning.id)
      this.warning_show(warning)
    } else {
      let index = this.warning.selected_list.findIndex(id => id === warning.id)
      if (index !== -1) {
        this.warning.selected_list.splice(index, 1)
      }
      this.warning_hide(warning)
    }

    let { list, selected_list } = this.warning
    list = list.map(item => ({
      ...item,
      __selected: (selected_list && selected_list.length) ? selected_list.includes(item.id) : false
    }))
    this.warning.list = list || []
  },

  /**
   * 显示一个设备
   * @param {*} warning 
   */
  show (warning, is_all) {
    // console.log("show warning:", warning);
    if (!warning.longitude || !warning.latitude) {
      this.$message.error('暂无位置信息');
      return;
    }


    try {
      let image, id = warning.id
      let position = Cesium.Cartesian3.fromDegrees(warning.longitude, warning.latitude)
      if (warning.hasOwnProperty('baseName')) {
        image = image3
      } else if (warning.hasOwnProperty('nestName')) {
        image = image2
      } else {
        image = image1
      }
      warning_entity[id] = {}
      let entity_data = {
        id: warning.id,
        name: warning.baseName,
        address: warning.address,
        organizationName: warning.organizationName,
        longitude: warning.longitude,
        latitude: warning.latitude,
        date: new Date().getTime()
      }
      let entity_id = JSON.stringify(entity_data)
      warning_entity[id].billboard = viewer.entities.add({
        id: entity_id,
        name: 'warning',
        position,
        billboard: {
          // width: 27,
          // height: 44,
          // width: 48,
          // height: 48,
          scale: 0.7,
          width: 54,
          height: 89,
          image,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
        },
      });
      // warning_entity[id].ellipse = viewer.entities.add({
      //     name: "",
      //     position: position,
      //     ellipse: {
      //       // height: 200,
      //       semiMinorAxis: 100,
      //       semiMajorAxis: 100,
      //       material: new Cesium.CircleWaveMaterialProperty({
      //         duration: 2e3,
      //         gradient: 0,
      //         color: new Cesium.Color(1.0, 0.0, 0.0, 1.0),
      //         count: 3
      //       })
      //     }
      // });
      // let _util = new Cesium.Utils(viewer)
      // _util.setRadarScanEffect({
      //     position: Cesium.Cartesian3.fromDegrees(106.54439406642704, 29.53412750079538, 10.0),
      //     color: Cesium.Color.DARKCYAN,
      //     radius: 500
      // })
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(warning.longitude, warning.latitude, 500.0),
        // orientation: {
        //     heading: Cesium.Math.toRadians(20.0),
        //     pitch: Cesium.Math.toRadians(-35.0),
        //     roll: 0.0,
        // },
      });
    } catch (error) {
      this.$el_message("无位置信息", () => { }, "info");
    }
  },

  /**
   * 隐藏一个设备
   * @param {*} warning 
   */
  hide (warning) {
    let id = warning.id
    this.warning_close(id)
  },

  /**
   * 设备分布
   */
  async distribution () {
    let data = await API.TOWER.GetAllwarning()
    if (!is_all) {
      data.list.forEach(item => {
        this.warning_show(item, true)
      })
      is_all = true
    } else {
      is_all = false
      this.warning_clear_entity()
    }
  },
  close (id) {
    if (warning_entity && warning_entity[id]) {
      Object.values(warning_entity[id]).forEach(item => {
        viewer.entities.remove(item)
      })
      warning_entity[id] = null
    }
  },
  clear_entity () {
    for (let key in warning_entity) {
      this.warning_close(key)
    }
    warning_entity = {}
    this.warning.selected_list = []
  }
}