// 创建弹窗对象的方法
var Popup = function (info) {
  this.constructor(info);
}
Popup.prototype.id = 0;
Popup.prototype.constructor = function (info) {
  // console.log("info.this---***>", info);
  var _this = this;
  _this.viewer = info.viewer;//弹窗创建的viewer
  // _this.viewer = viewer;//弹窗创建的viewer
  _this.geometry = info.geometry;//弹窗挂载的位置
  _this.data = info.data;
  _this.vue_this = info.vue_this;
  _this.callback = info.callback;
  _this.uav_id = info.data.id || info.id;
  _this.is_danger = info.data.is_danger;
  // if(info.hasOwnProperty('arr')) {
  //   _this.arr = info.arr
  // }
  var class_name = _this.is_danger ? 'bx-popup-ctn active' : 'bx-popup-ctn'

  _this.id = "popup_" + _this.__proto__.id++;
  // _this.ctn = $("<div class='bx-popup-ctn' id='" + _this.id + "'>");
  _this.ctn = $("<div class='" + class_name + "' id='" + _this.id + "'>");
  $(_this.viewer.container).append(_this.ctn);

  let contentHtml = ''
  if (_this.data?.organizationName) {
    contentHtml += `<div class="li"><div class="label">单位：</div><div class="text" style="width: 160px;">${_this.data.organizationName}</div></div>`
  }
  if (_this.data?.address) {
    contentHtml += `<div class="li"><div class="label">地址：</div><div class="text">${_this.data.address}</div></div>`
  }
  if (_this.data?.longitude) {
    contentHtml += `<div class="li"><div class="label">经度：</div><div class="text">${_this.data.longitude}</div></div>`
  }
  if (_this.data?.latitude) {
    contentHtml += `<div class="li"><div class="label">纬度：</div><div class="text">${_this.data.latitude}</div></div>`
  }
  if (_this.data?.altitude) {
    contentHtml += `<div class="li"><div class="label">海拔：</div><div class="text">${_this.data.altitude}</div></div>`
  }
  if (_this.data?.height) {
    contentHtml += `<div class="li"><div class="label">高度：</div><div class="text">${_this.data.height}</div></div>`
  }
  if (_this.data?.airspace_hint) {
    contentHtml += `<div class="li"><div class="label">空域：</div><div class="text">${_this.data.airspace_hint}</div></div>`
  }
  if (_this.data?.airline_hint) {
    contentHtml += `<div class="li"><div class="label">航线：</div><div class="text">${_this.data.airline_hint}</div></div>`
  }
  if (_this.data?.hedgehop_hint) {
    contentHtml += `<div class="li"><div class="label">低空预警：</div><div class="text">${_this.data.hedgehop_hint}</div></div>`
  }
  if (_this.data?.airline_distance) {
    contentHtml += `<div class="li"><div class="label">距离最近无人机：</div><div class="text">${_this.data.airline_distance}米</div></div>`
  }
  if (_this.data?.bs) {
    contentHtml += `<div class="li"><div class="label">快反时间：</div><div class="text">${_this.data.bs}</div></div>`
  }
  if (_this.data?.unitIndexCode) {
    contentHtml += `<div class="li"><div class="hk-link" data-code="${_this.data.unitIndexCode}">视频查看</div></div>`
  }
  if (_this.data.typeId == 1) {
    contentHtml += `<div class="li"><div class="label">提示：</div><div class="text">此处为禁飞区</div></div>`
  }
  if (_this.data.typeId == 2) {
    contentHtml += `<div class="li"><div class="label">提示：</div><div class="text">此处为电子围栏</div></div>`
  }
  if (_this.data.typeId == 3) {
    contentHtml += `<div class="li"><div class="label">提示：</div><div class="text">此处为电子围栏</div></div>`
  }
  if (_this.data?.min_height) {
    contentHtml += `<div class="li"><div class="label">本网格限高：</div><div class="text">${_this.data.min_height}米</div></div>`
  }
  if (_this.data?.temperature) {
    contentHtml += `<div class="li"><div class="label">温度：</div><div class="text">${_this.data.temperature}°</div></div>`
  }
  if (_this.data?.humidity) {
    contentHtml += `<div class="li"><div class="label">湿度：</div><div class="text">${_this.data.humidity}%</div></div>`
  }
  if (_this.data?.elecState) {
    contentHtml += `<div class="li"><div class="label">供电状态：</div><div class="text">${_this.data.elecState ? '正常' : '断电'}</div></div>`
  }
  if (_this.data?.network) {
    contentHtml += `<div class="li"><div class="label">运营商：</div><div class="text">${_this.data.network == 1 ? '电信' : (_this.data.network == 2 ? '移动' : '联通')}</div></div>`
  }
  if (_this.data?.signalRate) {
    contentHtml += `<div class="li"><div class="label">信号覆盖范围：</div><div class="text">${_this.data.signalRate.toFixed(2)}</div></div>`
  }
  if (_this.data?.addtime) {
    contentHtml += `<div class="li"><div class="label">创建时间：</div><div class="text">${_this.data.addtime}</div></div>`
  }
  if (_this.data?.content) {
    contentHtml += `<div class="li"><div class="label">内容：</div><div class="text">${_this.data.content}</div></div>`
  }


  //测试弹窗内容
  var testConfig = {
    header: _this.data.name || _this.data.carNo || '',
    content: `<div>${contentHtml}</div>`,
  }
  _this.ctn.append(_this.createHtml(testConfig.header, testConfig.content));
  // 实时刷新
  _this.offsetWidth = _this.ctn.get(0).offsetWidth
  _this.offsetHeight = _this.ctn.get(0).offsetHeight

  _this.render(_this.geometry);

  // _this.eventListener = function (clock) {
  //   console.log('onTick')
  //   _this.render(_this.geometry);
  // }
  // _this.viewer.clock.onTick.addEventListener(_this.eventListener)

  _this.eventListener = () => { // 每一帧都去计算气泡的正确位置
    _this.render(_this.geometry);
  }
  _this.viewer.scene.postRender.addEventListener(_this.eventListener);

  _this.ctn.on("click", ".leaflet-popup-close-button", function (e) {
    e.stopPropagation();
    _this.close();
  });
}
Popup.prototype.render = function (geometry) {
  // console.log(geometry, 'render()...geometry');
  if (!geometry) return;
  var _this = this;
  var position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(_this.viewer.scene, geometry)
  if (!position) return;
  // _this.ctn.css("position","absolute");
  _this.ctn.css("left", position.x - _this.offsetWidth / 2);
  _this.ctn.css("top", position.y - _this.offsetHeight - 10);
}
// 动态生成内容
Popup.prototype.createHtml = function (header, content) {
  var html = '<div class="bx-popup-header-ctn">' +
    header +
    '</div>' +
    '<div class="bx-popup-content-ctn" >' +
    '<div class="bx-popup-content" >' +
    content +
    '</div>' +
    '</div>' +
    '<div class="bx-popup-tip-container" >' +
    '<div class="bx-popup-tip" >' +
    '</div>' +
    '</div>' +
    '<div class="el-icon-close leaflet-popup-close-button"></div>';
  return html;
}
// 关闭弹窗按钮
Popup.prototype.close = function () {
  var _this = this;
  _this.vue_this && _this.vue_this?.close(_this.uav_id)
  _this.callback && _this.vue_this[_this.callback](_this.uav_id)
  // _this.eventListener && _this.viewer.clock.onTick.removeEventListener(_this.eventListener);
  _this.eventListener && _this.viewer.scene.postRender.removeEventListener(_this.eventListener);
  _this.ctn.remove();


  const index = _this.vue_this.icon_id_arr.indexOf('_this.data.id');
  _this.vue_this.icon_id_arr.splice(index, 1)
}

export default Popup