<template>
  <div class="content dialog1027">
    <div class="content_head dialog-header-1" v-interact>
      <div class="left">
        <!-- <div class="dialog-header__icon"></div> -->
        <div class="dialog-header__title_1">
          {{ form ? "人员编辑" : "人员登记" }}
        </div>
      </div>
      <div class="dialog-header__close" @click="() => $emit('closeuser')">
        <!-- 关闭 -->
        <img src="@/assets/img/i40.png" alt="" class="img">
      </div>
    </div>
    <div class="content_box dialog-content">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
        <el-form-item label="用户名称:" prop="name">
          <el-input v-model="ruleForm.name" clearable placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item label="用户身份类型:">
          <el-radio-group v-model="ruleForm.type">
            <el-radio :label="0">驾驶员</el-radio>
            <el-radio :label="1">飞行助理</el-radio>
            <el-radio :label="2">机长</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="编号:" prop="USER_POLICE_ID" v-if="ruleForm.type == 0">
          <el-input v-model="ruleForm.USER_POLICE_ID" clearable placeholder="请输入编号"></el-input>
        </el-form-item>
        <el-form-item label="身份证号码:" prop="USER_CODE">
          <el-input v-model="ruleForm.USER_CODE" clearable placeholder="请输入身份证号码"></el-input>
        </el-form-item>
        <el-form-item label="用户账号:" prop="username">
          <el-input v-model="ruleForm.username" clearable placeholder="请输入用户账号"></el-input>
        </el-form-item>
        <el-form-item label="手机号码:" prop="phone">
          <el-input v-model="ruleForm.phone" clearable placeholder="请输入手机号码"
            oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
        </el-form-item>
        <el-form-item label="所属机构:" prop="organizationId">
          <el-cascader v-model="ruleForm.organizationId" :options="options" clearable :showAllLevels="false"
            popper-class="personal-cascader-user-info" placeholder="请选择所属机构" :props="{
              value: 'id',
              label: 'orgName',
              children: 'children',
              emitPath: false,
              checkStrictly: true,
            }"></el-cascader>
        </el-form-item>
        <el-form-item label="所属角色:" prop="mmcRoleId">
          <el-select popper-class="popper-select-panel" v-model="ruleForm.mmcRoleId" clearable placeholder="请选择所属角色">
            <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <div class="flex"> -->
        <el-form-item label="是否机构管理员:" prop="orgAdmin">
          <el-radio-group v-model="ruleForm.orgAdmin">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <!-- <el-switch v-model="ruleForm.orgAdmin"></el-switch> -->
        </el-form-item>
        <el-form-item label="是否为飞手:" prop="isPilot">
          <el-radio-group v-model="ruleForm.isPilot">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <!-- <el-switch v-model="ruleForm.isPilot"></el-switch> -->
        </el-form-item>
        <el-form-item label="是否取得相应驾驶证:" prop="isPoliceFlightCertificate">
          <el-radio-group v-model="ruleForm.isPoliceFlightCertificate">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
          <!-- <el-switch v-model="ruleForm.isPoliceFlightCertificate"></el-switch> -->
        </el-form-item>
        <!-- </div> -->
        <el-form-item label="用户层级:" prop="userLevel">
          <el-select popper-class="popper-select-panel-user-info" v-model="ruleForm.userLevel" clearable
            placeholder="请选择用户层级">
            <el-option v-for="item in hierarchyList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登陆方式:" prop="loginType">
          <el-select popper-class="popper-select-panel-user-info" v-model="ruleForm.loginType" clearable
            placeholder="请选择登陆方式">
            <el-option v-for="item in loginTypeList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="可控无人机类型:" prop="cateId">
          <el-select popper-class="popper-select-panel-user-info" v-model="ruleForm.cateId" clearable
            placeholder="请选择可控无人机类型">
            <el-option v-for="item in cateList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录密码:" prop="password">
          <!-- <el-input
            v-model.trim="ruleForm.password"
            type="password"
            clearable
            id="password"
            placeholder="请输入登录密码"
            @focus="(e)=>e.target.removeAttribute('readonly')"
            @blur="(e)=>e.target.readOnly = 'readonly'"
            readonly
          ></el-input> -->
          <!-- <el-input
            v-model.trim="ruleForm.password"
            type="password"
            clearable
            id="password"
            placeholder="请输入登录密码"
            @focus="((e) => {e.target.blur(),e.target.focus()})"
          ></el-input> -->
          <!-- <el-input
            v-model.trim="ruleForm.password"
            type="password"
            clearable
            id="password"
            placeholder="请输入登录密码"
            autocomplete="off"
          ></el-input> -->
          <el-input v-model.trim="ruleForm.password" type="password" clearable id="password" placeholder="请输入登录密码"
            @click.native="() => (readonly = false)" @mouseenter.native="() => (readonly = true)"
            :readonly="readonly"></el-input>
        </el-form-item>
        <el-form-item label="确认密码:" prop="confirmPassword">
          <el-input v-model.trim="ruleForm.confirmPassword" type="password" clearable id="confirmPassword"
            placeholder="请确认密码" @click.native="() => (readonly = false)" @mouseenter.native="() => (readonly = true)"
            :readonly="readonly"></el-input>
        </el-form-item>
        <el-form-item label="用户状态:" prop="status" v-if="ruleForm.id">
          <el-radio-group v-model="ruleForm.status">
            <el-radio label="1">正常</el-radio>
            <el-radio label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item label="警航证正面照:" prop="flightCertificatePathFront">
          <el-upload
            :action="url"
            :http-request="handle_http_request_1"
            :multiple="false"
            accept=".png, .jpg"
            :limit="1"
            :on-remove="onRemove_1"
          >
            <el-button size="small" type="primary" style="margin: 0"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">支持jpg/png格式</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="警航证反面照:" prop="flightCertificatePathBack">
          <el-upload
            :action="url"
            :http-request="handle_http_request_2"
            :multiple="false"
            accept=".png, .jpg"
            :limit="1"
            :on-remove="onRemove_2"
          >
            <el-button size="small" type="primary" style="margin: 0"
              >点击上传</el-button
            >
            <div slot="tip" class="el-upload__tip">支持jpg/png格式</div>
          </el-upload>
        </el-form-item> -->
        <el-form-item label="备注说明:" prop="comment">
          <el-input v-model="ruleForm.comment" clearable type="textarea" placeholder="请输入备注说明"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn-box1027">
        <div class="btn1027" @click="submit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  props: ["form"],
  data () {
    let validatePassword = (rule, value, callback) => {
      let password = /^[a-zA-Z0-9]{6,18}$/;
      if (!value) {
        callback(new Error("请输入登录密码"));
      } else if (!password.test(value) && value != "******") {
        callback(new Error("登录密码需由6-18位数字字母组成"));
      } else {
        callback();
      }
    };
    let validatorCfmPsd = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value != this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    let validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入用户名称"));
      } else if (value.length > 10) {
        callback(new Error("长度限制在10位数以内"));
      } else {
        callback();
      }
    };
    let validateUserName = async (rule, value, callback) => {
      let username = /^[0-9A-Za-z]{5,12}$/;
      if (!value) {
        callback(new Error("请输入用户账号"));
      } else if (!username.test(value)) {
        callback(new Error("用户账号需由5-12位数字字母组成"));
      } else {
        callback();
      }
    };
    let validatePhone = async (rule, value, callback) => {
      let phone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else if (!phone.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    let validateRemark = (rule, value, callback) => {
      if (value && value.length > 30) {
        callback(new Error("长度限制在30位数以内"));
      } else {
        callback();
      }
    };
    return {
      url: "",
      ruleForm: {
        password: null,
        confirmPassword: null,
        mmcRoleId: null,
        userLevel: null,
        loginType: 1,
        uavType: null,
        // type: [],
        orgAdmin: 0,
        isPilot: 0,
        isPoliceFlightCertificate: 0,
        type: 0,
      },
      rules: {
        username: [
          {
            required: true,
            validator: validateUserName,
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            validator: validateName,
            trigger: "blur",
          },
        ],
        userLevel: [
          {
            required: true,
            message: "请选择用户层级",
            trigger: "change",
          },
        ],
        uavType: [
          {
            required: true,
            message: "请选择可控飞机类型",
            trigger: "change",
          },
        ],
        loginType: [
          {
            required: true,
            message: "请选择登陆方式",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            validator: validatorCfmPsd,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        organizationId: [
          {
            required: true,
            message: "请选择所属机构",
            trigger: "change",
          },
        ],
        mmcRoleId: [
          {
            required: true,
            message: "请选择所属角色",
            trigger: "change",
          },
        ],
        remark: [
          {
            validator: validateRemark,
            trigger: "blur",
          },
        ],
      },
      hierarchyList: [
        {
          id: 0,
          name: "一级",
        },
        {
          id: 1,
          name: "二级",
        },
        {
          id: 2,
          name: "三级",
        },
      ],
      loginTypeList: [
        {
          id: 0,
          name: "数字证书",
        },
        {
          id: 1,
          name: "用户名密码",
        },
        {
          id: 2,
          name: "人脸识别",
        },
      ],
      cateList: [],
      options: [],
      roleList: [],
      readonly: true,
    };
  },
  methods: {
    /*无人机型号 */
    async getCate () {
      let res = await API.DEVICE.ListCate();
      this.cateList = res;
    },
    /*机构 */
    async getOptions () {
      let res = await API.DEPARTMENT.Drd();
      // each(res);
      console.log(res, '机构后端返回结果');
      this.options = [res] || [];
    },
    async getroleList () {
      let data = await API.DEVICE.roleList();
      console.log(data, "asdbhhjbdhjas");
      this.roleList = data || [];
    },
    async handle_http_request (e) {
      let file = e.file,
        fd = new FormData();
      fd.append("uploadFiles", file);
      let res = await API.MAPMANAGE.fileUpload(fd);
      if (res.status == 1) {
        this.ruleForm.image = res.data[0].url;
      } else {
        this.$message.error(res?.message);
      }
    },
    submit () {
      this.$refs["ruleForm"].validate((valid) => {
        if (this.ruleForm.password == "******") {
          delete this.ruleForm.password;
        }
        if (valid) {
          if (this.ruleForm.id) {
            API.DEVICE.userEdit(this.ruleForm).then((res) => {
              if (res.status == 1) {
                this.$message.success("编辑成功!");
                this.$emit("closeuser");
              }
            });
          } else {
            API.DEVICE.userAdd(this.ruleForm).then((res) => {
              if (res.status == 1) {
                this.$message.success("创建成功!");
                this.$emit("closeuser");
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    changePic (file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; //这一步，是 展示最后一次选择文件
      }
    },
    onRemove () {
      this.ruleForm.image = null;
    },
  },
  mounted () {
    let baseUrl = process.env.VUE_APP_BASE_URL;
    this.url = baseUrl + "/upload/v2_uploads";
    console.log(this.url, "jfdbshjahjfgasuf");
    this.getCate();
    this.getroleList();
    this.getOptions();
    if (this.form != null) {
      this.ruleForm = this.form;
      this.$set(this.ruleForm, "password", "******");
      this.$set(this.ruleForm, "confirmPassword", "******");
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: fixed;
  left: 596px;
  top: 10%;
  // height: 800px;
  height: auto;
  color: white;
  background-color: #0A293A !important;

  .content_head {
    height: 36px;
    background-color: #224D68 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 5px 10px 5px 10px;
    border-radius: 0 !important;
    // background: none !important;
    box-shadow: none !important;

    .left {
      .dialog-header__title_1 {
        color: #ffffff !important;
        font-weight: bold !important;
      }
    }

    .dialog-header__close {
      cursor: pointer;

      .img {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }

  }

  .content_box {
    width: 643px;
    height: 700px;
    padding: 16px;
    overflow-y: auto;
    overflow-x: hidden;

    border: 0 !important;
    border-radius: 0 !important;
  }

  .bg-color1 {
    background-color: #2B4169 !important;
  }

  .bg-color2 {
    background-color: #081A3A !important;
  }
}

::v-deep {
  .el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #ffffff;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
}
</style>
<style lang="scss">
.personal-cascader-user-info {
  background-image: url('~@/assets/img/i101.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0 !important;
  margin: 0 !important;
  // top: 130px !important;

  .el-scrollbar {
    background-color: transparent;
    border: 0;
    border-right: 1px solid #223E4D;
  }

  .el-scrollbar:last-child {
    border: 0 !important;
  }

  .el-cascader-node {
    background-color: transparent;
  }

  .el-cascader__dropdown {
    box-shadow: unset;
    margin: 0 !important;
    border: 0 !important;
  }

  .el-scrollbar__view {
    background-color: #0A293A;
  }

  .popper__arrow {
    display: none;
  }

  .el-cascader-node__label {
    color: #fff;
  }

  .el-icon-arrow-right {
    color: #fff;
  }
}

.popper-select-panel-user-info {
  .el-scrollbar {
    background-color: #0A293A !important;
    border: 1px solid #223E4D;

  }

  .el-select-dropdown__item:hover {
    background-color: #34466E !important;
  }
}
</style>
