<template>
  <div>
    <div class="cpt-command-situation-edit">
      <div class="title-box mt5" v-interact>警情派发</div>
      <img
        src="~@/assets/images/accident/close.png"
        class="img-close mt5"
        @click="handleCancer"
      />
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-form-item label="警情事件内容" prop="content">
          <el-input v-model="ruleForm.content" clearable></el-input>
        </el-form-item>
        <el-form-item label="目标位置" prop="destination">
          <el-input
            v-model="ruleForm.incidentAddress"
            :disabled="true"
          ></el-input>
          <div
            @click="() => $emit('handleLocation', situation_detail)"
            class="el-icon-location-outline"
          ></div>
          <!-- <div class="item-group" :title="ruleForm.destination">
            <el-autocomplete
              v-model="ruleForm.destination"
              :fetch-suggestions="handle_query_address_async"
              placeholder="请输入目标位置"
              :trigger-on-focus="false"
              @select="handle_select_address($event, 0)"
              clearable
            >
              <template slot-scope="{ item }">
                <div>
                  <span style="font-size: 14px; color: #333">{{
                    item.name
                  }}</span>
                  <span
                    style="font-size: 12px; color: #999; margin-left: 12px"
                    >{{ item.address }}</span
                  >
                </div>
              </template>
            </el-autocomplete>
            <div @click="handle_pick(0)" class="el-icon-location-outline"></div>
          </div> -->
        </el-form-item>

        <!-- 暂时不用 -->
        <!-- <el-form-item label="起点位置" prop="startPosition">
          <div class="item-group" :title="ruleForm.startPosition">
            <el-autocomplete
              v-model="ruleForm.startPosition"
              :fetch-suggestions="handle_query_address_async"
              placeholder="请输入起点位置地址"
              :trigger-on-focus="false"
              @select="handle_select_address($event, 1)"
              clearable
            >
              <template slot-scope="{ item }">
                <div>
                  <span style="font-size: 14px; color: #333">{{
                    item.name
                  }}</span>
                  <span
                    style="font-size: 12px; color: #999; margin-left: 12px"
                    >{{ item.address }}</span
                  >
                </div>
              </template>
            </el-autocomplete>
            <div @click="handle_pick(1)" class="el-icon-location-outline"></div>
          </div>
        </el-form-item> -->
        <el-form-item label="航线名称" prop="flight_line_id">
          <el-select
            v-model="ruleForm.flight_line_id"
            clearable
            placeholder="请选择航线"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in lineList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            <span style="float: left">{{ item.title }}</span>
            <span style="float: left; color: #00b900; font-size: 13px">{{ item.issafe == 1 ? '（安全）' : '' }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="警情类型" prop="jing_qing_type">
          <el-select
            v-model="ruleForm.jing_qing_type"
            clearable
            placeholder="请选择警情类型"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.title"
              :value="item.title"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="警情执行状态" prop="taskStatus">
          <el-select
            v-model="ruleForm.taskStatus"
            clearable
            placeholder="请选择执行状态"
            :popper-append-to-body="false"
            :disabled="true"
          >
            <el-option label="待执行" :value="0"></el-option>
            <el-option label="执行中" :value="3"></el-option>
            <el-option label="已完成" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="mountType" label="所需挂载">
          <el-select
            v-model="ruleForm.mountType"
            multiple
            collapse-tags
            clearable
            placeholder="请选择所需挂载"
          >
            <el-option
              v-for="item in mounteList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="责任单位" al prop="organizationId">
          <el-cascader
            ref="elcascader"
            @change="changeVal"
            placeholder="请选择所属机构"
            v-model="ruleForm.organizationId"
            :options="unitList"
            :show-all-levels="false"
            :props="{
              children: 'children',
              label: 'orgName',
              value: 'id',
              checkStrictly: true,
              emitPath: false,
            }"
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="需协飞单位">
          <!-- <el-checkbox v-model="checked1">本单位</el-checkbox> -->
          <el-checkbox v-model="checked2">最优调度</el-checkbox>
        </el-form-item>
        <el-form-item class="assist" v-show="checked2" label="协助单位">
          <el-dropdown :hide-on-click="false">
            <div class="assist-content pl15">
              {{ selectList }}
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in selectArr"
                :key="index"
                >{{ item }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <el-form-item label="飞手选择" prop="userIds">
          <el-select
            v-model="ruleForm.userIds"
            clearable
            placeholder="请选择"
            :popper-append-to-body="false"
            multiple
          >
            <el-option
              v-for="item in flyerList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容描述" prop="status">
          <el-input
            v-model="ruleForm.remark"
            clearable
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button @click="handleClickFn">提交</el-button>
        <el-button @click="handleCancer">取消</el-button>
      </div>
    </div>
    <Resource
      v-if="resourceVisible"
      @closeSource="resourceVisible = false"
      @acceptSelect="acceptSelectFn"
    ></Resource>
  </div>
</template>

<script>
import Resource from "@/components/command/situation/resource";
import {
  AddRadarScanPostStage,
  AddCircleScanPostStage,
} from "@/utils/radar.js";
import API from "@/api";
import { Utils } from "@/lib/cesium";
let handler = null;
let data_srouce = null;
let location_icon = null;
import { mapGetters } from "vuex";

export default {
  components: {
    // Popup,
    // PopupItem,
    Resource,
  },
  data() {
    return {
      resourceVisible: false,
      mounteSelect: [],
      mounteList: [],
      searchRange: 10,
      AssistInUnit: null,
      value1: null,
      checked1: true,
      checked2: false,
      ruleForm: {
        content: null,
        incidentAddress: null, //目标位置
        startPosition: null, //起始位置
        flight_line_id: null,
        jing_qing_type: null,
        taskStatus: null,
        organizationId: null,
        userIds: null,
        remark: null,
        taskId: null,
        id: null,
        mountType: null,
        resourceName: null,
      },
      rules: {},
      lineList: [],
      unitList: [],
      flyerList: [],
      typeList: [],
      destination: {
        // name: '',
        lng: 0,
        lat: 0,
      },
      startPosition: {
        // name: '',
        lng: 0,
        lat: 0,
      },
      selectList: "",
      selectArr: [],
    };
  },
  computed: {
    ...mapGetters(["situation_detail"]),
    cesium_layer() {
      return this.g_cesium_layer();
    },
  },
  inject: ["g_cesium_layer", "change_page"],
  async mounted() {
    this.getMount();
    await this.getLineList(),
      await this.getUnitList(),
      await this.getFlyerList(),
      await this.getTypeList(),
      this.getDetail();
  },
  methods: {
    handleCancer() {
      this.$emit("handleClick");
      this.checked2 = false;
      this.resourceVisible = false;
    },
    // 接收子组件勾选资源单位
    acceptSelectFn(val, resourceName) {
      this.ruleForm.resourceName = JSON.stringify(resourceName);
      let arr = [];
      val.map((item) => arr.push(item.organizationName));
      this.selectArr = Array.from(new Set(arr));
      this.selectList = this.selectArr.join();
    },
    changeVal() {
      this.$refs.elcascader.dropDownVisible = false;
    },
    getDetail() {
      const keys = Object.keys(this.ruleForm);
      keys.forEach((item) => {
        this.ruleForm[item] = this.situation_detail[item];
      });
    },
    async handleClickFn() {
      const keys = Object.keys(this.ruleForm);
      const form = JSON.parse(JSON.stringify(this.ruleForm));
      keys.forEach((item) => {
        if (form[item] == this.situation_detail[item]) {
          if (item != "id" && item != "taskId") {
            delete form[item];
          }
        }
      });
      try {
        if (form.resourceName) {
          form.flag = 1;
        }
        let res = await API.SITUATION.DoPoliceDispatch(form);
        this.$emit("handleClick", false);
        this.checked2 = false;
        this.resourceVisible = false;
        this.$el_message("提交成功");
      } catch (error) {
        this.$el_message("提交失败", () => {}, "error");
      }
    },
    async getLineList() {
      let data = await API.AIRWAY.Drd();
      this.lineList = data || [];
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async getFlyerList() {
      let data = await API.USER.DrdPilot();
      this.flyerList = data || [];
    },
    async getTypeList() {
      let data = await API.SITUATION.ListTpye();
      this.typeList = data || [];
    },
    // 获取挂载列表
    async getMount() {
      let data = await API.SITUATION.getAllMountTypeList();
      this.mounteList = data || [];
    },
    handle_query_address_async(address, cb) {
      API.MAP.Geo({ keywords: address })
        .then((res) => {
          cb(res.data.data.rows);
        })
        .catch((e) => cb([]));
    },
    handle_select_address(item, id) {
      if (item) {
        let location = item.location.split(",");
        if (id == 0) {
          this.ruleForm.destination = item.name;
          this.destination.lng = Number(location[0]);
          this.destination.lat = Number(location[1]);
        } else {
          this.ruleForm.startPosition = item.name;
          this.startPosition.lng = Number(location[0]);
          this.startPosition.lat = Number(location[1]);
        }

        let cesium_layer = this.g_cesium_layer();
        let viewer = cesium_layer.viewer();
        let potiion = Utils.transformWGS842Cartesian({
          lng: Number(location[0]),
          lat: Number(location[1]),
          alt: 1000,
        });
        viewer.camera.flyTo({
          destination: potiion,
        });
        this.init_location_icon(potiion);
      }
    },
    init_location_icon(position) {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();

      if (location_icon) {
        location_icon.position = position;
      } else {
        location_icon = viewer.entities.add({
          position,
          billboard: {
            width: 36,
            height: 36,
            image: require("@/assets/images/icons/icon_location.png"),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.Bottom,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          },
        });
      }
    },
    handle_pick(id) {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();

      this.destroy_cesium_handler();

      handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction(async (movement) => {
        let position = viewer.scene.camera.pickEllipsoid(
          movement.position,
          viewer.scene.globe.ellipsoid
        );
        let wgs84 = Utils.transformCartesian2WGS84(position);

        let address = await API.MAP.Regeo({
          location: `${wgs84.lng},${wgs84.lat}`,
        });

        if (address) {
          if (id == 0) {
            this.ruleForm.destination = address;
            this.destination.lng = wgs84.lng;
            this.destination.lat = wgs84.lat;
          } else {
            this.ruleForm.startPosition = address;
            this.startPosition.lng = wgs84.lng;
            this.startPosition.lat = wgs84.lat;
          }

          handler.destroy();
          handler = null;
        } else {
          this.$el_message("地址解析失败", () => {}, "error");
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      this.$el_message("请在地图上选择目标位置");
    },
    destroy_cesium_handler() {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();

      if (handler) {
        handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
        handler = null;
      }

      if (data_srouce) {
        data_srouce.entities.removeAll();
        viewer.dataSources.remove(data_srouce, true);
        data_srouce = null;
      }
    },
    beforeDestroy() {
      console.log("destory");
    },
  },
  watch: {
    checked2() {
      this.resourceVisible = this.checked2;
      if (!this.checked2) {
        this.selectList = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
.cpt-command-situation-edit {
  position: absolute;
  // left: 100px;
  // top: 170px;
  width: 619px;
  // height: 900px;
  box-sizing: border-box;
  background: url("~@/assets/images/plate/air_space_bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 0 25px;
}
.title-box {
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #dce9ff;
  font-weight: 400;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  line-height: 3.2;
  padding-left: 132px;
}

.img-close {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.el-form {
  padding-top: 30px;
  ::v-deep {
    .el-textarea__inner {
      height: 70px;
    }
  }
}
.btn {
  margin-left: 139px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.el-icon-location-outline {
  color: rgb(179, 201, 203);
  font-size: 30px;
  margin-left: 0.3em;
  cursor: pointer;
}
/deep/.el-checkbox__input {
  background: #000000;
  border: 1px solid #08c2d1;
  border-radius: 4px;
}
/deep/.el-checkbox__inner {
  border: none;
  background-color: transparent;
}
.assist {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #dcdfe3;
  font-weight: 400;
}
.assist-content {
  border: 1px solid #08c2d1;
  width: 375px;
  background-color: #000;
  border-radius: 1px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: transparent;
}
/deep/ .el-dropdown {
  color: #08c2d1;
}
</style>
