<template>
  <div class="cpt-command-situation-detail">
    <div class="title-box" v-interact>任务详情</div>
    <img
      src="~@/assets/images/accident/close.png"
      class="img-close"
      @click="change_page"
    />
    <div class="flight_detail_box">
      <div class="flight_detail_list textarea">
        <span>任务名称</span>
        <div>{{ flight_detail.taskTitle || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>添加时间</span>
        <div>{{ flight_detail.addtime || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>航线名称</span>
        <div>{{ flight_detail.airlineTitle || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>飞手</span>
        <div>
          {{
            flight_detail.team && flight_detail.team.length
              ? flight_detail.team.join("、")
              : "暂无"
          }}
        </div>
      </div>
      
      <div class="flight_detail_list">
        <span>警情执行状态</span>
        <div>{{ get_status(flight_detail) }}</div>
      </div>
      
      <div class="flight_detail_list">
        <span>预计开始时间</span>
        <div>{{ flight_detail.startedAt || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>责任单位</span>
        <div>{{ flight_detail.organizationTitle || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>任务类型</span>
        <div>{{ flight_detail.taskTypeTitle || "暂无" }}</div>
      </div>
      <div class="flight_detail_list">
        <span>任务场景</span>
        <div>{{ flight_detail.cenarioName || "暂无" }}</div>
      </div>
      <div class="flight_detail_list textarea">
        <span>内容描述</span>
        <div>{{ flight_detail.remark || "暂无" }}</div>
      </div>
    </div>
    <el-button @click="$emit('handleClick')">关闭</el-button>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import API from "@/api";
export default {
  
  data() {
    return {};
  },
  props:[],
  inject: ["change_page"],
  computed: {
        ...mapGetters(["user_info", "flight_detail"]),

  },
  mounted(){
  },
  methods: {
    
     get_status(item) {
      let val = item;
      let text = "";
      if (val.applyStatus == 1 && val.papplyStatus == 1) {
        text = "审批通过";

        if (val.executeStatus == 0) {
          text = "待执行";
        } else if (val.executeStatus == 1) {
          text = "已完成";
        } else {
          text = "执行中";
        }
      } else if (val.applyStatus == 2 || val.papplyStatus == 2) {
        text = "审批不通过";
      } else {
        text = "待审批";
      }
      return text;
      // const obj = {
      //   0: '待审批',
      //   1: '审批不通过',
      //   2: '待执行',
      //   3: '执行中',
      //   4: '已完成',
      // }
      // return obj[item.taskSituation]
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-situation-detail {
  position: absolute;
  width: 1220px;
  height: 696px;
  box-sizing: border-box;
  background: url("~@/assets/images/plate/flight_bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 0 25px;
}
.title-box {
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #dce9ff;
  font-weight: 400;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  line-height: 3.2;
  padding-left: 132px;
}
.img-close {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.el-button {
  width: 114px;
  height: 40px;
  padding: 10px 20px;
  background: #129c9c;
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #ffffff;
  border: none;
  margin-left: 530px;
  margin-top: 20px;
}
.flight_detail_box {
  box-sizing: border-box;
  margin: auto;
  padding-top: 35px;
  font-family: MicrosoftYaHeiUI;
  font-size: 16px;
  color: #ccedff;
  width: 1050px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .flight_detail_list {
    width: 46%;
    display: flex;
    line-height: 40px;
    margin-bottom: 23px;
    > span {
      margin-right: 16px;
      display: inline-block;
      width: 105px;
      text-align: right;
    }
    > div {
      box-sizing: border-box;
      width: 396px;
      // height: 40px;
      background: #000000;
      border: 1px solid #08c2d1;
      padding: 0 15px;
    }
  }
  .textarea {
    div {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 80px;
      line-height: normal;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>
