<!-- 
  实时调度组件
 -->
<template>
  <div class="real-time-container">
    <div class="header">
      <span class="ttile">实时调度</span>
      <div class="left">

        <el-tooltip class="item" effect="dark" content="重置" placement="top">
          <img class="img img1" src="@/assets/img/i17.png" @click="reset" alt="">
        </el-tooltip>

        <!-- <div class="img3">
          <img class="img33" width="14" height="14" src="@/assets/img/i18.png" alt="">
        </div> -->

        <el-tooltip class="item" effect="dark" content="新建" placement="top">
          <img class="img" src="@/assets/img/plus.png" alt="" @click="addTone(0)">
        </el-tooltip>

      </div>
    </div>

    <div class="form">
      <div class="search">
        <el-input class="input-search" v-model="search.name" placeholder="请输入点调名称" clearable
          @clear="clearSearchInput"></el-input>
        <div class="btn btn1" @click="searchClick">
          <i class="el-icon-search"></i>
        </div>
      </div>
      <div class="line line2">
        <el-cascader class="input-orgname-fly-task" placeholder="请选择责任单位" v-model="search.organId"
          suffix-icon="el-icon-date" @visible-change="cascaderVisibleChange" :options="unitList"
          @change="cascaderNodeChange" popper-class="unit-cascader-drop-panel" :show-all-levels="false" :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }" clearable>
        </el-cascader>

        <!-- real-time-form-date-picker  -->
        <el-date-picker v-model="form.date" @focus="datePickerFocus" type="daterange" class="date-panel-picker"
          popper-class="common-date-time-picker-panel" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          @change="formDatePickerChange">
        </el-date-picker>
      </div>
    </div>

    <div class="body custom-scrollbar" id="realTimeContainerBody" v-loading="loading" element-loading-text="数据加载中"
      element-loading-spinner="my-custom-loader" element-loading-background="rgba(0, 0, 0, 0)">
      <div class="card-body">
        <Card class="card" v-for="(item, index) in toneList" :key="index" :data="item" @sonClick="sonClick"
          @addTone="addTone"></Card>
      </div>
      <div class="pagation">
        <el-pagination small :page-sizes="[10, 20, 30, 40]" :current-page.sync="pagination.pageNo"
          :page-size="pagination.pageSize" layout="prev, pager, next, total,  jumper" :total="pagination.totalCount"
          :pager-count="5" @current-change="handleSizeChange_station">
        </el-pagination>
      </div>
      <!-- <img id="scrollToTopRT" src="@/assets/img/i98.png" alt="" @click="scroll2Top" title="回到顶部"> -->
    </div>
  </div>
</template>

<script>
import Card from './card.vue';
import dayjs from 'dayjs';
import API from "@/api";
import { mapGetters } from "vuex";
import _ from 'lodash';
import { DOM } from '@/utils/dom.js';
import Bus from '@/assets/ligature.js';
export default {
  inject: ["g_cesium_layer", "change_page"],
  components: {
    Card,
  },
  data () {
    return {
      activeName: 'first',
      form: {
        input: '',
        date: '',
      },
      panelFlag: 0,
      cardData: [],
      panel: {
        timeShow: false,
        unitShow: false,
        executeShow: false,
        timeLabel: '时间',
      },
      search: {
        // name: '',
        pageNo: 1,
        pageSize: 10,
        // organId: '',
        // done: null, // true 已完成, false 未完成
      },
      toneList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      unitList: [],
      trangleTimer: null,
      safeSelectFlag: -1, //执行状态下拉框选中标志
      executeStatusTitle: '执行状态',
      loading: false,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getUnitList();
      this.getList();
    });

    // Bus.$on('info-sidebar-selected', v => {
    //   if (v == 3) {
    //     this.$nextTick(() => {
    //       this.getList();
    //     });
    //   }
    // });

    // 鼠标滚动
    let dom = document.getElementById('realTimeContainerBody');
    let domScroll = document.getElementById('scrollToTopRT');
    dom.addEventListener('scroll', () => {
      let clientHeight = dom.clientHeight;
      let scrollTop = dom.scrollTop;
      let scrollHeight = dom.scrollHeight;
      domScroll.style.top = `${scrollTop + clientHeight - 50}px`;
      domScroll.style.display = 'block';

      if (scrollTop == 0) {
        domScroll.style.display = 'none';
      }

      if (Math.abs(parseInt(clientHeight + scrollTop) - scrollHeight) < 2) {
        // console.log('竖向滚动条已经滚动到底部')
        // 再次请求新的数据
        this.getList('scroll');
      }
    });
  },
  methods: {
    /**
     *@Description: 分页
     *@Date: 2023-11-06 15:03:23
     *@Params1: 
     *@Return1: 
    */
    handleSizeChange_station (v) {
      this.getList();
    },
    handleClick (v) {
      console.log(v);
    },
    // 点击搜索框的三个筛选按钮 flag 0 时间， 1 单位， 2 执行状态
    panelClick (key) {
      // this.panel.timeShow = false;
      // this.panel.unitShow = false;
      // this.panel.executeShow = false;
      switch (key) {
        case 0:
          this.panel.unitShow = false;
          this.panel.executeShow = false;
          this.panel.timeShow = !this.panel.timeShow;
          if (this.panel.timeShow) { this.$refs.formDatePicker.focus(); } else { this.$refs.formDatePicker.blur() }
          break;
        case 1:
          this.panel.timeShow = false;
          this.panel.executeShow = false;
          this.panel.unitShow = !this.panel.unitShow;




          // if (this.panel.unitShow) {
          //   this.$refs.unitCascaderPanel.focus();
          // } else {
          //   this.$refs.unitCascaderPanel.blur()
          // }
          break;
        case 2:
          this.panel.timeShow = false;
          this.panel.unitShow = false;
          this.panel.executeShow = !this.panel.executeShow;
          break;
      }

    },
    // 日期组件获取焦点
    datePickerFocus () {
      console.log('日期组件获取焦点');
    },
    // 搜索时间变化
    formDatePickerChange (v) {
      console.log(v, '搜索时间');
      // 时间确定之后，要调整时间箭头
      this.panel.timeShow = false;
      let a = dayjs(v[0]).format('YYYY/MM/DD');
      let b = dayjs(v[1]).format('YYYY/MM/DD');
      this.panel.timeLabel = a + '~' + b;
      // console.log(this.form.date, '==========================');
    },
    // 获取实时调度列表
    async getList () {
      if (this.form.date != '') {
        this.search.start = dayjs(this.form.date[0]).format('YYYY-MM-DD');
        this.search.end = dayjs(this.form.date[1]).format('YYYY-MM-DD');
        if (this.search.start == this.search.end) {
          this.search.start = null;
          this.search.end = null;
        }
      } else {
        this.search.start = null;
        this.search.end = null;
      }
      this.search = Object.assign({}, this.search, this.pagination);
      let res = await API.TONE.getSettingPage(this.search);
      // console.log(res, '实时调度')
      this.pagination.totalCount = parseInt(res.totalCount);
      this.pagination.pageNo = parseInt(res.pageNo);

      let temp = res.data || [];
      this.toneList = temp;
      this.loading = false;
    },
    async getUnitList () {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    cascaderVisibleChange (v) {
      console.log(v, '');
      // let timer = null;
      setTimeout(() => {
        let dom = document.querySelectorAll('.input-orgname .el-input__suffix');
        console.log(dom, 'domdom');
        let d = dom[2];
        if (v) {
          d.style.transform = "rotate(0deg)";
          this.trangleTimer = setInterval(() => {
            d.style.transform = "rotate(0deg)";
            console.log('11111111');
          }, 20);
        } else {
          clearInterval(this.trangleTimer);
          setTimeout(() => {
            d.style.transform = "rotate(180deg)";
          }, 50)
        }
      }, 100);
    },
    cascaderNodeChange (v) {
      clearInterval(this.trangleTimer);
    },
    // 执行状态
    safeClickHandle (v) {
      this.safeSelectFlag = v;
      this.panel.executeShow = false;
      if (v == 0) {
        this.search.done = true;
        this.executeStatusTitle = '已完成';
      } else {
        this.search.done = false;
        this.executeStatusTitle = '未完成';
      }
    },
    // 回到顶部
    scroll2Top () {
      let dom = document.getElementById('realTimeContainerBody');
      dom.scrollTop = 0;
      setTimeout(() => {
        let domScroll = document.getElementById('scrollToTopRT');
        domScroll.style.display = 'none';
      }, 100);
    },
    // 点击搜索按钮
    searchClick () {
      console.log(this.search);
      console.log(this.form);
      this.search.pageNo = 1;
      this.search.pageSize = 10;
      this.getList();
    },
    // 清除搜索框事件
    clearSearchInput () {
      this.search.name = '';
      this.search.pageNo = 1;
      this.search.pageSize = 10;
      this.getList();
    },
    // 重置搜索
    reset () {
      this.search.name = '';
      this.search.pageNo = 1;
      this.search.pageSize = 10;
      // 时间
      this.panel.timeLabel = '时间';
      this.form.date = '';
      // 责任单位
      delete this.search.organId;
      // 执行状态
      delete this.search.done;
      this.executeStatusTitle = '执行状态';
      this.getList();
    },
    /**
     *@Description: 新建 开始 再次 点调
     *@Date: 2023-10-11 10:54:32
     *@Params1: type 0 新建，1 开始，2 再次 点调， val id
     *@Return1: 
    */
    addTone (type, val) {
      this.$emit("addTone", type, val);
    },
    /**
     *@Description: 点击卡片的点调
     *@Date: 2023-10-11 13:48:12
     *@Params1: 
     *@Return1: 
    */
    sonClick (type, id) {
      console.log(type, id, id);
      this.$emit("sonClick", type, id);
    }
  },
}
</script>

<style lang="scss" scoped>
.real-time-container {
  width: 100%;
  height: 100%;
  color: #fff;

  .header {
    width: 100%;
    height: 54px;
    background: #224D68;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;

    .title {
      color: #fff !important;
    }

    .left {
      height: 100%;
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .img {
        height: 28px;
        width: 28px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: blue;

        box-sizing: border-box;
      }

      .img1,
      .img2 {
        height: 26px;
        width: 26px;
        padding: 5px;
        border-radius: 50%;
        background-color: #4E68A2;
      }

      .img2 {
        height: 26px;
        width: 26px;
        padding: 4px;
        border-radius: 50%;
        background-color: #4E68A2;
      }

      .img3 {
        height: 26px;
        width: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #4E68A2;
        cursor: pointer;
      }
    }
  }

  .form {
    width: 100%;
    height: 121px;
    background: #133E55;
    padding: 5px 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    .search {
      width: 100%;
      height: 40%;
      // background-color: #00F5FF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      // border: 1px solid yellow;

      .input-search {
        width: 85%;
        background-color: unset;
      }

      .btn {
        height: 40px;
        background: #4E87AC;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      .btn1 {
        width: 40px;
      }

      .btn2 {
        width: 78px;
      }

      .btn3 {
        width: 68px;
      }
    }

    .line {
      height: 30%;
      width: 100%;
    }

    .line2 {
      height: 30%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .input-orgname-fly-task {
        width: 40%;
      }

      .date-panel-picker {
        width: 58%;

        /deep/ .el-range-input {
          background-color: transparent;
          color: #C0C4CC;
        }

      }

      /deep/ .el-range-separator {
        color: #C0C4CC;
        margin-top: 9px;
      }

    }

    .line3 {
      height: 30%;
      // border: 1px solid yellow;
    }

    /deep/ .el-input__inner {
      color: #fff;
      padding: 0 10px;
      box-sizing: border-box;
    }

  }

  .body {
    // 54+121=175
    box-sizing: border-box;
    padding: 5px 10px;
    height: calc(100% - 175px);
    width: 100%;
    background-color: rgba(10, 41, 58, 1);
    overflow-y: auto;
    position: relative;

    .card {
      margin-top: 10px;
    }

    #scrollToTopRT {
      position: absolute;
      // bottom: 2%;
      right: 10px;
      z-index: 100;
      width: 28px;
      height: 28px;
      // float: right;
      // margin-top: -50px;
      // top: 300px;
      cursor: pointer;
    }

    .card-body {
      width: 100%;
      height: calc(100% - 30px);
      box-sizing: border-box;
      // border: 1px solid yellow !important;
      overflow-y: auto !important;
    }

    .card-body::-webkit-scrollbar {
      display: none !important;
    }

    .pagation {
      height: 30px;
      box-sizing: border-box;
      padding: 0 0px;
      height: 30px;
      width: 100%;
      background-color: #0E3449;
      padding-top: 5px;
      // border: 1px solid red;

      /deep/ .el-pagination--small {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 0 !important;
      }

      /deep/ .el-pagination__total {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        margin-left: 5px;
      }

      /deep/ .btn-prev {
        background-color: #253B5A !important;
        color: #ffffff;
        width: 22px;
        border-radius: 2px;
      }

      /deep/ .btn-next {
        background-color: #253B5A !important;
        color: #ffffff;
        width: 22px;
        margin-left: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
      }

      /deep/ .el-pager {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;

        .number {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        li {
          margin-left: 2px;
          background-color: #253B5A !important;
          color: #ffffff;
          border-radius: 2px;
        }

        li.active {
          color: #FFFFFF;
          background-color: #409EFF !important;
        }

        li:hover {
          border: 1px solid #367DB4;
          background-color: #0C3549 !important;
        }
      }

      /deep/ .el-pagination__sizes .el-select--mini {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .el-input__inner {
          color: #ffffff;
        }
      }

      /deep/ .el-pagination__jump {
        margin-left: 5px !important;
        color: #ffffff;
        margin-top: -3px;
      }

      /deep/ .el-pagination__jump .el-input {
        width: 30px !important;
        background-color: #253B5A !important;

        .el-input__inner {
          background-color: #253B5A !important;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "@/styles/wuhuCommon.scss";

.real-time-form-date-picker {
  // margin-top: 230px !important;
  left: 90px !important;
  top: 223px;

  .in-range {
    div {
      background-color: #064989 !important;
    }
  }

  .start-date,
  .end-date {
    span {
      background-color: #0D224F !important;
    }
  }

  .popper__arrow {
    display: none;
  }

  .el-picker-panel__body-wrapper {
    border: 0;
  }
}

.input-orgname {
  .el-input__inner {
    background-color: transparent !important;
    color: #fff;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers，webkit内核浏览器 */
    color: #fff;
  }

  .el-input {
    display: relative;

    .el-input__suffix {
      position: absolute;
      top: 16px;
      right: 10px;
      height: 9px;
      width: 13px;
      background-image: url('~@/assets/img/i62.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }

  .el-input__suffix-inner {
    background-color: #fff;
    height: 10px !important;
    width: 10px !important;

    i {
      display: none;
    }
  }
}

.unit-cascader-drop-panel {
  background-image: url('~@/assets/img/i101.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 0 !important;
  margin: 0 !important;



  .el-scrollbar {
    background-color: transparent;
    border: 0;
    border-right: 1px solid #223E4D;
  }

  .el-scrollbar:last-child {
    border: 0 !important;
  }

  .el-cascader-node {
    background-color: transparent;
  }

  .el-cascader__dropdown {
    box-shadow: unset;
    margin: 0 !important;
    border: 0 !important;
  }

  .el-scrollbar__view {
    background-color: #0A293A;
  }

  .popper__arrow {
    display: none;
  }

  .el-cascader-node__label {
    color: #fff;
  }

  .el-icon-arrow-right {
    color: #fff;
  }

}
</style>