<template>
  <div class="page-command-flight-task">
    <!-- 飞行任务  不需要  -->
    <FlightTaskList v-show="visible == 1" @handleLocation="flightTask_handle_position" :num="visible" />
    <SituationEdit v-if="visible == 2" @handleLocation="flightTask_handle_position" ref="situationEdit" />
    <SituationResource v-if="visible == 2 && isShow == true" @handleLocation="flightTask_handle_position"
      @acceptSelect="accept_select" />
    <SituationDetail v-if="visible == 3" />
    <VideoMedia v-if="visible == 1 && mediaIsShow" :historyVideo="flightTask_video"
      @changeMediaIsShow="mediaIsShow = false" />
    <!-- 历时图片组件 -->
    <ImageMedia v-if="visible == 5" :historyImage="flightTask_imageList" />
    <TaskCreate :type="state" v-if="visible == 6" ref="taskCreate" />
    <TaskResubmit v-if="visible == 7" />

    <!-- 任务详情组件 -->
    <TaskDetail v-if="visible == 8" />
    <CheckPopup v-if="visible == 9" />

    <!-- ------------------------------结束------------------------------ -->

    <!--------------------------------重新设计 开始 -------------------------------->
    <SlideBar class="slide-bar" @handleSelected="handleSelected"></SlideBar>
    <!-- 智能空域 -->
    <IntelligentAirspace class="panel intelligent-airspace" v-if="panelShowFlag == 0"></IntelligentAirspace>
    <!-- 飞行任务 -->
    <!-- <Transition name="panel-list-fade"> -->
    <FlyTask class="panel fly-task" v-if="panelShowFlag == 1"></FlyTask>
    <!-- </Transition> -->
    <!-- 航空航线 -->
    <!-- <Transition name="panel-list-fade"> -->
    <AirLine class="panel air-line" v-if="panelShowFlag == 2" @showRouteLabel="showRouteLabel"></AirLine>
    <!-- </Transition> -->
    <!-- 实时调度 -->
    <!-- <Transition name="panel-list-fade"> -->
    <RealTime class="panel real-time" v-if="panelShowFlag == 3" @addTone="addTone" @sonClick="sonClick"></RealTime>
    <!-- </Transition> -->
    <!-- 飞行任务、航空航线、实时调度 折叠标志 -->
    <div class="foldFlag" v-if="panelShowFlag != 0" :class="[foldFlag0 ? '' : 'un-foldFlag']" @click="foldFlagFun">
      <img v-if="foldFlag0" class="img" src="@/assets/img/i26.png" alt="">
      <img v-else class="img" src="@/assets/img/i27.png" alt="">
    </div>

    <!-- 航空航线里面 航线标签组件 -->
    <transition name="el-fade-in-linear">
      <RouteLabel :id="routeLabelId" v-if="routeLabelShowFlag" class="route-label transition-box"
        @close="routeLabelShowFlag = false"></RouteLabel>
    </transition>
    <!-- 实时调度里面 新建、编辑点调组件 -->
    <transition name="el-fade-in-linear">
      <ToneForm v-if="spotToneShowFlag" :type="spotToneType" :id="spotToneFormId" @close="spotToneRec"></ToneForm>
    </transition>
    <!-- 实时调度里面 点调组件 -->
    <transition name="el-fade-in-linear">
      <Start v-if="spotToneStartShowFlag" :doneDisable="doneDisable" :id="spotToneStartItemId" @close="closeStart">
      </Start>
    </transition>
    <!-------------------------------- 重新设计 结束 -------------------------------->

  </div>
</template>

<script>
// 飞行任务1027
import FlightTaskList from "@/components/command/flight-task";

import SituationEdit from "@/components/command/flight-task/situation/edit";
import SituationResource from "@/components/command/flight-task/situation/resource";
import SituationDetail from "@/components/command/flight-task/situation/detail";
import VideoMedia from "@/components/command/flight-task/video-media";
// import ImageMedia from "@/components/command/flight-task/image-media";
import ImageMedia from "@/components/command/flight-task/image-media/index-new.vue";
import TaskCreate from "@/components/command/flight-task/create";
import TaskResubmit from "@/components/command/flight-task/resubmit";
import TaskDetail from "@/components/command/flight-task/detail/index-new.vue";
import CheckPopup from "@/components/command/flight-task/check-popup";

import SlideBar from "@/components/command/flight-task/new-components/sideBar.vue";
import IntelligentAirspace from "@/components/command/flight-task/new-components/intelligentAirspace";
import FlyTask from "@/components/command/flight-task/new-components/flyTask";
import AirLine from "@/components/command/flight-task/new-components/airLine";
import RealTime from "@/components/command/flight-task/new-components/realTime";
// 航线标签组件
import RouteLabel from '@/components/command/airway-list/components/routeLabel.vue';
// 实时调度 新建、编辑点调组件
import ToneForm from "@/components/command/spottone/addUpdate";
// 实时调度 点调组件
import Start from "@/components/command/spottone/start";

import data from "./data";
import methods from "./methods";
import Bus from '@/assets/ligature.js';
export default {
  components: {
    FlightTaskList,
    SituationEdit,
    SituationResource,
    SituationDetail,
    VideoMedia,
    ImageMedia,
    TaskCreate,
    TaskResubmit,
    TaskDetail,
    CheckPopup,
    SlideBar,
    IntelligentAirspace,
    FlyTask,
    AirLine,
    RealTime,
    RouteLabel, ToneForm, Start,
  },
  data () {
    return {
      ...data,
      visible: -1,
      isShow: false,
      mediaIsShow: false,
      state: null,
      panelShowFlag: 0,
      routeLabelShowFlag: false,
      routeLabelId: '',
      // 新建、编辑点调组件显示
      spotToneShowFlag: false,
      spotToneType: 0,
      spotToneFormId: '',
      // 点调组件
      spotToneStartShowFlag: false,
      doneDisable: true,
      spotToneStartItemId: '',
      foldFlag0: true,

    };
  },
  provide () {
    return {
      close_history_img_model: this.closeHistoryImgModel,
      flight_task_chage_view: this.chage_view,
      handle_video_Player: this.flightTask_handleVideoPlayer,
      handle_image: this.flightTask_handleImage,
      handle_air_line_preview: this.flightTask_handleAirLinePreview,
      handle_location: this.flightTask_handleLocation,
      handle_histoty_track: this.flightTask_handleHistotyTrack,
      change_cate_id: this.change_cate_id,
      change_media_is_show: this.change_media_is_show,
    };
  },
  inject: ["g_cesium_toolbar", "g_cesium_layer"],
  mounted () {
    Bus.$on('info-sidebar-selected', (v) => {
      // console.log('飞行任务', '清除图上智能空域绘制的东西');
      // 定位图标
      this.flightTask_clearHandleLocation();
      // 航线预览
      this.flightTask_clearHandleAirLinePreview();
    });
    // Bus.$on('info-sideBar-lock', (v) => {
    //   this.panelShowFlag = v;
    // });
    let { page, item } = this.$route.query;
    if (item == '安全管制') {
      this.$nextTick(() => {
        Bus.$emit('airspace-info-show');
      });
      return;
    }
    if (item) {
      let title = JSON.parse(item).title;
      // console.log(title, 'title');
      if (title == '航空航线') {
        this.panelShowFlag = 2;
        Bus.$emit('info-slider-flag', 2);
        // Bus.$emit('info-sidebar-selected', 2);
      }
      if (title == '实时调度') {
        this.panelShowFlag = 3;
        Bus.$emit('info-slider-flag', 3);
      }
    }
    Bus.$on('intelligentAirspaceFlod', (v) => {

    });
  },
  created () {

  },
  methods: {
    ...methods,
    closeHistoryImgModel () {
      this.visible = -1;
    },
    chage_view (item, isShow, state) {
      if (state) {
        this.isShow = isShow;
        if (isShow === false && this.$refs.situationEdit) {
          this.$refs.situationEdit.checked2 = false;
        }
      } else {
        this.visible = item;
      }
    },
    change_cate_id (num) {

      let _this = this;
      this.state = num
      this.$nextTick(() => {
        if (_this.$refs.taskCreate) {
          _this.$refs.taskCreate.form.cateId = num;
        }
      });
    },
    change_media_is_show (status) {
      this.mediaIsShow = status;
    },
    accept_select (arr, resourceName) {
      if (this.$refs.situationEdit) {
        this.$refs.situationEdit.acceptSelectFn(arr, resourceName);
      }
    },
    // 侧边栏子组件点击事件
    handleSelected (v) {
      // console.log(v, '侧边栏子组件');
      // console.log(this.panelShowFlag, 'this.panelShowFlag');
      // 清空图上的东西
      // if (this.panelShowFlag != v) {
      //   // 智能空域清除

      //   // 飞行任务清除
      //   // 航空航线清除
      //   // 实时调度清除
      // }
      this.panelShowFlag = v;
      Bus.$emit('info-sidebar-selected', v);
    },
    /**
     *@Description: 航空航线 航线标签
    *@Date: 2023-10-10 17:01:32
    *@Params1: 
    *@Return1: 
    */
    showRouteLabel (item) {
      this.routeLabelShowFlag = true;
      this.routeLabelId = item.id;
    },
    /**
     *@Description: 新建点调
    *@Date: 2023-10-11 10:58:23
    *@Params1: 
    *@Return1: 
    */
    addTone (type, val) {
      console.log(type, 'type');
      console.log(val, 'val');
      this.spotToneType = type;
      this.spotToneFormId = val;
      this.spotToneShowFlag = true;
    },
    /**
     *@Description: 关闭新建、编辑点调组件
    *@Date: 2023-10-11 13:49:01
    *@Params1: 
    *@Return1: 
    */
    spotToneRec () {
      this.spotToneShowFlag = false;
    },
    /**
     *@Description:查看、开始点调
    *@Date: 2023-10-11 14:50:49
    *@Params1: 
    *@Return1: 
    */
    sonClick (type, id) {
      console.log('type', type);
      console.log('id', id);
      this.spotToneStartShowFlag = true;
      this.spotToneStartItemId = id;
      if (type == 1) {
        this.doneDisable = false;
      } else {
        this.doneDisable = true;
      }
    },
    /**
     *@Description: 关闭点调组件
    *@Date: 2023-10-11 14:50:20
    *@Params1: 
    *@Return1: 
    */
    closeStart () {
      this.spotToneStartShowFlag = false;
    },
    /** 飞行任务，航空航线，实时调度 折叠
     *@Description:
    *@Author: name 
    *@Date: 2023-11-20 17:00:27
    *@Params1: 
    *@Return1: 
    */
    foldFlagFun () {
      this.foldFlag0 = !this.foldFlag0;
      console.log(this.panelShowFlag, 'this.panelShowFlag1');
      if (this.panelShowFlag == 1 || this.panelShowFlag == -1) {
        if (this.panelShowFlag == 1) {
          this.panelShowFlag = -1;
        } else {
          this.panelShowFlag = 1;
        }
      }
      if (this.panelShowFlag == 2 || this.panelShowFlag == -2) {
        if (this.panelShowFlag == 2) {
          this.panelShowFlag = -2;
        } else {
          this.panelShowFlag = 2;
        }
      }
      if (this.panelShowFlag == 3 || this.panelShowFlag == -3) {
        if (this.panelShowFlag == 3) {
          this.panelShowFlag = -3;
        } else {
          this.panelShowFlag = 3;
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.page-command-flight-task {
  width: 100%;
  height: 100%;

  .slide-bar {
    background: #0E3042;
    position: absolute;
    top: 64px;
    left: 0;
    height: calc(100% - 64px);
    width: 82px;
  }

  .panel {
    width: 410px;
    background: #0A293A;
    position: absolute;
    top: 64px;
    left: 82px;
    height: calc(100% - 64px);
    // pointer-events: none;
  }

  .foldFlag {
    position: fixed;
    top: 120px;
    left: 492px;
    height: 30px;
    width: 24px;
    background-color: #214D68;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all;

    .img {
      width: 20px;
      height: 20px;

    }
  }

  .un-foldFlag {
    left: 82px;
  }

  .route-label {
    position: absolute;
    top: 20%;
    left: calc(50% - 117px);
  }

  /** 飞行器列表显隐动画 **/
  @keyframes panel-list-comein {
    0% {
      transform: translateX(-500px)
    }

    100% {
      transform: translateX(0)
    }
  }

  @keyframes panel-list-comeout {
    0% {
      transform: translateX(0px)
    }

    100% {
      transform: translateX(-500px)
    }
  }

  .panel-list-fade-enter-active {
    animation: panel-list-comein 0 ease;
  }

  .panel-list-fade-leave-active {
    animation: panel-list-comeout .6s ease;
  }
}
</style>