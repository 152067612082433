<template>
  <div class="cpt-command-device-status">
    <!-- <div class="hd-box">设备工作状态统计</div> -->
    <div class="flexs justify-between items-center">
      <div class="flexs height-">
        <div class="waikuang" @click="feixing">
          <i class="icon iconfont icon-kekongwurenji" style="font-size: 16px; margin-right: 4px" />
          <span>无人机飞行统计</span>
        </div>
        <div class="waikuang active">
          <img src="~@/assets/images/device/device_icon1.svg" style="width: 16px; height: 16px; margin-right: 5px" />
          <span>设备工作管理状态</span>
        </div>
      </div>
      <div class="search-box">
        <el-cascader v-model="filter.organizationId" :options="options" clearable :show-all-levels="false"
          placeholder="请选择单位" :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"></el-cascader>
        <el-button @click="handle_search" class="btn">搜索</el-button>
      </div>
    </div>
    <div class="bd-box">
      <div class="bd-box-chart">
        <div class="title">无人机工作状态统计</div>
        <!-- <div id="main" style="height: 500px; margin: 0 auto;width:500px"></div> -->
        <div id="drone_container"></div>
      </div>
      <div class="bd-box-chart">
        <div class="title">机巢工作状态统计</div>
        <div id="nest_container" class="cp"></div>
      </div>
      <div class="bd-box-chart">
        <div class="title">无人机执行任务统计</div>
        <div id="task_container" class="cp"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import { mapGetters } from "vuex";

import API from "@/api";
import { point } from "@turf/helpers";
import Bus from '@/assets/ligature'
export default {
  data () {
    return {
      dataDrone: [
        { name: "在线数量", value: 0 },
        { name: "离线数量", value: 0 },
      ],
      dataNest: [
        { name: "在线数量", value: 0 },
        { name: "离线数量", value: 0 },
      ],
      dataTask: [
        { name: "常态任务", value: 0 },
        { name: "临时任务", value: 0 },
        { name: "警情任务", value: 0 },
      ],
      chart: null,
      filter: {
        organizationId: null,
      },
      options: [],
      drone_container: null,
      nest_container: null,
      task_container: null,
      // chart:null,//hightcharts图
    };
  },
  async mounted () {
    // this.drawChart();
    this.filter.organizationId = this.user_info.departmentId;
    await this.getStatusInfo();
    await this.getOptions();

    Bus.$emit('work-manage', { dataDrone: this.dataDrone, dataNest: this.dataNest, dataTask: this.dataTask });


    this.init(this.dataDrone, "drone_container");
    this.init(this.dataNest, "nest_container");
    this.init(this.dataTask, "task_container");
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  methods: {
    // 切换到飞行管理
    feixing () {
      this.$parent.circle_diagram_statistics();
    },

    async handle_search () {
      await this.getStatusInfo();
      this.init(this.dataDrone, "drone_container");
      this.init(this.dataNest, "nest_container");
      this.init(this.dataTask, "task_container");
    },
    async getOptions () {
      console.log("是否启动");
      let res = await API.DEPARTMENT.Drd();
      this.options = [res] || [];
    },
    async getStatusInfo () {
      let res = await API.DEVICE.StatusInfo(this.filter);
      this.dataDrone[0].value = res.device.onlineDevice;
      this.dataDrone[1].value = res.device.offlineDevice;

      this.dataNest[0].value = res.nest.onlineDevice;
      this.dataNest[1].value = res.nest.offlineDevice;

      this.dataTask[0].value = Number(res.normality.data);
      this.dataTask[1].value = Number(res.temping.data);
      this.dataTask[2].value = Number(res.jingqing.data);
    },
    init (data, dataName) {
      let _this = this;
      if (!this[dataName]) {
        const chart = (this[dataName] = new Chart({
          container: dataName,
          autoFit: true,
          width: 400,
          height: 180,
          // cursor:point
        }));

        chart.data(data);

        chart.coordinate("theta", {
          radius: 0.75,
        });

        chart.tooltip({
          showTitle: false,
          showMarkers: false,
        });

        chart.legend({
          position: "left",
          itemName: {
            style: {
              fill: "#fff",
            },
          },
        });

        chart
          .interval()
          .adjust("stack")
          .position("value")
          .color("name", ["#5b8ff9", "#56cea0", "#ea9D61"]);

        chart.interaction("element-active");
        chart.render();
        chart.interaction("element-single-selected", {
          start: [{ trigger: "element:mousemove", action: "cursor:pointer" }],
          end: [{ trigger: "element:mouseleave", action: "cursor:default" }],
        });
        chart.on("element:click", async (ev) => {
          console.log("---------ev", ev);
          let title = ev.view.ele.id;
          const obj = {
            drone_container: 11,
            nest_container: 12,
            task_container: 13,
          };
          if (ev?.data?.data) {
            let {
              data: { data },
            } = ev;
            if (title == "drone_container") {
              data.type = 1;
            }
            if (title == "nest_container") {
              data.type = 2;
            }
            _this.$emit(
              "clickChart",
              obj[title],
              data,
              this.filter.organizationId
            );
          }
        });
      } else {
        this[dataName].data(data);
        this[dataName].render();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-device-status {
  height: 318px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  padding: 16px 16px 34px;
  box-sizing: border-box;

  .hd-box {
    height: 56px;
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
    line-height: 70px;
    padding-left: 92px;
    overflow: hidden;
  }

  .search-box {
    display: flex;

    .btn {
      margin-left: 15px;
    }

    ::v-deep {
      .el-button {
        width: 64px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("~@/assets/images/monitoring/btn_active1.png");
        font-family: PangMenZhengDao;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #d2d9ff;
        border-radius: 2px;
        border-width: 0;
        opacity: 1 !important;

        &:hover {
          opacity: 0.7 !important;
        }
      }

      .el-input__inner {
        background: #000000;
        border: 1px solid #00aeff;
        font-family: MicrosoftYaHeiUI;
        font-size: 16px;
        color: #00aeff;
        border-radius: 0;
      }
    }

    .el-cascader ::v-deep {
      width: 170px;
      height: 32px;
      transform: translateY(-4px);

      input {
        height: 32px;
        font-size: 14px;
        color: RGBA(121, 132, 152, 1);
        background: #02173d;
        border-radius: 2px;
        border: 1px solid #315ec7;
        box-sizing: border-box;
      }
    }
  }

  .bd-box {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;

    .bd-box-chart {
      text-align: center;

      .title {
        font-size: 24px;
        font-family: LCDBold;
        color: #01a8ff;
        line-height: 22px;
        letter-spacing: 1px;
      }
    }
  }
}

.flexs {
  display: flex;
}

.waikuang {
  height: 32px;
  border: 1px rgba(49, 94, 199, 1) solid;
  border-radius: 3px;
  background-color: rgba(2, 23, 61, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2d9ff;
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;

  &.active {
    background-image: url("~@/assets/images/monitoring/btn_active.png");
    background-size: 100% 100%;
  }

  &:hover {
    background-image: url("~@/assets/images/monitoring/btn_active.png");
    background-size: 100% 100%;
  }
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.btn-start {
  background: rgba(20, 45, 92, 0.6);
  box-shadow: 1px 1px 2px 0px rgba(3, 16, 50, 0.5);
  border-radius: 6px;
  opacity: 0.6;
  border: 1px solid #43deff;
}

.xian {
  width: 100%;
  height: 1px;
  background: linear-gradient(207deg,
      rgba(36, 146, 252, 0) 0%,
      #2492fc 53%,
      rgba(36, 146, 252, 0) 100%);
  margin: 16px 0 0 0;
}

#drone_container {
  width: 200px;
}

.imgs {
  width: 24px;
  height: 24px;
}

/deep/ .el-button {
  font-family: MicrosoftYaHei !important;
  color: #dce9ff !important;
}</style>
