<!-- 设备管理 -->
<!-- 设备出入库统计 -->
<template>
  <div class="cpt-command-device-library">
    <div class="flexs justify-between items-center my-header" v-show="true">
      <div class="flexs height">
        <div class="waikuang active " @click="sbcrkClickHandle" :class="[flag == 0 ? 'waikuang-selected' : '']">
          <!-- <img src="~@/assets/images/equipmentImges/sbcrktj.png" style="width: 16px; height: 16px; margin-right: 4px"
            alt="" /> -->
          <span>设备出入库统计</span>
        </div>
        <div class="waikuang" @click="Statistics" :class="[flag == 1 ? 'waikuang-selected' : '']">
          <!-- <img src="~@/assets/images/equipmentImges/sbzttj.png" style="width: 15px; height: 15px; margin-right: 4px"
            alt="" /> -->
          <span>设备状态统计</span>
        </div>
      </div>
      <div class="header">
        <!-- <div class="hd-box">设备出入库统计</div> -->
        <el-select popper-class="popper-select-panel-info-person" v-model="type" class="input-excute"
          placeholder="请选择设备类型">
          <el-option label="无人机" :value="1"></el-option>
          <el-option label="机巢" :value="2"></el-option>
          <el-option label="挂载" :value="3"></el-option>
        </el-select>
      </div>
    </div>
    <div class="bd-box-chart">
      <!-- <div id="library_container"></div> -->
      <div class="item" v-for="(item, index) in libraryData" :key="index" @click="itemClickHandle(item)">
        <div class="title">{{ item.name }}</div>
        <div class="mydot" :style="{ left: item.left }"></div>
        <div class="mydot-out" :style="{ left: item.leftOut }"></div>
        <div class="lowbg"></div>
        <div class="frontbg" :style="{ width: item.leftOut }"></div>
        <div class="value">{{ item.count }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import API from "@/api";
export default {
  data () {
    return {
      libraryData: [],
      type: 1,
      chart: null,
      flag: 0, //设备出入库统计 0，设备状态统计 1
    };
  },
  async mounted () {
    await this.getLibraryInfo();
    let arr = this.libraryData.reverse();
    this.init(arr, "library_container");
  },
  watch: {
    async type (newVal) {
      await this.getLibraryInfo();
      this.chart.destroy();
      let arr = this.libraryData.reverse();
      this.init(arr, "library_container");
    },
  },
  methods: {
    Statistics () {
      this.$parent.Equipment();
      this.flag = 1;
    },
    async getLibraryInfo () {
      let { type } = this;
      let res = await API.DEVICE.LibraryInfo({ type: type });
      this.libraryData = res;
      // console.log(this.libraryData, 'libraryDatalibraryDatalibraryDatalibraryData');
      let sum = 0;
      for (let i = 0; i < this.libraryData.length; i++) {
        sum += parseInt(this.libraryData[i].count);
      }
      for (let i = 0; i < this.libraryData.length; i++) {
        this.libraryData[i].left = ((parseInt(this.libraryData[i].count) / sum) * 100 + 0.95) + '%';
        this.libraryData[i].leftOut = ((parseInt(this.libraryData[i].count) / sum) * 100) + '%';
        // console.log(this.libraryData[i].left, 'item.left');
      }

    },
    init (data, dataName) {
      let _this = this;
      // let count_arr = []
      // let tickInterval = 2
      // data.forEach((item) => {
      //   count_arr.push(item.count)
      // })
      // let max_count = Math.max(...count_arr)
      // if (max_count <= 10) {
      //   tickInterval = 2
      // } else if (max_count > 10 && max_count <= 20) {
      //   tickInterval = 4
      // } else if (max_count > 20 && max_count <= 30) {
      //   tickInterval = 6
      // } else {
      //   tickInterval = 8
      // }

      const chart = (this.chart = new Chart({
        container: dataName,
        autoFit: true,
        height: 250,
        appendPadding: [0, 25, 0, 0], // 上，右，下，左
      }));
      chart.data(data);

      chart.axis("name", {
        title: null,
        tickLine: null,
        line: null,
      });

      chart.scale("count", {
        nice: true,
        alias: "数量",
      });

      chart.axis("count", {
        label: null,
        title: {
          offset: 30,
          style: {
            fontSize: 12,
            fontWeight: 300,
            fill: "#fff",
          },
        },
        grid: null,
      });
      chart.legend(false);
      // chart.legend({
      //   // position: 'top',
      //   itemName: {
      //     style: {
      //       fill: '#fff',
      //     },
      //   },
      // })
      chart.coordinate().transpose();
      chart
        .interval({
          background: {
            style: {
              radius: 20,
              fill: "#07032C",
            },
          },
        })
        .position("name*count")
        .size(20)
        .label("count", {
          style: {
            fill: "#fff",
          },
          offset: 10,
        })
        .color("l(0) 0:#068CFF 1:#8F10FF")
        .style({ radius: [20, 20, 20, 20] });
      chart.interaction("element-active");
      chart.interaction("element-single-selected", {
        start: [{ trigger: "element:mousemove", action: "cursor:pointer" }],
        end: [{ trigger: "element:mouseleave", action: "cursor:default" }],
      });
      chart.render();

      chart.on("element:click", async (ev) => {
        if (ev?.data?.data) {
          let {
            data: {
              data: { id, status },
            },
          } = ev;
          // console.log(id, status,"发送数据")  1无人机 2机巢 3挂载
          _this.$emit("handleChart", id, status);
        }
      });
    },
    /**
     *@Description:设备出入库标题点击
     *@Date: 2023-10-24 10:40:13
     *@Params1: 
     *@Return1: 
    */
    sbcrkClickHandle () {
      this.flag = 0;
    },
    /**
     *@Description: 点击每一个条目
     *@Date: 2023-10-24 11:12:36
     *@Params1: 
     *@Return1: 
    */
    itemClickHandle (item) {
      console.log(item, '发送数据');
      this.$emit("handleChart", item.id, item.status);
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-device-library {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  // height: 318px;
  // background: rgba(12, 34, 73, 0.7);
  // border-radius: 10px;
  // border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  // padding: 16px;
  padding: 0;
  display: flex;
  flex-direction: column;
  // border: 1px solid red;

  .header {
    // display: flex;
    // justify-content: space-around;
    // margin-top: 25px;
    // padding-left: 50px;
  }

  .my-header {
    height: 36px;
    width: 100%;

    // background-color: #00F5FF;
    .flexs {
      width: 100%;
      height: 100%;
    }
  }

  .hd-box {
    font-family: PangMenZhengDao;
    font-size: 22px;
    color: #dce9ff;
    font-weight: 400;
    // border: 1px solid #06E2FF;
  }

  ::v-deep {
    .el-select {
      .el-input__inner {
        width: 170px;
        height: 32px;
        background: #02173d;
        border-radius: 2px;
        border: 1px solid #315ec7;
      }

      .el-select__caret {
        line-height: 32px;
      }
    }
  }
}

.bd-box-chart {
  // height: 388px;
  // margin-top: 12px;
  overflow: auto;
  // border: 1px solid yellow;
  height: calc(100% - 36px);
  padding: 15px 30px 0 30px;
  box-sizing: border-box;

}

#library_container {
  height: 600px;
}

.flexs {
  display: flex;
  padding: 0px 7px;
  box-sizing: border-box;
}

.waikuang {
  height: 36px;
  // border: 1px rgba(49, 94, 199, 1) solid;
  // border-radius: 3px;
  // background-color: rgba(2, 23, 61, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #d2d9ff;
  cursor: pointer;
  // padding: 0 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 14px;
  // border: 1px solid yellow;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #9197A6;
  width: 40%;
  background-image: url('~@/assets/img/i128.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &.active {
    // background-image: url("~@/assets/images/monitoring/btn_active.png");
    // background-size: 100% 100%;
    color: #ffffff;
  }

  &:hover {
    // background-image: url("~@/assets/images/monitoring/btn_active.png");
    // background-image: url("~@/assets/images/monitoring/btn_active.png");
    // background-size: 100% 100%;
  }
}

.waikuang-selected {
  background-image: url('~@/assets/img/i65.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.btn-start {
  background: rgba(20, 45, 92, 0.6);
  box-shadow: 1px 1px 2px 0px rgba(3, 16, 50, 0.5);
  border-radius: 6px;
  opacity: 0.6;
  // border: 1px solid #43deff;
}

.xian {
  width: 100%;
  height: 1px;
  background: linear-gradient(207deg,
      rgba(36, 146, 252, 0) 0%,
      #2492fc 53%,
      rgba(36, 146, 252, 0) 100%);
  margin: 16px 0 0 0;
}

.imgs {
  width: 24px;
  height: 24px;
}

.bd-box-chart {
  .item {
    height: 48px;
    width: 100%;
    // background-color: #2492fc;
    position: relative;
    // border: 1px solid yellow;
    cursor: pointer;

    .title {
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }

    .lowbg {
      position: absolute;
      top: 25px;
      left: 0;
      width: 100%;
      height: 5px;
      background: #FFFFFF;
      opacity: 0.2;
    }

    .frontbg {
      position: absolute;
      top: 25px;
      left: 0;
      width: 80%;
      height: 5px;
      // background: #FFFFFF;
      background: linear-gradient(to right, #1365DB 0, #126EDE 20%, #0D9EEB 40%, #09C1F6 60%, #06E2FF 100%, );

    }

    .mydot {
      position: absolute;
      width: 12px;
      height: 12px;
      top: 20px;
      // left: 10.4%;
      // left: 6.53147% !important;
      // left: 6.45147%;
      background-color: #00F5FF;
      border-radius: 50%;
      z-index: 100;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 101;
    }

    .mydot-out {
      position: absolute;
      width: 22px;
      height: 22px;
      z-index: 99;
      top: 15px;
      left: 11%;
      background-color: #00F5FF;
      border-radius: 50%;
      z-index: 100;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      // background: radial-gradient(circle, red, yellow);
      background: #00F5FF;
      opacity: 0.4;
    }

    .value {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #00F5FF;
    }
  }
}
</style>
